import styled from 'styled-components';
import commonStyles, { respondTo } from '../commonStyles';

export const AddressFields = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & .warning_message {
    color: ${commonStyles.colors.warning};
    margin-bottom: 16px;
    font-size: 12px;
    margin-left: 32px;
  }
`;

export const PlaceInputBlockWrapper = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const PlaceInputWrapper = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  position: relative;

  & > .places_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    min-height: 40px;
    width: 40px;
    min-width: 40px;
    transition: 0.4s all;
    cursor: pointer;
    border-radius: 5px;
    background: ${commonStyles.colors.white};
    border: 1px solid ${commonStyles.colors.primary};
    margin-left: 12px;
    
    & > .dropdown_icon {
      fill: ${commonStyles.colors.primary};
      height: 20px;
      width: 20px;
    }
    
    &:hover {
      background: ${commonStyles.colors.primary};
      & > .dropdown_icon {
        fill: ${commonStyles.colors.white};
      }
    }
  }
  
  & .delivery-tool_address-field {
    width: 100%;
    margin-bottom: ${({ hasWarning }) => hasWarning ? 0 : 16}px;
  }

  ${respondTo.md`
    & .delivery-tool_address-field {
      margin-bottom: ${({ hasWarning }) => hasWarning ? 0 : 30}px;
    }
  `}
  
  & > .places__dropdown {
    width: 90%;
    left: 0;
    border: none;
    box-shadow: 0 36px 47px -18px rgb(0 29 35 / 15%);

    & > div {
      padding: 10px 20px;

      &:nth-child(odd) {
        background: ${commonStyles.colors.grey}33;
      }

      &:hover {
        background: ${commonStyles.colors.grey};
      }
    }
  }
`;

export const TimeFieldsTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  & .delivery-tool_select-icon {
    margin-right: 12px;
  }

  & .delivery-tool_select-title {
    font-size: 24px;
  }

  ${respondTo.md`
    margin: 0;
  `}
`;

export const TimeFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;

  & .delivery-tool_select-field {
    margin-bottom: 16px;
  }

  ${respondTo.sm`
    flex-direction: row;
    justify-content: space-between;
    & .delivery-tool_select-field {
      width: 200px;
      margin: 0;
    }
  `}
  ${respondTo.md`
    padding-left: 34px;
    & .delivery-tool_select-field {
      $:first-child {
        margin-bottom: 0;
        margin-right: 10px;
      }
    }
  `}
`;

export const TimeFields = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const DeliveryMapContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  height: 180px;
  background: antiquewhite;
  box-shadow: 0px 36px 47px -18px rgba(0, 29, 35, 0.15);
  border-radius: 5px;
  ${respondTo.md`
    width: 316px;
    height: 236px;
  `}
`;

export const DeliveryMapToolContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  min-height: 400px;
  background: antiquewhite;
  box-shadow: 0 36px 47px -18px rgba(0, 29, 35, 0.15);
  border-radius: 5px;
`;

export const BackButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);
  cursor: pointer;
  top: 24px;
  ${respondTo.md`
    top: 40px;
    left: 40px;
  `}
`;

export const DeliveryToolContainer = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 60px;

  & .delivery-tool_section {
    margin-bottom: 40px;

    &:last-child {
      margin: 0;
    }
  }

  & .delivery-tool_field {

  }

  ${respondTo.sm`
    & .delivery-tool_section {
      max-width: 444px;
    }
  `}
  ${respondTo.md`
    display: block;
    padding: 60px 0 80px;
    
    & .delivery-tool_section {
      float: left;
      margin-bottom: 0;
      &:first-child {
        margin-bottom: 44px; 
      }
      &:nth-child(2) {
        float: right;
      }
    }
    &:after {
      content:"";
      display:table;
      clear:both;
    }
  `}
`;

export const MapPictureContainer = styled.div.attrs(() => ({}))`
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;

export const FlagImage = styled.img.attrs(() => ({}))`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 40px;
  width: 40px;
  transform: translateY(-80%);
`;

export const MapContainer = styled.div.attrs(() => ({}))`
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  border-radius: 8px;
  transition: height, width 0.15s;
  position: relative;
`;

export const GeolocationButton = styled.button.attrs(() => ({}))`
  cursor: pointer;
  height: 60px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border: none;
  background-color: ${commonStyles.colors.food};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > svg {
    margin-right: 16px;
  }
`;

export const ApplyPlaceButtonContainer = styled.div.attrs(() => ({}))`
  position: absolute;
  bottom: -85px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
