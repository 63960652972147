import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import serviceTypes from '../../constants/serviceTypes';
import { useStores } from '../../stores/helpers/use-stores';
import commonStyles from '../commonStyles';
import { Text } from '../shared-components';
import { LangButtonContainer } from './LangButton.style';

const languages = {
  de: 'DE',
  en: 'EN'
};

const getNextLang = lng => {
  return lng === 'en' ? 'de' : 'en';
}

const LangButton = props => {
  const { type } = props;
  const { uiStore } = useStores();
  const [color, setColor] = useState();
  const { lang } = uiStore;

  useEffect(() => {
    if ([serviceTypes.MAHLZEIT, serviceTypes.MEDICINE, 'primary'].includes(type)) {
      setColor(commonStyles.colors.white);
    } else {
      setColor(commonStyles.colors.primary);
    }
  }, [type])

  const handleLangSwitch = () => {
    const nextLang = getNextLang(lang);
    uiStore.updateLanguage(nextLang);
  };

  return (
    <LangButtonContainer onClick={handleLangSwitch} color={color}>
      <Text type={'bodySmall'} className={'lang_text'}>{languages[lang]}</Text>
    </LangButtonContainer>
  );
};

LangButton.propTypes = {
  type: PropTypes.string,
}

export default observer(LangButton);
