import styled from 'styled-components';
import commonStyles, { respondTo } from '../../commonStyles';

export const LoyaltyWrapper = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TitleWrapper = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;

  & > .current_status {
    font-weight: 600;

    & > span {
      font-weight: 700;
      color: ${({ color }) => color ?? commonStyles.colors.primary};
    }
  }
`;

export const InfoWrapper = styled.div.attrs(() => ({}))`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  margin-bottom: 8px;
`;

export const LoyaltyBarWrapper = styled.div.attrs(() => ({}))`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  align-self: center;
  margin: 32px 0;
`;

export const LoyaltyItemWrapper = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
`;


export const ItemLabelWrapper = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > .label_name {
    text-transform: uppercase;
    color: ${({ color }) => color ?? commonStyles.colors.primary};
    font-size: 12px;
    ${respondTo.md`
        font-size: 14px;
    `}
  }
`;

export const LoyaltyItemIconWrapper = styled.div.attrs(() => ({}))`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #FFFFFF;
  box-shadow: 0 36px 47px -18px ${commonStyles.colors.alphaString(commonStyles.colors.primary, 0.35)};
  box-sizing: border-box;
  margin-bottom: 4px;

  & > .loyalty_icon {
    height: 30px;
    width: 30px;
    fill: ${({ color }) => color ?? commonStyles.colors.primary};
  }

  ${respondTo.sm`
    height: 60px;
    width: 60px;
    border-radius: 30px;
    & > .loyalty_icon {
      height: 40px;
      width: 40px;
      fill: ${({ color }) => color ?? commonStyles.colors.primary};
    }
  `}
`;

export const LoyaltyBar = styled.div.attrs(() => ({}))`
  width: 30px;
  height: 2px;
  border-radius: 1px;
  background-color: ${commonStyles.colors.alphaString(commonStyles.colors.grey, 0.2)};
  margin: 0 8px 22px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  ${respondTo.sm`
    width: 100px;
    height: 4px;
    border-radius: 2px;
    margin: 0 8px 28px;
  `}
`;

export const Progress = styled.div.attrs(() => ({}))`
  height: 100%;
  width: ${({ widthPercent }) => widthPercent}%;
  background-color: ${({ color }) => color ?? commonStyles.colors.primary};
`;

export const YearlyOrdersWrapper = styled.div.attrs(() => ({}))`
  margin: 32px 0;

  & > .user_orders_number {
    margin-bottom: 8px;

    & > span {
      font-weight: 700;
    }
  }
`;

export const ProgressCircleWrapper = styled.div.attrs(() => ({}))`
  margin: 32px 0;
  align-self: center;
  height: 250px;
  width: 250px;

  ${respondTo.sm`
    height: 400px;
    width: 400px;
  `}
  & > div > div > div .points_number {
    font-weight: 700;
    color: ${({ color }) => color ?? commonStyles.colors.primary};
  }

  & > div > div > div .points_text {
    color: ${({ color }) => color ?? commonStyles.colors.primary};
  }
`;

export const LoyaltySlidesWrapper = styled.div.attrs(() => ({}))`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 50px;
`;

export const LoyaltyBonusRow = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px;
`;

export const LoyaltyBonusIconContainer = styled.div.attrs(() => ({}))`
  display: flex;
  align-items: center;
`;

export const LoyaltyBonusInfoContainer = styled.div.attrs(() => ({}))`
  padding-left: 12px;
`;

export const LoyaltySlideWrapper = styled.div.attrs(() => ({}))`
  width: 350px;
  min-width: 280px;
  box-shadow: 0 36px 47px -18px ${commonStyles.colors.alphaString(commonStyles.colors.primary, 0.35)};
  border-radius: 15px;
  margin: 0 0 32px;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  background: #FFF;
  padding-bottom: 16px;

  ${respondTo.sm`
    margin: 0 32px 32px 0;
  `}
  
  ${respondTo.lg`
    width: 400px;
  `}
  
  &:hover {
    transform: scale(1.05, 1.05);
  }

  & > .loyalty_header {
    text-transform: uppercase;
    color: ${({ color }) => color};
    text-align: center;
    margin: 16px 0 0;
  }

  & > ${LoyaltyBonusRow} > ${LoyaltyBonusIconContainer} > .loyalty_bonus_icon {
    fill: ${({ color }) => color};
    height: 30px;
    width: 30px;
    ${respondTo.sm`
      height: 35px;
      width: 35px;
    `}
  }
`;

