import styled from 'styled-components';
import commonStyles, { isLinuxOrUnixFFOrChrome } from '../commonStyles';

export const BreadcrumbsContainer = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
`;

export const CrumbContainer = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: max-content;
  
  & .crumb-label.icon {
    margin-top: ${isLinuxOrUnixFFOrChrome() ? -8 : -3}px;
    fill: ${({ type, isLast }) => commonStyles.colors.alphaString(type === 'food' ? commonStyles.colors.primary : commonStyles.colors.white, isLast ? 1 : 0.6)};

    &:hover {
      fill: ${({ type }) => type === 'food' ? commonStyles.colors.primary : commonStyles.colors.white};
    }
  }

  & .crumb-label {
    color: ${({ type, isLast }) => commonStyles.colors.alphaString(type === 'food' ? commonStyles.colors.primary : commonStyles.colors.white, isLast ? 1 : 0.6)};

    &:hover {
      text-decoration: underline dotted;
      text-underline-offset: 2px;
      color: ${({ type }) => type === 'food' ? commonStyles.colors.primary : commonStyles.colors.white};
    }
  }

  & .crumb-separator {
    margin: 0 8px;
    fill: ${({ type }) => commonStyles.colors.alphaString(type === 'food' ? commonStyles.colors.primary : commonStyles.colors.white, 0.4)};
  }
`;
