import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../stores/helpers/use-stores';
import NoItemsFound from '../../ItemsList/NoItemsFound';
import { CardsSection } from '../../shared-components';
import OrderCard from './OrderCard';
import OrderInfoModal from './OrderInfoModal';
import { OrderListContainer } from './UserOrders.style';

const initialModalData = {
  visible: false,
  orderData: null
};

const OrderList = () => {
  const { dataStores: { userStore, authStore } } = useStores();
  const [infoModalData, setInfoModalData] = useState(initialModalData);
  const { t } = useTranslation();
  const {
    userOrderList,
    totalUserOrders,
    ordersSearchMeta,
    ordersSortParam,
    userOrderListStatuses
  } = userStore;
  const { loading } = authStore;

  const orderList = useMemo(() => toJS(userOrderList), [userOrderList]);

  useEffect(() => {
    userStore.getUserOrders();
  // eslint-disable-next-line
  }, [ordersSearchMeta.limit, ordersSearchMeta.offset, ordersSortParam.field, ordersSortParam.order, userOrderListStatuses]);

  const showInfoModal = (orderData) => {
    setInfoModalData({
      visible: true,
      orderData
    });
  };

  return (
    <OrderListContainer>
      <CardsSection
        buttonText={orderList.length < totalUserOrders && t('products.showMore')}
        onButtonClick={() => userStore.setOrdersOffset()}
        className="cards__section"
        justifyContent="left"
      >
        {!!orderList?.length && orderList.map((order, idx) =>
          <OrderCard
            key={`order-${order._id}-${idx}`}
            orderData={order}
            onOrderInfo={data => showInfoModal(data)}
          />)}
        {!orderList?.length && <NoItemsFound
          isLoading={loading}
          text={t('userOrders.noOrdersFound')}
        />}
      </CardsSection>
      <OrderInfoModal {...infoModalData} onHide={() => setInfoModalData(initialModalData)} />
    </OrderListContainer>
  );
};

export default observer(OrderList);
