import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { BasicPage, Text, Button } from '../shared-components';
import { Container } from './NotFound.style';
import { useTranslation } from "react-i18next";
import { useStores } from '../../stores/helpers/use-stores';

import { ReactComponent as Brokencart } from '../../assets/svg/broken-cart.svg';

const NotFound = () => {
  const { t } = useTranslation();
  const { dataStores: { servicesStore } } = useStores();

  useEffect(() => {
    servicesStore.setSelectedService('white');
    // eslint-disable-next-line
  }, []);

  return(
    <BasicPage>
      <Container>
        <Brokencart className="not-found__image"/>
        <Text className="not-found__title" type="h2">{t('not-found.title')}</Text>
        <Button href="/" type="food" text={t('not-found.goHome')}/>
      </Container>
    </BasicPage>
  )
};

export default observer(NotFound);
