import React from 'react';
import PropTypes from 'prop-types';
import { StyledLink } from './Link.style';

const LinkView = ({
  children, href, onClick, className,
}) => (
  <StyledLink to={href ?? '/'} onClick={href ? undefined : onClick} className={className}>
    {children}
  </StyledLink>
);

LinkView.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  href: PropTypes.string,
  onClick: PropTypes.func,
};

LinkView.defaultProps = {
  className: undefined,
  href: undefined,
  onClick: undefined,
};

export default LinkView;
