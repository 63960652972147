import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStores } from '../../../stores/helpers/use-stores';
import { Loader } from '../../shared-components';
import DeliveryInfoSection from './DeliveryInfoSection';
import PromosSection from './PromosSection';
import { OrderInfoContainer } from './OrderInfo.style';
import OrderPlaceModal from './OrderPlaceModal';
import PaymentInfoSection from './PaymentInfoSection';

const initialModalState = {
  isVisible: false,
  orderData: null,
  onSubmit: () => {},
  onCancel: () => {},
};

const OrderInfo = props => {
  const history = useHistory();
  const { dataStores: { orderStore, servicesStore } } = useStores();
  const [orderModal, setOrderModal] = useState(initialModalState);
  const { createdOrderData, placingOrder, orderPlaceError } = orderStore;

  useEffect(() => {
    if (createdOrderData) {
      setOrderModal({
        isVisible: true,
        orderData: toJS(createdOrderData),
        onSubmit: () => {
          // Follow order Link
          setOrderModal(initialModalState);
          orderStore.resetCart();
          orderStore.clearCreatedOrder();
          const orderLinkParams = createdOrderData.orderLink.split('/');
          const orderLink = `/order-status/${orderLinkParams[orderLinkParams.length - 1]}`;
          history.push(orderLink);
        },
        onCancel: () => {
          setOrderModal(initialModalState);
          orderStore.resetCart();
          orderStore.clearCreatedOrder();
          history.push('/');
        }
      });
    } else if (orderPlaceError) {
      setOrderModal({
        isVisible: true,
        orderData: null,
        onCancel: () => {
          setOrderModal(initialModalState);
          orderStore.clearCreatedOrder()
        }
      });
    }
    // eslint-disable-next-line
  }, [createdOrderData, orderPlaceError]);

  return (
    <OrderInfoContainer>
      <DeliveryInfoSection />
      <PromosSection />
      <PaymentInfoSection />
      {placingOrder && <Loader type={servicesStore.selectedService}/>}
      <OrderPlaceModal {...orderModal} />
    </OrderInfoContainer>
  );
};

export default observer(OrderInfo);
