import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Analytics } from '../../../helpers/analytics';
import { KEYS } from '../../../keys';
import { useStores } from '../../../stores/helpers/use-stores';
import { PaymentButtonsContainer } from './OrderInfo.style';

const paypalStyle = {
  color: 'blue',
  height: 55,
  layout: 'vertical',
  shape: 'rect',
  tagline: false
};

const PaymentButtons = props => {
  const { dataStores: { orderStore, deliveryModalStore, servicesStore } } = useStores();
  const [paymentData, setPaymentData] = useState(null);
  const { ageConfirmed, dataConfirmed } = props;
  const { priceData, deliveryDate, minimumOrderDifference, orderItems, orderProvider } = orderStore;
  const { validationErrors } = deliveryModalStore;

  const payAmount = useMemo(() => priceData?.totalAmt, [priceData]);

  useEffect(() => {
    if (paymentData) {
      orderStore.placeOrder(paymentData);
      setPaymentData(null);
    }
    // eslint-disable-next-line
  }, [paymentData]);

  const checkIfDisabled = () => {
    return !ageConfirmed ||
      !dataConfirmed ||
      !deliveryDate ||
      minimumOrderDifference > 0 ||
      validationErrors
  }

  if (!payAmount) return null;

  return (
    <PaymentButtonsContainer>
      <PayPalScriptProvider options={KEYS.payPalOptions}>
        <PayPalButtons
          style={paypalStyle}
          disabled={checkIfDisabled()}
          forceReRender={[payAmount]}
          createOrder={(data, actions) => actions.order.create({
            purchase_units: [
              {
                amount: {
                  currency_code: 'EUR',
                  value: payAmount,
                },
              },
            ],
          })
            .then((orderId) => {
              Analytics.beginCheckoutEvent(orderItems, orderProvider, servicesStore.selectedService);
              // Your code here after create the order
              return orderId;
            })
          }
          onApprove={(data, actions) => {
            actions.order.capture().then(() => {
              setPaymentData(data);
              return;
            }).catch((e) => {
              orderStore.orderPlaceLoaded();
              console.error(e);
            });
          }}
          onCancel={() => {}}
        />
      </PayPalScriptProvider>
    </PaymentButtonsContainer>
  );
};

PaymentButtons.propTypes = {
  ageConfirmed: PropTypes.bool,
  dataConfirmed: PropTypes.bool,
}

export default observer(PaymentButtons);
