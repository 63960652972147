import React, { useState } from 'react';
import { capitalize } from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { findParentCategory } from '../../helpers/utils';
import { useDebouncedEffect } from '../../hooks/useDebouncedCallback';
import { useStores } from '../../stores/helpers/use-stores';
import { BreadcrumbsContainer } from './Breadcrumbs.style';
import CrumbItem from './CrumbItem';

const Breadcrumbs = props => {
  const { dataStores: { providersStore, productsStore }, uiStore } = useStores();
  const { selectedProvider, providerList } = providersStore;
  const { categoriesList, selectedCategory } = productsStore;
  const { serviceType, providerName: providerSlug, categoryName: categorySlug } = uiStore.urlParams;
  const [crumbs, setCrumbs] = useState([]);

  const getCategoryParents = () => {
    const cats = [toJS(selectedCategory)];
    let counter = selectedCategory.level;
    while (!!counter) {
      const foundParent = findParentCategory(categoriesList, cats[cats.length - 1]);
      cats.push(foundParent);
      --counter;
    }
    return cats.filter(Boolean).reverse();
  };

  useDebouncedEffect(() => {
    const list = [];
    if (serviceType) {
      list.push({
        name: 'Home',
        path: '/',
        label: 'CABDO Delivery'
      });
      if (toJS(providerList)?.length > 1) {
        list.push({
          name: 'Service',
          path: `/${serviceType}/providers`,
          label: capitalize(serviceType)
        });
      }
    }
    if (providerSlug && selectedProvider) {
      list.push({
        name: 'Provider',
        path: `/${serviceType}/providers/${providerSlug}`,
        label: selectedProvider.name
      });
    }
    if (categorySlug && selectedCategory) {
      if (selectedCategory.level === 0) {
        list.push({
          name: 'Category',
          path: `/${serviceType}/providers/${providerSlug}/${categorySlug}`,
          label: selectedCategory.name
        });
      } else {
        const categories = getCategoryParents();
        categories.forEach(c => {
          list.push({
            name: 'Category',
            path: `/${serviceType}/providers/${providerSlug}/${c.slug}`,
            label: c.name
          });
        })
      }
    }
    list.pop();
    setCrumbs(list);
  }, 400, [serviceType, providerSlug, selectedProvider, categorySlug, selectedCategory, providerList]);

  if (!crumbs.length) return null;

  return <BreadcrumbsContainer>
    {crumbs.map((data, index) =>
      <CrumbItem
        key={data.path}
        data={data}
        serviceType={serviceType}
        isLast={index === crumbs.length - 1}
        isFirst={!index}
      />)}
  </BreadcrumbsContainer>;
};

export default observer(Breadcrumbs);
