import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useStores } from '../../../stores/helpers/use-stores';
import { CardsSection, Text } from '../../shared-components';
import NoItemsFound from '../NoItemsFound';
import ProviderFoundCard from '../ProviderFoundCard';
import { FoundProvidersContainer } from './ProviderProductSearchList.style';

const ProviderProductSearchList = props => {
  const { t } = useTranslation();
  const { query } = props;
  const { serviceType } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { dataStores: { productsStore } } = useStores();

  const results = useMemo(() => toJS(productsStore.searchItemsResult), [productsStore.searchItemsResult]);

  useEffect(() => {
    productsStore.searchItemsGlobally(serviceType, query);
    // eslint-disable-next-line
  }, [serviceType, query]);

  const handleProviderSelect = (providerSlug) => {
    history.push(`${location.pathname}/${providerSlug}?search=${query}`);
  }

  return (
    <FoundProvidersContainer>
      <Text type={'h3'} className={'found_items_text'}>{t('products.searchResults', { query })}</Text>
      <CardsSection
        className="cards__section"
        justifyContent="center"
      >
        {results.map((provider, index) =>
          <ProviderFoundCard
            key={`search-provider-${provider.slug}-${index}`}
            providerData={provider}
            onSelect={() => handleProviderSelect(provider.slug)}
          />
        )}
        {!results.length &&
          <NoItemsFound
            isLoading={productsStore.isFetching}
            hasButton={true}
            btnText={t('products.back')}
            btnAction={() => history.goBack()}
          />
        }
      </CardsSection>
    </FoundProvidersContainer>
  );
};

ProviderProductSearchList.propTypes = {
  query: PropTypes.string,
};

export default observer(ProviderProductSearchList);
