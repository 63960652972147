import styled from 'styled-components';
import commonStyles, { respondTo } from '../../commonStyles';

export const AutocompleteContainer = styled.div`
  width: 100%;

  & .autocomplete__dropdown {
    width: 100%;
    left: 0;
    border: none;
    box-shadow: 0px 36px 47px -18px rgb(0 29 35 / 15%);

    & > div {
      padding: 10px 20px;

      &:nth-child(odd) {
        background: ${commonStyles.colors.grey}33;
      }

      &:hover {
        background: ${commonStyles.colors.grey};
      }
    }
  }
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.div`
  margin-right: 12px;
`;

export const InputIcon = styled.div`
  &.main-search-icon {
    display: none;
  }

  ${respondTo.md`
    &.search-icon {
      display: none;
    }
    &.main-search-icon {
      display: block;
    }
  `}
`;

export const InputField = styled.input`
  width: 100%;
  height: 32px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${commonStyles.colors.grey}66;
  color: ${commonStyles.colors.primary};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  
  ${commonStyles.typo.mob.body};

  &::placeholder {
    color: ${commonStyles.colors.grey};
  }

  &:focus {
    outline: none;
  }

  ${respondTo.md`
    ${commonStyles.typo.bodySmall};
  `}
`;

export const DropdownContainer = styled.div`
  display: ${props => props.show ? 'block' : 'none'};
  position: absolute;
  top: 35px;
  left: 30px;
  width: ${props => props.inputWidth ? props.inputWidth : 300}px;
  background-color: ${commonStyles.colors.white};
  border: 1px solid ${commonStyles.colors.grey};
  border-radius: 8px;
  z-index: 20000;
`;

export const Suggestion = styled.div`
  cursor: pointer;
  background-color: ${commonStyles.colors.white};
  color: ${commonStyles.colors.primary};
  padding: 8px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;

  &:hover {
    background-color: ${commonStyles.colors.alphaString(commonStyles.colors.primary, 0.6)};
    color: ${commonStyles.colors.white};
  }

  &:first-child {
    border-top: none;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-bottom: none;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  & > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
