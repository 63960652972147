import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../../helpers/priceHelpers';
import { useStores } from '../../../stores/helpers/use-stores';
import WalletDataItem from './WalletDataItem';

const WalletMoneyData = (props) => {
  const { t } = useTranslation();
  const { dataStores: { authStore } } = useStores();

  const { userData: { walletData } } = authStore;

  return (
    <WalletDataItem
      title={t('userWallet.money.title')}
      value={formatPrice(walletData?.walletBalance ?? 0)}
      description={t('userWallet.money.desc')}
      type={'wallet'}
      onTransactionsShow={props.onTransactionsShow}
    />
  );
};

export default observer(WalletMoneyData);
