export class BrowserDetect {
  constructor() {
    this.userAgent = navigator.userAgent;
  }

  /* mobile detection */
  isMobile() {
    return this.userAgent.match(/iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile|CRiOS|OPiOS|Mobile|FxiOS/i) != null;
  }

  isDesktop() {
    return !this.isMobile();
  }

  browserName() {
    let temp;
    let match = this.userAgent.match(/(fxios|opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(match[1])) {
      temp = /\brv[ :]+(\d+)/g.exec(this.userAgent) || [];
      return 'IE ' + (temp[1] || '');
    }
    if (match[1] === 'Chrome') {
      /*
       * IE Edge has "chrome" as user agent and
       * this check is mandatory at this point.
       *
       * If the check is passed the M variabled
       * is replaced and the code continue like
       * other browers.
       */
      temp = this.userAgent.match(/(edge(?=\/))\/?\s*(\d+)/i);
      if (!!temp) {
        match = temp;
      } else {
        temp = this.userAgent.match(/\bOPR\/(\d+)/)
        if (temp !== null) return 'Opera ' + temp[1];
      }
    }
    match = match[2] ? [match[1], match[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((temp = this.userAgent.match(/version\/(\d+)/i)) !== null) match.splice(1, 1, temp[1]);
    return match.join(' ');
  }

  os() {
    if (this.isIOS()) { return 'ios'; }
    if (this.isAndroid()) { return 'android'; }
    if (this.isWindows()) { return 'windows'; }
    if (this.isUnix()) { return 'unix'; }
    if (this.isMac()) { return 'mac'; }
    if (this.isLinux()) { return 'linux'; }
    if (this.isBlackBerry()) { return 'blackberry'; } else return undefined;
  }

  browser() {
    if (this.isSafari()) { return 'safari'; }
    if (this.isChrome()) { return 'chrome'; }
    if (this.isIE()) { return 'ie'; }
    if (this.isEdge()) { return 'edge'; }
    if (this.isFirefox()) { return 'firefox'; }
    if (this.isOpera()) { return 'opera'; }
    return undefined;
  }

  /* desktop detection */
  isSafari() {
    return (this.userAgent.indexOf("Safari") !== -1 && navigator.vendor.indexOf("Apple Computer") !== -1 && !this.isChrome()) && !this.isFirefox() && !this.isOpera();
  }

  isChrome() {
    return ((this.userAgent.indexOf("Chrome") !== -1 && navigator.vendor.indexOf("Google Inc") !== -1) || this.userAgent.indexOf("CriOS") !== -1) && !this.isOpera();
  }

  isIE() {
    return this.browserName().toLowerCase().match(/ie|msie|iemobile/i) !== null;
  }

  isEdge() {
    return this.browserName().toLowerCase().match(/edge/i) !== null;
  }

  isFirefox() {
    return this.browserName().toLowerCase().match(/firefox|fxios/i) !== null;
  }

  isOpera() {
    return this.userAgent.toLowerCase().match(/opera|opr|opera mini|opios/i) !== null || Object.prototype.toString.call(window.operamini) === "[object OperaMini]";
  }

  /* mobile browsers detection */
  isMobileChrome() {
    return (this.userAgent.toLowerCase().match("crios") !== null) || (this.isChrome() && this.isMobile())
  }

  isMobileSafari() {
    return this.isMobile() && this.isSafari();
  }

  isMobileIE() {
    return this.isMobile() && this.isIE();
  }

  isMobileOpera() {
    return this.isMobile() && this.isOpera();
  }

  isMobileFirefox() {
    return this.isMobile() && this.isFirefox();
  }

  /* HD resolution detection */
  isHD() {
    if (!window.devicePixelRatio)
      return false;
    return window.devicePixelRatio && window.devicePixelRatio > 1;
  }

  pixelRatio() {
    if (!window.devicePixelRatio)
      return 1;
    return window.devicePixelRatio;
  }

  isIOS() {
    return this.userAgent.match(/iPhone|iPad|iPod/i) !== null;
  }

  isAndroid() {
    return this.userAgent.match(/Android/i) !== null;
  }

  isWindows() {
    return this.userAgent.match(/Windows/i) !== null;
  }

  isUnix() {
    return this.userAgent.match(/Unix/i) !== null;
  }

  isMac() {
    return this.userAgent.match(/Mac/i) !== null && !this.isIOS();
  }

  isLinux() {
    return this.userAgent.match(/Linux/i) !== null && !this.isAndroid();
  }

  isBlackBerry() {
    return this.userAgent.match(/BlackBerry/i) !== null;
  }

  isIPad() {
    return (/ipad/gi).test(navigator.platform);
  }

  isIPhone() {
    return (/iphone/gi).test(navigator.platform);
  }

  isLandscape() {
    return window.innerHeight < window.innerWidth;
  }

  isPortrait() {
    return this.isLandscape();
  }

  getIOSVersion() {
    if (this.isIOS()) {
      let OSVersion = navigator.appVersion.match(/OS (\d+_\d+)/i);
      OSVersion = OSVersion[1] ? +OSVersion[1].replace('_', '.') : 0;
      return OSVersion;
    }
    return false;
  }

  isStandAlone() {
    if (!!navigator.standalone) return navigator.standalone;
    return false;
  }

  version() {
    return this.browserName().replace(/^\D+/g, '');
  }

  maxTouchPoints() {
    if (navigator.maxTouchPoints) return navigator.maxTouchPoints;
    return null;
  }

  isTouch() {
    return 'ontouchstart' in document.documentElement;
  }
}

export const isMobile = () => {
  return new BrowserDetect().isMobile();
}
