import styled from 'styled-components';
import commonStyles, { respondTo } from '../commonStyles';

export const ButtonContainer = styled.button.attrs(() => ({}))`
  position: fixed;
  right: 50%;
  transform: translateX(50%);
  cursor: pointer;
  background-color: ${commonStyles.colors.primary};
  border: 1.5px solid ${({ type }) => commonStyles.colors[type ?? 'white']};
  bottom: 20px;
  height: 60px;
  width: calc(100% - 32px);
  border-radius: 5px;
  z-index: 999;
  transition: all 0.4s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 36px 47px -18px rgba(0, 29, 35, 0.35);

  &:hover {
    background-color: ${({ type }) => commonStyles.colors[type ?? 'food']};
    transform: translateX(50%) scale(1.05);
  }

  &.added {
    transform: translateX(50%) scale(1.05);
    transition: all 0.2s ease-in-out;
  }

  &.cart_opened {
    transform: translateX(50%) scale(0.01);
    transform-origin: center center;
    ${respondTo.md`
      transform: rotate(180deg) translateX(-50%) scale(0.01);
      transform-origin: center center;
    `}
  }

  ${respondTo.sm`
    flex-direction: column;
    height: 180px;
    width: 180px;
    border-radius: 50%; 
  `}
  ${respondTo.md`
    right: 15%;
  `}
  & > .cart_name, .cart_price {
    color: ${commonStyles.colors.white};
    margin: 0 16px;
    font-size: 18px;
    ${respondTo.sm`
      font-size: 28px;
      margin: 8px 0;
    `}
    ${respondTo.md`
      margin: 0;
      font-size: 28px;
    `}
  }
`;

export const IconContainer = styled.div.attrs(() => ({}))`
  position: relative;
  margin: 0 16px;

  & > svg {
    height: 28px;
    width: 28px;
    ${respondTo.md`
       margin: 0;
       height: 36px;
       width: 36px;
    `}
  }
`;

export const QtyLabel = styled.div.attrs(() => ({}))`
  position: absolute;
  background-color: ${commonStyles.colors.white};
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -5px;
  right: -8px;
  font-size: 10px;
  font-weight: bold;
`;
