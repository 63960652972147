import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../stores/helpers/use-stores';
import { Text } from '../../shared-components';
import { BlockWrapper } from './Referrals.style';
import ReferralSummary from './ReferralSummary';
import ReferralUserRow from './ReferralUserRow';

const ReferralsEarnings = () => {
  const { t } = useTranslation();
  const { dataStores: { userStore, authStore } } = useStores();

  const { referralData } = userStore;

  useEffect(() => {
    userStore.getUserReferralsInfo();
    // eslint-disable-next-line
  }, [authStore.userToken]);

  const userList = useMemo(() => toJS(referralData?.referralUsers ?? []), [referralData])

  if (!referralData) {
    return null;
  }

  return (
    <BlockWrapper>
      <Text type={'h4'} className={'code_title'}>{t('referrals.myReferrals')}:</Text>
      <ReferralSummary referralData={referralData}/>
      {!!userList.length && userList.map((user, idx) => <ReferralUserRow key={idx} user={user} />)}
    </BlockWrapper>
  );
};

export default observer(ReferralsEarnings);
