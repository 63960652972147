import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import ServiceTypes from '../../../constants/serviceTypes';
import { useStores } from '../../../stores/helpers/use-stores';
import { useTranslation } from 'react-i18next';
import { ToolSectionContainer, ToolSectionPart, Label, ToolSectionElement } from './ToolSection.style';
import { Select } from '../../shared-components';

const FoodFields = (t) => [
  {
    label: t('products.name'),
    value: 'name'
  }, {
    label: t('products.price'),
    value: 'price'
  }];

const GroceryFields = (t) => [
  {
    label: t('products.name'),
    value: 'name'
  }, {
    label: t('products.price'),
    value: 'price'
  }, {
    label: t('products.date'),
    value: 'date'
  }];

const OrderByFields = (t) => [
  {
    label: t('products.asc'),
    value: 'asc',
  }, {
    label: t('products.desc'),
    value: 'desc',
  }];

export const ShowFields = [20, 40, 60, 80, 100].map(item => ({ label: String(item), value: item }));

const ToolSection = props => {
  const { t } = useTranslation();

  const {
          dataStores: {
            servicesStore,
            productsStore,
          }
        } = useStores();

  const { selectedService } = servicesStore;
  const { searchMeta, sortParam } = productsStore;

  const sortByList = useMemo(() => {
    if (selectedService === ServiceTypes.FOOD) {
      return FoodFields(t);
    }
    return GroceryFields(t);
  }, [t, selectedService]);

  const orderByFields = useMemo(() => OrderByFields(t), [t]);

  useEffect(() => {
    return () => {
      productsStore.setSearchMetaToDefault()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <ToolSectionContainer>
      <ToolSectionPart>
        <ToolSectionElement>
          <Label type="bodySmall" weight="700">{t('products.sortBy')}</Label>
          <Select
            selectedValue={sortByList.find(opt => opt.value === sortParam.field)}
            className={'inverted-select sort_name'}
            options={sortByList}
            outlined
            inversion
            textAlign={'left'}
            onValueChange={(sel) => productsStore.setSortField(sel.value)}
          />
        </ToolSectionElement>
        <ToolSectionElement>
          <Label type="bodySmall" weight="700">{t('products.sortOrder')}</Label>
          <Select
            selectedValue={orderByFields.find(opt => opt.value === sortParam.order)}
            options={orderByFields}
            className={'inverted-select sort_order'}
            outlined
            inversion
            textAlign={'left'}
            onValueChange={(sel) => productsStore.setSortOrder(sel.value)}
          />
        </ToolSectionElement>
      </ToolSectionPart>
      <ToolSectionPart>
        <ToolSectionElement>
          <Label type="bodySmall" weight="700">{t('products.show')}</Label>
          <Select
            selectedValue={ShowFields.find(opt => opt.value === searchMeta.limit)}
            options={ShowFields}
            className={'inverted-select sort_number'}
            outlined
            inversion
            textAlign={'left'}
            onValueChange={(sel) => productsStore.setMetaLimit(sel.value)}
          />
        </ToolSectionElement>
      </ToolSectionPart>
    </ToolSectionContainer>
  );
};

export default observer(ToolSection);
