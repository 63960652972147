import styled from 'styled-components';
import { respondTo } from '../../commonStyles';

export const ReduceContainerDiv = styled.div`
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
  ${respondTo.md`
    padding: 0 20px;
  `}
`;