import styled, { css } from 'styled-components';

export const RootDiv = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-family: Circe, sans-serif;
  ${({ isModalOpened }) => isModalOpened && css`
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `}
`;

export const BaseContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
`;

export const Page = styled.div``;
