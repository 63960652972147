import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import commonStyle from '../../commonStyles';

const TogglerBody = styled.div`
  cursor: pointer;
  position: relative;
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  border-radius: ${props => props.height / 2}px;
  border: 1px solid ${props => props.disabledColor || props.color};
  background-color: ${props => props.disabledBGColor};
  transition: all 0.2s ease-in-out;

  &.enabled {
    background-color: ${props => props.color};
    border: 1px solid ${props => props.color};
  }
`;

const TogglerBall = styled.div`
  box-shadow: 1px 1px 4px ${commonStyle.colors.alphaString(commonStyle.colors.primary, 0.25)};
  height: ${props => props.height - 4}px;
  width: ${props => props.height - 3}px;
  border-radius: ${props => (props.height - 4) / 2}px;
  background-color: ${props => props.disabledBallColor || props.color};
  position: absolute;
  top: 2px;
  left: 2px;
  transition: all 0.2s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;

  &.enabled {
    background-color: ${props => props.invertedBallColor};
    transform: translateX(${props => props.width - props.height - 3}px);
  }
`;

const VerticalLine = styled.div`
  width: 1px;
  height: ${props => props.height / 2}px;
  background-color: ${props => props.invertedBallColor};
  margin: 0 ${props => props.height / 30}px;

  &.inverted {
    background-color: ${props => props.color};
  }
`;

const Toggler = props => {
  const { isEnabled, height, width, enabledColor, disabledColor, disabledBallColor, disabledBGColor, invertedBallColor } = props;
  return (
    <TogglerBody
      height={height}
      width={width}
      color={enabledColor}
      disabledColor={disabledColor}
      disabledBGColor={disabledBGColor}
      className={isEnabled ? 'enabled' : ''}
      onClick={props.onChange}
    >
      <TogglerBall
        height={height}
        width={width}
        disabledBallColor={disabledBallColor}
        color={enabledColor}
        invertedBallColor={invertedBallColor}
        className={isEnabled ? 'enabled' : ''}
      >
        {[...new Array(3)].map((v, i) => <VerticalLine
          key={i}
          height={height}
          color={enabledColor}
          invertedBallColor={invertedBallColor}
          className={isEnabled ? 'inverted' : ''}
        />)}
      </TogglerBall>
    </TogglerBody>
  );
};

Toggler.propTypes = {
  isEnabled: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  enabledColor: PropTypes.string,
  invertedBallColor: PropTypes.string,
  disabledBGColor: PropTypes.string,
  disabledColor: PropTypes.string,
  disabledBallColor: PropTypes.string,
  onChange: PropTypes.func,
};

Toggler.defaultProps = {
  height: 16,
  width: 30,
  enabledColor: commonStyle.colors.primary,
  invertedBallColor: commonStyle.colors.white,
  disabledBGColor: 'transparent',
};

export default Toggler;
