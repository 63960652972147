import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DropdownContainer } from './Header.style';
import UserMenuItem from './UserMenuItem';

const getUserPages = (t) => {
  return [{
    key: 'settings-page',
    link: '/user/settings',
    label: t('userSettings.title')
  }, {
    key: 'wallet-page',
    link: '/user/wallet',
    label: t('userWallet.title')
  }, {
    key: 'loyalty-page',
    link: '/user/loyalty',
    label: t('userLoyalty.title')
  }, {
    key: 'referrals',
    link: '/user/referrals',
    label: t('referrals.title')
  }, {
    key: 'orders-page',
    link: '/user/orders',
    label: t('userOrders.title')
  }, {
    key: 'logout',
    link: '/user/logout',
    label: t('auth.signOut')
  }];
};

const UserDropdownMenu = props => {
  const { isVisible } = props;
  const { t } = useTranslation();

  const menu = useMemo(() => getUserPages(t), [t]);

  return (
    <DropdownContainer visible={isVisible}>
      {menu.map((item) => <UserMenuItem {...item} onMenuHide={props.onMenuHide} />)}
    </DropdownContainer>
  );
};

UserDropdownMenu.propTypes = {
  isVisible: PropTypes.bool,
  onMenuHide: PropTypes.func,
};

export default UserDropdownMenu;
