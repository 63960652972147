import { toJS } from 'mobx';
import moment from 'moment';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import flag from '../../../assets/img/info_icons/location_flag.png';
import { KEYS } from '../../../keys';
import ActionModal from '../../ActionModal';
import { FlagImage } from '../../DeliveryToolModal/DeliveryToolModal.style';
import { Button, Text } from '../../shared-components';
import { ModalBody, ModalBodyWrapper, ImageContainer } from './OrderInfo.style';

const OrderPlaceModal = props => {
  const { t } = useTranslation();
  const { isVisible, orderData } = props;

  const orderPlaceData = useMemo(() => {
    if (!orderData) return null;
    const data = {
      providerName: orderData.provider.fname,
      date: '',
      timeFrom: '',
      timeTo: '',
      deliveryPlace: null
    };
    data.date = moment().day() === moment(orderData.deliveryDate).day()
      ? t('deliveryModal.today')
      : `am ${moment(orderData.deliveryDate).format('DD MMM')}`;
    data.timeFrom = moment(orderData.deliveryDate).format('HH:mm');
    data.timeTo = moment(orderData.deliveryDate)
      .add(orderData.slotLength, 'minutes')
      .format('HH:mm');
    data.deliveryPlace = toJS(orderData.deliveryPlace);
    return data;
  }, [orderData, t]);

  return (
    <ActionModal
      isOpen={isVisible}
      imgType={!orderData ? 'alert' : null}
      closeHandler={() => {}}
      title={t(`orderPlaceModal.${!!orderData ? 'successTitle' : 'failTitle'}`)}
      footerContent={<>
        <Button
          type={'ghost'}
          text={t(`unavailableItemsModal.${!!orderData ? 'submit' : 'cancel'}`)}
          onClick={props.onCancel}
        />
        {!!orderData && <Button
          type={'primary'}
          text={t('orderPlaceModal.orderStatus')}
          onClick={props.onSubmit}
        />}
      </>}
    >
      {!orderData && <ModalBody>
        <Text type={'body'}>{t('orderPlaceModal.failBody')}</Text>
        <Text type={'body'}>{t('orderPlaceModal.failContact')}</Text>
      </ModalBody>}
      {!!orderData && <ModalBodyWrapper>
        <ImageContainer>
          <img
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${orderPlaceData.deliveryPlace.location.latitude},${orderPlaceData.deliveryPlace.location.longitude}&zoom=15&size=240x240&language=de&key=${KEYS.GOOGLE_KEY}`}
            alt="Place to deliver"
          />
          <FlagImage src={flag} alt={'Location Marker'} />
        </ImageContainer>
        <ModalBody>
          <Text type="body" className={'orderModal_successBodyText'}>
            <Trans
              i18nKey={'orderPlaceModal.successBody'}
              values={orderPlaceData}
            >
              Deine Lieferung aus dem <strong>{orderPlaceData.providerName}</strong> wird
              Dir <strong>{orderPlaceData.date} in der Zeit
              von {orderPlaceData.timeFrom} bis {orderPlaceData.timeTo}</strong> gebracht.
            </Trans>
          </Text>
          <Text type={'body'} className={'orderModal_successBodyTextStatus'}>{t('orderPlaceModal.successLine2')}</Text>
          <Text type={'body'} className={'orderModal_successBodyFollow'}>
            <Trans
              i18nKey={'orderPlaceModal.followUs'}
              values={{ fb: 'Facebook', inst: 'Instagram' }}
            >
              Folge uns & erfahre direkt von Updates & Aktionen auf
              <a
                href="https://www.facebook.com/CabdoApp"
                target="_blank"
                className={'fb'}
                rel="noreferrer"
              >
                Facebook
              </a> und
              <a
                href="https://www.instagram.com/cabdofood"
                target="_blank"
                className={'inst'}
                rel="noreferrer"
              >
                Instagram
              </a>.
            </Trans>
          </Text>
        </ModalBody>
      </ModalBodyWrapper>}
    </ActionModal>
  );
};

OrderPlaceModal.propTypes = {
  isVisible: PropTypes.bool,
  type: PropTypes.oneOf(['success', 'fail', null]),
  orderData: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default OrderPlaceModal;
