import styled from 'styled-components';
import { respondTo } from '../commonStyles';

export const SocialsSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: 16px;
  ${respondTo.sm`
    justify-content: center;
  `}
  & > a {
    margin-bottom: 0;
  }
  & > a > svg {
    height: 45px;
    width: 162px;
  }
`;


export const ModalImg = styled.div`
  text-align: center;

  & img {
    width: 100%;
    margin-bottom: -8px;
    ${respondTo.sm`
      width: auto;
      margin: 0;
    `}
  }
`;

export const ModalContent = styled.div`
  padding: 50px 20px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  
  & .mobile-app-modal__title, .mobile-app-modal__content {
    margin-bottom: 24px;
  }

  & .mobile-app-modal__content > strong {
    font-weight: 700;
  }
  
  & > .only_button {
    align-self: center;
  }
`;

export const ModalContainer = styled.div`
  & .mobile-app-modal__content {
    font-weight: 300;
  }
`;
