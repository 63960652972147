import React from 'react';
import PropTypes from 'prop-types';
import { hasCategory } from '../../../helpers/utils';
import { CategoriesSideBarContainer } from './Categories.style';
import SideCategoryItem from './categoryItem';

const SideCategories = props => {
  const { categories, currentCategorySlug, exact } = props;
  return (
    <CategoriesSideBarContainer>
      {categories.map((cat, index) =>
        <SideCategoryItem
          key={`${cat._id}-${index}`}
          categoryData={cat}
          active={cat.slug === currentCategorySlug ||
            (!!cat.subCategories?.length && !!hasCategory(cat.subCategories, currentCategorySlug))
          }
          isSubCategory={false}
          exact={!!exact}
        />
      )}
    </CategoriesSideBarContainer>
  );
};

SideCategories.propTypes = {
  categories: PropTypes.array,
  currentCategorySlug: PropTypes.string,
  exact: PropTypes.bool,
};

export default SideCategories;
