import styled from 'styled-components';
import commonStyles, { respondTo } from '../../commonStyles';

export const CategoriesSideBarContainer = styled.div.attrs(() => ({}))`
  display: none;
  width: 200px;
  margin-right: 30px;
  min-width: 200px;
  max-width: 200px;
  ${respondTo.md`
    display: block;
  `}
  & > .category_item-container:last-child {
    border: none;
  }
}
`;

export const CategoryItemContainer = styled.div.attrs(() => ({}))`
  ${({
       isCollapsed,
       isActive,
       isSubCategory,
       nestingLevel
     }) => {
    const show = !nestingLevel;
    const isActiveCat = (!isCollapsed || isActive) && !isSubCategory;
    return show ? `
      border-bottom-width: ${isActiveCat ? 2 : 1}px;
      border-bottom-style: solid;
      border-bottom-color: ${isActiveCat ?
            commonStyles.colors.alphaString(commonStyles.colors.primary, 0.8) :
            commonStyles.colors.alphaString(commonStyles.colors.grey, 0.2)}
    ` : '';
  }}
}


& > .category_item-nameContainer {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: ${({ nestingLevel }) => nestingLevel * 16}px;

  &:hover {
    background-color: ${({ isSubCategory }) => isSubCategory ? commonStyles.colors.grey : 'transparent'};
    transition: all 0.1s ease-in;

    & > .category_item-name {
      color: ${commonStyles.colors.primary};
      font-weight: 700;
    }

    & > .category_item-chevronContainer > .category_item-chevron path {
      transition: all 0.2s ease-in-out;
      fill: ${commonStyles.colors.primary} !important;
    }
  }

  & > .category_item-name {
    color: ${({
                isCollapsed,
                isActive,
                isSubCategory
              }) => !isCollapsed || isActive || isSubCategory ? commonStyles.colors.primary : commonStyles.colors.grey};
    font-weight: ${({
                      isCollapsed,
                      isActive,
                    }) => (!isCollapsed || isActive) ? 700 : 400};
  }

  & > .category_item-chevronContainer > .category_item-chevron {
    transition: all 0.1s ease-in;
    transform: rotate(${({ isCollapsed }) => isCollapsed ? 0 : 180}deg);
  }

}

}`;

export const SubCategoriesContainer = styled.div.attrs(() => ({}))`
  background-color: ${commonStyles.colors.alphaString(commonStyles.colors.grey, 0.2)};
  border-radius: 5px;
`;

const getHorizontalItemBackground = (type, active) => {
  if (type === 'primary') {
    return active ? commonStyles.colors.primary : commonStyles.colors.white;
  }
  return active ? commonStyles.colors[type ?? 'food'] : commonStyles.colors.primary;
}

export const HorizontalCategoryItem = styled.div.attrs(() => ({}))`
  margin: 16px 8px 30px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  background-color: ${({ active, type }) => getHorizontalItemBackground(type, active)};
  min-width: 100px;
  border: ${({ type }) => type === 'primary' ? `1px solid ${commonStyles.colors.primary}` : 'none'};
  width: max-content;
  & .horizontal-categoryItem {
    color: ${({ type, active }) => type === 'primary' && !active ? commonStyles.colors.primary : commonStyles.colors.white};
    font-weight: ${({ active }) => active ? 700 : 400};
  }
`;

export const HorizontalCategoriesContainer = styled.div.attrs(() => ({}))`
  width: 100%;
  box-sizing: border-box;
  ${respondTo.md`
    display: none;
  `}
  & div > .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }

  & div > .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  
  & ${HorizontalCategoryItem}:first-child {
    margin-left: 0;
  }
`;

