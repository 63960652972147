import React from 'react';
import PropTypes from 'prop-types';

import Text from '../Text';
import Link from '../Link';

import { CustomButton } from './Button.style';

const Button = ({
  className,
  type,
  disabled,
  onClick,
  secondary,
  text,
  href,
  multiline,
  content
}) => (
  <CustomButton
    className={className}
    disabled={disabled}
    secondary={secondary}
    onClick={onClick}
    type={type}
    multiline={multiline}
  >
    {!!text && <Text
      className="button__text"
      type="body"
      weight="700"
    >
      {text}
    </Text>}
    {!!content && content}
    {href && (
      <Link className="button__link" href={href} />
    )}
  </CustomButton>
);

Button.propTypes = {
  type: PropTypes.oneOf(['primary', 'food', 'mahlzeit', 'ghost',]).isRequired,
  text: PropTypes.string,
  content: PropTypes.element,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  multiline: PropTypes.bool,
};

Button.defaultProps = {
  className: undefined,
  disabled: false,
  onClick: () => {
    // intentional
  },
  secondary: false,
};

export default Button;
