import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IconFont, Text } from '../../shared-components';
import { TooltipWrapper } from '../../shared-components/IconFont/IconFont.style';
import PlacesInput from '../../shared-components/PlacesInput/PlacesInput';
import { InputGroupWrapper, InputWrapper, UserPlaceToolWrapper } from './AccountData.style';
import { ReactComponent as MapIcon } from '../../../assets/svg/dest_map.svg'

const PlaceInputTool = props => {
  const { t } = useTranslation();
  const { data, hasAddressProblem } = props;

  const label = useMemo(() => {
    if (!data) return '';
    switch (data.name) {
      case 'home':
        return t('userPlaces.home');
      case 'favorite':
        return t('userPlaces.favorite');
      default:
        const num = parseFloat(data.name.split('-')[1]);
        return t('userPlaces.place', { num: num + 1 });
    }
  }, [data, t]);

  const handleAddressSelect = (addressPayload) => {
    const { addressData, address, location } = addressPayload;
    props.onPlaceUpdate(data.index, {
      address,
      location
    }, !addressData.home && !addressData.public_place);
  };

  const handleAddressChange = (address) => {
    props.onPlaceUpdate(data.index, {
      address,
      location: {
        latitude: null,
        longitude: null
      }
    });
  };

  const handleClear = () => {
    props.onPlaceUpdate(data.index, {
      address: '',
      location: {
        latitude: null,
        longitude: null
      }
    });
  };

  const handleMapPick = () => {
    props.onMapPress(data.index, data.place, label);
  };

  if (!data) return null;

  return (
    <UserPlaceToolWrapper>
      <Text type={'bodySmall'} weight={700} className={'label'}>{label}:</Text>
      <InputGroupWrapper>
        <InputWrapper>
          <PlacesInput
            className="address-field"
            icon="pin"
            tooltip={label}
            placeholder={label}
            address={data.place.address || ''}
            onSelect={handleAddressSelect}
            onAddressChange={handleAddressChange}
            onError={(e) => { console.error(e.message) }}
            isValid={!data.place.address || !!(data.place.address && data.place.location.latitude && data.place.location.longitude)}
          />
          {!!hasAddressProblem && <Text
            type={'bodySmall'}
            className={'warning_message'}
          >
            {t('deliveryModal.houseWarning')}
          </Text>}
        </InputWrapper>
        <TooltipWrapper tooltip={t('userPlaces.clear')}>
          <button
            className={'button clear_btn'}
            onClick={handleClear}
            disabled={!data.place.address}
          >
            <IconFont icon={'xCircle'} size={20} className={'clear_icon'}/>
          </button>
        </TooltipWrapper>
        <TooltipWrapper tooltip={t('userPlaces.pickOnMap')}>
          <button
            className={'button map_btn'}
            onClick={handleMapPick}
          >
            <MapIcon className={'map_icon'}/>
          </button>
        </TooltipWrapper>
      </InputGroupWrapper>
    </UserPlaceToolWrapper>
  );
};

PlaceInputTool.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    place: PropTypes.shape({
      address: PropTypes.string,
      location: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      }).isRequired,
    }).isRequired,
  }),
  onPlaceUpdate: PropTypes.func,
  onMapPress: PropTypes.func,
  hasAddressProblem: PropTypes.bool,
};

export default PlaceInputTool;

