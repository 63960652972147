import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import ServiceTypes from '../constants/serviceTypes';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useGoogleAnalytics } from '../hooks/useGoogleAnalytics';
import AuthModal from './AuthModal';
import Breadcrumbs from './Breadcrumbs';
import CartButton from './CartButton';
import CartModal from './CartModal';
import DeliveryToolModal from './DeliveryToolModal';
// import MobileAppModal from './MobileAppModal';
import ItemsList from './ItemsList';
import OrderStatus from './OrderStatus';
import ProductModal from './ProductModal';
import Main, { MainTopSection } from './Main';
import Lib from './lib';
import Settings from './Settings';
import NotFound from './NotFound';
import Footer from './Footer';
import SocialCallback from './SocialCallback';
import UserSettings from './UserSettings';
import { RootDiv, BaseContainer } from './App.style'
import { useStores } from '../stores/helpers/use-stores';
import { Layout, Loader } from './shared-components';

import 'moment/locale/de';

const App = () => {
  const {
          dataStores: {
            deliveryModalStore,
            sideModalStore,
            servicesStore,
            productsStore,
            providersStore,
          }
        } = useStores();
  const { isModalOpened } = deliveryModalStore;
  const { isProductModalOpened, isCartModalOpened } = sideModalStore;
  const { selectedService, currentPageMeta } = servicesStore;
  const location = useLocation();

  useGoogleAnalytics(location);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const routes = [
    {
      name: 'main',
      path: '/',
      Component: Main,
      isExact: true,
      TopSection: <MainTopSection/>,
    },
    {
      name: 'lib',
      path: '/lib',
      Component: Lib,
    },
    {
      name: 'settings',
      path: '/settings',
      Component: Settings,
    },
    {
      name: 'items-list',
      path: '/:serviceType/providers/:providerName?/:categoryName?',
      Component: ItemsList,
      isExact: true,
      hasBreadcrumbs: true
    },
    {
      name: 'user-settings',
      path: '/user/:userPage',
      Component: UserSettings,
      isExact: true,
      hasBreadcrumbs: false
    },
    {
      name: 'order-status',
      path: '/order-status/:orderId',
      Component: OrderStatus,
      isExact: true,
      hasBreadcrumbs: false
    },
    {
      path: '/social-callback/:type?',
      name: 'social-callback',
      Component: SocialCallback,
      hasBreadcrumbs: false
    },
    {
      name: '404',
      path: '*',
      Component: NotFound,
    },
  ];

  const getTopSection = () => {
    const foundRoute = routes.find((route) => route.name === currentPageMeta?.name);
    if (!foundRoute) return null;
    return foundRoute.hasBreadcrumbs ? <Breadcrumbs/> : foundRoute.TopSection;
  };

  return (
    <RootDiv isModalOpened={isModalOpened || isProductModalOpened || isCartModalOpened}>
      <DeliveryToolModal/>
      <ProductModal/>
      {/*<MobileAppModal/>*/}
      <CartModal/>
      <AuthModal/>
      <CartButton/>
      {(productsStore.isFetching || providersStore.isFetching) && (
        <Loader
          type={selectedService ?? ServiceTypes.FOOD}
        />
      )}
      <BaseContainer>
        <Layout
          type={selectedService ?? ServiceTypes.FOOD}
          isMain={currentPageMeta?.name === 'main'}
          pageTitle={currentPageMeta?.title}
          topSectionContent={getTopSection()}
        >
          <Switch>
            {routes.map(({ name, path, Component, isExact }, key) => (
              <Route
                exact={isExact}
                path={path}
                key={key}
                render={() => <Component pageName={name}/>}
              />
            ))}
          </Switch>
        </Layout>
      </BaseContainer>
      <Footer/>
    </RootDiv>
  )
};

export default observer(App);
