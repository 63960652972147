import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SummaryContainer } from './Referrals.style';
import SummaryItem from './SummaryItem';
import { ReactComponent as UserIcon } from '../../../assets/svg/user-icon.svg';
import { ReactComponent as Reward1Icon } from '../../../assets/svg/reward-1-icon.svg';
import { ReactComponent as Reward2Icon } from '../../../assets/svg/reward-2-icon.svg';

const ReferralSummary = props => {
  const { t } = useTranslation();
  const { referralData: { referralUsers, earnedReferralMoney, expectedReferralMoney } } = props;

  return (
    <SummaryContainer>
      <SummaryItem
        text={t('referrals.friends')}
        value={referralUsers?.length ?? 0}
        icon={<UserIcon className={'summary_icon'} />}
      />
      <SummaryItem
        text={t('referrals.earned')}
        value={`${earnedReferralMoney} €`}
        icon={<Reward1Icon className={'summary_icon'} />}
      />
      <SummaryItem
        text={t('referrals.expected')}
        value={`${expectedReferralMoney} €`}
        icon={<Reward2Icon className={'summary_icon'} />}
      />
    </SummaryContainer>
  );
};

ReferralSummary.propTypes = {
  referralData: PropTypes.shape({
    referralUsers: PropTypes.array,
    earnedReferralMoney: PropTypes.number,
    expectedReferralMoney: PropTypes.number,
  }),
};

export default ReferralSummary;
