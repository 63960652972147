import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTrash } from 'react-icons/bs';
import { formatPrice } from '../../../helpers/priceHelpers';
import { useStores } from '../../../stores/helpers/use-stores';
import { InfoBox, Text } from '../../shared-components';
import { TooltipWrapper } from '../../shared-components/IconFont/IconFont.style';
import CartItem from './cartItem';
import { CartItemsContainer, CartItemsHeaderRow, ClearCartButton } from './CartItems.style';

const CartItems = props => {
  const { t } = useTranslation();
  const { dataStores: { orderStore } } = useStores();
  const { orderProvider, itemsQty, orderItems, orderAmt, minimumOrderDifference } = orderStore;

  const items = useMemo(() => toJS(orderItems), [orderItems]);

  useEffect(() => {
    orderStore.recalculateMinOrderDifference();
    // eslint-disable-next-line
  }, [orderProvider, orderAmt]);

  const handleItemQtyChange = (newQty, index) => {
    const item = items[index];
    items[index].qty = newQty;
    if (!!item.artNo) {
      const pfandIndex = items.findIndex(
        (it) => it.name === 'Pfand' && it.artNo.includes(item.artNo)
      );
      if (pfandIndex >= 0) {
        items[pfandIndex].qty = newQty;
      }
    }
    orderStore.updateCart(items);
  }

  const handleItemRemove = (index) => {
    const item = items[index];
    const indexesToFilter = [index];
    if (!!item.artNo) {
      const pfandIndex = items.findIndex(
        (it) => it.name === 'Pfand' && it.artNo.includes(item.artNo)
      );
      if (pfandIndex >= 0) {
        indexesToFilter.push(pfandIndex);
      }
    }
    orderStore.updateCart(items.filter((it, idx) => !indexesToFilter.includes(idx)));
  }

  const handleItemComment = (e, index) => {
    items[index].comment = e.target.value;
    orderStore.updateCart(items);
  }

  const handleClearCart = () => {
    orderStore.resetCart();
  }

  return (
    <CartItemsContainer>
      <CartItemsHeaderRow>
        <Text type={'h3'} weight={700} className={'cart-modal_itemsHeader'}>{t('cart.itemsTitle')}</Text>
        <TooltipWrapper tooltip={t('cart.clearCart')} onClick={handleClearCart}>
          <ClearCartButton>
            <BsTrash size={24}/>
          </ClearCartButton>
        </TooltipWrapper>
      </CartItemsHeaderRow>
      {minimumOrderDifference > 0 &&
        <InfoBox type={'white'} className={'cart-modal_minPriceAlert'} imgType={'alert'}>
          {t('cart.minSum', { price: formatPrice(minimumOrderDifference) })}
        </InfoBox>}
      <Text
        type={'h4'}
        className={'cart-modal_providerText'}
      >
        {t('cart.productsFrom')}
        <Text type={'body'} className={'cart-modal_providerName'}>
          {orderProvider?.name ?? ''}
        </Text>
        {`: ${itemsQty}`}
      </Text>
      {items.map((item, index) => <CartItem
        key={`cart_${item.name}-${index}`}
        data={item}
        onCartItemQtyChange={(qty) => handleItemQtyChange(qty, index)}
        onCartItemRemove={() => handleItemRemove(index)}
        onCartItemCommentChange={(e) => handleItemComment(e, index)}
      />)}
    </CartItemsContainer>
  );
};

export default observer(CartItems);
