import styled from 'styled-components';
import commonStyles from '../commonStyles';

export const LangButtonContainer = styled.div.attrs(() => ({}))`
  cursor: pointer;
  border: 2px solid ${({ color }) => color ?? commonStyles.colors.primary};
  height: 40px !important;
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in;
  &:hover {
    transform: scale(1.05, 1.05);
  }
  & .lang_text {
    transition: all 0.3s ease-in;
    color: ${({ color }) => color ?? commonStyles.colors.primary};
  }
`;
