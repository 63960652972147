import styled from 'styled-components';

export const OrderInfoWrapper = styled.div.attrs(() => ({}))`
  width: 100%;
  & > .order_title {
    padding: 32px 0;
    font-weight: 400;
    & > span {
      font-weight: 700;
      margin: 0 8px;
    }
  } 
  & > .order_products {
    padding: 32px 0 16px;
  }
`;

export const InfoRowWrapper = styled.div.attrs(() => ({}))`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  
  & > .info_value {
    font-weight: 700;
  }
`;
