import styled, { css } from 'styled-components';
import commonStyles, { respondTo } from '../../commonStyles';

const getTextType = (props, isMobile = true) => props.type && css`
  ${isMobile ? commonStyles.typo.mob[props.type] : commonStyles.typo[props.type]}
`;

export const Header1 = styled.h1`
  color: ${commonStyles.colors.primary};
  margin: 0;
  ${(props) => getTextType(props)}
  ${({weight}) => weight && css`
    font-weight: ${weight};
  `}
  ${({align}) => align && css`
    text-align: ${align};
  `}
  ${respondTo.md`
    ${(props) => getTextType(props, false)}
  `}
`;

export const Header2 = styled.h2`
  color: ${commonStyles.colors.primary};
  margin: 0;
  ${(props) => getTextType(props)}
  ${({weight}) => weight && css`
    font-weight: ${weight};
  `}
  ${({align}) => align && css`
    text-align: ${align};
  `}
  ${respondTo.md`
    ${(props) => getTextType(props, false)}
  `}
`;

export const Header3 = styled.h3`
  color: ${commonStyles.colors.primary};
  margin: 0;
  ${(props) => getTextType(props)}
  ${({weight}) => weight && css`
    font-weight: ${weight};
  `}
  ${({align}) => align && css`
    text-align: ${align};
  `}
  ${respondTo.md`
    ${(props) => getTextType(props, false)}
  `}
`;

export const Header4 = styled.h4`
  color: ${commonStyles.colors.primary};
  margin: 0;
  ${(props) => getTextType(props)}
  ${({weight}) => weight && css`
    font-weight: ${weight};
  `}
  ${({align}) => align && css`
    text-align: ${align};
  `}
  ${respondTo.md`
    ${(props) => getTextType(props, false)}
  `}
`;

export const CustomText = styled.p`
  color: ${commonStyles.colors.primary};
  margin: 0;
  ${(props) => getTextType(props)}
  ${({weight}) => weight && css`
    font-weight: ${weight};
  `}
  ${({align}) => align && css`
    text-align: ${align};
  `}
  ${respondTo.md`
    ${(props) => getTextType(props, false)}
  `}
`;
