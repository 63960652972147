import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from '../shared-components';
import ProductChoiceInfo from './ProductChoiceInfo';
import { AddToCartControlContainer } from './ProductModal.style';
import QtyTool from './qtyTool';

const AddToCartControl = props => {
  const { t } = useTranslation();
  const { product, isButtonDisabled, serviceType } = props;
  return (
    <AddToCartControlContainer className={'product-modal_add_to_cart_controls_container'} type={serviceType}>
      <ProductChoiceInfo product={product} collapsible={false} isInvertable={false} />
      <QtyTool
        qty={product.qty}
        unit={product?.unit}
        serviceType={serviceType}
        onChange={props.onQtyChange}
      />
      <Button
        onClick={props.onAddToCart}
        className="product-modal__button"
        type={serviceType}
        text={t('productModal.addButton')}
        disabled={isButtonDisabled}
      />
    </AddToCartControlContainer>
  );
};

AddToCartControl.propTypes = {
  product: PropTypes.object,
  serviceType: PropTypes.string,
  onQtyChange: PropTypes.func,
  isButtonDisabled: PropTypes.bool,
  onAddToCart: PropTypes.func,
};

export default AddToCartControl;
