import styled, { css } from 'styled-components';
import commonStyles, { respondTo } from '../../commonStyles';

export const ImageContainer = styled.div`
  width: 100%;
  height: 40px;
  max-width: 40px;
  margin-right: 24px;
  & > svg {
    width: 100%;
    height: auto;
  }
`;

export const InfoBoxContainer = styled.div`
  display: flex;
  cursor: ${({ isClickable }) => isClickable ? 'pointer' : 'default'};
  align-items: center;
  padding: 8px 16px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: ${({type}) => commonStyles.colors[type]}; 
  & .info-box_text {
    ${({ type }) => (type === 'mahlzeit' || type === 'primary') && css`
        color: ${commonStyles.colors.white};
    `}
  }
  ${respondTo.md`
    padding: 10px 20px;
  `}
`;
