import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import './index.css';
import App from './components/App';
import GoogleApiLoader from './components/GoogleApiLoader';
import TokenListener from './components/TokenListener';
import { KEYS } from './keys';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { StoreProvider } from './stores/helpers/store-context';
import TagManager from 'react-gtm-module'
import './i18n';

if (!KEYS.IS_DEV) {
  Sentry.init(KEYS.sentryOptions);
  TagManager.initialize({ gtmId: 'GTM-P574KN8' });
}

ReactDOM.render(
  <StoreProvider>
    <BrowserRouter>
      <TokenListener/>
      <GoogleApiLoader/>
      <App/>
    </BrowserRouter>
  </StoreProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
