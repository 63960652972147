import { observer } from 'mobx-react-lite';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDebouncedEffect } from '../../../hooks/useDebouncedCallback';
import { useStores } from '../../../stores/helpers/use-stores';
import { DeliveryMapToolContainer, DeliveryToolContainer } from '../../DeliveryToolModal/DeliveryToolModal.style';
import MapPickTool from '../../DeliveryToolModal/mapTool';
import { Modal } from '../../shared-components';

const MapToolModal = props => {
  const { t } = useTranslation();
  const { visible, initialPlace, title } = props;
  const { uiStore: { windowDimensions } } = useStores();
  const [mapToolContainerDimensions, setMapToolContainerDimensions] = useState({ height: 0, width: 0 });

  const mapToolContainerRef = useRef();

  useDebouncedEffect(() => {
    if (mapToolContainerRef.current) {
      setMapToolContainerDimensions({
        width: mapToolContainerRef.current.offsetWidth,
        height: mapToolContainerRef.current.offsetHeight
      });
    }
  }, 200, [windowDimensions]);

  const handleLocationConfirm = place => {
    props.onConfirm(place);
  };

  return (
    <Modal
      isOpen={visible}
      onRequestClose={props.onHide}
      ariaHideApp={false}
      contentLabel={'Map Pick Modal'}
      title={title ?? t('userPlaces.pickAddress')}
      overflow
    >
      <DeliveryToolContainer>
        <DeliveryMapToolContainer
          ref={mapToolContainerRef}
          className="delivery-tool_tool"
          {...mapToolContainerDimensions}
        >
          <MapPickTool
            windowWidth={windowDimensions.width}
            initialLat={initialPlace?.location?.latitude ?? 51.50178919745418}
            initialLng={initialPlace?.location?.longitude ?? 7.485875971469521}
            onLocationConfirm={handleLocationConfirm}
          />
        </DeliveryMapToolContainer>
      </DeliveryToolContainer>
    </Modal>
  );
};

MapToolModal.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  initialPlace: PropTypes.object,
  onHide: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default observer(MapToolModal);
