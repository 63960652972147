import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStores } from '../../stores/helpers/use-stores';
import { SideModal } from '../shared-components';
import CartItems from './CartItemsInfo';
import OrderInfo from './OrderInfo';

const CartModal = props => {
  const { dataStores: { sideModalStore, orderStore } } = useStores();

  const { isCartModalOpened } = sideModalStore;
  const { itemsQty, isHydrated } = orderStore;

  useEffect(() => {
    if (!itemsQty && isHydrated) {
      sideModalStore.hideCartModal();
      orderStore.setOrderProvider();
    }
    // eslint-disable-next-line
  }, [itemsQty, isHydrated]);

  return (
    <SideModal
      isModalOpened={isCartModalOpened}
      isReversed={false}
      onHide={() => sideModalStore.hideCartModal()}
      mainSection={<OrderInfo/>}
      secondarySection={<CartItems/>}
      hasSecondarySection={true}
    />
  );
};

export default observer(CartModal);
