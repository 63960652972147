import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generateTimesArray } from '../../helpers/dateHelpers';
import commonStyles from '../commonStyles';
import { IconFont, Select, Text } from '../shared-components';
import { TimeFields, TimeFieldsContainer, TimeFieldsTitle } from './DeliveryToolModal.style';

const getDate = (date, timeString) => {
  if (timeString) {
    const [hrs, mins] = timeString.split(':');
    return date.hours(parseInt(hrs, 10)).minutes(parseInt(mins, 10));
  }
};

const getDeliverySlotOptions = (t) => [
  {
    value: 0,
    label: t('deliveryModal.asap')
  },
  {
    value: 1,
    label: t('deliveryModal.today')
  },
  {
    value: 2,
    label: t('deliveryModal.tomorrow')
  },
];

const DesiredTimeSelectTool = props => {
  const { t } = useTranslation();

  const { desiredDate, selectedProvider } = props;
  const deliverySlotsOptions = useMemo(() => getDeliverySlotOptions(t), [t]);

  const [selectedDeliverySlot, setSelectedDeliverySlot] = useState(deliverySlotsOptions[0]);
  const [selectedDeliveryTime, setSelectedDeliveryTime] = useState();
  const [timeOptionsList, setTimeOptionsList] = useState([]);

  useEffect(() => {
    if (!desiredDate) {
      setSelectedDeliverySlot(deliverySlotsOptions[0]);
      setSelectedDeliveryTime(null);
      setTimeOptionsList([]);
    } else {
      const todayDay = moment().day();
      const desiredDateDay = moment(desiredDate).day();
      setSelectedDeliverySlot(todayDay === desiredDateDay ? deliverySlotsOptions[1] : deliverySlotsOptions[2]);
      const timeString = moment(desiredDate).format('HH:mm');
      setSelectedDeliveryTime({ label: timeString, value: timeString });
    }
  }, [deliverySlotsOptions, desiredDate, selectedDeliverySlot, selectedProvider]);

  useEffect(() => {
    if (!selectedDeliverySlot) {
      setTimeOptionsList([]);
      return;
    }
    const cutOffTime = selectedProvider?.providerData?.cutOffTime ?? 45;
    const startDate = moment().add(selectedDeliverySlot.value === 2 ? 1 : 0, 'days');
    const slots = generateTimesArray(startDate, cutOffTime).map((item) => {
      return {
        value: item,
        label: item,
        date: startDate
      }
    });
    setTimeOptionsList(slots);
    // eslint-disable-next-line
  }, [desiredDate, selectedDeliverySlot]);

  const handleDateSlotChange = (slot) => {
    if (!slot.value) {
      props.onChange(null);
      setTimeOptionsList([]);
    } else {
      const cutOffTime = selectedProvider?.providerData?.cutOffTime ?? 45;
      const startDate = moment().add(slot.value === 2 ? 1 : 0, 'days');
      const generatedList = generateTimesArray(startDate, cutOffTime).map((item) => {
        return {
          value: item,
          label: item,
        }
      });
      const date = getDate(startDate, generatedList[0]?.value);
      props.onChange(date);
    }
  };

  const handleDateTimeChange = (timeSlot) => {
    const [hrs, mins] = timeSlot.value.split(':');
    props.onChange(moment(desiredDate).hours(parseInt(hrs, 10)).minutes(parseInt(mins, 10)));
  };

  return (
    <TimeFields className="delivery-tool_section">
      <TimeFieldsTitle>
        <IconFont className="delivery-tool_select-icon" icon="time" size="20px" color={commonStyles.colors.grey} tooltip={t('tooltips.deliveryDate')}/>
        <Text className="delivery-tool_select-title" type="h3">{t('deliveryModal.selectDeliveryTime')}</Text>
      </TimeFieldsTitle>
      <TimeFieldsContainer>
        <Select
          outlined
          className="delivery-tool_select-field"
          placeholder="Select day..."
          selectedValue={selectedDeliverySlot}
          onValueChange={handleDateSlotChange}
          options={deliverySlotsOptions}
        />
        <Select
          outlined
          isDisabled={selectedDeliverySlot.value === 0}
          className="delivery-tool_select-field"
          placeholder={t('deliveryModal.selectTime')}
          selectedValue={selectedDeliveryTime}
          onValueChange={handleDateTimeChange}
          options={timeOptionsList}
        />
      </TimeFieldsContainer>
    </TimeFields>
  );
};

DesiredTimeSelectTool.propTypes = {
  desiredDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  selectedProvider: PropTypes.shape({
    providerData: PropTypes.shape({
      cutOffTime: PropTypes.number,
    }),
  }),
  onChange: PropTypes.func,
}

export default DesiredTimeSelectTool;
