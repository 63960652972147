import { RouterStore } from 'mobx-react-router';
import DataStore from './dataStores';
import { UiStore } from './uiStore';

export default class RootStore {
  dataStores;
  uiStore;
  routeStore;

  init() {
    this.dataStores = new DataStore(this);
    this.uiStore = new UiStore();
    this.routeStore = new RouterStore();
  }
}
