import { css } from 'styled-components';
import Color from 'color';
import { BrowserDetect } from '../helpers/browserDetect';

export const isLinuxOrUnixFFOrChrome = (dt = null) => {
  let detector = dt;
  if (!dt) {
    detector = new BrowserDetect();
  }
  return detector.isLinux() ||
    (detector.isMac() && (detector.isChrome() || detector.isFirefox()));
}

export const isWinOrSafari = (dt = null) => {
  let detector = dt;
  if (!dt) {
    detector = new BrowserDetect();
  }
  return detector.isWindows() || detector.isSafari();
}

export const unixStringCompensation = () => {
  const detector = new BrowserDetect();
  if (detector.isMobile()) return {};
  if (isLinuxOrUnixFFOrChrome(detector)) {
    return { transform: 'translateY(-0.15em)' };
  }
  if (isWinOrSafari(detector)) {
    return { transform: 'translateY(0.05em)' };
  }
  return {};
};

const commonStyles = {
  colors: {
    alphaString: (hex, alpha) => Color(hex).alpha(alpha).string(),
    lighten: (hex, koef) => Color(hex).lighten(koef).string(),
    darken: (hex, koef) => Color(hex).darken(koef).string(),
    primary: '#001D23',
    mahlzeit: '#009453',
    food: '#F1AF00',
    danger: '#FD1D1D',
    warning: '#fa8c16',
    grey: '#B5B5B5',
    darkGrey: '#525252',
    white: '#FAFAFA',
    facebook: '#3b5998',
    google: '#de5246',
    appleDark: '#212124',
    instagram: '#fd1d1d',
    totalWhite: '#FFFFFF',
  },
  typo: {
    h1: {
      fontFamily: 'Circe',
      fontSize: '56px',
      lineHeight: '70px',
      letterSpacing: '0.05em',
      ...unixStringCompensation()
    },
    h2: {
      fontFamily: 'Circe',
      fontSize: '48px',
      lineHeight: '62px',
      ...unixStringCompensation()
    },
    h3: {
      fontFamily: 'Circe',
      fontSize: '36px',
      lineHeight: '48px',
      ...unixStringCompensation()
    },
    h4: {
      fontSize: '24px',
      lineHeight: '36px',
      ...unixStringCompensation()
    },
    body: {
      fontFamily: 'Circe',
      fontSize: '24px',
      lineHeight: '36px',
      ...unixStringCompensation()
    },
    bodySmall: {
      fontFamily: 'Circe',
      fontSize: '18px',
      lineHeight: '24px',
      ...unixStringCompensation()
    },
    mob: {
      h1: {
        fontFamily: 'Circe',
        fontSize: '36px',
        lineHeight: '48px',
        letterSpacing: '0.05em',
        ...unixStringCompensation()
      },
      h2: {
        fontFamily: 'Circe',
        fontSize: '32px',
        lineHeight: '40px',
        ...unixStringCompensation()
      },
      h3: {
        fontFamily: 'Circe',
        fontSize: '24px',
        lineHeight: '32px',
        ...unixStringCompensation()
      },
      h4: {
        fontFamily: 'Circe',
        fontSize: '16px',
        lineHeight: '24px',
        ...unixStringCompensation()
      },
      body: {
        fontFamily: 'Circe',
        fontSize: '16px',
        lineHeight: '24px',
        ...unixStringCompensation()
      },
      bodySmall: {
        fontFamily: 'Circe',
        fontSize: '16px',
        lineHeight: '18px',
        ...unixStringCompensation()
      },
    }
  },
  breakpoints: {
    sm: '600px',
    md: '900px',
    lg: '1240px',
  }
};

export const respondTo = Object.keys(commonStyles.breakpoints).reduce(
  (accumulator, label) => {
    accumulator[label] = (...args) => css`
      @media (min-width: ${commonStyles.breakpoints[label]}) {
        ${css(...args)};
      }
    `;
    return accumulator;
  },
  {}
);

export default commonStyles;
