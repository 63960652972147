import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Text, IconFont } from '../../shared-components';
import { CardContainer, CardSection, Thumbnail } from './ProductCard.style';
import foodImg from '../../../assets/img/info_icons/food_placeholder.png';
import { formatPrice } from '../../../helpers/priceHelpers';
import commonStyles from '../../commonStyles';

const ProductCard = (props) => {
  const { className, item, showPlus } = props;
  const { name, img, description, price } = item;
  const [priceText, setPriceText] = useState(formatPrice(price));
  const [modalMandatory, setModalMandatory] = useState(false);

  useEffect(() => {
    setModalMandatory(!!item.attributeGroup?.length || !!item.pfand);
    let price = formatPrice(item.price);
    const hasLooseSizePriceIndex = item.attributeGroup && item.attributeGroup.findIndex(a => !a.addToPrice);
    if (hasLooseSizePriceIndex >= 0) {
      price = `ab ${formatPrice(item.attributeGroup[hasLooseSizePriceIndex].attributes[0].price)}`;
    }
    setPriceText(price);
  }, [item]);

  const handlePlusClick = (e) => {
    e.stopPropagation();
    props.handlePlusClick(modalMandatory)
  }

  return (
    <CardContainer className={className} onClick={props.handleCardClick}>
      <CardSection className="card__section--" align="center">
        <Thumbnail img={img || foodImg}/>
        <Text className="card__text card__text--title" type="bodySmall" weight="700">{name}</Text>
      </CardSection>
      <CardSection className="card__section--description">
        <Text className="card__text card__text--description" type="bodySmall">{description}</Text>
      </CardSection>
      <CardSection className="card__section--price" align="center">
        <Text
          className="card__text"
          type="h4"
          weight="700"
        >
          {priceText}{modalMandatory ? '*' : ''}
        </Text>
        {showPlus &&
          <IconFont
            className="card__button"
            size="28px"
            icon="plus"
            color={commonStyles.colors.primary}
            onClick={handlePlusClick}
          />}
      </CardSection>
    </CardContainer>
  );
};

ProductCard.propTypes = {
  item: PropTypes.object,
  showPlus: PropTypes.bool,
  className: PropTypes.string,
  handleCardClick: PropTypes.func,
  handlePlusClick: PropTypes.func,
}

ProductCard.defaultProps = {
  showPlus: true,
}

export default ProductCard;
