import styled from 'styled-components';
import { respondTo } from '../commonStyles';

export const Wrapper = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: column;
  
  ${respondTo.md`
    flex-direction: row;
    & .items_list-grid_container {
      padding: 0 5px;
    }
  `}
`;
