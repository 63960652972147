import React from 'react';
import PropTypes from 'prop-types';
import { IconFont, Text } from '../../shared-components';
import { CartQtyContainer } from './CartItems.style';

const CartItemQtyControl = props => {
  const { qty, isDisabled } = props;

  const handleQtyChange = (isSubtract = false) => {
    if (!isSubtract) {
      props.onChange(qty + 1);
    } else {
      if (qty > 1) {
        props.onChange(qty - 1);
      } else {
        props.onRemove()
      }
    }
  }

  return (
    <CartQtyContainer>
      <div className={`iconContainer${isDisabled ? ' disabled' : ''}`}>
        {!isDisabled && <IconFont icon={'minus'} size={18} onClick={() => handleQtyChange(true)}/>}
      </div>
      <Text type={'body'} className={'cart-modal_itemQty'}>{qty}</Text>
      <div className={`iconContainer${isDisabled ? ' disabled' : ''}`}>
        {!isDisabled && <IconFont icon={'plus'} size={28} onClick={() => handleQtyChange()}/>}
      </div>
    </CartQtyContainer>
  );
};

CartItemQtyControl.propTypes = {
  qty: PropTypes.number,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

export default CartItemQtyControl;
