import React from 'react';
import PropTypes from 'prop-types';
import IcomoonReact, { iconList as list } from 'icomoon-react';
import iconSet from '../../../assets/icons/selection';
import 'rc-tooltip/assets/bootstrap.css';
import { TooltipWrapper } from './IconFont.style';

// To console.log all icons use: iconList(iconSet) function
const IconFont = (props) => {
  const { color, size, icon, className, tooltip } = props;
  if (!!tooltip) {
    return <TooltipWrapper tooltip={tooltip}>
      <IcomoonReact
        className={className}
        iconSet={iconSet}
        color={color}
        size={size}
        icon={icon}
        onClick={props.onClick}
      />
    </TooltipWrapper>;
  }
  return <IcomoonReact
    className={className}
    iconSet={iconSet}
    color={color}
    size={size}
    icon={icon}
    onClick={props.onClick}
  />;
};

IconFont.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  tooltip: PropTypes.string,
};

IconFont.defaultProps = {
  color: undefined,
  className: undefined,
};

const iconList = () => list(iconSet);

export { IconFont as default, iconList };
