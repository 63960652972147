import styled from 'styled-components';
import commonStyles, { respondTo } from '../../commonStyles';

export const Categories = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 30px;

  & .card-text {
    text-transform: capitalize;
  }

  ${respondTo.md`
    height: 35px;
    display: flex;
  `}
`;

export const CardFooterSection = styled.div`
  display: flex;
  align-items: end;

  & > * {
    margin-right: 8px;

    &:last-child {
      margin: 0;
    }
  }

  ${respondTo.md`
    align-items: initial;
  `}

`;
export const CardFooter = styled.div`
  position: absolute;
  height: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 16px;
  background-color: ${commonStyles.colors.alphaString(commonStyles.colors.primary, 0.9)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  ${respondTo.md`
    height: 50px;
    padding: 0 20px;
  `}
`;

export const CardContainerImg = styled.div`
  background: ${({ img }) => (img && `url(${img}) no-repeat`)};
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
`;

export const CardContainer = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 343px;
  height: 140px;
  box-shadow: 0 36px 47px -18px rgba(0, 29, 35, 0.35);
  border-radius: 5px;
  margin: 0 12px 24px;
  box-sizing: border-box;
  padding: 0 16px 40px;
  overflow: hidden;

  & .card-text {
    color: ${commonStyles.colors.white};
    z-index: 2;
  }

  & .card-title-container {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & .card-title {
      display: -webkit-box;
      text-align: center;
      line-height: 38px;
      color: ${commonStyles.colors.white};
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    & .card-will-open-text {
      color: ${commonStyles.colors.grey};
      font-weight: 400;
    }

    ${respondTo.md`
      height: 110px;
    `}
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${({ isOpened }) => commonStyles.colors.alphaString(commonStyles.colors.primary, isOpened ? 0.4 : 0.9)};
    z-index: 1;
  }

  &:hover {
    cursor: pointer;
  }

  ${respondTo.md`
    margin: 0 15px 40px;
    padding-bottom: 50px;
    width: 300px;
    height: 200px;
    &:hover {
      & ${CardContainerImg} {
        transform: scale(1.05,1.05);
      }
    }
  `}
`;
