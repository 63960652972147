import { KEYS } from '../../keys';
import api, { getTokenHeader } from './api';

export async function recalculateCart(token, providerSlug, body) {
  const headers = getTokenHeader(token);
  const options = {
    url: `${KEYS.API_URL}/api/booker/recalculate-cart/${providerSlug}`,
    data: body,
    ...headers,
  };
  return api.post(options);
}

export async function getAvailableTimeSlots(token, providerSlug) {
  const headers = getTokenHeader(token);
  const options = {
    url: `${KEYS.API_URL}/api/booker/available-timeslots/${providerSlug}`,
    ...headers,
  };
  return api.get(options);
}

export async function placePaidOrder(token, providerSlug, orderData) {
  const headers = getTokenHeader(token);
  const options = {
    url: `${KEYS.API_URL}/api/booker/place-order/${providerSlug}`,
    data: orderData,
    ...headers,
  };
  return api.post(options);
}

export async function getOrderStatus(orderId) {
  const options = {
    url: `${KEYS.API_URL}/api/booker/order-status/${orderId}`,
  };
  return api.get(options);
}

export async function getUserOrdersList(token, data) {
  const options = {
    headers: {
      'Authorization': token,
    },
    url: `${KEYS.API_URL}/api/booker/auth/orders`,
    data
  };
  return api.post(options);
}

export async function checkPromocode(token, providerSlug, data) {
  const options = {
    headers: {
      'Authorization': token,
    },
    url: `${KEYS.API_URL}/api/booker/auth/promo/${providerSlug}`,
    data
  };
  return api.post(options);
}

export async function getUserAvailablePromos(token, providerSlug, date, amt) {
  const options = {
    headers: {
      'Authorization': token,
    },
    url: `${KEYS.API_URL}/api/booker/auth/promo/${providerSlug}?date=${date}&amt=${amt}`,
  };
  return api.get(options);
}
