import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GoogleLogin from 'react-google-login';
import { FaGoogle } from 'react-icons/fa';
import { KEYS } from '../../../keys';
import { useStores } from '../../../stores/helpers/use-stores';
import { TooltipWrapper } from '../../shared-components/IconFont/IconFont.style';

const GoogleButton = () => {
  const { t } = useTranslation();
  const { dataStores: { authStore } } = useStores();

  const onGoogleResponse = (resp) => {
    authStore.setLoaded(resp?.accessToken ? null : 'Ooops, something went wrong!');
    if (resp?.accessToken) {
      authStore.socialLogin({ ...resp, token: resp.accessToken, version: 'v2' }, 'google');
    }
  };

  const handleFail = resp => {
    authStore.setLoaded();
    console.error(resp);
  }

  return (
    <GoogleLogin
      clientId={KEYS.googleClientId}
      autoLoad={false}
      onSuccess={onGoogleResponse}
      onFailure={handleFail}
      uxMode={'popup'}
      redirectUri={`${KEYS.DOMAIN_URL}/social-callback/google`}
      onRequest={authStore.setLoading}
      accessType={'online'}
      render={renderProps => (
        <TooltipWrapper tooltip={t('auth.loginWithGoogle')}>
          <button
            className={'login-google-button social_button'}
            onClick={renderProps.onClick}
          >
            <FaGoogle size={26} className={'google_icon'}/>
          </button>
        </TooltipWrapper>
      )}
    />
  );
};

export default observer(GoogleButton);
