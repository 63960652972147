import styled from 'styled-components';
import { respondTo } from '../../commonStyles';

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${respondTo.md`
    margin-left: -15px;
    margin-right: -15px;
  `}
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 24px;
  justify-content: center;
  & .main_food-card {
    display: none;
    &:nth-child(-n+5){
      display:flex;
    }
  }
  
  ${respondTo.md`
    & .main_food-card {
      display: flex;
    }
  `}
  
  ${respondTo.lg`
    justify-content: ${({justifyContent}) => justifyContent || 'center'};
  `}
`;
