import styled, { css } from 'styled-components';
import commonStyles from '../../../commonStyles';

export const CustomOption = styled.div`
  padding: 8px;
  position: relative;
  cursor: pointer;
  background-color: ${commonStyles.colors.white};
  color: ${commonStyles.colors.primary};
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
  min-height: 36px;
  display: flex;
  align-items: center;
  font-size: 16px;
  border-bottom-color: ${commonStyles.colors.alphaString(commonStyles.colors.grey, 0.2)};
  border-bottom-style: solid;
  border-bottom-width: 2px;
  ${(props) => !props.isSelected && css`
    &:hover {
      cursor: pointer;
      background: ${commonStyles.colors.alphaString(commonStyles.colors.primary, 0.8)};
      color: ${commonStyles.colors.white};
    }
  `}
  ${(props) => props.isSelected && css`
    && {
      background: ${commonStyles.colors.primary};
      color: ${commonStyles.colors.white};
    }
  `}
  ${(props) => props.isFocused && !props.isSelected && css`
    && {
      background: ${commonStyles.colors.alphaString(commonStyles.colors.primary, 0.8)};
      color: ${commonStyles.colors.white};
    }
  `}
`;
