import _ from 'lodash';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { isHydrated, makePersistable } from 'mobx-persist-store';
import moment from 'moment';
import momentTz from 'moment-timezone';
import DiscountTypes from '../../../constants/discountTypes';
import { Analytics } from '../../../helpers/analytics';
import {
  checkPromocode,
  getAvailableTimeSlots,
  getUserAvailablePromos,
  placePaidOrder,
  recalculateCart
} from '../../../utils/api/order';
import { getAvailableStorage } from '../../storage';

const getCETOffset = (date) => momentTz(date).tz('CET').utcOffset();

export default class OrderStore {
  @observable deliveryDate = null;
  @observable clientPhoneNo = '';
  @observable clientEmail = '';
  @observable clientOrderName = '';
  @observable clientOrderLName = '';
  @observable orderComment = '';
  @observable paymentMethod = 'paypal';
  @observable slotLength = 45;
  @observable discountData = null;
  @observable discountCoupon = null;
  @observable priceData = null;
  @observable orderItems = [];
  @observable itemsQty = 0;
  @observable orderAmt = 0;
  @observable minimumOrderDifference = 0;
  @observable additionalFields = null;
  @observable hasAdultItems = false;
  @observable hasPfand = false;
  @observable orderProvider = null;
  @observable agreedOnPfand = false;

  @observable availableTimeSlotsData = null;

  @observable createdOrderData = null;
  @observable adjustedDate = null;
  @observable placingOrder = false
  @observable orderPlaceError = false;
  @observable availableDiscounts = null;
  @observable promocodeMessage = null;
  @observable promocode = null;

  @observable limitedProductsModalState = {
    visible: false,
    onSubmit: () => {}
  }

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    makePersistable(this, {
      name: 'UserData',
      properties: [
        'clientPhoneNo',
        'clientEmail',
        'clientOrderName',
        'clientOrderLName',
        'paymentMethod',
      ],
      storage: getAvailableStorage()
    }, {
      delay: 200,
    });
    makePersistable(this, {
      name: 'OrderData',
      properties: [
        'discountData',
        'priceData',
        'orderItems',
        'hasAdultItems',
        'itemsQty',
        'orderAmt',
        'orderProvider',
        'additionalFields',
        'availableTimeSlots',
        'orderComment',
      ],
      storage: window.localStorage,
      expireIn: 24 * 60 * 60 * 100,
      removeOnExpiration: false,
    }, {
      delay: 200,
    });
  }

  @action setOrderComment = (comment = '') => {
    this.orderComment = comment;
  }

  @action setClientPhone = (phone = '') => {
    this.clientPhoneNo = phone;
  }

  @action updatePromoCode = (code = '') => {
    this.promocode = code;
  }

  @action resetPromocode = () => {
    this.promocode = null;
    this.promocodeMessage = null;
    this.discountData = null;
    this.discountCoupon = null;
  }

  @action setClientLName = (lname = '') => {
    this.clientOrderLName = lname;
  }

  @action setClientName = (fname = '') => {
    this.clientOrderName = fname;
  }

  @action setClientEmail = (email = '') => {
    this.clientEmail = email;
  }

  @action setPaymentMethod = (method = 'paypal') => {
    this.paymentMethod = method;
  }

  @action setSlotLength = (length = 45) => {
    this.slotLength = length;
  }

  @action checkAdultItems = () => {
    this.hasAdultItems = this.orderItems.some(i => i.category?.adultOnly);
  }

  @action checkPfand = () => {
    this.hasPfand = this.orderItems.some(i => i.name === 'Pfand');
  }

  @action toggleAgreedOnPfand = () => {
    this.agreedOnPfand = !this.agreedOnPfand;
  }

  @action setOrderProvider = (provider = null) => {
    this.orderProvider = provider;
  }

  @action checkAndUpdateDeliveryDate = date => {
    const unavailableItemIds = this.checkOrderListAvailability(date);
    if (!unavailableItemIds?.length) {
      this.updateDeliveryDate(date);
    } else {
      this.showLimitedProductsModalState(unavailableItemIds, date);
    }
  }

  @action updateDeliveryDate = (date) => {
    this.deliveryDate = date;
    if (date) {
      this.adjustedDate = date;
    }
    // this.rootStore.dataStores.servicesStore.setOrderDate(date);
  }

  @action showLimitedProductsModalState = (unavailableItemIds, date) => {
    const orderItems = this.orderItems;
    this.limitedProductsModalState = {
      visible: true,
      onSubmit: () => {
        this.updateCart(orderItems.filter(item => !unavailableItemIds.includes(item.id)));
        this.updateDeliveryDate(date);
        this.hideLimitedProductsModalState();
      }
    }
  }

  @action hideLimitedProductsModalState = () => {
    this.limitedProductsModalState = {
      visible: false,
      onSubmit: () => {}
    }
  }

  @action orderPlaceLoading = () => {
    this.createdOrderData = null;
    this.placingOrder = true;
    this.orderPlaceError = false;
  }

  @action orderPlaceLoaded = (order = null) => {
    this.createdOrderData = order;
    this.placingOrder = false;
    this.orderPlaceError = !order;
  }

  checkOrderListAvailability = (date) => {
    const items = this.orderItems;
    const itemsWithLimitedVisibility = items.filter(item => item.category?.hasVisibilityLimitations && !!item.category?.visibilityData);
    if (!itemsWithLimitedVisibility?.length) return null;
    const unavailableIds = [];
    const preferredDateDay = moment(date).day();
    itemsWithLimitedVisibility.forEach(item => {
      const limitDayData = item.category.visibilityData.find((dt) => dt.day === preferredDateDay);
      if (limitDayData) {
        if (!limitDayData.isActive) {
          unavailableIds.push(item.id);
          return;
        }
        const [fromHrs, fromMins] = limitDayData.from.split(':');
        const [toHrs, toMins] = limitDayData.to.split(':');
        const limitStartDate = moment(date)
          .utcOffset(getCETOffset(date))
          .hours(fromHrs)
          .minutes(fromMins);
        const limitEndDate = moment(date).utcOffset(getCETOffset(date)).hours(toHrs).minutes(toMins);
        if (!moment(date).isBetween(limitStartDate, limitEndDate, undefined, '[]')) {
          unavailableIds.push(item.id);
        }
      }
    });
    return unavailableIds;
  };

  calculateNearestDeliveryDate = (desiredDate, availableSlots) => {
    const { nearestDelivery, deliveryDates } = availableSlots;
    const checkDate = moment(desiredDate ?? undefined);
    const desiredDateKey = checkDate.format('DD.MM');
    const slots = deliveryDates[desiredDateKey];
    if (!slots || !slots.length) {
      return moment(nearestDelivery ? nearestDelivery.from : undefined).toDate();
    }
    const firstSlotDate = moment(slots[0]?.from);
    if (firstSlotDate) {
      const slotLength = moment(slots[0].to).diff(firstSlotDate, 'minute');
      this.setSlotLength(slotLength);
    }
    const lastSlotDate = moment(slots[slots.length - 1]?.from);
    if (checkDate.isBefore(firstSlotDate)) {
      return firstSlotDate.toDate();
    }
    if (checkDate.isAfter(lastSlotDate)) {
      return lastSlotDate.toDate();
    }
    const fullSlot = slots.find((s) =>
      checkDate.isBetween(moment(s.from), moment(s.to), undefined, '[]')
    );
    if (!!fullSlot) return moment(fullSlot.from).toDate();
    const earlierSlot = slots.find((s, i) => {
      const nextSlot = slots[i + 1];
      if (!nextSlot) return true;
      return checkDate.isBetween(moment(s.to), moment(nextSlot.from), undefined, '[]');
    });
    return moment(earlierSlot ? earlierSlot.from : undefined).toDate();
  };

  @action getNearestDeliveryDate = () => {
    this.updateDeliveryDate(null);
    const desiredDate = this.adjustedDate ?? this.rootStore.dataStores.servicesStore.desiredOrderDate;
    const availableSlots = this.availableTimeSlotsData;
    if (!availableSlots) return;
    const nearestDate = this.calculateNearestDeliveryDate(desiredDate, availableSlots);
    this.checkAndUpdateDeliveryDate(nearestDate);
  }

  @action addToCart = (product) => {
    try {
      const qty = product.qty;
      const currentProvider = this.rootStore.dataStores.providersStore.selectedProvider;
      if (!currentProvider) return;
      if (this.orderItems.length && this.orderItems.some(i => i.providerId !== currentProvider._id)) {
        return this.rootStore.dataStores.differentProviderModalStore.showModal(product);
      }
      Analytics.addToCartEvent({
        ...product,
        qty
      }, currentProvider, this.rootStore.dataStores.servicesStore.selectedService);
      let currentItems = _.cloneDeep(this.orderItems);
      const pfandItem = product.pfand;
      const existingItemIndex = currentItems.findIndex(item => {
        const listItem = _.cloneDeep(item);
        const addItem = _.cloneDeep(product);
        delete listItem.qty;
        delete addItem.qty;
        delete listItem.providerId;
        return _.isEqual(listItem, addItem);
      });
      if (existingItemIndex >= 0) {
        currentItems[existingItemIndex].qty += qty;
      } else {
        currentItems.push({ ...product, qty, providerId: currentProvider._id });
      }
      if (pfandItem) {
        const existingPfandIndex = currentItems.findIndex(i => i.artNo === pfandItem.artNo);
        if (existingPfandIndex >= 0) {
          currentItems[existingPfandIndex].qty += qty;
        } else {
          currentItems.push({
            ...pfandItem,
            id: pfandItem.productId,
            itemPrice: pfandItem.price,
            qty,
            providerId: currentProvider._id
          });
        }
      }
      if (!this.orderProvider || this.orderProvider._id !== currentProvider._id) {
        this.orderProvider = currentProvider;
      }
      this.updateCart(currentItems);
    } catch (e) {
      console.error('Error adding Item to cart', e);
    }
  }

  @action updateCart = (orderItems) => {
    this.itemsQty = orderItems.reduce((aggr, item) => aggr + item.qty, 0);
    this.orderAmt = orderItems.reduce((aggr, item) => aggr + item.price * item.qty, 0);
    this.orderItems = orderItems;
    this.checkAdultItems();
    this.checkPfand();
    this.recalculateCart();
  }

  @action getAvailableTimeSlots = async () => {
    try {
      const bookerToken = this.rootStore.dataStores.servicesStore.bookerToken;
      const providerSlug = this.orderProvider?.slug;
      if (!providerSlug) return;
      const resp = await getAvailableTimeSlots(bookerToken, providerSlug);
      if (resp.success) {
        runInAction(() => {
          this.availableTimeSlotsData = resp.data;
          this.getNearestDeliveryDate();
        })
      } else {
        console.error('Failed to get available timeSlots', resp.message);
      }
    } catch (e) {
      console.error('Error getting available timeSlots', e);
    }
  }

  @action recalculateCart = _.debounce(async () => {
    try {
      const bookerToken = this.rootStore.dataStores.servicesStore.bookerToken;
      const providerSlug = this.orderProvider?.slug;
      if (!this.orderItems?.length) {
        return;
      }
      const resp = await recalculateCart(
        bookerToken,
        providerSlug,
        {
          orderItems: this.orderItems,
          discountData: this.discountData
        }
      );
      if (resp.success) {
        const { priceData, itemsQty } = resp.data;
        runInAction(() => {
          this.priceData = priceData;
          this.itemsQty = itemsQty;
        });
      } else {
        console.error('Failed to recalculate cart', resp.message);
      }
    } catch (e) {
      console.error('Error recalculating cart', e);
    }
  }, 400);

  @action recalculateMinOrderDifference = () => {
    if (!this.orderProvider) {
      this.minimumOrderDifference = 0;
      return;
    }
    this.minimumOrderDifference = this.orderProvider.providerData.minimumOrder - this.orderAmt;
  }

  get isHydrated() {
    return isHydrated(this);
  }

  @action resetCart = () => {
    this.orderProvider = null;
    this.resetPromocode();
    this.updateCart([]);
  }

  @action clearCreatedOrder = () => {
    this.createdOrderData = null;
    this.orderPlaceError = false;
  }

  @action placeOrder = async (paypalPayload) => {
    try {
      this.orderPlaceLoading();
      const bookerToken = this.rootStore.dataStores.servicesStore.bookerToken;
      const providerSlug = this.orderProvider.slug;
      Analytics.purchaseEvent(paypalPayload.orderID, this.orderItems, this.priceData, this.orderProvider, this.rootStore.dataStores.servicesStore.selectedService);
      const order = {
        deliveryDate: this.deliveryDate,
        deliveryPlace: this.rootStore.dataStores.servicesStore.deliveryPlace,
        fullDeliveryAddress: this.rootStore.dataStores.servicesStore.fullDeliveryAddress,
        isSelfPickup: false,
        isSelfDelivery: this.orderProvider.providerData.hasOwnDelivery,
        clientPhoneNo: this.clientPhoneNo,
        clientEmail: this.clientEmail,
        clientOrderLName: this.clientOrderLName,
        clientOrderName: this.clientOrderName,
        orderItems: this.orderItems,
        itemsQty: this.itemsQty,
        priceData: this.priceData,
        orderComment: this.orderComment,
        paymentMethod: 'paypal',
        slotLength: this.slotLength,
        discountData: this.discountData,
        discountCoupon: this.discountCoupon,
        additionalFields: {
          agreedOnPfand: this.agreedOnPfand,
          paypalPayload,
        }
      };
      const client = this.rootStore.dataStores.authStore.userData?._id;
      if (client) {
        order.client = client;
      }
      const resp = await placePaidOrder(
        bookerToken,
        providerSlug,
        order
      );
      if (resp.success) {
        this.orderPlaceLoaded(resp.data);
      } else {
        this.orderPlaceLoaded();
        console.error('Failed to recalculate cart', resp.message);
      }
    } catch (e) {
      console.error('Failed to place order', e);
    }
  }

  @action getAvailablePromos = _.debounce(async () => {
    try {
      const token = this.rootStore.dataStores.authStore.getUserToken();
      if (!token) {
        return;
      }
      const providerSlug = this.orderProvider.slug;
      const resp = await getUserAvailablePromos(token, providerSlug, moment(this.deliveryDate).toISOString(), this.orderAmt);
      runInAction(() => {
        this.availableDiscounts = resp.success ? resp.data : null;
      });
    } catch (e) {
      console.error('Failed to get available promos');
    }
  }, 400)

  @action checkPromocode = async () => {
    try {
      const token = this.rootStore.dataStores.authStore.getUserToken();
      if (!token) {
        return;
      }
      runInAction(() => {
        this.placingOrder = true;
      });
      const providerSlug = this.orderProvider.slug;
      const data = {
        paymentMethod: 'paypal',
        deliveryType: 'delivery',
        code: this.promocode,
        date: this.deliveryDate,
        orderAmount: this.orderAmt,
      };
      const resp = await checkPromocode(token, providerSlug, data);
      if (resp.success) {
        runInAction(() => {
          this.discountData = resp.data;
          this.promocodeMessage = resp.message;
        });
      } else {
        this.promocodeMessage = resp.message;
      }
      runInAction(() => {
        this.placingOrder = false;
      });
    } catch (e) {
      console.error('Failed to check promocode');
    }
  }

  @action updateDiscountData = (data = null) => {
    this.discountData = data;
    if (data?.type === DiscountTypes.walletCoupon) {
      this.discountCoupon = data.couponId;
    } else {
      this.discountCoupon = null;
    }
  }
}
