import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useStores } from '../../../stores/helpers/use-stores';
import { Text } from '../../shared-components';
import { InfoWrapper, LoyaltyWrapper, TitleWrapper, YearlyOrdersWrapper } from './Loyalty.style';
import LoyaltyInfoModal from './LoyaltyInfoModal';
import LoyaltyItems from './LoyaltyItems';
import LoyaltyProgressBar from './LoyaltyProgressBar';
import PointsProgressCircle from './PointsProgressCircle';

const UserLoyalty = () => {
  const { t } = useTranslation();
  const { dataStores: { userStore, authStore } } = useStores();
  const [infoModalVisible, toggleInfoModal] = useState(false);

  const { loyaltyList } = userStore;
  const { userData } = authStore;

  const loyalties = useMemo(() => {
    const list = toJS(loyaltyList);
    if (!list?.length) return null;
    return list.sort((a, b) => a.minTrips - b.minTrips);
  }, [loyaltyList]);

  useEffect(() => {
    userStore.getLoyaltyList();
    // eslint-disable-next-line
  }, []);

  const showStatusInfoModal = () => {
    toggleInfoModal(true);
  };

  if (!userData || !loyalties?.length) {
    return null;
  }

  return (
    <LoyaltyWrapper>
      <TitleWrapper color={userData?.loyalty?.color}>
        <Text
          type={'h4'}
          className={'current_status'}
        >
          {t('userLoyalty.yourStatus')}<span>{userData?.loyalty?.name}</span>
        </Text>
        <InfoWrapper onClick={showStatusInfoModal}>
          <AiOutlineInfoCircle className={'status_info_icon'} size={18}/>
        </InfoWrapper>
      </TitleWrapper>
      <LoyaltyProgressBar
        loyaltyList={loyalties}
        currentLoyalty={userData?.loyalty}
        ordersNumber={userData?.totalRides + userData?.totalOrders}
      />
      <YearlyOrdersWrapper>
        <Text type={'body'} className={'user_orders_number'}>
          {t('userLoyalty.tripsTaken')}
          <span>{userData.totalRides}</span>
        </Text>
        <Text type={'body'} className={'user_orders_number'}>
          {t('userLoyalty.foodsTaken')}
          <span>{userData.totalOrders}</span>
        </Text>
      </YearlyOrdersWrapper>
      <PointsProgressCircle
        color={userData.loyalty?.color}
        currentPoints={userData.walletData?.pointsBalance}
        maxPoints={userData.walletData?.pointsConversionAmt}
      />
      <LoyaltyItems
        loyalties={loyalties}
        activeLoyaltyName={userData.loyalty?.name}
        inviteBonus={userData.walletData?.inviteBonusAmt}
      />
      <LoyaltyInfoModal visible={infoModalVisible} onHide={() => toggleInfoModal(false)}/>
    </LoyaltyWrapper>
  );
};

export default observer(UserLoyalty);
