import Tooltip from 'rc-tooltip';
import React from 'react';
import styled from 'styled-components';

export const TooltipWrapper = styled(Tooltip).attrs((props) => ({
  placement: 'bottom',
  trigger: ['click', 'hover'],
  overlay: <span>{props.tooltip}</span>,
  mouseEnterDelay: 0.2,
  overlayInnerStyle: {
    minHeight: 'unset'
  },
  overlayStyle: { zIndex: 20000 /* because modal is 1050 */ }
}))`
`;
