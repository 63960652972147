import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../stores/helpers/use-stores';
import { Text } from '../../shared-components';
import { BlockWrapper, DescWrapper, FeatureWrapper } from './Referrals.style';
import { ReactComponent as Invite1Icon } from '../../../assets/svg/invite-friends-1-icon.svg';
import { ReactComponent as Invite2Icon } from '../../../assets/svg/invite-friends-2-icon.svg';

const ReferralsGeneralInfo = () => {
  const { t } = useTranslation();
  const { dataStores: { authStore: { userData } } } = useStores();

  return (
    <BlockWrapper>
      <Text type={'h4'} className={'code_title'}>{t('referrals.codeTitle')}:</Text>
      <Text type={'h3'} className={'code'}>{userData.referralCode}</Text>
      <FeatureWrapper>
        <Invite1Icon className={'referral_icon'}/>
        <Text
          type={'body'}
          weight={700}
        >
          {t('referrals.freeUpTo', { discount: userData?.walletData?.inviteBonusAmt })}
        </Text>
      </FeatureWrapper>
      <FeatureWrapper>
        <Invite2Icon className={'referral_icon'}/>
        <Text
          type={'body'}
          weight={700}
        >
          {t('referrals.inReturn', { discount: userData?.walletData?.bonusForInvited })}
        </Text>
      </FeatureWrapper>
      <DescWrapper>
        <li>
          <Text
            type={'bodySmall'}
            className={'desc_text'}
          >
            {t('referrals.shareCodeDescription1', { discount: userData?.walletData?.inviteBonusAmt })}
          </Text>
        </li>
        <li>
          <Text
            type={'bodySmall'}
            className={'desc_text'}
          >
            {t('referrals.shareCodeDescription2', { discount: userData?.walletData?.bonusForInvited })}
          </Text>
        </li>
      </DescWrapper>
    </BlockWrapper>
  );
};

export default observer(ReferralsGeneralInfo);
