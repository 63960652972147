const AuthModalState = {
  login: 'login',
  register: 'register',
  socialRegister: 'socialRegister',
  mobileConfirm: 'mobileConfirm',
  passReset: 'passReset',
  passResetConfirm: 'passResetConfirm',
  changePass: 'changePass',
};

export default AuthModalState;
