const isLocalStorageSupported = () => {
  try {
    const testKey = "local_storage_availability_test";
    window.localStorage.setItem(testKey, testKey);
    window.localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
}

export const getAvailableStorage = () => {
  if (isLocalStorageSupported()) {
    return window.localStorage;
  }
  return window.sessionStorage;
}
