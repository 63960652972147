import { createBrowserHistory } from 'history';
import makeInspectable from 'mobx-devtools-mst';
import { syncHistoryWithStore } from 'mobx-react-router';
import { createContext } from 'react';
import { createStore } from './create-store';
import { useLocalObservable } from 'mobx-react-lite';

export const StoreContext = createContext({});

export const StoreProvider = ({ children }) => {
  const rootStore = useLocalObservable(() => createStore());
  const browserHistory = createBrowserHistory();
  const history = syncHistoryWithStore(browserHistory, rootStore.routeStore);
  makeInspectable(rootStore);

  return (
    <StoreContext.Provider value={rootStore} routing={rootStore.routeStore} history={history}>
      {children}
    </StoreContext.Provider>
  )
};

