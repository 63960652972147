import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { useDrag } from '../../../hooks/useDrag';
import { usePreventBodyScroll } from '../../../hooks/usePreventScroll';
import { Text } from '../../shared-components';
import {
  CategoriesSideBarContainer,
  CategoryItemContainer,
  HorizontalCategoriesContainer, HorizontalCategoryItem
} from '../Categories/Categories.style';

const TagsList = props => {
  const { t } = useTranslation();
  const { tags, selectedTag, selectedService } = props;
  const { dragStart, dragStop, dragMove, onWheel } = useDrag();
  const { disableScroll, enableScroll } = usePreventBodyScroll();
  const scrollRef = useRef();

  const handleDrag = ({ scrollContainer }) => (ev) =>
    dragMove(ev, (posDiff) => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft += posDiff;
      }
    });

  const onTagSelect = (tag = null) => {
    if (!tag || tag === t('products.showAll')) {
      props.onTagSelect(null);
    } else {
      props.onTagSelect(tag);
    }
  }

  if (!tags?.length) return null;

  return (
    <>
      <HorizontalCategoriesContainer>
        <div
          onMouseLeave={() => {
            enableScroll();
            dragStop();
          }}
          onMouseEnter={disableScroll}
        >
          <ScrollMenu
            onWheel={onWheel}
            onMouseDown={() => dragStart}
            onMouseUp={() => dragStop}
            onMouseMove={handleDrag}
            apiRef={scrollRef}
          >
            {tags.map((tag, index) =>
              <HorizontalCategoryItem
                key={`horTag-${tag}-${index}`}
                active={tag === selectedTag || (tag === t('products.showAll') && !selectedTag)}
                type={selectedService}
                onClick={() => onTagSelect(tag)}
              >
                <Text type={'body'} className={'horizontal-categoryItem'}>{tag}</Text>
              </HorizontalCategoryItem>)}
          </ScrollMenu>
        </div>
      </HorizontalCategoriesContainer>
      <CategoriesSideBarContainer>
        {tags.map(tag => <CategoryItemContainer
          key={tag}
          className={'category_item-container'}
          nestingLevel={0}
          isCollapsed={true}
          isSubCategory={false}
          isActive={tag === selectedTag || (tag === t('products.showAll') && !selectedTag)}
        >
          <div
            className={'category_item-nameContainer'}
            onClick={() => onTagSelect(tag)}
          >
            <Text className={'category_item-name'} type={'bodySmall'}>{tag}</Text>
          </div>
        </CategoryItemContainer>)}
      </CategoriesSideBarContainer>
    </>
  );
};

TagsList.propTypes = {
  tags: PropTypes.array,
  selectedService: PropTypes.string,
  selectedTag: PropTypes.string,
  onTagSelect: PropTypes.func,
};

export default TagsList;
