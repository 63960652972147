import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { KEYS } from '../keys';

export const useGoogleAnalytics = (location) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    ReactGA.initialize(KEYS.analyticsDataFlow, {
      testMode: KEYS.IS_DEV
    });
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    }
  }, [location, initialized]);

  return null;
};
