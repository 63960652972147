import styled from 'styled-components';
import commonStyles, { respondTo } from '../../commonStyles';
import { Text } from '../../shared-components';

export const Label = styled(Text)`
  color: ${commonStyles.colors.white};
  white-space: nowrap;
  margin-right: 8px;
`;

export const ToolSectionElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 0;
  margin-top: 20px;
  &:last-child {
    margin-right: 0;
  }
  ${respondTo.md`
    margin-top: 0;
    margin-right: 40px;
    justify-content: center;
  `}
  
  & .multi_select {
    max-width: 180px;
    min-width: 180px;
    ${respondTo.md`
      max-width: auto;
      min-width: auto;
  `}
  }
  
  & .inverted-select {
    max-width: 180px;
    min-width: 180px;
    ${respondTo.md`
      max-width: auto;
      min-width: auto;
      &.sort_name {
        min-width: 115px;
      }
      &.sort_order {
        min-width: 161px;
      }
      &.sort_number {
        min-width: 95px;
      }
      &.order_status {
        min-width: 180px;
      }
  `}
  }
`;

export const ToolSectionPart = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  ${respondTo.md`
    flex-direction: row;
  `}
`;

export const ToolSectionContainer = styled.div.attrs(() => ({}))`
  width: 100%;
  background-color: ${commonStyles.colors.primary};
  border-radius: 5px;
  min-height: 70px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px 20px;
  box-sizing: border-box;
  flex-wrap: wrap;
  z-index: 3;
  ${respondTo.md`
    padding: 16px 20px;
    flex-direction: row;
  `}
`;
