import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../stores/helpers/use-stores';
import { Text } from '../../shared-components';
import { SearchContainer, SearchField, PressableContainer } from '../Main.style';
import FakeInputBar from './fakeInputBar';

const AddressBar = props => {
  const { t } = useTranslation();
  const { dataStores: { servicesStore, deliveryModalStore } } = useStores();
  const { deliveryPlace, currentCityName, currentCityId } = servicesStore;

  if (deliveryPlace.address) {
    return <SearchContainer>
      <Text type="h2">{t('main.deliveryToAddress')}</Text>
      <PressableContainer onClick={deliveryModalStore.showDeliveryToolModal}>
        <Text type="h1" weight="700">{deliveryPlace.address ?? '66, Karl-Marx-Straße'}</Text>
      </PressableContainer>
    </SearchContainer>;
  }

  if (!!currentCityId && currentCityName) {
    return <SearchContainer>
      <Text type="h2">{t('main.deliveryInCity', { city: currentCityName })}</Text>
      <SearchField onClick={deliveryModalStore.showDeliveryToolModal}>
        {/*<InputContainer>*/}
          <FakeInputBar />
          {/*<Input*/}
          {/*  isSearch*/}
          {/*  isMain*/}
          {/*  placeholder={t('main.addressInputPlaceholder')}*/}
          {/*  inputProps={{*/}
          {/*    onFocus: deliveryModalStore.showDeliveryToolModal*/}
          {/*  }}*/}
          {/*/>*/}
        {/*</InputContainer>*/}
      </SearchField>
    </SearchContainer>;
  }

  return (
    <SearchContainer>
      <PressableContainer onClick={deliveryModalStore.showDeliveryToolModal}>
        <Text type="h1" weight="700">{t('main.pickAddressForServicesList')}</Text>
      </PressableContainer>
    </SearchContainer>
  );
};

export default observer(AddressBar);
