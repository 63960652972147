import React, { useState } from 'react';
import { Section, SectionContent, WarningContent } from './lib.style'
import { BasicPage, IconFont, Text, Input, InfoBox, Button } from '../shared-components';
import { iconList } from '../shared-components/IconFont';
import ActionModal from '../ActionModal';
import commonStyles from '../commonStyles';
import { useTranslation } from 'react-i18next';

const Lib = () => {
  const { t } = useTranslation();
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const testOrderNumber = 'P913N540';

  return (
    <BasicPage
      type="food"
      pageTitle="Library"
      topSectionContent="test"
    >
      <Section>
        <Text type="h3">Thank you for your order Modal </Text>
        <SectionContent direction="row">
          <div onClick={() => setIsModalOpen1(true)}>Show Modal</div>
          <ActionModal
            isOpen={isModalOpen1}
            title={t('thankYouModal.title')}
            closeHandler={() => setIsModalOpen1(false)}
            type="success"
            provider="MacDonalds"
            deliveryDate="29.12"
            deliveryTime="21:30"
            footerContent={
              <>
                <Button
                  type="food"
                  text="OK"
                  onClick={() => setIsModalOpen1(false)}
                />
                <Button
                  type="ghost"
                  text={t('thankYouModal.checkStatus')}
                  href={`/order-status/${testOrderNumber}`}
                  // onClick={handleConfirmDeliveryData}
                />
              </>
            }
          />
        </SectionContent>
      </Section>
      <Section>
        <Text type="h3">Warning Modal </Text>
        <SectionContent direction="row">
          <div onClick={() => setIsModalOpen2(true)}>Show Modal</div>
          <ActionModal
            isOpen={isModalOpen2}
            title={t('warningModal.title')}
            closeHandler={() => setIsModalOpen2(false)}
            type="warning"
            imgType="search"
            footerContent={
              <>
                <Button
                  type="food"
                  text="OK"
                  onClick={() => setIsModalOpen2(false)}
                />
                <Button
                  type="ghost"
                  text={t('thankYouModal.checkStatus')}
                  href={`/order-status/${testOrderNumber}`}
                  // onClick={handleConfirmDeliveryData}
                />
              </>
            }
          >
            <WarningContent>
              <Text type="body">{t('warningModal.message1')}</Text>
              <Text type="body">{t('warningModal.message2')}</Text>
            </WarningContent>
          </ActionModal>
        </SectionContent>
      </Section>
      <Section>
        <Text type="h3">Icons (hover to get icon name)</Text>
        <SectionContent direction="row">
          {iconList().map((name, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`${i}-${name}`} title={name} style={{ padding: '7px' }}>
              <IconFont icon={name} size="1.6rem" color={commonStyles.colors.primary} />
            </div>
          ))}
        </SectionContent>
      </Section>
      <Section>
        <Text type="h3">Input</Text>
        <SectionContent>
          <Input isSearch isMain placeholder="Search"/>
        </SectionContent>
      </Section>
      <Section>
        <Text type="h3">Info Box</Text>
        <SectionContent direction="column">
          <div style={{maxWidth: '600px', marginBottom: '20px'}}>
            <InfoBox type="white" imgType="alert">Due to the high load, orders may be delivered with a delay</InfoBox>
          </div>
          <div style={{maxWidth: '600px', marginBottom: '20px'}}>
            <InfoBox type="food" imgType="calendar">Due to the high load, orders may be delivered with a delay</InfoBox>
          </div>
          <div style={{maxWidth: '600px', marginBottom: '20px'}}>
            <InfoBox type="mahlzeit" imgType="dish">Due to the high load, orders may be delivered with a delay</InfoBox>
          </div>
          <div style={{maxWidth: '600px', marginBottom: '20px'}}>
            <InfoBox type="primary" imgType="flag">Due to the high load, orders may be delivered with a delay</InfoBox>
          </div>
          <div style={{maxWidth: '600px', marginBottom: '20px'}}>
            <InfoBox type="food" imgType="grocery">Due to the high load, orders may be delivered with a delay</InfoBox>
          </div>
          <div style={{maxWidth: '600px', marginBottom: '20px'}}>
            <InfoBox type="mahlzeit" imgType="search">Due to the high load, orders may be delivered with a delay</InfoBox>
          </div>
          <div style={{maxWidth: '600px', marginBottom: '20px'}}>
            <InfoBox type="primary" imgType="slow">Due to the high load, orders may be delivered with a delay</InfoBox>
          </div>
        </SectionContent>
      </Section>
    </BasicPage>
  )
};

export default Lib;
