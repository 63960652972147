import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineSwap } from 'react-icons/ai';
import { useStores } from '../../../stores/helpers/use-stores';
import commonStyles from '../../commonStyles';
import { Button, Text } from '../../shared-components';
import { WalletDataWrapper, WalletItemTitleWrapper, WalletMainRow } from './WalletInfo.syle';

const WalletDataItem = props => {
  const { title, value, description, type } = props;
  const { dataStores: { userStore } } = useStores();

  const { userTransactions } = userStore;

  const transactions = useMemo(() => {
    if (!userTransactions) return [];
    return toJS(userTransactions).filter(t => t.type === type).sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
  }, [type, userTransactions]);

  const showTransactions = () => {
    props.onTransactionsShow(type, transactions);
  };

  return (
    <WalletDataWrapper>
      <WalletMainRow>
        <WalletItemTitleWrapper>
          <Text type={'h3'} className={'wallet_item_title'} weight={700}>{title}</Text>
          {!!transactions?.length &&
            <Button
              type={'primary'}
              className={'transactions_button'}
              content={<AiOutlineSwap className={'transactions_icon'} style={{ fill: commonStyles.colors.white }} size={18}  />}
              onClick={showTransactions}
            />}
        </WalletItemTitleWrapper>
        <Text type={'h3'} className={'wallet_item_value'} weight={700}>{value}</Text>
      </WalletMainRow>
      <Text type={'bodySmall'} className={'wallet_desc'}>{description}</Text>
    </WalletDataWrapper>
  );
};

WalletDataItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  onTransactionsShow: PropTypes.func,
};

export default observer(WalletDataItem);
