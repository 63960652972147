import moment from 'moment';

export const formatDayTimeValueToDecimal = (val) => {
  const [strHours, strMinutes] = val.split(':');
  const minutesVal = (parseInt(strMinutes, 10) / 60).toFixed(2);
  return parseInt(strHours, 10) + parseFloat(minutesVal);
};

export const formatDayTimeValueToString = (val) => {
  const decimalHours = Math.floor(val);
  const decimalMinutes = val % 1;
  const hours = decimalHours >= 10 ? decimalHours : `0${decimalHours}`;
  let minutes = decimalMinutes ? `${Math.floor(60 * decimalMinutes)}` : '00';
  if (minutes.length === 1) {
    minutes = `0${minutes}`;
  }
  return `${hours}:${minutes}`;
};

export const generateTimesArray = (date, addTime = 40) => {
  const slotsLength = 24 * 4;
  const slots = [...new Array(slotsLength)].map((val, idx) => formatDayTimeValueToString(idx * 0.25));
  const today = moment().day();
  const dateDay = moment(date).day();
  if (today === dateDay) {
    const dateTo = moment().add(addTime, 'minutes');
    return slots.filter(timeStr => {
      const [strHours, strMinutes] = timeStr.split(':');
      return moment().hours(parseInt(strHours, 10)).minutes(parseInt(strMinutes, 10)).isAfter(dateTo);
    })
  }
  return slots;
}
