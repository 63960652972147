import styled, { css } from 'styled-components';
import commonStyles, { respondTo } from '../commonStyles';

export const HeaderSection = styled.div`
  display: flex;
  align-items: center;

  & .header__address-button {
    margin-right: 40px;
    position: relative;

    &:before {
      content: '${({ label }) => label}';
      display: none;
      border-radius: 5px;
      background-color: ${commonStyles.colors.white};
      border: 2px solid ${commonStyles.colors.primary};
      padding: 8px 16px;
      position: absolute;
      top: 60px;
      width: 250px;
    }

    &:after {
      content: '';
      display: none;
      background-color: ${commonStyles.colors.white};
      border-left: 2px solid ${commonStyles.colors.primary};
      border-top: 2px solid ${commonStyles.colors.primary};
      position: absolute;
      top: 54px;
      left: 50%;
      margin-left: -8px;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
    }

    &:hover {
      &:before, &:after {
        display: block;
      }
    }
  }

  & .login_button {
    margin-left: 16px;
    height: 40px;
    padding: 0;
    width: 40px;
    min-width: 40px;
    max-width: unset;

    ${respondTo.sm`
      width: unset;
      padding: 0 24px;
      max-width: 140px;
      height: 50px;
    `}
    &:hover, :active {
      background-color: ${({ type }) => type === 'food' ? commonStyles.colors.white : commonStyles.colors.food};

      & .button__text {
        color: ${commonStyles.colors.primary};
      }
    }
  }
`;

export const HeaderInner = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  ${respondTo.md`
    height: 64px;
    position: sticky;
    top: 0;
  `}
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 64px;
  position: sticky;
  top: 0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  align-items: center;
  z-index: 9999;

  ${({ type }) => type && css`
    background-color: ${commonStyles.colors[type]};
    ${(type !== 'white' && type !== 'food') && css`
      & .header__address-button > .button__text {
        color: ${commonStyles.colors.white};
        border-color: ${commonStyles.colors.white};

        &:hover {
          color: ${commonStyles.colors.white};
          border-color: ${commonStyles.colors.white};
        }
      }
    `}
  `}
  & .headerLogo_mob {
    width: 40px;
    height: auto;
    ${({ isMain }) => isMain && css`
      display: none;
      width: 120px;
    `}
    ${respondTo.md`
      width: 175px;
    `}
  }

  & .headerLogo {
    display: none;
    width: 120px;
    ${({ isMain }) => isMain && css`
      display: block;
    `}
  }

  ${respondTo.md`
    height: 100px;
    top: -36px;
    & .headerLogo {
      display: block;
      width: 175px;
    }
    & .headerLogo_mob {
      display: none;
    }
  `}

`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > a {
    display: flex;
  }

  & > a > img {
    transition: all 0.2s ease-in;
  }

  & > a > img:hover {
    transform: scale(1.02);
  }

  & > * {
    margin-right: 30px;

    &:last-child {
      margin: 0;
    }
  }

  ${respondTo.sm`
   & .header__address-button {
     display: block;
     & > div {
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
     }
   }
 `}
  ${respondTo.md`
   & .header__address-button {
    & > div {
      max-width: 300px;
    }
   }
 `}
`;

export const UserIconContainer = styled.div.attrs(() => ({}))`
  cursor: pointer;
  margin-left: 24px;

  & > .user_icon {
    transition: all 0.2s ease-in;
  }

  & > .user_icon:hover {
    transform: scale(1.05);
  }

  ${respondTo.md`
    position: relative;
  `}
`;

export const DropdownContainer = styled.div.attrs(() => ({}))`
  position: absolute;
  border-radius: 5px;
  background-color: ${commonStyles.colors.white};
  border: 1px solid ${commonStyles.colors.primary};
  display: ${({ visible }) => visible ? 'flex' : 'none'};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  right: 2.5vw;
  top: 60px;
  width: 95vw;
  z-index: 2000;
  box-sizing: border-box;

  & > .user-menu-item {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & > .user-menu-text {
      margin: 0;
      ${respondTo.md`
        margin-left: 16px;
      `}
    }

    &:hover {
      cursor: pointer;
      background: ${commonStyles.colors.alphaString(commonStyles.colors.primary, 0.8)};

      & > .user-menu-text {
        font-weight: 700;
        color: ${commonStyles.colors.white};
      }
    }

    ${respondTo.md`
      justify-content: flex-start;
    `}
  }

  & > .user-menu-item:not(:last-child) {
    border-bottom: 1px solid ${commonStyles.colors.primary};
  }

  ${respondTo.md`
    width: 240px;
    top: 40px;
    right: 0;
  `}
`;
