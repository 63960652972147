import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IconFont, Text } from '../index';
import { ProviderDataElement, ProviderDataWrapper } from './Layout.style';

const ProviderData = props => {
  const { provider, type } = props;
  const { t } = useTranslation();
  const providerData = useMemo(() => provider?.providerData, [provider]);

  if (!providerData) return null;

  return (
    <ProviderDataWrapper type={type}>
      <ProviderDataElement className={'provider-data-element'}>
        <IconFont size="20px" icon="wallet" className={'provider-data-icon'} tooltip={t('tooltips.minOrder')}/>
        <Text className="provider-data-text" type="bodySmall" weight="700">{providerData.minimumOrder}€</Text>
      </ProviderDataElement>
      <ProviderDataElement className={'provider-data-element'}>
        <IconFont size="20px" icon="time" className={'provider-data-icon'} tooltip={t('tooltips.deliveryFare')}/>
        <Text className="provider-data-text" type="bodySmall" weight="700">{providerData.deliveryFare}€</Text>
      </ProviderDataElement>
    </ProviderDataWrapper>
  );
};

ProviderData.propTypes = {
  provider: PropTypes.object,
  type: PropTypes.string,
};

export default ProviderData;
