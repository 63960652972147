import React from 'react';
import { StyledReactModal, ModalHeader, ModalContent, ModalFooter } from './Modal.style';
import { Text, IconFont } from '../../shared-components';
import commonStyles from '../../commonStyles';
import { useStores } from '../../../stores/helpers/use-stores';

const Modal = (props) => {
  const {
    title,
    children,
    footerContent,
    onRequestClose,
    className,
  } = props;

  const { dataStores: { deliveryModalStore } } = useStores();
  const modalCloseHandler = () => {
    if (onRequestClose) {
      onRequestClose();
      return;
    }
    deliveryModalStore.hideDeliveryToolModal();
  };

  return (
    <StyledReactModal className={className} {...props}>
      <ModalHeader>
        <Text className="modal__title" type="h3" weight={700}>{title}</Text>
        <IconFont
          className="modal__close-button"
          icon="cross"
          size="16px"
          color={commonStyles.colors.grey}
          onClick={modalCloseHandler}
        />
      </ModalHeader>
      <ModalContent>
        {children}
      </ModalContent>
      <ModalFooter>{footerContent}</ModalFooter>
    </StyledReactModal>
  );
};

export default Modal;
