import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../../shared-components';
import { UserDataBlock, UserRow } from './Referrals.style';
import { ReactComponent as UserIcon } from '../../../assets/svg/user-icon.svg';
import { ReactComponent as ClockIcon } from '../../../assets/svg/clock-icon.svg';

const ReferralUserRow = props => {
  const { user } = props;
  return (
    <UserRow>
      <UserIcon className={'user_icon'} />
      <UserDataBlock>
        <Text type={'body'} weight={700} className={'user_name'}>{`${user.fname} ${user.lname}`}</Text>
        <Text type={'bodySmall'} className={'user_email'}>{user.email}</Text>
      </UserDataBlock>
      {user.activated && <ClockIcon className={'clock_icon'} />}
    </UserRow>
  );
};

ReferralUserRow.propTypes = {
  user: PropTypes.shape({
    referralId: PropTypes.string,
    fname: PropTypes.string,
    lname: PropTypes.string,
    email: PropTypes.string,
    activated: PropTypes.bool,
  }),
};

export default ReferralUserRow;
