import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '../../shared-components';
import { LoyaltyBonusIconContainer, LoyaltyBonusInfoContainer, LoyaltyBonusRow } from './Loyalty.style';

const LoyaltyBonusItem = (props) => {
  const { icon, header, text } = props;
  return (
    <LoyaltyBonusRow>
      <LoyaltyBonusIconContainer>
        {icon}
      </LoyaltyBonusIconContainer>
      <LoyaltyBonusInfoContainer>
        <Text type={'bodySmall'} className={'loyalty_item_header'} weight={700}>{header}</Text>
        <Text type={'bodySmall'} className={'loyalty_item_text'}>{text}</Text>
      </LoyaltyBonusInfoContainer>
    </LoyaltyBonusRow>
  );
};

LoyaltyBonusItem.propTypes = {
  icon: PropTypes.element,
  header: PropTypes.string,
  text: PropTypes.string,
}

export default LoyaltyBonusItem;
