import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CodeInput from 'react-code-input';
import { useStores } from '../../../stores/helpers/use-stores';
import commonStyles from '../../commonStyles';
import { Button, Text } from '../../shared-components';
import { RegisterFormWrapper } from '../AuthModal.style';

const inputStyle = {
  fontFamily: 'Circe',
  margin: '16px',
  MozAppearance: 'textfield',
  maxWidth: '40px',
  width: '40px',
  borderRadius: '5px',
  fontSize: '32px',
  height: '60px',
  textAlign: 'center',
  border: `1px solid ${commonStyles.colors.grey}`,
  backgroundColor: commonStyles.colors.white,
  color: commonStyles.colors.primary,
  paddingBottom: '8px',
};

const invalidStyle = {};

const MobileVerification = () => {
  const { t } = useTranslation();
  const { dataStores: { authStore } } = useStores();
  const { loading, errorMessage } = authStore;
  const [code, setCode] = useState('');
  const [counter, setCounter] = useState(45);

  const intervalRef = useRef(null);

  useEffect(() => {
    initCounter();
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (counter === 0 && intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  }, [counter]);

  useEffect(() => {
    if (code?.length === 4) {
      authStore.verifyCode(code);
    }
    // eslint-disable-next-line
  }, [code]);

  const initCounter = () => {
    intervalRef.current = setInterval(() => {
      setCounter(cnt => cnt - 1);
    }, 1000);
  }

  const isBtnDisabled = () => {
    return !!counter && (loading || !code || code.length !== 4);
  };

  const handleCodeChange = (data) => {
    setCode(`${data}`);
  }

  const checkMobile = () => {
    if (code?.length === 4) {
      authStore.verifyCode(code);
    }
    if (counter === 0) {
      authStore.resendCode();
      setCode('');
      setCounter(45);
      initCounter();
    }
  };

  return (
    <RegisterFormWrapper>
      {!!errorMessage && <Text type={'bodySmall'} className={'error_message'}>{errorMessage}</Text>}
      <Text type={'body'} className={'send_code_title'}>{t('auth.sendCodeTitle')}</Text>
      <Text type={'bodySmall'} className={'send_code_subtitle'}>{t('auth.sendCodeSubTitle')}</Text>
      <CodeInput
        name={'mobile_input'}
        inputMode={'numeric'}
        fields={4}
        inputStyle={inputStyle}
        inputStyleInvalid={invalidStyle}
        onChange={(data) => handleCodeChange(data)}
      />
      <Text type={'bodySmall'} className={'counter_text'}>{t('auth.mobileTimeout', { counter })}</Text>
      <Button
        type={'primary'}
        text={counter ? t('auth.verify') : t('auth.resend')}
        disabled={isBtnDisabled()}
        className={'main_button mobile_check'}
        onClick={checkMobile}
      />
    </RegisterFormWrapper>
  );
};

export default observer(MobileVerification);

