import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { formatPrice } from '../../helpers/priceHelpers';
import { IconFont, Text } from '../shared-components';
import { AttributeItemContainer, RowElem, SelectMark } from './ProductModal.style';

const AttributeItem = props => {
  const { data, isDisabled, isDefault, showPrice, showPlus } = props;

  const classNames = useMemo(() => {
    if (isDefault) return '';
    let classes = '';
    if (data.selected) {
      classes += 'active ';
    }
    if (isDisabled) {
      classes += 'disabled ';
    }
    return classes;
  }, [data, isDisabled, isDefault]);

  return (
    <AttributeItemContainer
      disabled={isDisabled}
      default={isDefault}
      className={`attribute_container ${classNames}`}
      onClick={props.onAttributeCheck}
    >
      <RowElem>
        <Text type={'body'} className={'attribute_name'}>
          {data.name}
        </Text>
        {!!showPrice && <Text
          type={'body'}
          weight={700}
          className={'attribute_price'}
        >
          {`${showPlus ? '+' : ''}${formatPrice(data.price)}`}
        </Text>}
      </RowElem>
      <SelectMark disabled={isDisabled} def={isDefault}>
        {data.selected && <IconFont icon={'check'} size={18}/>}
      </SelectMark>
    </AttributeItemContainer>
  );
};

AttributeItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.number,
    selected: PropTypes.bool,
  }),
  isDefault: PropTypes.bool,
  isDisabled: PropTypes.bool,
  showPrice: PropTypes.bool,
  showPlus: PropTypes.bool,
  onAttributeCheck: PropTypes.func,
};

export default AttributeItem;
