import styled from 'styled-components';
import commonStyles, { respondTo } from '../../commonStyles';

export const Thumbnail = styled.div`
  width: 74px;
  min-width: 74px;
  height: 74px;
  margin-right: 16px;
  border-radius: 5px;
  background: ${({ img }) => (img && `url(${img}) no-repeat`)};
  background-size: cover;
  ${respondTo.sm`
    width: 56px;
    min-width: 56px;
    height: 56px;
    margin-right: 10px;
  `}
`;

export const CardSection = styled.div`
  display: flex;
  &.card__section {
    &--info {
      justify-content: flex-start;
    }
    &--price {
      margin-left: 90px;
      margin-top: -24px;
      justify-content: space-between;
    }
  }
  & .card__text {
    &--title{
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      height: 100%;
    }
    &--description {
      display: none;
    }
  }
  & .card__button {
    transition: transform .2s;
    &:hover {
      transform: scale(1.3);
    }
  }
  ${respondTo.sm`
    align-items: ${({ align }) => align};
    justify-content: space-between;
    &.card__section--price {
      margin: 0;
    }
    & .card__text--description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    & .card__text--title {
      -webkit-line-clamp: 3;
    }
  `}
`;

export const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding: 30px 0;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  max-width: 343px;
  border-bottom: 1px solid ${commonStyles.colors.alphaString(commonStyles.colors.primary, 0.15)};
  transition: all 0.3s ease-in-out;
  background-color: ${commonStyles.colors.white};
  ${respondTo.sm`
    background-color: ${commonStyles.colors.totalWhite};
    padding: 20px;
    margin: 0 15px 30px;
    width: 300px;
    height: 200px;
    box-shadow: 0px 10px 25px -18px rgba(0, 29, 35, 0.55);
    border-radius: 5px;
    border: none;
  `}
  ${respondTo.md`
    &:hover {
      transform: scale(1.05,1.05);
      cursor: pointer;
    }
  `}
`;
