import { observer } from 'mobx-react-lite';
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../shared-components';
import { ActionModalContainer, ButtonsContainer, ImageContainer } from './ActionModal.style';
import { getInfoImage } from '../../utils/getInfoImage';

const ActionModal = (props) => {
  const {
          isOpen,
          title,
          closeHandler,
          children,
          imgType,
          className,
          footerContent,
        } = props;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeHandler}
      title={title}
      overflow
      className={className}
      footerContent={
        <ButtonsContainer>
          {footerContent}
        </ButtonsContainer>
      }
    >
      <ActionModalContainer>
        {!!imgType && (
          <ImageContainer>
            {getInfoImage(imgType)}
          </ImageContainer>
        )}
        {children}
        {/*{ type === 'success'*/}
        {/*  ? (*/}
        {/*    <OrderInfoContent>*/}
        {/*      <Text type="body">*/}
        {/*        <Trans*/}
        {/*          i18nKey={'thankYouModal.orderInfo1'}*/}
        {/*          values={{ provider, deliveryDate, deliveryTime }}*/}
        {/*        >*/}
        {/*          Your delivery from <strong>{{provider}}</strong> will be yours as of <strong>{{deliveryDate}}</strong> at <strong>{{deliveryTime}}</strong>.*/}
        {/*        </Trans>*/}
        {/*      </Text>*/}
        {/*      <Text type="body">{t('thankYouModal.orderInfo2')}</Text>*/}
        {/*      <Text type="body" className="order-info__socials">*/}
        {/*        <Trans*/}
        {/*          i18nKey={'thankYouModal.orderInfo3'}*/}
        {/*          values={{ facebook: "Facebook", instagram: "Instagram" }}*/}
        {/*        >*/}
        {/*          Follow us for updates directly & actions*/}
        {/*          on <a href="https://www.facebook.com/CabdoApp" target="_blank">Facebook</a>*/}
        {/*          and <a href="https://www.instagram.com/cabdofood" target="_blank">Instagram</a>!*/}
        {/*        </Trans>*/}
        {/*      </Text>*/}
        {/*    </OrderInfoContent>*/}
        {/*  )*/}
        {/*  : (children)}*/}
      </ActionModalContainer>
    </Modal>
  );
};

ActionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['success', 'warning', null]),
  imgType: PropTypes.oneOf(['alert', 'calendar', 'dish', 'flag', 'grocery', 'search', 'slow', null]),
  children: PropTypes.node,
  footerContent: PropTypes.node,
  className: PropTypes.string,
  provider: PropTypes.string,
  deliveryDate: PropTypes.string,
  deliveryTime: PropTypes.string,
};

export default observer(ActionModal);
