import { ReactComponent as Alert } from '../assets/svg/info/alert.svg';
import { ReactComponent as Calendar } from '../assets/svg/info/calendar.svg';
import { ReactComponent as Dish } from '../assets/svg/info/dish.svg';
import { ReactComponent as Flag } from '../assets/svg/info/flag.svg';
import { ReactComponent as Grocery } from '../assets/svg/info/grocery.svg';
import { ReactComponent as Search } from '../assets/svg/info/search.svg';
import { ReactComponent as Slow } from '../assets/svg/info/slow.svg';

export const getInfoImage = (img) => {
  const imgList = {
    alert: <Alert />,
    calendar: <Calendar />,
    dish: <Dish />,
    flag: <Flag />,
    grocery: <Grocery />,
    search: <Search />,
    slow: <Slow />,
  };
  return imgList[img];

};