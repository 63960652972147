import Modal from 'react-modal';
import styled, { css } from 'styled-components';
import commonStyles, { respondTo } from '../../commonStyles';

export function ReactModalAdapter({ className, modalClassName, ...props }) {
  return (
    <Modal
      className={modalClassName}
      portalClassName={className}
      bodyOpenClassName="portalOpen"
      {...props}
    />
  );
}

export const ModalHeader = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${commonStyles.colors.white};

  & .modal__close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10;
    &:hover {
      cursor: pointer;
    }
  }

  ${respondTo.lg`
    & .modal__close-button {
      top: 35px;
      right: 35px;
    }
  `}
`;

export const ModalContent = styled.div`
  display: flex;
  flex: 1;
  padding: 0 8px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: ${commonStyles.colors.primary} ${commonStyles.colors.alphaString(commonStyles.colors.grey, 0.4)};

  ${respondTo.lg`
    overflow-y: scroll;
    height: calc(100vh - 30px);
    padding: 0px 30px 30px;
  `}
  &::-webkit-scrollbar {
    width: 5px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
    padding-right: 0;
  }

  &::-webkit-scrollbar-track {
    background: ${commonStyles.colors.alphaString(commonStyles.colors.grey, 0.4)};
    border-radius: 2.5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${commonStyles.colors.primary};
    padding-right: 5px;
  }
`;

export const ModalSection = styled.div`
  position: relative;
  width: 100%;
  max-width: 550px;
  ${respondTo.lg`
    min-width: 500px;
    background-color: ${commonStyles.colors.white};
  `}
`;

export const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${props => props.isReversed ? 'column' : 'column-reverse'};
  flex: 1;
  ${respondTo.lg`
    flex-direction: row-reverse;
    transition: all .5s 0.1s ease;
    transform: translateX(100%);
    ${({ openAnimate }) => openAnimate && css`
    transform: translateX(0);
  `}
  `}
`;


export const StyledReactModal = styled(ReactModalAdapter).attrs({
  overlayClassName: 'Overlay',
  modalClassName: 'Modal',
})`
  & .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${commonStyles.colors.alphaString(commonStyles.colors.primary, 0.9)};
    z-index: 10000;
    overflow-y: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    transition-delay: 0.2s;
    transition: opacity 0.5s ease;
    ${respondTo.lg`
      justify-content: flex-end;
    `}
  }

  & .Modal {
    width: 100%;
    max-width: 550px;
    background-color: ${commonStyles.colors.white};
    outline: none;
    overflow: ${props => props.overflow || 'auto'};
    padding: 0 16px;
    box-sizing: border-box;
    min-height: 100vh;
    ${respondTo.lg`
      background-color: transparent;
      max-width: none;
      width: auto;
      padding: 0;
      transform: none;
      margin: 0;
      right: 0;
      left: auto;
      top: 0;
    `}
  }

  &[class*='--after-open'] {

  }

  &[class*='--before-close'] {

  }
}`;

