import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PRODUCT_ATTRIBUTE_TYPES from '../../../constants/attribytesTypes';
import { formatPrice } from '../../../helpers/priceHelpers';
import ProductChoiceInfo from '../../ProductModal/ProductChoiceInfo';
import { Input, Text } from '../../shared-components';
import CartItemQtyControl from './cartItemQtyControl';
import { CartItemContainer, CartItemRow } from './CartItems.style';

const CartItem = props => {
  const { t } = useTranslation();
  const { data } = props;
  const { name, itemPrice, artNo, comment, qty } = data;

  const itemData = useMemo(() => {
    const dt = {
      name: data.name,
      showChoice: false,
    };
    const hasAttributes = !!data.attributes?.length;
    if (!hasAttributes) return dt;
    const singleAttrs = data.attributes
      .filter(attr => !attr.isMulti &&
        [PRODUCT_ATTRIBUTE_TYPES.cookType, PRODUCT_ATTRIBUTE_TYPES.size].includes(attr.type));
    if (singleAttrs.length) {
      singleAttrs.forEach(attr => {
        const selected = attr.attributes.find(a => a.selected);
        dt.name += ` (${selected.name})`;
      });
    }
    dt.showChoice = data.attributes.some(attr => attr.isMulti);
    return dt;
  }, [data]);

  return (
    <CartItemContainer>
      <CartItemRow>
        <Text type={'body'} weight={700} className={'cart-modal_itemName'}>{itemData.name}</Text>
        <CartItemQtyControl
          qty={qty}
          isDisabled={name.includes('Pfand')}
          onChange={props.onCartItemQtyChange}
          onRemove={props.onCartItemRemove}
        />
        <div className={'cart-modal_itemPriceContainer'}>
          <Text type={'body'} className={'cart-modal_itemPrice'}>{formatPrice(itemPrice * qty)}</Text>
        </div>
      </CartItemRow>
      {itemData.showChoice && <CartItemRow>
        <div className={'cart-modal_choiceContainer'}>
          <ProductChoiceInfo
            product={data}
            isInvertable={false}
            collapsible={true}
            showTitle={false}
            showSubTitle={false}
          />
        </div>
      </CartItemRow>}
      {!!artNo && <CartItemRow>
        <Text
          type={'bodySmall'}
          weight={400}
          className={'cart-modal_itemSecondary'}
        >
          {`${t('cart.artNo')}${artNo}`}
        </Text>
      </CartItemRow>}
      {!artNo && <CartItemRow>
        <Input
          className={'cart-modal_commentInputContainer'}
          placeholder={t('cart.itemComment')}
          inputProps={{
            value: comment,
            onChange: props.onCartItemCommentChange
          }}
        />
      </CartItemRow>}
    </CartItemContainer>
  );
};

CartItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    qty: PropTypes.number,
    itemPrice: PropTypes.number,
    mwst: PropTypes.number,
    comment: PropTypes.string,
    attributes: PropTypes.array,
    artNo: PropTypes.string,
  }),
  onCartItemQtyChange: PropTypes.func,
  onCartItemRemove: PropTypes.func,
  onCartItemCommentChange: PropTypes.func,
};

export default CartItem;
