import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import UserPages from '../../constants/userPages';
import { useStores } from '../../stores/helpers/use-stores';
import { BasicPage } from '../shared-components';
import AccountData from './AccountData';
import UserLoyalty from './Loyalty';
import PageList from './PageList';
import Referrals from './Referrals/Referrals';
import UserOrders from './UserOrders';
import { Wrapper } from './UserSettings.style';
import WalletInfo from './WalletInfo';

const UserSettings = () => {
  const { t } = useTranslation();
  const { userPage } = useParams();
  const history = useHistory();
  const { dataStores: { servicesStore, authStore } } = useStores();
  const [pageComponent, setPageComponent] = useState(null);
  const [pageName, setPageName] = useState(null);

  const { isLoggedIn, userToken, isHydrated: isAuthStoreHydrated } = authStore;

  useEffect(() => {
    if (userPage !== 'logout') {
      authStore.setupUser();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    servicesStore.setSelectedService('primary');
    return () => {
      servicesStore.setSelectedService();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isAuthStoreHydrated) {
      return;
    }
    if (!isLoggedIn || !userToken) {
      history.replace('/');
      return;
    }
    let component = null;
    let pageName;
    switch (userPage) {
      case UserPages.settings:
        component = <AccountData />;
        pageName = t('userSettings.title');
        break;
      case UserPages.wallet:
        component = <WalletInfo />;
        pageName = t('userWallet.title');
        break;
      case UserPages.loyalty:
        component = <UserLoyalty />;
        pageName = t('userLoyalty.title');
        break;
      case UserPages.orders:
        component = <UserOrders />;
        pageName = t('userOrders.title');
        break;
      case UserPages.referrals:
        component = <Referrals />;
        pageName = t('referrals.title');
        break;
      case 'logout':
        authStore.signOut();
        history.replace('/');
        break;
      default:
        history.replace('/');
        break;
    }
    if (component) {
      setPageComponent(component);
    }
    if (pageName) {
      setPageName(pageName);
    }
    // eslint-disable-next-line
  }, [userPage, history, isLoggedIn, userToken, isAuthStoreHydrated, t]);

  return (
    <BasicPage pageName={pageName} pageTitle={pageName}>
      <Wrapper>
        <PageList />
        {/* Page list same as categories */}
        {pageComponent}
      </Wrapper>
    </BasicPage>
  );
};

export default observer(UserSettings);
