import { KEYS } from '../../keys';
import api, { getTokenHeader } from './api';

export async function getProviders(token, serviceSlug) {
  const headers = getTokenHeader(token);
  const options = {
    url: `${KEYS.API_URL}/api/booker/providers/${serviceSlug}`,
    ...headers
  };
  return api.get(options);
}
