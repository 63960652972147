import React from 'react';
import { useParams } from 'react-router-dom';
import { CardsContainer, CardsWrapper } from './CardsSection.style';
import { Button } from '../../shared-components';

const CardsSection = ({ buttonText, onButtonClick, children, justifyContent, className }) => {
  const { serviceType } = useParams();
  return (
    <CardsWrapper>
      <CardsContainer className={className} justifyContent={justifyContent}>
        {children}
      </CardsContainer>
      {buttonText && (
        <Button type={serviceType ?? 'food'} onClick={() => onButtonClick()} secondary text={buttonText} />
      )}
    </CardsWrapper>
  )
};

export default CardsSection;
