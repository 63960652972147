import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import commonStyles from '../../commonStyles';
import IconFont from '../IconFont';
import Text from '../Text';
import { BackButtonWrapper } from './Layout.style';

const BackButton = props => {
  const { t } = useTranslation();
  const { type } = props;
  return (
    <BackButtonWrapper type={type} onClick={() => window.history.back()}>
      <IconFont
        icon="rightChevron"
        size="24px"
        color={type === 'food' ? commonStyles.colors.primary : commonStyles.colors.white}
        className="back_button"
      />
      <Text type={'bodySmall'} weight={'700'} className={'back-text'}>{t('products.back')}</Text>
    </BackButtonWrapper>
  );
};

BackButton.propTypes = {
  type: PropTypes.string,
}

export default BackButton;
