import React from 'react';
import PropTypes from 'prop-types';
import { formatPrice } from '../../../helpers/priceHelpers';
import { Text } from '../../shared-components';

const PriceRow = props => {
  const { price, isBold, name } = props;
  return (
    <div className={'cart_modal-price-info-row'}>
      <Text type={'body'} weight={isBold ? 700 : 400}>{name}</Text>
      <Text type={'body'} weight={isBold ? 700 : 400}>{formatPrice(price)}</Text>
    </div>
  );
};

PriceRow.propTypes = {
  price: PropTypes.number,
  name: PropTypes.string,
  isBold: PropTypes.bool,
};

export default PriceRow;
