import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../hooks/useTitle';
import AddressBar from './AddressBar';
import {
  Wrapper,
  Section,
} from './Main.style'
import { BasicPage, Text } from '../shared-components';
import AboutSection from './AboutSection';
import { useStores } from '../../stores/helpers/use-stores';
import ServicesList from './ServicesList';

export const MainTopSection = () => (
  <>
    <AddressBar />
    <ServicesList />
  </>
);

const Main = ({ pageName }) => {
  const { t } = useTranslation();
  const {
    dataStores: {
      servicesStore,
      providersStore
    }
  } = useStores();
  const { serviceType, providerName } = useParams();

  const { bookerToken, isHydrated } = servicesStore;

  useTitle(t('main.pageTitle'));

  useEffect(() => {
    if (!serviceType) {
      servicesStore.setSelectedService();
    }
    if (!providerName) {
      providersStore.selectProvider();
    }
    // eslint-disable-next-line
  }, [serviceType, providerName]);

  useEffect(() => {
    if (bookerToken && isHydrated) {
      servicesStore.getAvailableServices();
    }
    // eslint-disable-next-line
  }, [bookerToken, isHydrated]);

  return (
    <Wrapper>
      <BasicPage pageName={pageName}>
        <Section>
          <Text type="h2" weight="700" align="center">{t('main.cabdoTeam')}</Text>
          <AboutSection />
        </Section>
      </BasicPage>
    </Wrapper>
  )
};

export default observer(Main);
