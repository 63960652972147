import _ from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import discountTypes from '../../../constants/discountTypes';
import { useStores } from '../../../stores/helpers/use-stores';
import { Select } from '../../shared-components';
import { DiscountSelectorWrapper } from './OrderInfo.style';

const DiscountSelector = () => {
  const { t } = useTranslation();
  const { dataStores: { orderStore } } = useStores();
  const { discountData, availableDiscounts } = orderStore;

  const discountsList = useMemo(() => availableDiscounts ? toJS(availableDiscounts) : [], [availableDiscounts]);

  const discountOptions = useMemo(() => discountsList.map(d => ({ label: d.label, value: d })), [discountsList]);

  useEffect(() => {
    if (discountData &&
      discountData.type !== discountTypes.promocode &&
      discountsList.length &&
      !discountsList.some(d => !_.isEqual(d, discountData))
    ) {
      orderStore.updateDiscountData();
    }
    // eslint-disable-next-line
  }, [discountsList, discountData]);

  const handleDiscountChange = (sel) => {
    orderStore.updateDiscountData(sel?.value ?? null);
  }

  if (!discountsList?.length) {
    return null;
  }

  return (
    <DiscountSelectorWrapper>
      <Select
        selectedValue={discountsList.find(d => _.isEqual(d, discountData))}
        className={'promo_select'}
        options={discountOptions}
        outlined
        isClearable
        placeholder={t('cart.noDiscount')}
        textAlign={'center'}
        onValueChange={handleDiscountChange}
      />
    </DiscountSelectorWrapper>
  );
};

export default observer(DiscountSelector);
