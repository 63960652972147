import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../stores/helpers/use-stores';
import ActionModal from '../ActionModal';
import { Button, Text } from '../shared-components';

const DifferentProviderModal = props => {
  const { t } = useTranslation();
  const { dataStores: { differentProviderModalStore } } = useStores();
  return (
    <ActionModal
      isOpen={differentProviderModalStore.isVisible}
      type={'warning'}
      title={t('differentProviderModal.title')}
      imgType={'alert'}
      closeHandler={() => differentProviderModalStore.hideModal()}
      footerContent={
        <>
          <Button
            type={'ghost'}
            text={t('differentProviderModal.confirm')}
            onClick={() => differentProviderModalStore.onConfirm()}
          />
          <Button
            type={'primary'}
            text={t('differentProviderModal.cancel')}
            onClick={() => differentProviderModalStore.hideModal()}
          />
        </>
      }
    >
      <Text type={'body'}>{t('differentProviderModal.body')}</Text>
    </ActionModal>
  );
};

export default observer(DifferentProviderModal);
