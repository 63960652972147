import styled from 'styled-components';

export const Wrapper = styled.div.attrs(() => ({}))`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  & .search-icon {
    height: 200px;
    width: 200px;
    margin: 40px 0 80px;
  }
  & .not-found-button {
    margin: 40px;
  }
`;
