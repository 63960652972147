import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../stores/helpers/use-stores';
import ActionModal from '../../ActionModal';
import { Button, Text } from '../../shared-components';

const UnavailableItemsModal = props => {
  const { t } = useTranslation();
  const { dataStores: { orderStore } } = useStores();
  const { limitedProductsModalState } = orderStore;
  return (
    <ActionModal
      isOpen={limitedProductsModalState.visible}
      title={t('unavailableItemsModal.title')}
      closeHandler={orderStore.hideLimitedProductsModalState}
      type="warning"
      imgType="search"
      footerContent={
        <>
          <Button
            type="primary"
            text={t('unavailableItemsModal.cancel')}
            onClick={orderStore.hideLimitedProductsModalState}
          />
          <Button
            type="primary"
            text={t('unavailableItemsModal.submit')}
            onClick={limitedProductsModalState.onSubmit}
          />
        </>
      }
    >
      <Text type={'body'}>{t('unavailableItemsModal.body')}</Text>
    </ActionModal>
  );
};

export default observer(UnavailableItemsModal);
