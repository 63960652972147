import styled from 'styled-components';
import commonStyles, { respondTo } from '../commonStyles';

export const ModalContentWrapper = styled.div.attrs(() => ({}))`
  padding: 20px 20px 0;
  ${respondTo.md`
    padding: 40px 40px 0;  
  `}
`;

export const LoginFormWrapper = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: column;
  width: 100%;

  & .email_input {
    margin-bottom: 24px;
  }

  & .main_button {
    align-self: center;
    margin-top: 32px;
  }

  & .error_message {
    text-align: center;
    color: ${commonStyles.colors.danger};
    margin-bottom: 20px;
  }
  
  & .social_title {
    text-align: center;
    margin-top: 32px;
    margin-bottom: 8px;
  }
`;

export const PassWrapper = styled.div.attrs(() => ({}))`
  position: relative;
`;

export const EyeIconContainer = styled.div.attrs(() => ({}))`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  right: 0;
  top: 3px;
  width: 40px;
  height: 30px;
`;

export const PassForgerContainer = styled.div.attrs(() => ({}))`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & .forget_pass {
    text-align: right;
    color: ${commonStyles.colors.grey};
    cursor: pointer;
    display: inline;

    &:hover {
      color: ${commonStyles.colors.primary};
    }
  }
`;

export const SocialButtonsContainer = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  & .social_button {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    box-shadow: 0 0 15px ${commonStyles.colors.alphaString(commonStyles.colors.primary, 0.15)};
    transition: all 0.4s;
    cursor: pointer;
  }
  
  & .login-facebook-button {
    background-color: ${commonStyles.colors.facebook};
    & .fb_icon {
      fill: ${commonStyles.colors.white};
    }
    &:hover {
      background-color: ${commonStyles.colors.white};
      & .fb_icon {
        fill: ${commonStyles.colors.facebook};
      }
    }
  }
  
  & .login-google-button {
    background-color: ${commonStyles.colors.white};
    & .google_icon {
      fill: ${commonStyles.colors.google};
    }
    &:hover {
      background-color: ${commonStyles.colors.google};
      & .google_icon {
        fill: ${commonStyles.colors.white};
      }
    }
  }
  
  & .login-apple-button {
    background-color: ${commonStyles.colors.appleDark};
    align-items: flex-start;
    & .apple_icon {
      margin-top: 9px;
      fill: ${commonStyles.colors.white};
    }
    &:hover {
      background-color: ${commonStyles.colors.white};
      & .apple_icon {
        fill: ${commonStyles.colors.appleDark};
      }
    }
  }
`;

export const FormSwitchContainer = styled.div.attrs(() => ({}))`
  & .form_switch_text {
    text-align: center;
    margin-top: 40px;
    color: ${commonStyles.colors.grey};

    & .form_switch_trigger {
      font-weight: 700;
      cursor: pointer;
      color: ${commonStyles.colors.primary};

      &:hover {
        color: ${commonStyles.colors.food}
      }
    }
  }
`;

export const RegisterFormWrapper = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  & .react-code-input {
    align-self: center;
  }
  
  & .input {
    margin-bottom: 24px;
  }

  & .main_button {
    align-self: center;
    margin-top: 32px;
    &.mobile_check {
      margin-top: 16px;
    }
  }
  
  & .send_code_title {
    text-align: center;
    font-weight: 700;
  }  
  
  & .send_code_subtitle {
    text-align: center;
    font-weight: 400;
  }  

  & .error_message {
    text-align: center;
    color: ${commonStyles.colors.danger};
    margin-bottom: 20px;
  }
  
  & .counter_text {
    text-align: center;
    font-weight: 700;
    margin-top: 20px;
  }
`;

export const PoliciesTextContainer = styled.div.attrs(() => ({}))`
  & > .policy_text {
    text-align: center;
    margin-top: 40px;
    color: ${commonStyles.colors.grey};
    font-size: 10px;
    
    ${respondTo.sm`
      font-size: 12px;
    `}
    
    & > .policy_link {
      font-weight: 700;
      cursor: pointer;
      color: ${commonStyles.colors.primary};
      text-decoration: none;
      
      &:hover {
        color: ${commonStyles.colors.food}
      }
    }
  }
`;

export const Hr = styled.div.attrs(() => ({}))`
  width: 100%;
  height: 3px;
  margin: 0 0 24px 0;
  border-style: solid;
  border-color: ${commonStyles.colors.alphaString(commonStyles.colors.grey, 0.5)};
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-right-width: 0;
  border-left-width: 0;
`;
