import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedEffect } from '../../hooks/useDebouncedCallback';
import { useStores } from '../../stores/helpers/use-stores';
import commonStyles from '../commonStyles';
import { Modal, Button, IconFont } from '../shared-components';
import {
  DeliveryToolContainer,
  DeliveryMapContainer, DeliveryMapToolContainer, BackButton,
} from './DeliveryToolModal.style';
import MapPic from './mapPic';
import MapPickTool from './mapTool';
import PlaceTool from './placeTool';
import DesiredTimeSelectTool from './timeTool';

const initialDeliveryPlace = {
  address: '66, Karl-Marx Straße, Dortmund',
  location: {
    latitude: 50.50198,
    longitude: 7.48591
  }
};

const DeliveryToolModal = props => {
  const { t } = useTranslation();

  const { dataStores: { deliveryModalStore, servicesStore, providersStore, orderStore, userStore }, uiStore: { windowDimensions } } = useStores();
  const { isModalOpened, deliveryZone } = deliveryModalStore;
  const { desiredOrderDate, deliveryPlace } = servicesStore;
  const { selectedProvider } = providersStore;
  const { orderComment } = orderStore;

  const [desiredDate, setDesiredDate] = useState(null);
  const [isMapView, toggleMapView] = useState(false);
  const [deliveryComment, setDeliveryComment] = useState('');
  const [deliveryPlaceData, setDeliveryPlaceData] = useState(initialDeliveryPlace);
  const [mapPicContainerDimensions, setMapPicContainerDimensions] = useState({ height: 0, width: 0 });
  const [mapToolContainerDimensions, setMapToolContainerDimensions] = useState({ height: 0, width: 0 });

  const mapToolContainerRef = useRef();
  const mapPicContainerRef = useRef();

  useEffect(() => {
    if (isModalOpened) {
      setDesiredDate(desiredOrderDate);
      setDeliveryComment(orderComment);
      setDeliveryPlaceData(toJS(deliveryPlace));
      deliveryModalStore.setPlacesSearchOptions(toJS(deliveryPlace));
      userStore.getUserPlaces();
    }
    toggleMapView(false);
    // eslint-disable-next-line
  }, [isModalOpened]);

  useDebouncedEffect(() => {
    if (mapPicContainerRef.current) {
      setMapPicContainerDimensions({
        width: mapPicContainerRef.current.offsetWidth,
        height: mapPicContainerRef.current.offsetHeight
      });
    }
  }, 200, [isModalOpened, windowDimensions, isMapView]);

  useDebouncedEffect(() => {
    if (mapToolContainerRef.current) {
      setMapToolContainerDimensions({
        width: mapToolContainerRef.current.offsetWidth,
        height: mapToolContainerRef.current.offsetHeight
      });
    }
  }, 200, [isMapView, windowDimensions]);

  const handleDesiredDateChange = (date) => {
    setDesiredDate(date?.toISOString());
  };

  const handleConfirmDeliveryData = () => {
    servicesStore.setOrderDate(desiredDate);
    orderStore.setOrderComment(deliveryComment);
    if (deliveryPlaceData.address && deliveryPlaceData.location.latitude && deliveryPlaceData.location.longitude) {
      servicesStore.updateDeliveryPlace(deliveryPlaceData);
    }
    deliveryModalStore.hideDeliveryToolModal();
  };

  return (
    <Modal
      isOpen={isModalOpened}
      onRequestClose={deliveryModalStore.hideDeliveryToolModal}
      ariaHideApp={false}
      contentLabel={'Delivery Tool Modal'}
      title={t('deliveryModal.header')}
      overflow
      footerContent={
        <>
          {!isMapView && <Button
            type="food"
            text={t('deliveryModal.confirmSettings')}
            onClick={handleConfirmDeliveryData}
          />}
        </>
      }
    >
      {isMapView && <BackButton onClick={() => toggleMapView(false)}>
        <IconFont icon={'rightChevron'} size="2rem" color={commonStyles.colors.primary} tooltip={t('products.back')} />
      </BackButton>}
      <DeliveryToolContainer>
        {!isMapView && <>
          <PlaceTool
            deliveryPlaceData={deliveryPlaceData}
            deliveryComment={deliveryComment}
            onDeliveryCommentChange={v => setDeliveryComment(v)}
            onDeliveryPlaceDataChange={setDeliveryPlaceData}
          />
          <DeliveryMapContainer
            ref={mapPicContainerRef}
            className="delivery-tool_section"
            onClick={() => toggleMapView(true)}
          >
            <MapPic
              lat={deliveryPlaceData.location.latitude || deliveryPlace.location.latitude}
              lng={deliveryPlaceData.location.longitude || deliveryPlace.location.longitude}
              {...mapPicContainerDimensions}
            />
          </DeliveryMapContainer>
          <DesiredTimeSelectTool
            desiredDate={desiredDate}
            selectedProvider={selectedProvider}
            onChange={handleDesiredDateChange}
          />
        </>}
        {isMapView && <DeliveryMapToolContainer
          ref={mapToolContainerRef}
          className="delivery-tool_tool"
          {...mapToolContainerDimensions}
        >
          <MapPickTool
            windowWidth={windowDimensions.width}
            initialLat={deliveryPlace.location.latitude ?? 51.50178919745418}
            initialLng={deliveryPlace.location.longitude ?? 7.485875971469521}
            deliveryZone={deliveryZone}
            onLocationConfirm={(place) => {
              setDeliveryPlaceData(place);
              toggleMapView(false);
            }}
          />
        </DeliveryMapToolContainer>
        }
      </DeliveryToolContainer>
    </Modal>
  );
};

export default observer(DeliveryToolModal);
