import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IoArrowDownCircleOutline, IoArrowUpCircleOutline } from 'react-icons/all';
import { formatPrice, roundNumber } from '../../../helpers/priceHelpers';
import commonStyles from '../../commonStyles';
import { SideModal, Text } from '../../shared-components';
import { AmountWrapper, TransactionsHeaderRow, TransactionsItemWrapper, TransactionsWrapper } from './WalletInfo.syle';

const TransactionsModal = props => {
  const { visible, type, transactions } = props;
  const { t } = useTranslation();

  const renderAmount = (item) => {
    return type === 'wallet' ?
      formatPrice(item.amount) :
      t('userWallet.points.value', { points: roundNumber(item.amount, 0.01) });
  };

  const formatDate = (date) => moment(date).format('DD.MM.YYYY [um] HH:mm');

  const renderMessage = item => {
    if (!item.message) {
      return type === 'wallet' ?
        t('userWallet.withdrawForTrip', { money: item.amount, trip: item.tripId }) :
        t('userWallet.debitedForTrip', { trip: item.tripId });
    }
    if (item.message && item.tripId) {
      return `${item.message} #${item.tripId}`;
    }
    return item.message;
  };

  return (
    <SideModal
      isModalOpened={visible}
      hasSecondarySection={false}
      onHide={props.onHide}
      mainSection={<TransactionsWrapper>
        <Text
          type={'h3'}
          className={'transactions_title'}
        >
          {t(`userWallet.${type === 'wallet' ? 'money' : type}.transactionsTitle`)}
        </Text>
        {transactions.map(item => <TransactionsItemWrapper key={item._id}>
          <TransactionsHeaderRow>
            <AmountWrapper>
              {!!item.walletIdFrom ?
                <IoArrowDownCircleOutline color={commonStyles.colors.danger} size={24}/> :
                <IoArrowUpCircleOutline color={commonStyles.colors.mahlzeit} size={24}/>
              }
              <Text className={'transaction_amount'} type={'bodySmall'}>{renderAmount(item)}</Text>
            </AmountWrapper>
            <Text type={'bodySmall'} className={'transaction_date'}>{formatDate(item.updatedAt)}</Text>
          </TransactionsHeaderRow>
          <Text type={'bodySmall'} className={'transaction_message'}>{renderMessage(item)}</Text>
        </TransactionsItemWrapper>)}
      </TransactionsWrapper>}
    />
  );
};

TransactionsModal.propTypes = {
  visible: PropTypes.bool,
  type: PropTypes.string,
  transactions: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    walletIdFrom: PropTypes.string,
    tripId: PropTypes.string,
    amount: PropTypes.number,
    updatedAt: PropTypes.any,
    message: PropTypes.string,
    type: PropTypes.string,
  })),
  onHide: PropTypes.func,
};

export default TransactionsModal;
