import React from 'react';
import PropTypes from 'prop-types';
import { KEYS } from '../../keys';
import { MapPictureContainer, FlagImage } from './DeliveryToolModal.style';
import flag from '../../assets/img/info_icons/location_flag.png';

const MapPic = props => {
  const { width, height, lat, lng } = props;

  if (!width || !height) {
    return null;
  }

  return (
    <MapPictureContainer height={height} width={width}>
      <img
        src={`https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=15&size=${width}x${height}&language=de&key=${KEYS.GOOGLE_KEY}`}
        alt="Place on the map"
      />
      <FlagImage src={flag} alt={'Location Marker'} />
    </MapPictureContainer>
  );
};

MapPic.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default MapPic;
