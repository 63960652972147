import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DropdownIcon } from '../../assets/svg/dropMenu.svg';
import { useStores } from '../../stores/helpers/use-stores';
import { Input, Text } from '../shared-components';
import { TooltipWrapper } from '../shared-components/IconFont/IconFont.style';
import PlacesInput from '../shared-components/PlacesInput';
import { AddressFields, PlaceInputBlockWrapper, PlaceInputWrapper } from './DeliveryToolModal.style';
import PlacesDropdown from './PlacesDropdown';

const PlaceTool = props => {
  const { t } = useTranslation();
  const { deliveryComment, deliveryPlaceData } = props;
  const [notFullAddress, toggleNotFullAddress] = useState(false);
  const [showPlaces, toggleShowPlaces] = useState(false);
  const { dataStores: { userStore } } = useStores();
  const { initialUserPlaces } = userStore;
  const divRef = useRef();

  const handleAddressSelect = (data) => {
    const { addressData } = data;
    toggleNotFullAddress(!addressData.home && !addressData.public_place)
    props.onDeliveryPlaceDataChange(data);
  };

  const handleFavoriteSelect = (place) => {
    toggleShowPlaces(false);
    props.onDeliveryPlaceDataChange(place);
  };

  const placesList = useMemo(() => {
    const list = toJS(initialUserPlaces);
    return list
      .filter(placeData => !!placeData?.place?.address)
      .map(placeData => ({
        label: placeData.place.address,
        value: placeData.place
      }));
  }, [initialUserPlaces]);

  return (
    <AddressFields className="delivery-tool_section">
      <PlaceInputBlockWrapper>
        <PlaceInputWrapper hasWarning={notFullAddress} ref={divRef}>
          <PlacesInput
            className="delivery-tool_address-field"
            icon="pin"
            tooltip={t('tooltips.deliveryAddress')}
            placeholder={t('deliveryModal.deliveryAddressPlaceholder')}
            address={deliveryPlaceData.address || ''}
            onSelect={(data) => handleAddressSelect(data)}
            onAddressChange={address => {
              props.onDeliveryPlaceDataChange({
                address,
                location: {
                  latitude: null,
                  longitude: null
                }
              })
            }}
            onError={(e) => { console.error(e.message) }}
            isValid={!!deliveryPlaceData.address && !!deliveryPlaceData.location.latitude && !!deliveryPlaceData.location.longitude}
          />
          <PlacesDropdown
            visible={showPlaces}
            options={placesList}
            width={divRef?.current?.getBoundingClientRect()?.width}
            onSelect={handleFavoriteSelect}
          />
          {!!placesList?.length && <TooltipWrapper tooltip={t('deliveryModal.favoritePick')}>
            <button
              className={'places_btn'}
              onClick={() => toggleShowPlaces(!showPlaces)}
            >
              <DropdownIcon className={'dropdown_icon'}/>
            </button>
          </TooltipWrapper>}
        </PlaceInputWrapper>
        {!!notFullAddress && <Text
          type={'bodySmall'}
          className={'warning_message'}
        >
          {t('deliveryModal.houseWarning')}
        </Text>}
      </PlaceInputBlockWrapper>
      <Input
        className="delivery-tool_address-field"
        icon="home"
        tooltip={t('tooltips.orderComment')}
        inputProps={{
          type: 'text',
          value: deliveryComment,
          onChange: e => props.onDeliveryCommentChange(e.target.value)
        }}
        placeholder={t('deliveryModal.deliveryComment')}
      />
    </AddressFields>
  );
};

PlaceTool.propTypes = {
  deliveryPlaceData: PropTypes.shape({
    address: PropTypes.string,
    location: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
  }),
  deliveryComment: PropTypes.string,
  onDeliveryPlaceDataChange: PropTypes.func,
  onDeliveryCommentChange: PropTypes.func,
};

export default observer(PlaceTool);
