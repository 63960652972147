import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ServiceTypes from '../../constants/serviceTypes';
import { formatPrice } from '../../helpers/priceHelpers';
import { Text } from '../shared-components';
import {
  ItemImage,
  ProductInfo,
  ArticlesContainer,
  ArticlesTabs,
  ArticlesTab,
  ArticlesContent,
} from './ProductModal.style';

import FoodPlaceholder from '../../assets/img/info_icons/food_placeholder.png';
import GroceryPlaceholder from '../../assets/img/info_icons/grocery_placeholder.png';

const getImageUrl = (productData, serviceType, fullScaleImg) => {
  if (fullScaleImg) return fullScaleImg;
  if (productData?.img) {
    return productData.img;
  }
  return serviceType === ServiceTypes.FOOD ? FoodPlaceholder : GroceryPlaceholder;
}

const ProductInfoSection = props => {
  const { productData, serviceType, itemPrice, addToCartElement, hideCartControlsOnCollapse } = props;
  const [fullScaleImg, setFullScaleImg] = useState();
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (productData && serviceType === ServiceTypes.MAHLZEIT) {
      fetchProductImage();
    } else {
      setFullScaleImg(null);
    }
    // eslint-disable-next-line
  }, [productData, serviceType]);

  const fetchProductImage = async () => {
    const img = await props.onGetImage(productData.id);
    if (img) {
      setFullScaleImg(img);
    }
  }

  return (
    <ProductInfo hideCartControlsOnCollapse={hideCartControlsOnCollapse}>
      <ItemImage isPlaceholder={!productData?.img && !fullScaleImg} img={getImageUrl(productData, serviceType, fullScaleImg)}/>
      <Text type="h3" weight={700} className={'product-modal__productName'}>{productData?.name}</Text>
      {!!productData?.artNo && (
        <Text type="bodySmall" className={'product-modal__artNo'}>
          {`Art.#: ${productData.artNo}`}
        </Text>
      )}
      <Text type="h3" className={'product-modal__productPrice'}>
        {itemPrice}
      </Text>
      {!!productData?.mwst && serviceType === ServiceTypes.MAHLZEIT && (
        <Text type="bodySmall" className={'product-modal__mwst'}>
          {`inkl. ${productData.mwst}% USt., inkl. `}
        </Text>
      )}
      <Text type="bodySmall" className={'product-modal__productDescription'}>{productData?.description}</Text>
      {!!productData?.pfand && (
        <Text
          type="bodySmall"
          className={'product-modal__pfand'}
        >
          {`Dieser Artikel erhält Pfand (${formatPrice(productData.pfand.price)}), welches bei Auswahl in Deinem Warenkorb angezeigt wird.`}
        </Text>
      )}
      {addToCartElement}
      {!!productData?.articles && !!productData?.articles.length && (
        <ArticlesContainer>
          <ArticlesTabs>
            {productData.articles.map((article, index) => (
              <ArticlesTab
                key={article.title}
                selected={selectedTab === index}
                serviceType={serviceType}
                onClick={() => setSelectedTab(index)}
              >
                <Text className="articles-tab__text" type="h4" weight={700}>
                  {article.title}
                </Text>
              </ArticlesTab>
            ))}
          </ArticlesTabs>
          <ArticlesContent>
            <Text type="bodySmall">
              {productData.articles[selectedTab].content}
              </Text>
          </ArticlesContent>
        </ArticlesContainer>
      )}
    </ProductInfo>
  );
};

ProductInfoSection.propTypes = {
  hideCartControlsOnCollapse: PropTypes.bool,
  productData: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    price: PropTypes.number,
    mwst: PropTypes.number,
    img: PropTypes.string,
    category: PropTypes.shape({
      name: PropTypes.string,
      catId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      adultOnly: PropTypes.bool,
    }),
    artNo: PropTypes.string,
    unit: PropTypes.string,
    pfand: PropTypes.object,
    articles: PropTypes.array,
    description: PropTypes.string,
    numberOnMenu: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    attributeGroup: PropTypes.array,
    videoUrl: PropTypes.string,
    dishType: PropTypes.string,
  }),
  itemPrice: PropTypes.string,
  productToOrder: PropTypes.object,
  serviceType: PropTypes.string,
  onProductUpdate: PropTypes.func,
  onGetImage: PropTypes.func,
  addToCartElement: PropTypes.element,
};

export default ProductInfoSection;
