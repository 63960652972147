import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { getDeliveryZone, isInDeliveryZone } from '../../../utils/api/services';

export default class DeliveryModalStore {
  @observable isModalOpened = false;
  @observable deliveryZone = null;
  @observable isGoogleApiLoaded = false;
  @observable isPlaceInZone = false;
  @observable placesSearchOptions = {
    location: {
      lat: () => 51.5017,
      lng: () => 7.4858
    },
    radius: 2000,
  };
  @observable validationErrors = false;

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @action setValidationError = (isError) => {
    this.validationErrors = isError
  };

  @action setGoogleLibraryLoaded() {
    this.isGoogleApiLoaded = true;
  }

  @action setPlacesSearchOptions(place) {
    this.placesSearchOptions = {
      location: {
        lat: () => place.location.latitude,
        lng: () => place.location.longitude,
      },
      radius: 2000,
    }
  }

  @action setDeliveryZone = (zone = null) => {
    this.deliveryZone = zone;
  }

  @action getDeliveryZone = async () => {
    try {
      let providerSlug;
      if (this.rootStore.dataStores.orderStore.orderProvider) {
        providerSlug = this.rootStore.dataStores.orderStore.orderProvider.slug;
      } else {
        providerSlug = this.rootStore.dataStores.providersStore.providerSlug;
      }
      const token = this.rootStore.dataStores.servicesStore.bookerToken;
      const resp = await getDeliveryZone(token, providerSlug);
      if (resp.success) {
        this.setDeliveryZone(resp.data);
      }
    } catch (e) {
      console.error('Failed to get Delivery Zone', e);
    }
  }

  @action checkIfPlaceInZone = async () => {
    try {
      let providerSlug;
      if (this.rootStore.dataStores.orderStore.orderProvider) {
        providerSlug = this.rootStore.dataStores.orderStore.orderProvider.slug;
      } else {
        providerSlug = this.rootStore.dataStores.providersStore.providerSlug;
      }
      const token = this.rootStore.dataStores.servicesStore.bookerToken;
      const resp = await isInDeliveryZone(token, providerSlug);
      runInAction(() => {
        this.isPlaceInZone = !!(resp.success && resp.data);
      });
    } catch (e) {
      console.error('Failed to get check Delivery Zone', e);
    }
  }

  @action showDeliveryToolModal = () => {
    this.isModalOpened = true;
  }

  @action hideDeliveryToolModal = () => {
    this.isModalOpened = false;
  }
}
