import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useDebouncedEffect } from '../../hooks/useDebouncedCallback';
import { usePrevious } from '../../hooks/usePrevious';
import { useStores } from '../../stores/helpers/use-stores';

const TokenListener = () => {
  const { i18n } = useTranslation();
  const {
          uiStore,
          dataStores: {
            servicesStore,
            deliveryModalStore,
            providersStore,
            authStore,
            orderStore: { orderProvider },
          },
        } = useStores();
  const { deliveryPlace, desiredOrderDate, bookerToken, isHydrated: servicesHydrated, currentCityId } = servicesStore;
  const { selectedProvider } = providersStore;
  const { isHydrated: authStoreHydrated } = authStore;
  const { lang } = uiStore;

  const history = useHistory();
  const location = useLocation();
  const previousCityId = usePrevious(currentCityId);

  useDebouncedEffect(() => {
    if (bookerToken && servicesHydrated) {
      deliveryModalStore.getDeliveryZone();
      deliveryModalStore.checkIfPlaceInZone();
    }
    if (servicesHydrated && !bookerToken && !location.pathname.includes('order-status')) {
      history.push('/');
    }
    // eslint-disable-next-line
  }, 100, [bookerToken, selectedProvider, servicesHydrated, orderProvider]);

  useEffect(() => {
    if (!!previousCityId && previousCityId !== currentCityId) {
      providersStore.setProviderList([]);
      history.push('/');
    }
    // eslint-disable-next-line
  }, [currentCityId])

  useEffect(() => {
    Cookies.set('cabdo-booker-token', bookerToken, { secure: true, expires: 2 / 24 });
  }, [bookerToken]);

  useEffect(() => {
    if (servicesHydrated) {
      servicesStore.getBookerToken();
    }
    // eslint-disable-next-line
  }, [deliveryPlace, desiredOrderDate, servicesHydrated]);

  useEffect(() => {
    if (uiStore.isHydrated) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n, uiStore.isHydrated]);

  useEffect(() => {
    if (authStoreHydrated) {
      authStore.setupUser();
    }
    // eslint-disable-next-line
  }, [authStoreHydrated])

  return null;
};

export default observer(TokenListener);
