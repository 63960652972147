import styled from 'styled-components';
import commonStyles, { respondTo } from '../../commonStyles';

export const FoundProviderContainer = styled.div.attrs(() => ({}))`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 316px;
  background-color: ${commonStyles.colors.white};
  margin: 0 15px 30px;
  box-shadow: 0px 0px 10px 2px ${commonStyles.colors.alphaString(commonStyles.colors.primary, 0.15)};
  border-radius: 10px;
  padding: 20px 30px;
  border: none;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.02,1.02);
  }
`;

export const ProviderDataRaw = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 70px;
  justify-content: space-between;
  margin-bottom: 20px;
  & .found_provider-moreText {
    display: none;
    text-decoration: underline;
    text-decoration-style: dashed;
    text-underline-offset: 5px;
    text-underline-color: ${commonStyles.colors.primary};
    color: ${commonStyles.colors.grey};
    ${respondTo.sm`
      display: block;
    `}
  }
`;

export const Thumbnail = styled.div`
  width: 120px;
  min-width: 120px;
  height: 120px;
  margin-right: 16px;
  border-radius: 5px;
  box-shadow: 0 10px 25px -18px rgba(0, 29, 35, 0.55);
  background: ${({ img }) => (img && `url(${img}) no-repeat`)};
  background-size: cover;
`;

export const ProductsRow = styled.div.attrs(() => ({}))`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  & .found_productCard:last-child {
    border: none;
  }
  & .found_productCard {
    background-color: ${commonStyles.colors.white};
    &:hover {}
   ${respondTo.sm`
      background-color: ${commonStyles.colors.white};
      margin: 0 8px 20px;
      width: 280px;
      &:hover {}
    `}
  }
  ${respondTo.lg`
    justify-content: space-between;
  `}
`;
