import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useStores } from '../../stores/helpers/use-stores';
import commonStyles from '../commonStyles';
import { IconFont } from '../shared-components';
import { UserIconContainer } from './Header.style';
import UserDropdown from './UserDropdown';

const UserDetailsButton = (props) => {
  const { dataStores: { authStore: { userData } } } = useStores();
  const [dropdownVisible, toggleDropdown] = useState(false);
  const { type } = props;

  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener('click', hideDropdown, true);
    } else {
      document.removeEventListener('click', hideDropdown, true);
    }
  }, [dropdownVisible])

  const handleToggle = () => {
    toggleDropdown(!dropdownVisible);
  }

  const hideDropdown = () => {
    toggleDropdown(false);
  };

  return (
    <UserIconContainer>
      <IconFont
        icon="userLogin"
        className={'user_icon'}
        size="30px"
        tooltip={userData?.userName}
        color={['food', 'white'].includes(type) ? commonStyles.colors.primary : commonStyles.colors.white}
        onClick={handleToggle}
      />
      <UserDropdown isVisible={dropdownVisible} onMenuHide={() => hideDropdown()}/>
    </UserIconContainer>
  );
};

UserDetailsButton.propTypes = {
  type: PropTypes.string,
}

export default observer(UserDetailsButton);
