import styled from 'styled-components';
import commonStyles from '../../commonStyles';

export const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${commonStyles.colors.alphaString(commonStyles.colors.primary, 0.6)};
  z-index: 10001;
  display: flex;
  justify-content: center;
  align-items: center;
`;
