import { KEYS } from '../../keys';
import api, { getUserToken } from './api';

export async function login(body) {
  const options = {
    url: `${KEYS.API_URL}/api/booker/auth/login`,
    data: body,
  };
  return api.post(options);
}

export async function setupUser(jwtAccessToken) {
  const options = {
    url: `${KEYS.API_URL}/api/booker/auth/login`,
    data: { jwtAccessToken },
  };
  return api.put(options);
}

export async function logout(token) {
  const headers = getUserToken(token);
  const options = {
    url: `${KEYS.API_URL}/api/auth/logout`,
    ...headers
  };
  return api.get(options);
}

export async function socialLogin(type, payload) {
  let query = '';
  if (type === 'google' && payload?.version === 'v2') {
    query = '?version=v2';
  }
  const options = {
    url: `${KEYS.API_URL}/api/booker/auth/${type}/authenticate${query}`,
    data: payload
  };
  return api.post(options);
}

export async function checkReferralCode(referralCode) {
  const options = {
    url: `${KEYS.API_URL}/api/referralcodes`,
    data: {
      referralCode
    }
  };
  return api.put(options);
}

export async function registerUser(userData) {
  const options = {
    url: `${KEYS.API_URL}/api/booker/auth/register`,
    data: userData
  };
  return api.post(options);
}

export async function verifyMobileCode(token, code) {
  const options = {
    headers: {
      'Authorization': token,
    },
    url: `${KEYS.API_URL}/api/booker/auth/verify`,
    data: { code }
  };
  return api.post(options);
}

export async function resendVerificationCode(token) {
  const options = {
    headers: {
      'Authorization': token,
    },
    url: `${KEYS.API_URL}/api/booker/auth/verify`,
  };
  return api.get(options);
}

export async function resetUserPassword(email) {
  const options = {
    url: `${KEYS.API_URL}/api/booker/auth/password-reset`,
    data: { email }
  };
  return api.post(options);
}

export async function updateUserPolicy(token, policy) {
  const options = {
    headers: {
      'Authorization': token,
    },
    url: `${KEYS.API_URL}/api/users/updatePolicies`,
    data: { ...policy }
  };
  return api.put(options);
}

export async function changeUserPass(token, form) {
  const options = {
    headers: {
      'Authorization': token,
    },
    url: `${KEYS.API_URL}/api/users/changePass`,
    data: form
  };
  return api.put(options);
}

export async function updateUserData(token, data) {
  const options = {
    data,
    headers: {
      'Authorization': token,
    },
    url: `${KEYS.API_URL}/api/booker/auth/user`,
  };
  return api.put(options);
}

export async function getUserTransactions(token) {
  const options = {
    headers: {
      'Authorization': token,
    },
    url: `${KEYS.API_URL}/api/payment/transactions`,
  };
  return api.get(options);
}

export async function getLoyaltyList(token) {
  const options = {
    headers: {
      'Authorization': token,
    },
    url: `${KEYS.API_URL}/api/loyalty`,
  };
  return api.get(options);
}

export async function getReferralInfo(token) {
  const options = {
    headers: {
      'Authorization': token,
    },
    url: `${KEYS.API_URL}/api/referralcodes/history`,
  };
  return api.get(options);
}

export async function getUserPlaces(token) {
  const options = {
    headers: {
      'Authorization': token,
    },
    url: `${KEYS.API_URL}/api/booker/auth/user/places`,
  };
  return api.get(options);
}

export async function updateUserPlaces(token, places) {
  const options = {
    headers: {
      'Authorization': token,
    },
    url: `${KEYS.API_URL}/api/booker/auth/user/places`,
    data: { places }
  };
  return api.post(options);
}
