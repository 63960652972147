import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import discountTypes from '../../../constants/discountTypes';
import { useStores } from '../../../stores/helpers/use-stores';
import { Text } from '../../shared-components';
import DiscountSelector from './DiscountSelector';
import { PromosWrapper } from './OrderInfo.style';
import PromocodeChecker from './PromocodeChecker';

const PromosSection = () => {
  const { t } = useTranslation();
  const { dataStores: { authStore, orderStore } } = useStores();
  const { userToken, userData } = authStore;
  const { discountData, deliveryDate, orderAmt, orderProvider } = orderStore;
  const [promocodeInputShown, togglePromocodeInput] = useState(discountData?.type === discountTypes.promocode);

  useEffect(() => {
    orderStore.recalculateCart();
    // eslint-disable-next-line
  }, [discountData]);

  useEffect(() => {
    orderStore.getAvailablePromos();
    // eslint-disable-next-line
  }, [deliveryDate, orderAmt, orderProvider]);

  const handleInputToggle = () => {
    orderStore.resetPromocode();
    togglePromocodeInput(!promocodeInputShown);
  };

  if (!userToken || !userData) {
    return null;
  }

  return (
    <PromosWrapper promocodeShown={promocodeInputShown}>
      <Text type={'h3'} weight={700} className={'promos_header'}>
        {t('cart.privilege')}
      </Text>
      {!promocodeInputShown && <DiscountSelector />}
      {!!promocodeInputShown && <PromocodeChecker/>}
      <div className={'checkbox_row'}>
        <Text type={'bodySmall'} className={'checkbox_question'}>
          {t('cart.havePromocode')}
        </Text>
        <div
          className={'checkbox'}
          onClick={handleInputToggle}
        />
      </div>
    </PromosWrapper>
  );
};

export default observer(PromosSection);
