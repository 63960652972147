import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputContainer, InputField, IconContainer, InputIcon } from './Input.style';
import { IconFont } from '../';
import commonStyles from '../../commonStyles';
import InputMask from 'react-input-mask';

const Input = (props) => {
  const {
    className,
    isSearch,
    isMain,
    placeholder,
    icon,
    inputProps,
    hasBtn,
    btnName,
    mask,
    isInvalid,
    validationMessage,
    isFieldEdited,
    tooltip,
    inputRef,
  } = props;

  const [isEdited, setIsEdited] = useState(isFieldEdited);
  return (
    <InputContainer
      className={className}
      isSearch={isSearch}
      isMain={isMain}
      isInvalid={(isEdited || !!(inputProps?.disabled)) && isInvalid}
      validationMessage={validationMessage}
    >
      <IconContainer isSearch={isSearch} isMain={isMain}>
        {isMain && (
          <InputIcon className="main-search-icon">
            <IconFont icon="pin" size="30px" color={commonStyles.colors.grey} tooltip={tooltip}/>
          </InputIcon>
        )}
        {isSearch && (
          <InputIcon className="search-icon">
            <IconFont icon="search" size="20px" color={commonStyles.colors.grey} tooltip={tooltip}/>
          </InputIcon>
        )}
        {(icon && !isMain && !isSearch) && (
          <InputIcon className="input-icon">
            <IconFont
              icon={icon}
              size="20px"
              color={(isInvalid && isEdited) ? commonStyles.colors.danger : commonStyles.colors.grey}
              tooltip={tooltip}
            />
          </InputIcon>
        )}
      </IconContainer>
      {mask
        ? (
          <InputMask
            mask={mask}
            value={inputProps.value}
            maskChar={null}
            onChange={inputProps.onChange}
            onBlur={() => setIsEdited(true)}
          >
            {(inputProps) => (
              <InputField
                ref={inputRef}
                isSearch={isSearch}
                isMain={isMain}
                hasBtn={hasBtn}
                disabled={inputProps?.disabled || isMain}
                placeholder={placeholder}
                isInvalid={isEdited && isInvalid}
                validationMessage={validationMessage}
                {...inputProps}
              />
            )}
          </InputMask>
        )
        : (
          <InputField
            isSearch={isSearch}
            ref={inputRef}
            isMain={isMain}
            hasBtn={hasBtn}
            disabled={inputProps?.disabled || isMain}
            placeholder={placeholder} {...inputProps}
            isInvalid={isEdited && isInvalid}
            validationMessage={validationMessage}
            onBlur={() => setIsEdited(true)}
          />
        )
      }
      {hasBtn && <button className={'input-button'} onClick={props.onBtnClick}>{btnName}</button>}
    </InputContainer>
  )
};

Input.propTypes = {
  className: PropTypes.string,
  isSearch: PropTypes.bool,
  isMain: PropTypes.bool,
  hasBtn: PropTypes.bool,
  btnName: PropTypes.string,
  onBtnClick: PropTypes.func,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  inputProps: PropTypes.object,
  mask: PropTypes.string,
  tooltip: PropTypes.string,
};

Input.defaultProps = {
  inputProps: {},
  hasBtn: false
};

export default Input;
