import { AuthModalStore } from './authModal';
import DeliveryModalStore from './deliveryModalStore';
import { DifferentProviderModalStore } from './differentProviderModalStore';
import OrderStatusStore from './orderStatus';
import SideModalStore from './sideModalStore';
import OrderStore from './orderStore';
import ProductsStore from './productsStore';
import ProvidersStore from './providersStore';
import ServicesStore from './servicesStore';
import AuthStore from './authStore';
import UserStore from './userStore';

export default class DataStore {
  constructor(rootStore) {
    this.servicesStore = new ServicesStore(rootStore);
    this.deliveryModalStore = new DeliveryModalStore(rootStore);
    this.sideModalStore = new SideModalStore(rootStore);
    this.providersStore = new ProvidersStore(rootStore);
    this.productsStore = new ProductsStore(rootStore);
    this.orderStore = new OrderStore(rootStore);
    this.orderStatusStore = new OrderStatusStore();
    this.differentProviderModalStore = new DifferentProviderModalStore(rootStore);
    this.authStore = new AuthStore(rootStore);
    this.userStore = new UserStore(rootStore);
    this.authModalStore = new AuthModalStore();
  }
}
