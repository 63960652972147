import { toJS } from 'mobx';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Text } from '../../shared-components';
import {
  ItemLabelWrapper,
  LoyaltyBar,
  LoyaltyBarWrapper,
  LoyaltyItemIconWrapper,
  LoyaltyItemWrapper, Progress
} from './Loyalty.style';
import { ReactComponent as LoyaltyIcon } from '../../../assets/svg/loyalty.svg';

const LoyaltyProgressBar = props => {
  const { loyaltyList, currentLoyalty, ordersNumber } = props;

  const widthArray = useMemo(() => {
    const list = toJS(loyaltyList);
    return list.map((loyalty, idx) => {
      let width;
      if (ordersNumber >= loyalty.minTrips && list[idx + 1] && ordersNumber < list[idx + 1].minTrips) {
        width = ((ordersNumber - loyalty.minTrips) / (list[idx + 1].minTrips - loyalty.minTrips)) * 100;
      } else {
        width = loyalty.minTrips < ordersNumber ? 100 : 0;
      }
      return width;
    });
  }, [loyaltyList, ordersNumber]);

  return (
    <LoyaltyBarWrapper>
      {loyaltyList.map(((loyalty, idx) => <LoyaltyItemWrapper key={`${loyalty.name}-${idx}`}>
        <ItemLabelWrapper color={loyalty.color}>
          <LoyaltyItemIconWrapper color={loyalty.color}>
            <LoyaltyIcon className={'loyalty_icon'} />
          </LoyaltyItemIconWrapper>
          <Text className={'label_name'} type={'bodySmall'}>{loyalty.name}</Text>
        </ItemLabelWrapper>
        {(idx !== loyaltyList.length - 1) && <LoyaltyBar>
          <Progress color={currentLoyalty.color} widthPercent={widthArray[idx]} />
        </LoyaltyBar>}
      </LoyaltyItemWrapper>))}
    </LoyaltyBarWrapper>
  );
};

LoyaltyProgressBar.propTypes = {
  loyaltyList: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    minTrips: PropTypes.number,
    multiplier: PropTypes.number,
    offPeakDiscount: PropTypes.number,
    preBookingGuarantee: PropTypes.bool,
    referralRides: PropTypes.number,
    freeFoodDeliveries: PropTypes.number,
    color: PropTypes.string,
  })),
  currentLoyalty: PropTypes.shape({
    name: PropTypes.string,
    minTrips: PropTypes.number,
    multiplier: PropTypes.number,
    offPeakDiscount: PropTypes.number,
    preBookingGuarantee: PropTypes.bool,
    referralRides: PropTypes.number,
    freeFoodDeliveries: PropTypes.number,
    color: PropTypes.string,
  }),
  ordersNumber: PropTypes.number,
};

export default LoyaltyProgressBar;
