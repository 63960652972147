import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../../shared-components';
import { CardContainer, CardContainerImg } from './CategoryCard.style';
import foodImg from '../../../assets/img/food.png';

const CategoryCard = (props) => {
  const { className, item } = props;
  const { name, image } = item;

  return (
    <CardContainer className={className} onClick={props.onCategorySelect}>
      <CardContainerImg img={image || foodImg}/>
      <Text className="card-text card-title" type="h4" weight="700">{name}</Text>
    </CardContainer>
  );
};

CategoryCard.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object,
  onCategorySelect: PropTypes.func,
}

export default CategoryCard;
