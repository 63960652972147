import Modal from 'react-modal';
import styled from 'styled-components';
import commonStyles, { respondTo } from '../../commonStyles';

Modal.setAppElement('#root');

export function ReactModalAdapter({ className, modalClassName, ...props }) {
  return (
    <Modal
      className={modalClassName}
      portalClassName={className}
      bodyOpenClassName="portalOpen"
      {...props}
    />
  );
}

export const ModalContent = styled.div``;
export const ModalHeader = styled.div`
  position: relative;
  & .modal__title {
    margin-left: 40px;
    ${respondTo.sm`
      margin-left: 0;
    `}
  }
  & .modal__close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    &:hover {
      cursor: pointer;
    }
  }
  ${respondTo.sm`
    text-align: center;
  `}

`;
export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${respondTo.sm`
    flex-direction: row;
  `}
`;

export const StyledReactModal = styled(ReactModalAdapter).attrs(({ className }) => ({
  overlayClassName: 'Overlay',
  modalClassName: `Modal ${className}`,
}))`
  & .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${commonStyles.colors.alphaString(commonStyles.colors.primary, 0.9)};
    z-index: 11000;
    overflow-y: auto;
  }

  & .Modal {
    width: 100%;
    min-height: 100vh;
    background-color: ${commonStyles.colors.white};
    outline: none;
    overflow: ${props => props.overflow || 'auto'};
    padding: 24px 16px;
    box-sizing: border-box;
    &.mobile-app-modal {
      overflow: hidden;
      width: 96%;
      position: absolute;
      top: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      left: 50%;
      min-height: auto;
      border-radius: 10px;
      padding: 0;
      & .modal__close-button {
        top: 20px;
        right: 20px;
      }
    }
    ${respondTo.md`
      position: absolute;
      top: 50%;
      max-width: ${({ maxWidth }) => maxWidth ?? 850}px;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      left: 50%;
      min-height: auto;
      border-radius: 10px;
      padding: 30px;
    `}
  }

  &[class*='--after-open'] {

  }

  &[class*='--before-close'] {

  }
}`;

