import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContainer, ModalContent } from '../../MobileAppModal/MobileAppModal.style';
import { Button, Modal, Text } from '../../shared-components';

const LoyaltyInfoModal = (props) => {
  const { t } = useTranslation();
  const { visible } = props;
  return (
    <Modal
      isOpen={visible}
      onRequestClose={props.onHide}
      className="loyalty_info_modal"
    >
      <ModalContainer>
        <ModalContent>
          <Text className="mobile-app-modal__title" type="h3" weight={700}>
            {t('userLoyalty.infoModalTitle')}
          </Text>
          <Text className="mobile-app-modal__content" type="body">
            {t('userLoyalty.infoModalBody')}
          </Text>
          <Button onClick={props.onHide} type={'primary'} text={'OK'} className={'only_button'} />
        </ModalContent>
      </ModalContainer>
    </Modal>
  );
};

LoyaltyInfoModal.propTypes = {
  visible: PropTypes.bool,
  onHide: PropTypes.func,
}

export default LoyaltyInfoModal;
