import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../../shared-components';
import Toggler from '../../shared-components/Toggler/Toggler';
import { PrivacyDescriptionWrapper, PrivacyElementWrapper, PrivacyHeaderRow } from './AccountData.style';

const PrivacyItem = props => {
  const { privacyTitle, privacyText, hasBtn, onToggle, isTurnedOn } = props;
  return (
    <PrivacyElementWrapper>
      <PrivacyHeaderRow>
        <Text type={'h4'} className={'privacy_element_title'}>{privacyTitle}</Text>
        {hasBtn && <Toggler
          isEnabled={isTurnedOn}
          onChange={onToggle}
          height={20}
          width={40}
        />}
      </PrivacyHeaderRow>
      <PrivacyDescriptionWrapper>
        <Text type={'bodySmall'} className={'privacy_element_desc'}>{privacyText}</Text>
      </PrivacyDescriptionWrapper>
    </PrivacyElementWrapper>
  );
};

PrivacyItem.propTypes = {
  privacyTitle: PropTypes.string,
  privacyText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  hasBtn: PropTypes.bool,
  onToggle: PropTypes.func,
  isTurnedOn: PropTypes.bool,
};

export default PrivacyItem;
