export const parseNumberString = (str) => {
  const performedStr = str
    .split('')
    .map((c) => (c === ',' ? '.' : c))
    .join('')
    .split('.')
    .filter(Boolean)
    .join('.');
  return parseFloat(performedStr);
};

export const roundPrice = (cost) => {
  const val = Math.round(cost * 100);
  return (val - (val % 5)) / 100;
};

export const roundNumber = (value, step = 1.0) => {
  const inv = 1.0 / step;
  return Math.round(value * inv) / inv;
};

export const formatPrice = (fare, options = {}) => {
  const { toValue, addEuroSign } = { addEuroSign: true, toValue: 2, ...options };
  if (!fare && fare !== 0) {
    return 'N/A';
  }
  const fareString = fare.toFixed(toValue).replace('.', ',');
  return `${fareString}${addEuroSign ? '€' : ''}`;
};

export const calculateProductPrice = product => {
  let price = product.price;
  if (product.attributes.length) {
    const priceChangerIndex = product.attributes.findIndex(a => !a.addToPrice);
    if (priceChangerIndex >= 0) {
      const basePrices = product.attributes[priceChangerIndex].attributes;
      price = basePrices?.find(a => a.selected)?.price ?? basePrices[0].price;
    }
    product.attributes
      .filter(attrData => attrData.addToPrice)
      .forEach(attributeData => {
        if (attributeData.isMulti) {
          const selected = attributeData.attributes.filter(a => a.selected);
          selected.forEach((a, i) => {
            price += i < attributeData.numOfFreeItems ? 0 : a.price;
          });
        } else {
          const selected = attributeData.attributes.find(a => a.selected);
          price += selected?.price ?? 0;
        }
      });
  }
  return price;
}
