import styled, { css } from 'styled-components';
import ServiceTypes from '../../constants/serviceTypes';
import commonStyles, { respondTo } from '../commonStyles';

export const StatusPoint = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: ${commonStyles.colors.grey};
  justify-content: space-around;
  position: relative;
  white-space: nowrap;

  &:before {
    content: '${({ label }) => label}';
    display: block;
    position: absolute;
    left: -20px;
    top: 20px;
    font-size: 12px;
    text-transform: capitalize;
    color: ${commonStyles.colors.grey};
    ${respondTo.md`
      font-size: 18px;
    `}
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    height: 2px;
    width: 220px;
    left: 10px;
    background-color: ${commonStyles.colors.grey};
    z-index: -1;
  }

  &:first-child {
    &:before {
      left: 0;
    }
  }

  &:last-child {
    &:before {
      left: auto;
      right: 0;
    }

    &:after {
      content: none;
    }
  }

  ${({ status, type }) => (status !== undefined || status !== null) && css`
    &:nth-child(-n+${status + 1}) {
      background-color: ${type === ServiceTypes.FOOD ? commonStyles.colors.food : commonStyles.colors.mahlzeit};
    }

    &:nth-child(-n+${status + 1}):before {
      color: ${commonStyles.colors.primary};
    }

    &:nth-child(-n+${status}):after {
      background-color: ${type === ServiceTypes.FOOD ? commonStyles.colors.food : commonStyles.colors.mahlzeit};
    }
  `}
`;

export const StatusBar = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  margin-top: 20px;
`;

export const InfoSection = styled.div`
  & .order__description {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .order-date__nowrap {
    white-space: nowrap;
  }

  & .order__status {
    margin: 20px 0;
    ${respondTo.md`
      margin: 0;
    `}
  }

  & .order__wait_message {
    margin: 10px 0 20px;
    color: ${commonStyles.colors.danger};
    ${respondTo.md`
      margin: 0;
    `}
  }
`;

export const OrderStatusInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 687px;
  ${respondTo.sm`
    width: 70%
  `}
`;

export const OrderStatusImg = styled.div`
  display: none;
  flex-grow: 1;
  width: 100%;
  max-width: 483px;
  height: auto;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 30px;

  & > img {
    width: 100%;
    height: auto;
  }

  ${respondTo.sm`
    display: block;
    width: 30%;
  `}
  ${respondTo.lg`
    min-width: 483px;
  `}
`;

export const OrderStatusContainer = styled.div`
  display: flex;
  margin-top: 56px;
  ${respondTo.md`
    margin-top: 100px;
  `}
  ${respondTo.lg`
    height: 300px;
  `}
`;
