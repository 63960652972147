import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import HorizontalSlider from '../../ItemsList/Categories/HorizontalSlider';
import SideCategories from '../../ItemsList/Categories/SideCategories';

const getPageList = (t) => [{
  _id: 'settings',
  slug: '/user/settings',
  name: t('userSettings.title'),
  subCategories: []
}, {
  _id: 'wallet',
  slug: '/user/wallet',
  name: t('userWallet.title'),
  subCategories: []
}, {
  _id: 'loyalty',
  slug: '/user/loyalty',
  name: t('userLoyalty.title'),
  subCategories: []
}, {
  _id: 'referrals',
  slug: '/user/referrals',
  name: t('referrals.title'),
  subCategories: []
}, {
  _id: 'orders',
  slug: '/user/orders',
  name: t('userOrders.title'),
  subCategories: []
}];

const PageList = () => {
  const { userPage } = useParams();
  const { t } = useTranslation();
  const pageList = useMemo(() => getPageList(t), [t]);

  return (
    <>
      <HorizontalSlider categories={pageList} currentCategorySlug={`/user/${userPage}`} exact/>
      <SideCategories categories={pageList} currentCategorySlug={`/user/${userPage}`} exact/>
    </>
  );
};

export default observer(PageList);
