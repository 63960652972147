export const SENT = 'versendet';
export const RECEIVED = 'empfangen';
export const CONFIRMED = 'bestätigt';
export const EN_ROUTE = 'unterwegs';
export const CANCELLED = 'abgebrochen';
export const COMPLETED = 'abgeschlossen';

const OrderStatuses = {
  SENT,
  RECEIVED,
  CONFIRMED,
  EN_ROUTE,
  CANCELLED,
  COMPLETED,
};

export default OrderStatuses;
