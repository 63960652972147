import styled from 'styled-components';
import commonStyles, { respondTo } from '../../commonStyles';

export const CartItemsContainer = styled.div.attrs(() => ({}))`
  width: 100%;
  padding: 40px 0 0;

  ${respondTo.lg`
    padding: 30px 0 0;
    width: 450px;
  `}
  & > .cart-modal_itemsHeader {
    margin-bottom: 20px;
  }

  & > .cart-modal_providerText {
    margin: 20px 0 50px;
    display: inline;
  }

  & > .cart-modal_providerText > .cart-modal_providerName {
    color: ${commonStyles.colors.darkGrey};
    display: inline;
  }

  & > .cart-modal_minPriceAlert {
    width: 92%;
    margin: 20px 0;
  }
`;

export const CartItemsHeaderRow = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  ${respondTo.md`
    margin-top: 0;
  `}
`;

export const ClearCartButton = styled.div.attrs(() => ({}))`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  border: 0.5px solid ${commonStyles.colors.primary};
  border-radius: 5px;

  &:hover {
    border: 2px solid ${commonStyles.colors.primary};
  }
  
  ${respondTo.md`
    height: 50px;
    width: 50px;
  `}
`;

export const CartItemContainer = styled.div.attrs(() => ({}))`
  width: 100%;
  margin: 30px 0;
`;

export const CartItemRow = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  flex: 10;

  & > .cart-modal_itemName {
    flex: 5;
    padding-right: 8px;
  }

  & > .cart-modal_commentInputContainer {
    margin-top: 8px;
  }

  & > .cart-modal_commentInputContainer > div {
    display: none;
  }

  & > .cart-modal_commentInputContainer > input {
    flex: 5;
    font-size: 16px;
  }

  & > .cart-modal_itemSecondary {
    flex: 5;
    color: ${commonStyles.colors.grey};
  }

  & > .cart-modal_choiceContainer {
    flex: 5;
  }

  & > .cart-modal_qtyContainer {
    flex: 3;
  }

  & > .cart-modal_itemPriceContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 2;
  }
`;

export const CartQtyContainer = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > .iconContainer {
    cursor: pointer;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    &:hover {
      border: 2px solid ${commonStyles.colors.primary};
      border-radius: 5px;
    }
  }

  & > .iconContainer.disabled {
    cursor: default;
  }

  & > .cart-modal_itemQty {
    margin: 0 16px;
  }
`;
