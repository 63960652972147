import React from 'react';
import PropTypes from 'prop-types';
import { InfoBoxContainer, ImageContainer } from './InfoBox.style';
import { Text } from '../../shared-components';
import { getInfoImage } from '../../../utils/getInfoImage';


const InfoBox = ({
  type,
  imgType,
  children,
  className,
  isClickable,
  onClick
}) => {

  return (
    <InfoBoxContainer type={type} className={className} onClick={onClick} isClickable={isClickable}>
      {imgType && (
        <ImageContainer>
          {getInfoImage(imgType)}
        </ImageContainer>
      )}
      <Text
        className="info-box_text"
        type="bodySmall"
      >
        {children}
      </Text>
    </InfoBoxContainer>
  )
};

InfoBox.propTypes = {
  type: PropTypes.oneOf(['primary', 'food', 'mahlzeit', 'white']).isRequired,
  imgType: PropTypes.oneOf(['alert', 'calendar', 'dish', 'flag', 'grocery', 'search', 'slow']),
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isClickable: PropTypes.bool,
};

InfoBox.defaultProps = {
  className: undefined,
  imgType: undefined,
  children: undefined,
  isClickable: undefined,
  onClick: () => {}
};

export default InfoBox;
