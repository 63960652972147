import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PRODUCT_ATTRIBUTE_TYPES from '../../../constants/attribytesTypes';
import { IconFont, Text } from '../../shared-components';
import { ProductChoiceInfoContainer } from './ProductChoiceInfo.style';

const ProductChoiceInfo = props => {
  const { t } = useTranslation();
  const { product, collapsible, showSubTitle, showTitle  } = props;

  const [isCollapsed, toggleCollapsed] = useState(collapsible);

  const isShown = useMemo(() => product.attributes?.length &&
    product.attributes.some(a => [PRODUCT_ATTRIBUTE_TYPES.addition, PRODUCT_ATTRIBUTE_TYPES.extras].includes(a.type) && a.attributes.some(at => at.selected)), [product]);

  const handleCollapse = () => {
    if (collapsible) {
      toggleCollapsed(!isCollapsed);
    }
  };

  if (!isShown) {
    return null;
  }

  return (
    <ProductChoiceInfoContainer isCollapsible={collapsible}>
      {isCollapsed && <div className={'product_choice-expand_container'} onClick={handleCollapse}>
        <IconFont icon={'downChevron'} className={'product_choice-down_chevron'} size={18}/>
        <Text type={'bodySmall'} weight={400} className={'product_choice-with_extras'}>{t('cart.withExtras')}</Text>
      </div>}
      {!isCollapsed && <div className={'product_choice-choice_container'} onClick={handleCollapse}>
        {showTitle && <Text type={'h4'} className={'product_choice-header'}>{t('cart.choiceHeader')}</Text>}
        {showSubTitle &&
          <Text
            type={'bodySmall'}
            weight={700}
            className={'product_choice-sub_header'}
          >
            {product.name}
            <Text type={'bodySmall'} weight={400} className={'product_choice-with'}>&nbsp;{t('cart.with')}</Text>
          </Text>
        }
        {product.attributes
          .filter(a => [PRODUCT_ATTRIBUTE_TYPES.addition, PRODUCT_ATTRIBUTE_TYPES.extras].includes(a.type)
            && a.attributes.some(at => at.selected))
          .map((attributeGroup, index) =>
            <div
              key={`${attributeGroup.name}-${index}`}
              className={'product_choice-choices_container'}
            >
              <Text type={'bodySmall'} className={'product_choice-attribute_name'}>{attributeGroup.name}:</Text>
              {attributeGroup.attributes.filter(a => a.selected).map((a, i) =>
                <Text
                  key={`${attributeGroup.name}-${a.name}-${i}`}
                  type={'bodySmall'}
                  weight={700}
                  className={'product_choice-attribute_choice'}
                >
                  {a.name}
                </Text>)}
            </div>
          )}
      </div>}
    </ProductChoiceInfoContainer>
  );
};

ProductChoiceInfo.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    qty: PropTypes.number,
    itemPrice: PropTypes.number,
    mwst: PropTypes.number,
    comment: PropTypes.string,
    attributes: PropTypes.array,
    artNo: PropTypes.string,
  }),
  isInvertable: PropTypes.bool,
  collapsible: PropTypes.bool,
  showTitle: PropTypes.bool,
  showSubTitle: PropTypes.bool,
};

ProductChoiceInfo.defaultProps = {
  isInvertable: false,
  collapsible: false,
  showTitle: true,
  showSubTitle: true,
};

export default ProductChoiceInfo;
