import styled, { css } from 'styled-components';
import commonStyles, { respondTo } from '../commonStyles';
import Text from '../shared-components/Text';

export const FooterWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 100%;
  background: ${commonStyles.colors.primary};
  padding: 40px 0 0;
  margin-top: 120px;

  ${respondTo.md`
    margin-top: 210px;
    padding: 40px 0;
  `}
`;

export const CurveContainer = styled.div.attrs(() => ({}))`
  position: absolute;
  left: 0;
  right: 0;
  top: -60px;
  height: 60px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  object-fit: fill;
  
  & .footer_curve_mob, .footer_curve {
    transform: rotate(180deg);
    & path {
      fill: ${commonStyles.colors.primary};
    }
  }
  
  & .footer_curve_mob {
    min-width: 600px;
  }

  & .footer_curve {
    display: none;
  }
  
  ${respondTo.sm`
    .footer_curve_mob { display: none; }
    & .footer_curve {
      min-width: 4200px;
      display: block;
    }
  `}
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  ${commonStyles.typo.mob.bodySmall};
  ${respondTo.md`
    flex-direction: row;
    ${commonStyles.typo.bodySmall};
  `}
`;

export const FooterTitle = styled(Text)`
  font-weight: 700;
  color: ${commonStyles.colors.white};
  margin-bottom: 20px;
`;

export const FooterLink = styled.a.attrs((props) => ({
  target: (props.isBlank || props.isBlank === undefined) ? '_blank' : '_self'
}))`
  color: ${commonStyles.colors.grey};
  margin-bottom: 16px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: ${props => props.isCentered ? 'center' : 'flex-start'};

  &:hover {
    color: ${commonStyles.colors.white};

    & .social-icon path {
      transition: all 0.2s ease-in-out;
      fill: ${props => props.hoverColor ?? commonStyles.colors.white} !important;
    }
  }

  &:last-child {
    margin: 0;
  }

  & > svg.store-button {
    height: 40px;
    width: 130px;
    transition: all 0.2s ease-in-out;
  }

  & > svg.store-button:hover {
    transform: scale(1.05);
  }

  ${(props) => props.isImg && css`
    width: 48%;

    & svg {
      width: 100%;
    }

    ${respondTo.sm`
      width: 165px;
    `}
    ${respondTo.md`
      width: 125px;
    `}
  `}
  ${respondTo.md`
    margin-bottom: 20px;
   `}
`;

export const SocialsSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  width: 100%;
  
  &:last-child {
    margin: 0;
  }

  & ${FooterLink} {
    margin: 0;
  }
  
  & > a.store-btn {
    &:first-child {
      justify-content: flex-start;
    }
    &:last-child {
      justify-content: flex-end;
    }
  }
`;

export const LinksSection = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 32px;
  ${respondTo.sm`
    margin: 0;
  `}
`;
export const Links = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  border-bottom: 1px solid ${commonStyles.colors.alphaString(commonStyles.colors.grey, 0.2)};
  ${respondTo.sm`
    padding-bottom: 48px;
    flex-direction: row;
    justify-content: space-around;
  `}
  ${respondTo.md`
    border-bottom: none;
    padding-bottom: 0;
    justify-content: space-between;
    flex-grow: 1;
    margin: 0;
    margin-right: 7%;
  `}
  ${respondTo.lg`
    margin-right: 15%;
  `}
`;

export const Socials = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 330px;
  width: 100%;
  align-self: center;
  padding: 24px 0;
  
  & ${FooterTitle} {
    align-self: flex-start;
  }
  
  ${respondTo.sm`
    & ${FooterTitle} {
      align-self: flex-start;
    }
    padding: 24px 16px;
  `}

  ${respondTo.md`
    & ${FooterTitle} {
      align-self: flex-start;
    }
    align-self: unset;
    max-width: 278px;
    margin: 0;
    flex-grow: 1;
    padding: 0;
    border: none;
  `}
`;

export const FooterLogo = styled.div`
  margin-bottom: 40px;

  & svg {
    width: 200px;
    height: auto;
    transition: all 0.2s ease-in-out;
  }
  
  & svg:hover {
    transform: scale(1.02);
  }
  
  ${respondTo.sm`
    margin-left: 59px;
    margin-bottom: 30px;
    & svg {
      width: 175px;
    }
  `}
  
  ${respondTo.md`
    margin-left: 0;
  `}
`;
