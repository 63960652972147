import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Text } from '../../shared-components';
import { LoyaltySlideWrapper } from './Loyalty.style';
import LoyaltyBonusItem from './LoyaltyBonusItem';
import { ReactComponent as SupportIcon } from '../../../assets/svg/technical-support-bronze-icon.svg';
import { ReactComponent as CupIcon } from '../../../assets/svg/cup-icon.svg';
import { ReactComponent as BadgeIcon } from '../../../assets/svg/badge-icon.svg';
import { ReactComponent as LoyaltyIcon } from '../../../assets/svg/loyalty.svg';

const LoyaltySlide = props => {
  const { t } = useTranslation();
  const { item, active, inviteBonus } = props;
  return (
    <LoyaltySlideWrapper color={item.color} active={active}>
      <Text type={'h4'} className={'loyalty_header'}>{item.name}</Text>
      <LoyaltyBonusItem
        icon={<SupportIcon className={'loyalty_bonus_icon'}/>}
        header={t('userLoyalty.multiplierTextHeader', { multiplier: item.multiplier })}
        text={t('userLoyalty.multiplierTextBody', { multiplier: item.multiplier })}
      />
      {!!item.offPeakDiscount && <LoyaltyBonusItem
        icon={<BadgeIcon className={'loyalty_bonus_icon'}/>}
        header={t('userLoyalty.discountTextHeader', { discount: item.offPeakDiscount })}
        text={t('userLoyalty.discountTextBody', { discount: item.offPeakDiscount })}
      />}
      {!!item.preBookingGuarantee &&
        <LoyaltyBonusItem
          icon={<CupIcon className={'loyalty_bonus_icon'}/>}
          header={t('userLoyalty.preBookingGuaranteeTextHeader')}
          text={t('userLoyalty.preBookingGuaranteeTextBody')}
        />
      }
      {!!item.referralRides &&
        <LoyaltyBonusItem
          icon={<LoyaltyIcon className={'loyalty_bonus_icon'}/>}
          header={t('userLoyalty.referralRidesTextHeader', {
            referralRides: item.referralRides,
            bonusAmount: inviteBonus
          })}
          text={t('userLoyalty.referralRidesTextBody', {
            referralRides: item.referralRides,
            bonusAmount: inviteBonus
          })}
        />
      }
    </LoyaltySlideWrapper>
  );
};

LoyaltySlide.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    minTrips: PropTypes.number,
    multiplier: PropTypes.number,
    offPeakDiscount: PropTypes.number,
    preBookingGuarantee: PropTypes.bool,
    referralRides: PropTypes.number,
    freeFoodDeliveries: PropTypes.number,
    color: PropTypes.string,
  }).isRequired,
  active: PropTypes.bool,
  inviteBonus: PropTypes.number,
};

export default LoyaltySlide;
