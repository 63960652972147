import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../stores/helpers/use-stores';
import { Select } from '../../shared-components';
import { DeliveryInputsContainer } from './OrderInfo.style';
import UnavailableItemsModal from './UnavailableItemsModal';

const getDateTime = (date) => moment(date).format('HH:mm');

const DeliveryTimeInputs = () => {
  const { t } = useTranslation();
  const { dataStores: { sideModalStore, orderStore } } = useStores();
  const { isCartModalOpened } = sideModalStore;
  const { availableTimeSlotsData, deliveryDate } = orderStore;
  const updateInterval = useRef(null);

  const dayOptions = useMemo(() => {
    if (availableTimeSlotsData) {
      const slotsData = toJS(availableTimeSlotsData);
      const { deliveryDates } = slotsData;
      const dateKeys = Object.keys(deliveryDates);
      if (!dateKeys?.length) return [];
      const currentDateKey = moment().format('DD.MM');
      return dateKeys
        .filter(k => !!deliveryDates[k]?.length)
        .map(k => ({
          label: k === currentDateKey ? t('deliveryModal.today') : moment(k, 'DD.MM').format('ddd, DD MMM'),
          value: k
        }));
    }
    return [];
    // eslint-disable-next-line
  }, [deliveryDate, availableTimeSlotsData]);

  const timeOptions = useMemo(() => {
    if (availableTimeSlotsData) {
      const slotsData = toJS(availableTimeSlotsData);
      const { deliveryDates } = slotsData;
      const dateKey = moment(deliveryDate).format('DD.MM');
      const slots = deliveryDates[dateKey];
      if (!slots || !slots.length) return [];
      return deliveryDates[dateKey].map(ts => ({
        label: `${getDateTime(ts.from)}-${getDateTime(ts.to)}`,
        value: ts.from
      }));
    }
    return [];
  }, [deliveryDate, availableTimeSlotsData]);

  const dateValue = useMemo(() => {
    const dateKey = moment(deliveryDate).format('DD.MM');
    return dayOptions.find(d => d.value === dateKey) ?? null;
  }, [dayOptions, deliveryDate]);

  const timeValue = useMemo(() => {
    return timeOptions.find(d => moment(d.value).isSame(deliveryDate)) ?? null;
  }, [deliveryDate, timeOptions]);

  useEffect(() => {
    if (isCartModalOpened) {
      orderStore.getAvailableTimeSlots();
      updateInterval.current = setInterval(() => {
        orderStore.getAvailableTimeSlots();
      }, 30 * 1000);
    } else {
      if (updateInterval.current) {
        clearInterval(updateInterval.current);
      }
    }
    return () => {
      if (updateInterval.current) {
        clearInterval(updateInterval.current);
      }
    }
    // eslint-disable-next-line
  }, [isCartModalOpened]);

  const handleDateChange = sel => {
    const dateKey = sel.value;
    const slotsData = toJS(availableTimeSlotsData);
    const { deliveryDates } = slotsData;
    const nearestDayDate = deliveryDates[dateKey][0];
    if (nearestDayDate) {
      handleDateUpdate(nearestDayDate.from);
    }
  }

  const handleDateUpdate = (date) => {
    orderStore.checkAndUpdateDeliveryDate(date);
  }

  return (
    <DeliveryInputsContainer>
      <Select
        className={'selector'}
        tooltip={t('tooltips.deliveryDate')}
        icon={'time'}
        options={dayOptions}
        selectedValue={dateValue}
        onValueChange={handleDateChange}
      />
      <Select
        className={'selector'}
        options={timeOptions}
        selectedValue={timeValue}
        onValueChange={(sel) => handleDateUpdate(sel.value)}
      />
      <UnavailableItemsModal/>
    </DeliveryInputsContainer>
  );
};

export default observer(DeliveryTimeInputs);
