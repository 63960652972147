import { observer } from 'mobx-react-lite';
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStores } from '../../../stores/helpers/use-stores';
import {
  SelectContainer,
  StyledSelect,
  CustomStyles,
} from './Select.style';
import IconFont from '../IconFont';
import commonStyles from "../../commonStyles";
import SelectOption from './SelectOption';

const Select = ({
  options,
  placeholder,
  selectedValue,
  onValueChange,
  isDisabled,
  icon,
  className,
  outlined,
  inversion,
  isSearchable,
  textAlign,
  tooltip,
  isMulti,
  isClearable,
  clearValue
}) => {
  const selectRef = useRef();
  const { uiStore: { windowDimensions } } = useStores();
  const [isFocused, setIsFocused] = useState(false);
  const [isMobile, toggleMobile] = useState(windowDimensions.width < 900);

  useEffect(() => {
    if (windowDimensions.width < 900 && !isMobile) {
      toggleMobile(true);
    } else if (windowDimensions.width >= 900 && isMobile) {
      toggleMobile(false);
    }
    // eslint-disable-next-line
  }, [windowDimensions]);

  const components = {
    DropdownIndicator: () => (
      <IconFont
        className='select__icon'
        size={outlined ? '20px' : '16px'}
        icon="downChevron"
        type="primary"
      />
    ),
    IndicatorSeparator: () => null,
    Option: SelectOption,
  };

  const getClassNamePrefix = () => {
    if (outlined && inversion) {
      return 'outlined--inversion'
    }
    if (outlined) {
      return 'outlined'
    }
    return ''
  };

  const handleOnFocus = () => {
    setIsFocused(!isFocused);
  };

  return (
    <SelectContainer onClick={handleOnFocus} className={className}>
      {icon && (
        <IconFont className="select__icon" icon={icon} size="20px" color={commonStyles.colors.grey} tooltip={tooltip}/>
      )}
      <StyledSelect
        ref={selectRef}
        styles={CustomStyles}
        isMulti={isMulti}
        isClearable={isClearable}
        clearValue={clearValue}
        isSearchable={isSearchable}
        options={options}
        placeholder={placeholder}
        value={selectedValue}
        onChange={onValueChange}
        isDisabled={isDisabled}
        components={components}
        maxMenuHeight={196}
        captureMenuScroll={false}
        classNamePrefix={getClassNamePrefix()}
        isMobile={isMobile}
        textAlign={textAlign}
        // menuPortalTarget={document.body}
      />
    </SelectContainer>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object]),
    }).isRequired,
  ),
  placeholder: PropTypes.string,
  selectedValue: PropTypes.oneOfType(
    [PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    }),
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        }),
      ),
      PropTypes.string,
    ],
  ),
  onValueChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  labelText: PropTypes.string,
  className: PropTypes.string,
  outlined: PropTypes.bool,
  inversion: PropTypes.bool,
  isSearchable: PropTypes.bool,
  textAlign: PropTypes.string,
  tooltip: PropTypes.string,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  clearValue: PropTypes.any,
};

Select.defaultProps = {
  isSearchable: false,
  className: undefined,
  selectedValue: undefined,
  options: undefined,
  placeholder: undefined,
  isDisabled: undefined,
  labelText: undefined,
  outlined: false,
  inversion: false,
  textAlign: 'center'
};

export default observer(Select);
