import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../stores/helpers/use-stores';
import { Button, Text } from '../../shared-components';
import { RegisterFormWrapper } from '../AuthModal.style';

const PasswordResetConfirm = () => {
  const { t } = useTranslation();
  const { dataStores: { authModalStore, authStore } } = useStores();
  const { resetEmail } = authStore;
  return (
    <RegisterFormWrapper>
      <Text type={'bodySmall'} className={'send_code_subtitle'}>{t('auth.passResetSuccessText', { email: resetEmail })}</Text>
      <Button
        type={'primary'}
        text={'Ok'}
        className={'main_button'}
        onClick={() => authModalStore.hideModal()}
      />
    </RegisterFormWrapper>
  );
};

export default observer(PasswordResetConfirm);
