import React from 'react';
import {
  CustomOption,
} from './SelectOption.style';

const SelectOption = (props) => {
  const {
    data,
    innerRef,
    innerProps,
    isSelected,
    isFocused,
    selectProps: { getOptionLabel },
    customMenuItemRenderFunc,
  } = props;

  let dataDisplay;
  if (customMenuItemRenderFunc) {
    dataDisplay = customMenuItemRenderFunc(data);
  } else if (getOptionLabel) {
    dataDisplay = getOptionLabel(data);
  } else {
    dataDisplay = data.label;
  }

  return (
    <CustomOption ref={innerRef} {...innerProps} isSelected={isSelected} isFocused={isFocused}>
      {dataDisplay}
    </CustomOption>
  );
};

export default SelectOption;
