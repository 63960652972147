import { capitalize } from 'lodash/string';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CANCELLED, COMPLETED } from '../../../../constants/orderStatuses';
import ServiceTypes from '../../../../constants/serviceTypes';
import { formatPrice } from '../../../../helpers/priceHelpers';
import commonStyles from '../../../commonStyles';
import { getServiceImage } from '../../../Main/ServicesList';
import { statusesList } from '../../../OrderStatus/OrderStatus';
import { IconFont, Text } from '../../../shared-components';
import {
  CardContainer,
  OrderInfo,
  CardFooter,
  CardFooterSection,
  StatusBar,
  StatusPoint
} from './OrderCard.style';

const getOrderList = (orderItems) => {
  return orderItems.map(item => `${item.qty}x ${item.name}`).join(', ');
};

const OrderCard = (props) => {
  const { orderData } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const orderImage = useMemo(() => {
    if (!orderData.provider.logoUrl) {
      return getServiceImage(orderData.type);
    }
    return orderData.provider.logoUrl;
  }, [orderData]);

  const isCompleted = useMemo(() => [COMPLETED, CANCELLED].includes(orderData.orderStatus), [orderData]);

  const statuses = useMemo(() => statusesList(t, orderData.isSelfPickup), [t, orderData]);

  const handleCardClick = () => {
    if (orderData.orderHref) {
      history.push(orderData.orderHref);
    } else {
      props.onOrderInfo(orderData);
    }
  }

  return (
    <CardContainer img={orderImage} isCompleted={isCompleted} onClick={handleCardClick}>
      <OrderInfo>
        <Text className="order-text" type="bodySmall">
          {`${t('userOrders.orderNo')}:`}
          <span>{orderData.type === ServiceTypes.FOOD ? '#' : ''}{orderData.orderNo}</span>
        </Text>
        <Text className="order-text" type="bodySmall">
          {`${t('userOrders.status')}:`}
          <span>{capitalize(orderData.orderStatus)}</span>
        </Text>
      </OrderInfo>
      <OrderInfo>
        <Text className="order-text bold" type="bodySmall">{orderData.provider.name}</Text>
        <Text className="order-text" type="bodySmall">{getOrderList(orderData.orderItems)}</Text>
      </OrderInfo>

      <CardFooter isStatusBar={!isCompleted}>
        {isCompleted && <>
          <CardFooterSection>
            <IconFont size="20px" icon="wallet" color={commonStyles.colors.grey}/>
            <Text className="food-text" type="bodySmall" weight="700">{formatPrice(orderData.priceData.totalAmt)}</Text>
          </CardFooterSection>
          <CardFooterSection>
            <IconFont size="20px" icon="timetable" color={commonStyles.colors.grey}/>
            <Text className="time-text" type="bodySmall">
              {moment(orderData.deliveryDate).locale('de').format('DD.MM.YYYY [um] HH:mm')}
            </Text>
          </CardFooterSection>
        </>}
        {!isCompleted &&
          <StatusBar>
            {statuses.map((status) => (
              <StatusPoint
                small
                key={status.statusId}
                status={statuses.find((item) => item.label === orderData.orderStatus)?.statusId}
                type={orderData.type}
                label={status.text}
              />
            ))}
          </StatusBar>
        }
      </CardFooter>
    </CardContainer>
  );
};

OrderCard.propTypes = {
  orderData: PropTypes.shape({
    deliveryDate: PropTypes.string,
    deliveryPlace: PropTypes.shape({
      address: PropTypes.string,
      location: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      })
    }),
    fullDeliveryAddress: PropTypes.string,
    isSelfPickup: PropTypes.bool,
    provider: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      slug: PropTypes.string,
      emplacement: PropTypes.shape({
        address: PropTypes.string,
        location: PropTypes.shape({
          latitude: PropTypes.number,
          longitude: PropTypes.number,
        })
      }),
      phoneNo: PropTypes.string,
      logoUrl: PropTypes.string,
      providerData: PropTypes.any,
    }),
    clientPhoneNo: PropTypes.string,
    clientEmail: PropTypes.string,
    clientOrderName: PropTypes.string,
    clientOrderLName: PropTypes.string,
    orderItems: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      qty: PropTypes.number,
      itemPrice: PropTypes.number,
      mwst: PropTypes.number,
      comment: PropTypes.string,
      artNo: PropTypes.string,
    })),
    priceData: PropTypes.shape({
      orderItemsFare: PropTypes.number,
      deliveryFare: PropTypes.number,
      discountPercent: PropTypes.number,
      discountAmt: PropTypes.number,
      commissionPercent: PropTypes.number,
      commissionAmt: PropTypes.number,
      totalAmt: PropTypes.number,
    }).isRequired,
    itemsQty: PropTypes.number,
    orderStatus: PropTypes.string,
    orderComment: PropTypes.string,
    paymentMethod: PropTypes.string,
    slotLength: PropTypes.number,
    type: PropTypes.string,
    orderHref: PropTypes.string,
  }),
  onOrderInfo: PropTypes.func,
};

export default OrderCard;
