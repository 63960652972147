import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useStores } from '../../../stores/helpers/use-stores';
import { Text } from '../../shared-components';
import { PrivacyWrapper } from './AccountData.style';
import PrivacyItem from './PrivacyItem';

const generateArticles = (privacyPolicy, t, handleToggle) => {
  return Object.entries(privacyPolicy)
    .filter(item => ['newsAndSuggestions', 'surveysAndStudy', 'personalAds'].includes(item[0]))
    .map(([key, value]) => ({
      key,
      privacyTitle: t(`privacyPolicy.${key}.title`),
      privacyText: t(`privacyPolicy.${key}.description`),
      hasBtn: true,
      isTurnedOn: value,
      onToggle: () => handleToggle({ ...privacyPolicy, [key]: !value })
    }));
}

const UserPrivacyPolicy = () => {
  const { t } = useTranslation();
  const { dataStores: { authStore, userStore } } = useStores();
  const { userData: { privacyPolicyAgreement } } = authStore;

  const handleChange = (newPolicy) => {
    userStore.updateUserPolicy(newPolicy);
  };

  const articles = useMemo(() => generateArticles(privacyPolicyAgreement, t, handleChange),
    // eslint-disable-next-line
    [t, privacyPolicyAgreement]);

  return (
    <PrivacyWrapper>
      <Text className={'privacy_header'} type={'h3'}>{t('privacyPolicy.title')}</Text>
      <PrivacyItem
        key={'privacy_pre_title'}
        privacyTitle={t('privacyPolicy.preTitle')}
        privacyText={<Trans
          i18nKey={'privacyPolicy.preDesc'}
          className={'privacy_element_desc'}
          components={{
            lnk: <a
              href={'https://www.cabdo.de/datenschutzbestimmung'}
              className={'privacy_link'}
              target={'_blank'}
              rel="noreferrer"
            >
              text
            </a>,
            italic: <span className={'italic'}>text</span>
          }}
        />}
      />
      {articles.map(art => <PrivacyItem {...art} />)}
    </PrivacyWrapper>
  );
};

export default observer(UserPrivacyPolicy);
