import { action, makeAutoObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { getProviders } from '../../../utils/api/providers';
import { getAvailableStorage } from '../../storage';

export default class ProvidersStore {
  @observable isFetching = false;
  @observable isError = false;
  @observable errorMessage = null;
  @observable providerList = [];
  @observable selectedProvider = null;
  @observable tagsList = [];

  constructor(rootStore) {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'ProvidersData',
      properties: [
        'selectedProvider',
        'tagsList',
      ],
      storage: getAvailableStorage(),
      expireIn: 6 * 60 * 60 * 100,
      removeOnExpiration: true,
    }, {
      delay: 200,
    });
    this.rootStore = rootStore;
  }

  @action selectProvider = (provider = null) => {
    this.selectedProvider = provider;
  };

  @action setProviderList = (providerList = []) => {
    this.providerList = providerList;
  };

  @action setTags = (tags = []) => {
    this.tagsList = tags;
  };

  @action setLoading = () => {
    this.isFetching = true;
    this.isError = false;
    this.errorMessage = null;
  };

  @action setLoaded = (isError = false, errorMessage = null) => {
    this.isFetching = false;
    this.isError = isError;
    this.errorMessage = errorMessage;
  };

  get providerSlug() {
    return this.selectedProvider?.slug ?? null;
  }

  get isSelfDelivery() {
    return this.selectedProvider?.providerData?.hasOwnDelivery ?? false;
  }

  @action clearStore = () => {
    this.setLoaded();
    this.setProviderList([]);
    this.setTags([]);
    this.selectProvider(null);
  };

  @action getProviderList = async () => {
    try {
      this.setLoading();
      this.setTags();
      const serviceSlug = this.rootStore.dataStores.servicesStore.currentServiceType;
      if (!serviceSlug) {
        this.setLoaded();
        return;
      }
      const resp = await getProviders(
        this.rootStore.dataStores.servicesStore.bookerToken,
        serviceSlug
      );
      if (resp.success) {
        this.setProviderList(resp.data);
        if (resp.tags?.length) {
          this.setTags(resp.tags);
        }
        this.setLoaded();
      } else {
        this.setLoaded(true, resp.message);
      }
    } catch (e) {
      console.error('Error fetching providers', e);
      this.setLoaded(true, e.message);
    }
  }

}
