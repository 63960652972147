import React from 'react';
import { observer } from 'mobx-react-lite';
import BackButton from './BackButton';
import { BasicWrapper, PageContent, TopSection, Title, CurveContainer } from './Layout.style';
import Header from '../../Header';
import { Text, ReduceContainer } from '../../shared-components';
import { useStores } from '../../../stores/helpers/use-stores';

import { ReactComponent as SectionCurve } from '../../../assets/svg/section_curve.svg';
import { ReactComponent as SectionCurveMob } from '../../../assets/svg/section_curve_mob.svg';
import ProviderData from './providerData';

const Layout = (props) => {
  const {
          type,
          children,
          isMain,
          pageTitle,
          topSectionContent,
        } = props;

  const { dataStores: { providersStore } } = useStores();
  const { selectedProvider } = providersStore;

  return (
    <BasicWrapper>
      <Header type={type} isMain={isMain}/>
      <PageContent>
        {type !== 'white' && (
          <TopSection type={type} isMain={isMain}>
            <ReduceContainer>
              {pageTitle && (
                <>
                  <BackButton type={type} />
                  <Title>
                    <Text className="title-text" type="h2" weight="700">{pageTitle}</Text>
                  </Title>
                </>
              )}
              <ProviderData provider={selectedProvider} type={type} />
              {topSectionContent}
            </ReduceContainer>
            <CurveContainer type={type}>
              <SectionCurveMob className='top_curve_mob' />
              <SectionCurve className='top_curve' />
            </CurveContainer>
          </TopSection>
        )}
        <ReduceContainer>
          {children}
        </ReduceContainer>
      </PageContent>
    </BasicWrapper>
  )
};

export default observer(Layout);
