import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../stores/helpers/use-stores';
import { ShowFields } from '../../ItemsList/ToolSection/ToolSection';
import {
  Label,
  ToolSectionContainer,
  ToolSectionElement,
  ToolSectionPart
} from '../../ItemsList/ToolSection/ToolSection.style';
import { Select } from '../../shared-components';

const ToolSection = () => {
  const { t } = useTranslation();
  const { dataStores: { userStore } } = useStores();
  const { ordersSearchMeta, ordersSortParam } = userStore;

  useEffect(() => {
    return () => {
      userStore.setOrdersSearchMetaToDefault();
    }
    // eslint-disable-next-line
  }, []);

  const sortByList = useMemo(() => {
    return [{
      label: t('products.date'),
      value: 'date'
    }, {
      label: t('products.price'),
      value: 'price'
    }];
  }, [t]);

  const orderByFields = useMemo(() => [
    {
      label: t('products.asc'),
      value: 'asc',
    }, {
      label: t('products.desc'),
      value: 'desc'
    }
  ], [t]);

  return (
    <ToolSectionContainer>
      <ToolSectionPart>
        <ToolSectionElement>
          <Label type="bodySmall" weight="700">{t('products.sortBy')}</Label>
          <Select
            selectedValue={sortByList.find(opt => opt.value === ordersSortParam.field)}
            className={'inverted-select sort_name'}
            options={sortByList}
            outlined
            inversion
            textAlign={'left'}
            onValueChange={(sel) => userStore.setOrdersSortField(sel.value)}
          />
        </ToolSectionElement>
        <ToolSectionElement>
          <Label type="bodySmall" weight="700">{t('products.sortOrder')}</Label>
          <Select
            selectedValue={orderByFields.find(opt => opt.value === ordersSortParam.order)}
            options={orderByFields}
            className={'inverted-select sort_order'}
            outlined
            inversion
            textAlign={'left'}
            onValueChange={(sel) => userStore.setOrdersSortOrder(sel.value)}
          />
        </ToolSectionElement>
      </ToolSectionPart>
      <ToolSectionPart>
        <ToolSectionElement>
          <Label type="bodySmall" weight="700">{t('products.show')}</Label>
          <Select
            selectedValue={ShowFields.find(opt => opt.value === ordersSearchMeta.limit)}
            options={ShowFields}
            className={'inverted-select sort_number'}
            outlined
            inversion
            textAlign={'left'}
            onValueChange={(sel) => userStore.setOrdersMetaLimit(sel.value)}
          />
        </ToolSectionElement>
      </ToolSectionPart>
    </ToolSectionContainer>
  );
};

export default observer(ToolSection);
