import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete, {
  geocodeByAddress
} from 'react-places-autocomplete';
import { formatAddressString, getAddressObject } from '../../../helpers/addressHelpers';
import { useStores } from '../../../stores/helpers/use-stores';
import commonStyles from '../../commonStyles';
import { IconFont } from '../index';
import {
  InputContainer,
  InputIcon,
  IconContainer,
  InputField,
  DropdownContainer,
  Suggestion,
  AutocompleteContainer,
} from './PlacesInput.styes';

const PlacesInput = props => {
  const { className, icon, address, placeholder, isValid, tooltip } = props;
  const { dataStores: { deliveryModalStore } } = useStores();
  const { isGoogleApiLoaded, placesSearchOptions } = deliveryModalStore;
  const inputRef = useRef(null);

  const handleSelect = (selectedAddress) => {
    geocodeByAddress(selectedAddress)
      .then(latLng => {
        const addressObject = getAddressObject(latLng[0]);
        const addressString = formatAddressString(addressObject, false);
        const place = {
          address: addressString,
          location: addressObject.location,
          fullAddress: addressObject.full_address,
          addressData: addressObject
        };
        props.onSelect(place);
      })
      .catch(e => props.onError(e.message));
  };

  if (!isGoogleApiLoaded) {
    return null;
  }

  return (
    <InputContainer className={className}>
      <IconContainer>
        <InputIcon className="input-icon">
          <IconFont icon={icon} size="20px" color={commonStyles.colors.grey} tooltip={tooltip}/>
        </InputIcon>
      </IconContainer>
      <PlacesAutocomplete
        value={address}
        debounce={400}
        searchOptions={placesSearchOptions}
        onChange={a => props.onAddressChange(a)}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <AutocompleteContainer>
            <InputField
              valid={isValid}
              ref={inputRef}
              {...getInputProps({
                placeholder
              })}
            />
            <DropdownContainer
              className="autocomplete__dropdown"
              show={!!suggestions.length}
              inputWidth={inputRef?.current?.getBoundingClientRect()?.width}
            >
              {loading && <Suggestion>Loading...</Suggestion>}
              {suggestions.map(suggestion => {
                return (
                  <Suggestion
                    key={suggestion.placeId}
                    {...getSuggestionItemProps(suggestion)}
                  >
                    <span>
                      {suggestion.description}
                    </span>
                  </Suggestion>
                );
              })}
            </DropdownContainer>
          </AutocompleteContainer>
        )}
      </PlacesAutocomplete>
    </InputContainer>
  );
};

PlacesInput.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  isClearable: PropTypes.bool,
  isValid: PropTypes.bool,
  address: PropTypes.string,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
  onAddressChange: PropTypes.func,
  tooltip: PropTypes.string,
};

export default observer(PlacesInput);
