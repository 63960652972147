import { action, makeAutoObservable, observable } from 'mobx';

export class DifferentProviderModalStore {
  @observable isVisible = false;
  @observable itemToAdd = null;

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @action showModal = (item) => {
    this.isVisible = true;
    this.itemToAdd = item;
  }

  @action hideModal = () => {
    this.isVisible = false;
    this.itemToAdd = null;
  }

  @action onConfirm = () => {
    this.rootStore.dataStores.orderStore.updateCart([]);
    this.rootStore.dataStores.orderStore.addToCart(this.itemToAdd);
    this.hideModal();
  }
}
