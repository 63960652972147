import _ from 'lodash';
import { toJS } from 'mobx';
import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDebouncedEffect } from '../../../hooks/useDebouncedCallback';
import { usePrevious } from '../../../hooks/usePrevious';
import { useStores } from '../../../stores/helpers/use-stores';
import { CardsSection } from '../../shared-components';
import CategoryCard from '../CategoryCard';

const MainCategoriesList = props => {
  const { serviceType, providerName: providerSlug, categoryName: categorySlug } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { dataStores: { servicesStore, providersStore, productsStore } } = useStores();
  const { providerList, selectedProvider } = providersStore;
  const { selectedService } = servicesStore;
  const { categoriesList } = productsStore;

  const previousProvider = usePrevious(selectedProvider);

  const providers = useMemo(() => toJS(providerList), [providerList]);
  const categories = useMemo(() => toJS(categoriesList), [categoriesList]);

  useEffect(() => {
    if (selectedService !== serviceType) {
      servicesStore.setSelectedService(serviceType);
    }
    // eslint-disable-next-line
  }, [serviceType, servicesStore]);

  useEffect(() => {
    if (!providers?.length) {
      providersStore.getProviderList();
    }
    // eslint-disable-next-line
  }, [providerSlug, providerList]);

  useDebouncedEffect(() => {
    const newProvider = providers.find((p) => p.slug === providerSlug);
    if (!_.isEqual(toJS(newProvider), toJS(previousProvider))) {
      providersStore.selectProvider(newProvider);
    }
    // eslint-disable-next-line
  }, 50, [providerList]);

  const handleCategorySelect = (cat) => {
    if (!categorySlug) {
      history.push(`${location.pathname}/${cat.slug}`);
    } else {
      history.push(`${cat.slug}`);
    }
  }

  return (
    <CardsSection className="cards__section" justifyContent="left">
      {categories?.map((item, i) => (
        <CategoryCard
          key={`${item.slug}-${i}`}
          item={item}
          onCategorySelect={() => handleCategorySelect(item)}
        />
      ))}
    </CardsSection>
  );
};

export default MainCategoriesList;
