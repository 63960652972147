import React from 'react';
import { observer } from 'mobx-react-lite';
import { LoaderContainer } from './Loader.style';
import { useScript } from '../../../hooks/useScript';
import commonStyles from '../../commonStyles';

const Loader = ({ type }) => {
  useScript('https://cdn.lordicon.com/libs/mssddfmo/lord-icon-2.1.0.js');

  return (
    <LoaderContainer>
      <lord-icon
        src="https://cdn.lordicon.com/fyhanzjw.json"
        trigger="loop"
        colors={`primary:${commonStyles.colors[type]},secondary:${commonStyles.colors[type]}`}
        style={{ width: '150px', height: '150px' }}
      >
      </lord-icon>
    </LoaderContainer>
  )
};

export default observer(Loader);
