import styled, { css } from 'styled-components';
import commonStyles, { respondTo } from '../../commonStyles';

const getButtonBackgroundColor = type => {
  switch (type) {
    case 'ghost':
      return commonStyles.colors.white;
    default:
      return commonStyles.colors.primary;
  }
};

const getButtonTextColor = type => {
  switch (type) {
    case 'ghost':
      return commonStyles.colors.primary;
    default:
      return commonStyles.colors.white;
  }
};

const getButtonHoverBackground = type => {
  switch (type) {
    case 'ghost':
      return commonStyles.colors.primary;
    case 'food':
      return commonStyles.colors.food;
    default:
      return commonStyles.colors[type];
  }
};

const getButtonHoverText = type => {
  switch (type) {
    case 'food':
      return commonStyles.colors.primary;
    case 'ghost':
    default:
      return commonStyles.colors.white;
  }
};

export const disabledButtonState = css`
  background-color: ${commonStyles.colors.grey};
  pointer-events: none;

  & .button__text {
    color: ${commonStyles.colors.white};
  }
`;

const hoverState = css`
  cursor: pointer;
  background-color: ${({ type }) => getButtonHoverBackground(type)};

  & .button__text {
    color: ${({ type }) => getButtonHoverText(type)};
  }

  ${({ secondary }) => secondary && css`
    background-color: transparent;

    & .button__text {
      ${({ type }) => type && css`
        color: ${type === 'ghost' ? commonStyles.colors.white : commonStyles.colors[type]};
        border-bottom-color: ${type === 'ghost' ? commonStyles.colors.white : commonStyles.colors[type]};
      `}
    }
  `}
`;

const secondaryState = css`
  box-shadow: none;
  background-color: transparent;
  height: auto;
  width: auto;

  & .button__text {
    color: ${commonStyles.colors.primary};
    border-bottom: 2px dashed ${commonStyles.colors.primary};
  }
`;

export const CustomButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  position: relative;

  border: none;
  border-radius: 5px;
  background-color: ${({ type }) => getButtonBackgroundColor(type)};
  transition: 0.1s ease-in;

  & .button__text {
    transition: 0.1s ease-in;
    color: ${({ type }) => getButtonTextColor(type)};
    ${({ multiline }) => multiline ? `
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    ` : ''}
  }

  & .button__link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  width: ${({ width }) => (width || '100%')};

  ${({ type }) => type === 'ghost' && css`
    box-shadow: inset 0 0 0 1px ${commonStyles.colors.primary};
  `}
  ${({ secondary }) => secondary && secondaryState}
  &:hover, &:active {
    ${hoverState}
  }

  &:disabled {
    ${disabledButtonState}
  }

  ${respondTo.sm`
    height: 60px;
    width: ${({ width }) => (width || '380px')};
    ${({ secondary }) => secondary && secondaryState};
  `};
`;
