import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../../shared-components';
import { SummaryItemWrapper } from './Referrals.style';

const SummaryItem = props => {
  const { icon, text, value } = props;
  return (
    <SummaryItemWrapper>
      {icon}
      <Text className={'summary_item_name'} type={'bodySmall'}>{text}</Text>
      <Text className={'summary_item_value'} type={'body'} weight={700}>{value}</Text>
    </SummaryItemWrapper>
  );
};

SummaryItem.propTypes = {
  icon: PropTypes.element,
  text: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SummaryItem;
