import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { hasCategory } from '../../../helpers/utils';
import commonStyles from '../../commonStyles';
import { IconFont, Text } from '../../shared-components';
import { CategoryItemContainer, SubCategoriesContainer } from './Categories.style';

const SideCategoryItem = props => {
  const { categoryData, active, isSubCategory, exact } = props;
  const { categoryName: currentCategorySlug } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [isCollapsed, toggleCollapse] = useState(!active || !categoryData.subCategories?.length);

  useEffect(() => {
    toggleCollapse(!active);
  }, [active]);

  const handleCategoryPress = () => {
    if (exact) {
      history.push(categoryData.slug);
      return;
    }
    if (!currentCategorySlug) {
      history.push(`${location.pathname}/${categoryData.slug}`);
    } else {
      history.push(`${categoryData.slug}`);
    }
  }

  return (
    <CategoryItemContainer
      className={'category_item-container'}
      nestingLevel={categoryData.level}
      isCollapsed={isCollapsed}
      isSubCategory={isSubCategory}
      isActive={active}
    >
      <div
        className={'category_item-nameContainer'}
        onClick={handleCategoryPress}
      >
        <Text className={'category_item-name'} type={'bodySmall'}>{categoryData.name}</Text>
        {!!categoryData.subCategories?.length && <div
          className={'category_item-chevronContainer'}
          onClick={(e) => {
            e.stopPropagation();
            toggleCollapse(!isCollapsed);
          }}
        >
          <IconFont
            className={'category_item-chevron'}
            icon={'downChevron'}
            color={commonStyles.colors[isCollapsed ? 'grey' : 'primary']}
            size={20}
          />
        </div>}
      </div>
      {!isCollapsed && <SubCategoriesContainer>
        {categoryData.subCategories.map((cat, index) => <SideCategoryItem
          key={`${cat.slug}-${index}`}
          categoryData={cat}
          active={currentCategorySlug === cat.slug ||
            (!!cat.subCategories?.length && !!hasCategory(cat.subCategories, currentCategorySlug))
          }
          isSubCategory={true}
        />)}
      </SubCategoriesContainer>}
    </CategoryItemContainer>
  );
};

SideCategoryItem.propTypes = {
  categoryData: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
    slug: PropTypes.string,
    description: PropTypes.string,
    subCategories: PropTypes.array,
    parentCatId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    sortOrder: PropTypes.number,
    level: PropTypes.number,
  }),
  active: PropTypes.bool,
  isSubCategory: PropTypes.bool,
  exact: PropTypes.bool,
};

export default SideCategoryItem;
