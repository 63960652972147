import axios from 'axios';
import Cookies from 'js-cookie';
import moment from 'moment';

export const getTokenHeader = (bookerToken = null) => {
  let token = bookerToken;
  if (!token) {
    token = Cookies.get('cabdo-booker-token');
  }
  if (!token) return null;
  return {
    headers: {
      'cabdo-booker-token': token,
    }
  };
};

export const getUserToken = (userToken = null) => {
  let token = userToken;
  if (!token) {
    token = Cookies.get('cabdo-user-token');
  }
  if (!token) return null;
  return {
    headers: {
      'Authorization': token,
    }
  };
};

export const getBookerDataHeaders = (bookerData = null) => {
  let data = bookerData;
  if (!data) {
    const bookedDataString = Cookies.get('cabdo-booker-data');
    data = JSON.parse(bookedDataString);
  }
  const resp = { headers: {} };
  if (data.deliveryPlace?.location?.latitude && data.deliveryPlace?.location?.longitude) {
    resp.headers['cabdo-booker-latitude'] = data.deliveryPlace.location.latitude;
    resp.headers['cabdo-booker-longitude'] = data.deliveryPlace.location.longitude;
  }
  if (data.desiredOrderDate) {
    resp.headers['cabdo-booker-order-date'] = moment(data.desiredOrderDate).toISOString();
  }
  if (data.currentCityName) {
    resp.headers['cabdo-booker-city-name'] = data.currentCityName;
  }
  if (data.currentCityId) {
    resp.headers['cabdo-booker-city-id'] = data.currentCityId;
  }
  return resp;
}

function formatErrorResponse(error) {
  const customError = error;
  customError.status = error.response ? error.response.status : null;
  return customError;
}

const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'Access-Control-Allow-Origin': '*'
};

export default async function api(options) {
  const { headers = {} , authToken, ...otherOptions } = options;
  try {
    const resp = await axios({ headers: { ...headers, ...defaultHeaders }, ...otherOptions });
    return resp.data;
  } catch (error) {
    return formatErrorResponse(error);
  }
}

api.get = (options) => api({ ...options, method: 'GET' });

api.post = (options) => api({ ...options, method: 'POST' });

api.put = (options) => api({ ...options, method: 'PUT' });

api.delete = (options) => api({ ...options, method: 'DELETE' });
