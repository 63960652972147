import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AuthModalState from '../../constants/authModalStates';
import { useStores } from '../../stores/helpers/use-stores';
import { Loader, Modal } from '../shared-components';
import { ModalContentWrapper } from './AuthModal.style';
import ChangePassword from './forms/ChangePass';
import LoginForm from './forms/Login';
import MobileVerification from './forms/MobileVerification';
import PassRecoveryForm from './forms/PassRecovery';
import PasswordResetConfirm from './forms/PasswordResetConfirm';
import RegisterForm from './forms/Register';
import SocialRegister from './forms/SocialRegister';

const AuthModal = () => {
  const { t } = useTranslation();
  const { dataStores: { authModalStore, authStore } } = useStores();
  const { modalVisible, modalState } = authModalStore;
  const { loading } = authStore;

  const childComponent = useMemo(() => {
    switch (modalState) {
      case AuthModalState.changePass:
        return <ChangePassword />;
      case AuthModalState.passReset:
        return <PassRecoveryForm />;
      case AuthModalState.passResetConfirm:
        return <PasswordResetConfirm />;
      case AuthModalState.register:
        return <RegisterForm />;
      case AuthModalState.socialRegister:
        return <SocialRegister />;
      case AuthModalState.mobileConfirm:
        return <MobileVerification />;
      case AuthModalState.login:
      default:
        return <LoginForm />;
    }
  }, [modalState]);

  const modalTitle = useMemo(() => {
    switch (modalState) {
      case AuthModalState.changePass:
        return t('auth.changePassword');
      case AuthModalState.passReset:
        return t('auth.forgotPassword');
      case AuthModalState.passResetConfirm:
        return t('auth.passResetConfirmTitle');
      case AuthModalState.register:
        return t('auth.registerTitle');
      case AuthModalState.socialRegister:
        return t('auth.socialRegisterTitle');
      case AuthModalState.mobileConfirm:
        return t('auth.mobileConfirmTitle');
      case AuthModalState.login:
      default:
        return t('auth.loginTitle');
    }
  }, [modalState, t]);

  const handleHide = () => {
    authModalStore.hideModal();
  }

  return (
    <Modal
      isOpen={modalVisible}
      onRequestClose={handleHide}
      ariaHideApp={false}
      contentLabel={'Auth Modal'}
      title={modalTitle}
      maxWidth={600}
      overflow
    >
      <ModalContentWrapper>
        {childComponent}
      </ModalContentWrapper>
      {loading && <Loader type={'food'}/>}
    </Modal>
  );
};

export default observer(AuthModal);
