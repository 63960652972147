import React from 'react';
import PropTypes from 'prop-types';
import { IconFont, Link, Text } from '../shared-components';
import { CrumbContainer } from './Breadcrumbs.style';

const CrumbItem = props => {
  const { data, isLast, serviceType, isFirst } = props;
  return (
    <CrumbContainer type={serviceType} isLast={isLast}>
      <Link href={data.path}>
        {isFirst ?
          <IconFont icon={'home'} size={22} className={'crumb-label icon'} /> :
          <Text type={'bodySmall'} weight={700} className={'crumb-label'}>{data.label}</Text>
        }
      </Link>
      {!isLast &&
        <IconFont
          icon={'rightChevron'}
          size={16}
          className={'crumb-separator'}
        />
      }
    </CrumbContainer>
  );
};

CrumbItem.propTypes = {
  data: PropTypes.shape({
    path: PropTypes.string,
    label: PropTypes.string,
  }),
  isLast: PropTypes.bool,
  isFirst: PropTypes.bool,
  serviceType: PropTypes.string,
};

export default CrumbItem;
