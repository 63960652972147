import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useDebouncedEffect } from '../../../hooks/useDebouncedCallback';
import { usePrevious } from '../../../hooks/usePrevious';
import commonStyles from '../../commonStyles';
import { IconFont } from '../../shared-components';
import { ClearContainer, IconContainer, SearchbarContainer, SearchInput } from './Searchbar.style';

const Searchbar = props => {
  const { t } = useTranslation();
  const { place, query } = props;
  const location = useLocation();
  const history = useHistory();
  const [queryString, setQueryString] = useState('');
  const prevQuery = usePrevious(query);

  useEffect(() => {
    setQueryString(query ?? '');
  }, [query]);

  useDebouncedEffect(() => {
    if (!!queryString && queryString.length > 2) {
      history.push(`${location.pathname}?search=${queryString}`);
    } else if (!!prevQuery) {
      history.push(`${location.pathname}`);
    }
  }, 600, [queryString]);

  return (
    <SearchbarContainer>
      <IconContainer>
        <IconFont
          icon={'search'}
          className={'searchbar-icon'}
          color={commonStyles.colors.grey}
          size={24}
        />
      </IconContainer>
      <SearchInput
        type={'text'}
        value={queryString}
        onChange={e => setQueryString(e.target.value)}
        placeholder={t('search.searchIn', { place })}
      />
      <ClearContainer onClick={() => setQueryString('')}>
        <IconFont
          icon={'cross'}
          className={'searchbar-icon'}
          size={10}
        />
      </ClearContainer>
    </SearchbarContainer>
  );
};

Searchbar.propTypes = {
  place: PropTypes.string,
  query: PropTypes.string,
}

export default observer(Searchbar);
