import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { getOrderStatus } from '../../../utils/api/order';

export default class OrderStatusStore {
  @observable orderData = null;
  @observable isLoading = false;
  @observable fetchMessage = null;

  constructor() {
    makeAutoObservable(this);
  }

  @action loading = () => {
    this.isLoading = true;
    this.fetchMessage = null;
  }

  @action loaded = (message = null) => {
    this.isLoading = false;
    this.fetchMessage = message;
  }

  @action getOrderStatus = async (orderId) => {
    try {
      this.loading();
      const resp = await getOrderStatus(orderId);
      if (resp.success) {
        runInAction(() => {
          this.orderData = resp.data;
        });
        this.loaded()
      } else {
        this.loaded(resp.message);
      }
    } catch (e) {
      console.error('Error getting orderStatus', e);
      this.loaded(e.message);
    }
  }
};
