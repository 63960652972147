import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { InfoBox } from '../shared-components';
import AttributeSection from './attributeSection';
import { AttributesContainer } from './ProductModal.style';

const ProductAdditionsSection = props => {
  const { t } = useTranslation();
  const { productAttributes, addToCartElement, showPlaceInfoBox } = props;

  if (!productAttributes?.length) {
    return null;
  }

  return (
    <AttributesContainer>
      {!!showPlaceInfoBox && <InfoBox
        type={'primary'}
        imgType={'flag'}
        className={'items_list-warning-message'}
      >
        {t('products.choosePlace')}
      </InfoBox>}
      {productAttributes
        .map((attr, i) =>
          <AttributeSection
            key={`${attr.type}-${i}`}
            data={attr}
            onChange={(options) => props.onAttributesChange(options, i)}
          />)
      }
      {addToCartElement}
    </AttributesContainer>
  );
};

ProductAdditionsSection.propTypes = {
  productAttributes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
    isMulti: PropTypes.bool,
    isMandatory: PropTypes.bool,
    addToPrice: PropTypes.bool,
    numOfFreeItems: PropTypes.number,
    attributes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.number,
      selected: PropTypes.bool,
    })),
  })),
  showPlaceInfoBox: PropTypes.bool,
  onAttributesChange: PropTypes.func,
  addToCartElement: PropTypes.element,
};

export default ProductAdditionsSection;
