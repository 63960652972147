import { action, makeAutoObservable, observable } from 'mobx';

export default class SideModalStore {
  @observable isProductModalOpened = false;
  @observable isCartModalOpened = false;

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @action showProductModal = () => {
    this.isProductModalOpened = true;
  };

  @action hideProductModal = () => {
    this.isProductModalOpened = false;
  };

  @action showCartModal = () => {
    this.isCartModalOpened = true;
  };

  @action hideCartModal = () => {
    this.isCartModalOpened = false;
  };
}
