import styled from 'styled-components';
import commonStyles, { respondTo } from '../../commonStyles';

export const Wrapper = styled.div.attrs(() => ({}))`
  width: 100%;
`;

export const UserRow = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;
  border-radius: 15px;

  &:nth-child(2n) {
    background: ${commonStyles.colors.alphaString(commonStyles.colors.grey, 0.3)};
  }

  &:hover {
    background: ${commonStyles.colors.alphaString(commonStyles.colors.grey, 0.6)};
  }

  & > .user_icon {
    margin-right: 24px;
  }

  & > .clock_icon {
    margin-left: 24px;
  }
`;

export const UserDataBlock = styled.div.attrs(() => ({}))`
  width: 100%;

  & > .user_email {
    color: ${commonStyles.colors.darkGrey};
  }
`;

export const BlockWrapper = styled.div.attrs(() => ({}))`
  margin-bottom: 40px;

  & > .code_title {
    margin-bottom: 40px;
  }

  & > .code {
    text-align: center;
    margin-bottom: 40px;
  }
`;

export const FeatureWrapper = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;

  & > .referral_icon {
    height: 40px;
    width: 40px;
    margin-right: 24px;
  }
`;

export const DescWrapper = styled.ul.attrs(() => ({}))`
  margin-top: 40px;

  & > li {
    list-style-type: circle;
    margin: 24px 0;
  }
`;

export const SummaryContainer = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
`;

export const SummaryItemWrapper = styled.div.attrs(() => ({}))`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 30px;

  ${respondTo.md`
    margin: 0 40px;
  `}
  & > .summary_icon {
    height: 60px;
    width: 60px;
  }

  & > .summary_item_name {
    margin: 8px 0;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    ${respondTo.sm`
      margin: 16px 0;
    `}
  }
`;

