import api, { getBookerDataHeaders, getTokenHeader } from './api';
import { KEYS } from '../../keys';

export async function getToken(bookerData = null) {
  const headers = getBookerDataHeaders(bookerData);
  const options = {
    url: `${KEYS.API_URL}/api/booker/get-token`,
    ...headers
  };
  return api.get(options);
}

export async function getServices(token = null) {
  const headers = getTokenHeader(token);
  const options = {
    url: `${KEYS.API_URL}/api/booker/services`,
    ...headers
  };
  return api.get(options);
}

export async function getDeliveryZone(token = null, providerSlug) {
  const headers = getTokenHeader(token);
  const options = {
    url: `${KEYS.API_URL}/api/booker/delivery-zone/${providerSlug ?? ''}`,
    ...headers
  };
  return api.get(options);
}

export async function isInDeliveryZone(token = null, providerSlug) {
  const headers = getTokenHeader(token);
  const options = {
    url: `${KEYS.API_URL}/api/booker/delivery-zone/${providerSlug}`,
    ...headers
  };
  return api.put(options);
}
