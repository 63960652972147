import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { calculateProductPrice, formatPrice } from '../../helpers/priceHelpers';
import { SideModal } from '../shared-components';
import AddToCartControl from './addToCartControl';
import ProductAdditionsSection from './productAdditionsSection';
import ProductInfoSection from './productInfoSection';
import { useStores } from '../../stores/helpers/use-stores';

const initialProductData = {
  id: '',
  name: '',
  qty: 1,
  itemPrice: 0,
  mwst: 0,
  comment: '',
  attributes: [],
  artNo: ''
};

const ProductModal = () => {
  const {
          dataStores: {
            sideModalStore,
            servicesStore,
            productsStore,
            orderStore
          }
        } = useStores();

  const { isProductModalOpened } = sideModalStore;
  const { selectedService, deliveryPlace } = servicesStore;
  const { selectedProduct } = productsStore;

  const [productToOrder, setProductToOrder] = useState(initialProductData);
  const [canAddToCart, setCanAddToCart] = useState(false);

  const hasDeliveryData = useMemo(() => !!deliveryPlace?.location?.latitude && deliveryPlace?.location?.longitude && !!deliveryPlace.address, [deliveryPlace]);

  useEffect(() => {
    let canAdd = true;
    const mandatoryAttributeDataArray = productToOrder.attributes.filter(a => a.isMandatory);
    if (!mandatoryAttributeDataArray.length) {
      setCanAddToCart(canAdd);
      return;
    }
    mandatoryAttributeDataArray.forEach(attrData => {
      if (!attrData.attributes.some(a => a.selected)) {
        canAdd = false;
      }
    });
    setCanAddToCart(canAdd);
  }, [productToOrder]);

  useEffect(() => {
    if (isProductModalOpened) {
      sideModalStore.hideCartModal();
    } else {
      setTimeout(() => {
        productsStore.setProduct();
      }, 200);
    }
    // eslint-disable-next-line
  }, [isProductModalOpened]);

  useEffect(() => {
    if (selectedProduct) {
      const updateProduct = {
        ...initialProductData,
        ..._.cloneDeep(selectedProduct),
      };
      updateProduct.attributes = selectedProduct.attributeGroup ? _.cloneDeep(selectedProduct.attributeGroup) : [];
      updateProduct.itemPrice = calculateProductPrice(updateProduct);
      setProductToOrder(updateProduct);
    } else {
      setProductToOrder(initialProductData);
    }
  }, [selectedProduct]);

  const itemPriceText = useMemo(() => {
    const hasLooseSizePriceIndex = productToOrder.attributes.findIndex(a => !a.addToPrice);
    if (hasLooseSizePriceIndex >= 0 &&
      productToOrder.attributes[hasLooseSizePriceIndex].attributes.every(a => !a.selected)) {
      return `ab ${formatPrice(productToOrder.attributes[hasLooseSizePriceIndex].attributes[0].price)}`;
    }
    return formatPrice(productToOrder.itemPrice * productToOrder.qty);
  }, [productToOrder]);


  const handleAddToCart = () => {
    orderStore.addToCart(_.cloneDeep(productToOrder));
    sideModalStore.hideProductModal();
  };

  const handleItemCounter = (isSubtract = false) => {
    if (!isSubtract) {
      handleProductUpdate({ qty: ++productToOrder.qty });
    } else {
      let currentQty = productToOrder.qty;
      if (currentQty <= 1) {
        currentQty = 1;
      } else {
        --currentQty;
      }
      handleProductUpdate({ qty: currentQty });
    }
  }

  const AddToCartControlsElement = useMemo(() => <AddToCartControl
      serviceType={selectedService}
      product={productToOrder}
      onAddToCart={handleAddToCart}
      isButtonDisabled={!canAddToCart || !hasDeliveryData}
      onQtyChange={handleItemCounter}
    />,
    // eslint-disable-next-line
    [selectedService, productToOrder, canAddToCart, hasDeliveryData]);

  const handleProductUpdate = (data) => {
    const prod = { ...productToOrder, ...data };
    prod.itemPrice = calculateProductPrice(prod);
    setProductToOrder(prod);
  }

  const handleAttributesUpdate = (attributes, attrGroupIndex) => {
    const prodAttrs = _.cloneDeep(productToOrder.attributes);
    prodAttrs[attrGroupIndex].attributes = attributes;
    handleProductUpdate({ attributes: prodAttrs });
  };

  return (
    <SideModal
      isModalOpened={isProductModalOpened}
      onHide={() => sideModalStore.hideProductModal()}
      mainSection={<ProductInfoSection
        productData={selectedProduct}
        productToOrder={productToOrder}
        serviceType={selectedService}
        itemPrice={itemPriceText}
        hideCartControlsOnCollapse={!!productToOrder.attributes?.length}
        addToCartElement={AddToCartControlsElement}
        onProductUpdate={handleProductUpdate}
        onGetImage={(id) => productsStore.getProductFullImage(id)}
      />}
      secondarySection={<ProductAdditionsSection
        showPlaceInfoBox={!hasDeliveryData}
        productAttributes={productToOrder?.attributes}
        addToCartElement={AddToCartControlsElement}
        onAttributesChange={handleAttributesUpdate}
      />}
      hasSecondarySection={!!productToOrder?.attributes?.length}
    />
  );
};

export default observer(ProductModal);
