import React from 'react';
import {  Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FooterWrapper,
  FooterContainer,
  Links,
  Socials,
  FooterTitle,
  FooterLink,
  FooterLogo,
  LinksSection,
  SocialsSection,
  CurveContainer,
} from './Footer.style';
import { ReduceContainer, IconFont } from '../shared-components';
import { ReactComponent as LogoLight } from '../../assets/svg/logo_light.svg';
import { ReactComponent as AppStoreIcon } from '../../assets/svg/appstore.svg';
import { ReactComponent as GooglePlayIcon } from '../../assets/svg/googleplay.svg';
import { ReactComponent as SectionCurve } from '../../assets/svg/section_curve.svg';
import { ReactComponent as SectionCurveMob } from '../../assets/svg/section_curve_mob.svg';
import commonStyles from '../commonStyles';

const Footer = () => {
  const { t } = useTranslation();
  const links = {
    contacts: [
      {
        title: t('main.userContact'),
        url: 'mailto:info@cabdo.de'
      },
      {
        title: t('footer.becomePartner'),
        url: 'https://www.cabdo.de/foodpartner'
      },
      {
        title: t('footer.becomeDriver'),
        url: 'https://www.cabdo.de/fahrer'
      },
    ],
    navigation: [
      {
        title: t('footer.toStart'),
        url: '/',
        blank: false
      },
      {
        title: t('footer.toApp'),
        url: 'https://www.cabdo.de/download'
      },
      {
        title: t('main.toCabdo'),
        url: 'https://www.cabdo.de'
      },
    ],
    company: [
      {
        title: t('footer.aboutUs'),
        url: 'https://www.youtube.com/watch?v=sTnbXA5gJTU'
      },
      {
        title: t('footer.awards'),
        url: 'https://land-der-ideen.de/wettbewerbe/deutscher-mobilitaetspreis/preistraeger/best-practice-2021/cabdo-on-demand-services-fuer-die-erste-und-letzte-meile'
      },
      {
        title: t('footer.privacyPolicy'),
        url: 'https://www.cabdo.de/datenschutzbestimmung'
      },
      {
        title: t('footer.cookiesPolicy'),
        url: 'https://www.cabdo.de/impressum'
      },
    ],
    socials: [
      {
        title: 'globe',
        url: 'https://www.cabdo.de'
      },
      {
        title: 'instagram',
        url: 'https://www.instagram.com/cabdo.app'
      },
      {
        title: 'facebook',
        url: 'https://www.facebook.com/CabdoApp'
      },
      {
        title: 'instagram',
        url: 'https://www.instagram.com/cabdofood',
        hoverColor: commonStyles.colors.food
      },
      {
        title: 'facebook',
        url: 'https://www.facebook.com/CABDOFood/',
        hoverColor: commonStyles.colors.food
      },
    ],
  };

  return (
    <FooterWrapper>
      <CurveContainer>
        <SectionCurveMob className='footer_curve_mob' />
        <SectionCurve className='footer_curve' />
      </CurveContainer>
      <ReduceContainer>
        <FooterLogo>
          <RouterLink to="/">
            <LogoLight className={'footer_logo'} />
          </RouterLink>
        </FooterLogo>
        <FooterContainer>
          <Links>
            <LinksSection>
              <FooterTitle type="bodySmall">{t('main.contact')}</FooterTitle>
              {links.contacts.map((item) => <FooterLink key={`${item.title} link`} href={item.url} isBlank={item.blank}>{item.title}</FooterLink>)}
            </LinksSection>
            <LinksSection>
              <FooterTitle type="bodySmall">{t('footer.navigation')}</FooterTitle>
              {links.navigation.map((item) => <FooterLink key={`${item.title} link`} href={item.url} isBlank={item.blank}>{item.title}</FooterLink>)}
            </LinksSection>
            <LinksSection>
              <FooterTitle type="bodySmall">{t('footer.company')}</FooterTitle>
              {links.company.map((item) => <FooterLink key={`${item.title} link`} href={item.url}>{item.title}</FooterLink>)}
            </LinksSection>
          </Links>
          <Socials>
            <FooterTitle type="bodySmall">{t('footer.followUs')}</FooterTitle>
            <SocialsSection>
              {links.socials.map((item, index) => (
                <FooterLink key={`${item.title}-${index}`} href={item.url} isCentered>
                  <IconFont className="social-icon" icon={item.title} size="30px" color={item.hoverColor ?? commonStyles.colors.grey} />
                </FooterLink>
              ))}
            </SocialsSection>
            <SocialsSection>
              <FooterLink className={'store-btn'} isImg href={'https://apps.apple.com/app/cabdo/id1273536131'}>
                <AppStoreIcon className={'store-button'} />
              </FooterLink>
              <FooterLink className={'store-btn'} isImg href={'https://play.google.com/store/apps/details?id=de.cabdo.taxiappv1'}>
                <GooglePlayIcon className={'store-button'} />
              </FooterLink>
            </SocialsSection>
          </Socials>
        </FooterContainer>
      </ReduceContainer>
    </FooterWrapper>
  )
};

export default Footer;
