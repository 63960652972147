import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Text } from '../../shared-components';
import { Wrapper } from './NoItemsFound.style';
import { ReactComponent as Search } from '../../../assets/svg/info/search.svg';

const NoItemsFound = props => {
  const { t } = useTranslation();
  const { text, hasButton, btnText, btnAction, isLoading } = props;
  if (isLoading) return null;
  return (
    <Wrapper>
      <Search className={'search-icon'}/>
      <Text type={'body'} className={'not-found-text'}>{text ?? t('products.notFound')}</Text>
      {hasButton && <Button text={btnText} type={'primary'} className={'not-found-button'} onClick={btnAction} />}
    </Wrapper>
  );
};

NoItemsFound.propTypes = {
  isLoading: PropTypes.bool,
  hasButton: PropTypes.bool,
  btnText: PropTypes.string,
  btnAction: PropTypes.func,
  text: PropTypes.string,
};

export default NoItemsFound;
