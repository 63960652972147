const discountTypes = {
  wallet: 'wallet',
  walletCoupon: 'walletCoupon',
  promo: 'promo',
  promocode: 'promocode',
  coupon: 'coupon',
  restaurant: 'restaurant',
  provider: 'provider'
};

export default discountTypes;
