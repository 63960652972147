import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useStores } from '../../../stores/helpers/use-stores';
import { CardsSection } from '../../shared-components';
import ProviderCard from '../ProviderCard';

const ProviderList = props => {
  const { selectedTag } = props;
  const { serviceType, providerName: providerSlug } = useParams();
  const history = useHistory();
  const location = useLocation();
  const {
          dataStores: {
            servicesStore,
            providersStore,
          }
        } = useStores();
  const { providerList, isFetching, tagsList } = providersStore;
  const { selectedService, bookerToken } = servicesStore;
  const timerRef = useRef();

  const providers = useMemo(() => toJS(providerList).filter(provider => {
    if (!selectedTag) return true;
    return provider.providerData.tags.includes(selectedTag);
  }), [providerList, selectedTag]);

  useEffect(() => {
    if (selectedService) {
      providersStore.setProviderList();
      providersStore.selectProvider();
      providersStore.getProviderList();
    }
    // eslint-disable-next-line
  }, [selectedService, bookerToken]);

  useEffect(() => {
    servicesStore.setSelectedService(serviceType);
    // eslint-disable-next-line
  }, [serviceType]);

  const handleProviderSelect = (provider) => {
    if (!providerSlug) {
      history.push(`${location.pathname}/${provider.slug}`);
    } else {
      history.push(`${provider.slug}`);
    }
  };

  useEffect(() => {
    if (!isFetching && providers?.length === 1 && !toJS(tagsList)?.length) {
      timerRef.current = setTimeout(() => {
        history.replace(`${location.pathname}/${providers[0].slug}`);
      }, 100);
    } else {
      clearTimeout(timerRef.current);
    }
    // eslint-disable-next-line
  }, [providers, isFetching, tagsList]);

  return (
    <CardsSection
      className='cards__section'
      justifyContent="left"
    >
      {providers.map((provider, index) =>
        <ProviderCard
          key={`provider-${provider.slug}-${index}`}
          item={provider}
          onProviderSelect={() => handleProviderSelect(provider)}/>
      )}
    </CardsSection>
  );
};

ProviderList.propTypes = {
  selectedTag: PropTypes.string,
};

export default observer(ProviderList);
