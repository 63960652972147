import styled, { css } from 'styled-components';
import commonStyles, { respondTo } from '../../commonStyles';

export const Title = styled.div`
  display: flex;
  align-items: center;

  & .title-text {
    color: ${commonStyles.colors.white};
  }
`;

export const TopSection = styled.div`
  position: relative;
  padding-top: 36px;
  display: flex;
  flex-direction: column;
  background-color: ${({ type }) => commonStyles.colors[type]};
  margin-bottom: 120px;
  overflow-x: clip;
  color: ${commonStyles.colors.white};
  transition: min-height .3s;
  min-height: ${({ isMain }) => isMain ? 370 : 40}px;

  ${({ type }) => type === 'food' && css`
    color: ${commonStyles.colors.primary};

    & .title-text {
      color: ${commonStyles.colors.primary};
    }
  `}
  ${respondTo.md`
    padding-top: 60px;
    margin-bottom: 210px;
    min-height: ${({ isMain }) => isMain ? 558 : 62}px;
  `}
`;

export const PageContent = styled.div`
  overflow-x: hidden;
`;

export const BasicWrapper = styled.div`
  width: 100%;
`;

export const ProviderDataWrapper = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 0;

  & .provider-data-element:not(:first-child) {
    margin-left: 16px;
  }

  & .provider-data-element > svg.provider-data-icon {
    fill: ${({ type }) => commonStyles.colors.alphaString(['food', 'white'].includes(type) ? commonStyles.colors.primary : commonStyles.colors.white, 0.4)};
    margin-right: 8px;
  }

  & .provider-data-element > .provider-data-text {
    color: ${({ type }) => ['food', 'white'].includes(type) ? commonStyles.colors.primary : commonStyles.colors.white};
  }

  & .provider-data-element > .provider-data-text.time {
    margin-left: 8px;
  }

`;

export const ProviderDataElement = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
`;

export const CurveContainer = styled.div.attrs(() => ({}))`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -60px;
  height: 60px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
  object-fit: fill;
  
  & .top_curve_mob, .top_curve {
    & path {
      fill: ${({ type }) => commonStyles.colors[type]};
    }
  }

  & .top_curve_mob {
    min-width: 600px;
  }

  & .top_curve {
    display: none;
  }

  ${respondTo.sm`
    .top_curve_mob { display: none; }
    & .top_curve {
      min-width: 4200px;
      display: block;
    }
  `}
`;

export const BackButtonWrapper = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  margin-bottom: 12px;
  max-width: 200px;
  & .back-text {
    color: ${({ type }) => type === 'food' ? commonStyles.colors.primary : commonStyles.colors.white}
  }
  
  & .back_button {
    transform: rotate(180deg);
    margin-right: 4px;
  }
  
  &:hover {
    animation: move 0.6s;
  }
  
  @keyframes move {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(8px);
    }
    100% {
      transform: translateX(0);
    }
  }
`;
