import * as turf from '@turf/turf';

const EARTH_RADIUS = 6371;

export const calculateManhattanDistance = (srcLoc, destLoc) => {
  const latDist = Math.abs(turf.degreesToRadians(destLoc[0] - srcLoc[0]));
  const longDist = Math.abs(turf.degreesToRadians(destLoc[1] - srcLoc[1]));

  const latA = Math.pow(Math.sin(latDist / 2), 2);
  const latC = 2 * Math.atan2(Math.sqrt(latA), Math.sqrt(1 - latA));
  const latGeoDist = EARTH_RADIUS * latC;

  const lngA = Math.pow(Math.sin(longDist / 2), 2);
  const lngC = 2 * Math.atan2(Math.sqrt(lngA), Math.sqrt(1 - lngA));
  const lngGeoDist = EARTH_RADIUS * lngC;

  return Math.abs(latGeoDist) + Math.abs(lngGeoDist);
};

export const isPointInPolygonArray = ({ latitude, longitude }, zoneArray) => {
  const point = turf.point([longitude, latitude]);
  return turf.booleanPointInPolygon(point, zoneArray);
};

export const getAddressObject = ({ address_components, formatted_address, geometry: { location: { lat, lng } } }) => {
  const ShouldBeComponent = {
    home: ['street_number'],
    postal_code: ['postal_code'],
    street: ['street_address', 'route'],
    public_place: [
      'establishment',
      'point_of_interest',
      'subway_station',
      'train_station',
      'transit_station',
      'park'
    ],
    region: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5'
    ],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4'
    ],
    country: ['country'],
  };

  const latitude = isFunction(lat) ? lat() : lat;
  const longitude = isFunction(lng) ? lng() : lng;

  const address = {
    public_place: '',
    home: '',
    postal_code: '',
    street: '',
    region: '',
    city: '',
    country: '',
    full_address: formatted_address,
    gpsLoc: [latitude, longitude],
    location: {
      latitude,
      longitude
    }
  };

  address_components.forEach(component => {
    for (let shouldBe in ShouldBeComponent) {
      if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
        if (shouldBe === "country") {
          address[shouldBe] = component.short_name;
        } else {
          address[shouldBe] = component.long_name;
        }
      }
    }
  });
  return address;
};

export const isFunction = (functionToCheck) => {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
};

export const formatAddressString = (addressObj, isFull = true) => {
  const hasObjData = !!addressObj.home && !!addressObj.street;
  const hasFullObjData = !!addressObj.home && !!addressObj.street && !!addressObj.city;
  if (!hasObjData && !isFull) {
    const placeName = addressObj.full_address.split(',')[0];
    return `${placeName}, ${addressObj.city}`;
  }
  if (!hasFullObjData && isFull) {
    return addressObj.full_address;
  }
  if (!isFull) {
    return `${addressObj.home}, ${addressObj.street}`;
  } else {
    return `${addressObj.home}, ${addressObj.street}${
      addressObj.postal_code ? `, ${addressObj.postal_code}` : ''
    }, ${addressObj.city}`;
  }
};


export const getAddressString = (data, isFull = true) => {
  const { formatted_address, address_components } = data;
  const parsedData = getAddressObject({ formatted_address, address_components });
  return formatAddressString(parsedData, isFull);
};
