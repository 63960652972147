import styled, { css } from 'styled-components';
import commonStyles, { respondTo } from '../commonStyles';
import { disabledButtonState } from '../shared-components/Button/Button.style';

export const ArticlesContent = styled.div`
  padding-bottom: 12px;
`;
export const ArticlesTab = styled.div`
  width: 100%;
  flex-grow: 1;
  text-align: center;
  border-bottom: 4px solid ${commonStyles.colors.primary};

  ${({ selected, serviceType }) => selected && serviceType && css`
    border-bottom: 4px solid ${commonStyles.colors[serviceType]};

    & .articles-tab__text {
      color: ${commonStyles.colors[serviceType]};
    }
  `}
  &:hover {
    cursor: pointer;
  }
`;
export const ArticlesTabs = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 12px;
`;


export const ArticlesContainer = styled.div`
`;

export const ItemImage = styled.div`
  width: 100%;
  height: 250px;
  background: ${({ img }) => (img && `url(${img}) center center no-repeat`)};
  background-color: #FFFFFF;
  box-shadow: 1px 1px 10px rgba(0, 29, 35, 0.15);
  border-radius: 5px;
  background-size: ${({ isPlaceholder }) => isPlaceholder ? '40%' : 'contain'};
  margin-top: 34px;
  margin-bottom: 36px;
  ${respondTo.lg`
    margin-bottom: 20px;
  `}
`;

export const ProductInfo = styled.div`
  padding: 30px 0;
  width: 100%;

  & .product-modal__button {
    width: 100%;
    margin: 30px 0;
  }

  & .product-modal__productPrice {
    margin: 20px 0;
  }

  & .product-modal__mwst {
    margin: -15px 0 20px;
    font-size: 14px;
  }

  & .product-modal__artNo {
    margin-top: 0;
    font-size: 14px;
    color: ${commonStyles.colors.grey};
  }

  & .product-modal__pfand {
    margin: 10px 0;
    font-size: 14px;
    color: ${commonStyles.colors.danger};
  }

  & .product-modal_add_to_cart_controls_container {
    display: ${(props) => props.hideCartControlsOnCollapse ? 'none' : 'block'};
    ${respondTo.lg`
      display: block;
    `}
  }
`;

export const QtyContainer = styled.div.attrs(() => ({}))`
  width: 100%;
  display: flex;
  margin: 20px 0;
  height: 50px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid ${props => commonStyles.colors[props.type]};

  & .product-modal__product_qty {
  }
`;

export const QtyBtn = styled.div.attrs(() => ({}))`
  cursor: pointer;
  display: flex;
  height: 100%;
  width: 50px;
  background-color: ${props => commonStyles.colors[props.type]};
  align-items: center;
  justify-content: center;
  transition: all 0.2s;

  &.left {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &.right {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:hover {
    background-color: ${commonStyles.colors.primary};
  }
`;

export const AttributesContainer = styled.div.attrs(() => ({}))`
  padding-top: 0;
  width: 100%;

  ${respondTo.lg`
    padding: 30px 0;
  `}
  & .product-modal_add_to_cart_controls_container {
    display: block;
    ${respondTo.lg`
      display: none;
    `}
  }

  & .items_list-warning-message {
    margin-bottom: 30px;
    box-sizing: border-box;
  }
`;

export const AttributeSectionContainer = styled.div.attrs(() => ({}))`
  padding: 0 8px;

  & > .attribute_container:last-child {
    margin-bottom: 20px;
  }
`;

export const AttributeRow = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const AttributeItemContainer = styled.div.attrs(() => ({}))`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  min-height: 60px;
  border-radius: 5px;
  background: ${commonStyles.colors.white};
  box-shadow: 1px 1px 10px rgba(0, 29, 35, 0.15);
  margin: 20px 0;
  padding: 2px 20px;

  &.attribute_collapse > div > .attribute_name {
    color: ${commonStyles.colors.food};
    font-weight: 700;
  }

  &.active, &.attribute_collapse {
    border: 2px solid ${commonStyles.colors.food};
  }

  &.disabled > div > .attribute_name {
    color: ${commonStyles.colors.grey};
  }

  & > div > .attribute_price {
    margin-left: 20px;
  }
`;

export const RequiredLabel = styled.div.attrs(() => ({}))`
  background-color: ${commonStyles.colors.food};
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  margin-left: 30px;
`;

export const RowElem = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
`;

export const SelectMark = styled.div.attrs(() => ({}))`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${({ disabled }) => disabled ? commonStyles.colors.grey : commonStyles.colors.primary};
  height: 28px;
  width: 28px;
  border-radius: 15px;
  margin-left: 8px;
`;

export const AddToCartControlContainer = styled.div.attrs(() => ({}))`
  & .product-modal__button {
    width: 100%;
    background-color: ${({ type }) => commonStyles.colors[type]};
    margin-bottom: 20px;
    & .button__text {
      color: ${({ type }) => type === 'food' ? commonStyles.colors.primary : commonStyles.colors.white};
    }
    &:hover {
      background-color: ${commonStyles.colors.primary};
      & .button__text {
        color: ${commonStyles.colors.white};
      }
    }
    &:disabled {
      ${disabledButtonState}
    }
  }
`;
