
export const stringToUnicode = string => {
  if (!string) return '';
  let result = '';
  let i = 0
  for (i; i < string.length; i++) {
    result += "\\u" + ("000" + string[i].charCodeAt(0).toString(16)).substr(-4);
  }
  return result;
};

export const findCategory = (categories, slug) => {
  let cat;
  categories.forEach(c => {
    const isMainEqual = c.slug === slug;
    if (isMainEqual) {
      cat = c;
      return;
    }
    if (c.subCategories?.length) {
      const found = findCategory(c.subCategories, slug);
      if (found) {
        cat = found;
        return;
      }
    }
  })
  return cat;
}

export const hasCategory = (categories, slug) => {
  return categories.some(c => {
    const isMainEqual = c.slug === slug;
    if (isMainEqual) {
      return true;
    }
    if (c.subCategories?.length) {
      const found = hasCategory(c.subCategories, slug);
      if (found) {
        return true
      }
    }
    return false;
  });
}

export const findParentCategory = (categories, category) => {
  try {
    const parentLevel = category.level - 1;
    let cat;
    categories.forEach(c => {
      if (c.level === parentLevel) {
        const hasCat = c.subCategories.some(subCat => subCat.slug === category.slug);
        if (hasCat) {
          cat = c;
          return;
        }
      }
      if (c.level < parentLevel && c.subCategories.length) {
        const found = findParentCategory(c.subCategories, category);
        if (found) {
          cat = found;
          return;
        }
      }
    });
    return cat;
  } catch (e) {
    console.error('Parent not found: ', e);
    return null;
  }
}
