import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../helpers/priceHelpers';
import { useStores } from '../../stores/helpers/use-stores';
import commonStyles from '../commonStyles';
import { IconFont, Text } from '../shared-components';
import { ButtonContainer, IconContainer, QtyLabel } from './CartButton.style';

const CartButton = props => {
  const { t } = useTranslation();
  const { dataStores: { orderStore, servicesStore, sideModalStore } } = useStores();
  const { priceData, itemsQty } = orderStore;
  const { selectedService } = servicesStore;
  const { isCartModalOpened } = sideModalStore;
  const [addClass, setAddclass] = useState('');

  useEffect(() => {
    setAddclass('added');
    setTimeout(() => {
      setAddclass('');
    }, 200);
  }, [itemsQty]);

  const itemsPrice = useMemo(() => {
    return priceData?.orderItemsFare;
  }, [priceData]);

  if (!itemsQty) {
    return null;
  }

  return (
    <ButtonContainer
      type={selectedService}
      className={`${isCartModalOpened ? 'cart_opened' : ''} ${addClass}`}
      onClick={sideModalStore.showCartModal}
    >
      <IconContainer>
        <IconFont icon={'wallet'} color={commonStyles.colors.white} size={12}/>
        <QtyLabel>
          {itemsQty}
        </QtyLabel>
      </IconContainer>
      <Text type={'h3'} weight={'400'} className={'cart_name'}>{t('cart.cart')}</Text>
      {!!itemsPrice &&
        <Text
          type={'h3'}
          weight={'700'}
          className={'cart_price'}
        >
          {formatPrice(itemsPrice)}
        </Text>
      }
    </ButtonContainer>
  );
};

export default observer(CartButton);
