import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStores } from '../../../stores/helpers/use-stores';
import { InfoBox } from '../../shared-components';
import TransactionsModal from './TransactionsModal';
import { Wrapper } from './WalletInfo.syle';
import WalletMoneyData from './WalletMoneyData';
import WalletPointsData from './WalletPointsData';

const initialTransactionModalState = {
  visible: false,
  type: null,
  transactions: [],
};

const WalletInfo = () => {
  const { dataStores: { userStore } } = useStores();
  const [transactionsModalProps, setTransactionsModalProps] = useState(initialTransactionModalState);

  const { errorMessage } = userStore;

  useEffect(() => {
    userStore.getUserTransactions();
    // eslint-disable-next-line
  }, []);

  const showTransactionsModal = (type, transactions = []) => {
    setTransactionsModalProps({
      visible: true,
      type,
      transactions
    });
  };

  return (
    <Wrapper>
      {!!errorMessage &&
        <InfoBox
          type={'primary'}
          imgType={'alert'}
          className={'warning-message'}
        >
          {errorMessage}
        </InfoBox>}
      <WalletMoneyData onTransactionsShow={showTransactionsModal} />
      <WalletPointsData onTransactionsShow={showTransactionsModal} />
      <TransactionsModal
        {...transactionsModalProps}
        onHide={() => setTransactionsModalProps(initialTransactionModalState)}
      />
    </Wrapper>
  );
};

export default observer(WalletInfo);
