import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { REGEXP_PHONE } from '../../../constants/regExp';
import { useStores } from '../../../stores/helpers/use-stores';
import { Button, Input, Text } from '../../shared-components';
import { RegisterFormWrapper } from '../AuthModal.style';
import PolicyText from './PolicyText';

const initialSocialForm = {
  phoneNo: '',
  referralCode: '',
};

const SocialRegister = props => {
  const { t } = useTranslation();
  const { dataStores: { authStore } } = useStores();
  const [formData, setFormData] = useState(initialSocialForm);

  const { errorMessage, loading } = authStore;

  const updateFormField = (key, e) => {
    setFormData({ ...formData, [key]: e.target.value });
  }

  const isBtnDisabled = () => {
    return !REGEXP_PHONE.test(formData.phoneNo) || !formData.phoneNo || loading;
  };

  const handleRegister = () => {
    authStore.socialRegister(formData);
  };

  return (
    <RegisterFormWrapper>
      {!!errorMessage && <Text type={'bodySmall'} className={'error_message'}>{errorMessage}</Text>}
      <Input
        icon={'phone'}
        tooltip={t('tooltips.phoneNo')}
        className={'input'}
        inputProps={{
          type: 'tel',
          value: formData.phoneNo,
          onChange: e => updateFormField('phoneNo', e)
        }}
        placeholder={t('cart.phonePlaceholder')}
        mask="+4\9 999 9999999"
        isInvalid={!REGEXP_PHONE.test(formData.phoneNo) || !formData.phoneNo}
        validationMessage={t('validation.phone')}
      />
      <Input
        className={'text_input input'}
        tooltip={t('auth.referralCode')}
        icon={'gift'}
        inputProps={{
          value: formData.referralCode,
          onChange: e => updateFormField('referralCode', e)
        }}
        placeholder={t('auth.referralCode')}
      />
      <Button
        type={'primary'}
        text={t('auth.register')}
        disabled={isBtnDisabled()}
        className={'main_button'}
        onClick={handleRegister}
      />
      <PolicyText />
    </RegisterFormWrapper>
  );
};

export default observer(SocialRegister);
