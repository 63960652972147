import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';
import { useStores } from '../../stores/helpers/use-stores';
import DifferentProviderModal from '../DifferentProviderModal';
import CategoriesList from './Categories/Categories';
import { BasicPage, InfoBox } from '../shared-components';
import { GridSection, Wrapper } from './ItemsList.style';
import MainCategoriesList from './MainCategoriesList/MainCategoriesList';
import ProductList from './ProductList';
import ProviderList from './ProviderList/ProviderList';
import ProviderProductSearchList from './ProviderProductSearchList';
import Searchbar from './Searchbar';
import TagsList from './Tags/Tags';
import ToolSection from './ToolSection';

const ItemsList = props => {
  const { t } = useTranslation();
  const { pageName } = props;
  const [gridComponent, setGridComponent] = useState(null);
  const [selectedTag, setSelectedTag] = useState();
  const [showTags, setShowTags] = useState(true);
  const params = useParams();
  const query = useQuery();
  const { dataStores: { providersStore, productsStore, servicesStore, deliveryModalStore }, uiStore } = useStores();
  const history = useHistory();
  const { selectedProvider, tagsList } = providersStore;
  const { selectedCategory } = productsStore;
  const { isPlaceInZone } = deliveryModalStore;
  const { deliveryPlace } = servicesStore;
  const { serviceType: serviceSlug, providerName: providerSlug, categoryName: categorySlug } = params;

  const tags = useMemo(() => [t('products.showAll'), ...toJS(tagsList)], [tagsList, t]);
  const searchQuery = useMemo(() => query.get('search'), [query]);

  const pageTitle = useMemo(() => {
    if (categorySlug && !!selectedCategory) {
      return selectedCategory.name;
    }
    if (providerSlug && !!selectedProvider) {
      return selectedProvider.name;
    }
    return serviceSlug ? t(`serviceTypes.${serviceSlug}`) : '';
    // eslint-disable-next-line
  }, [categorySlug, selectedCategory, providerSlug, selectedProvider, serviceSlug]);

  useEffect(() => {
    uiStore.updateUrlParams(params);
    return () => {
      uiStore.updateUrlParams({});
    };
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    if (!!categorySlug) {
      setShowTags(false);
      setGridComponent(<ProductList searchQuery={searchQuery}/>);
      return;
    }
    if (!!providerSlug) {
      setShowTags(false);
      setGridComponent(!!searchQuery ? <ProductList searchQuery={searchQuery}/> : <MainCategoriesList/>);
      return;
    }
    if (!!serviceSlug) {
      setShowTags(true);
      setGridComponent(
        (!!searchQuery && !selectedTag) ?
          <ProviderProductSearchList query={searchQuery}/> :
          <ProviderList selectedTag={selectedTag}/>
      );
      return;
    }
    history.push('/');
    // eslint-disable-next-line
  }, [serviceSlug, providerSlug, categorySlug, selectedTag, searchQuery]);

  return (
    <BasicPage
      pageName={pageName}
      pageTitle={pageTitle}
    >
      <Wrapper>
        {!showTags ?
          <CategoriesList/> :
          <TagsList
            tags={tags}
            selectedService={serviceSlug}
            selectedTag={selectedTag}
            onTagSelect={(tag) => setSelectedTag(tag)}
          />
        }
        <div className={'items_list-grid_container'}>
          {(!deliveryPlace?.location?.latitude || !deliveryPlace?.location?.longitude || !deliveryPlace.address) &&
            <InfoBox
              type={'primary'}
              imgType={'flag'}
              className={'items_list-warning-message'}
              isClickable={true}
              onClick={deliveryModalStore.showDeliveryToolModal}
            >
              {t('products.choosePlace')}
            </InfoBox>}
          {!isPlaceInZone &&
            <InfoBox
              type={'primary'}
              imgType={'flag'}
              className={'items_list-warning-message'}
              isClickable={true}
              onClick={deliveryModalStore.showDeliveryToolModal}
            >
              {t('products.notInDeliveryZone')}
            </InfoBox>}
          <Searchbar place={pageTitle} query={searchQuery}/>
          {(!!categorySlug || (!!providerSlug && !!searchQuery)) && <ToolSection/>}
          <GridSection>
            {gridComponent}
          </GridSection>
        </div>
      </Wrapper>
      <DifferentProviderModal />
    </BasicPage>
  );
};

ItemsList.propTypes = {
  pageName: PropTypes.string,
};

export default observer(ItemsList);
