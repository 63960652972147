import React from 'react';
import { useTranslation } from 'react-i18next';
import commonStyles from '../../commonStyles';
import { IconFont, Text } from '../../shared-components';
import { FakeInputBarContainer } from './fakeInputBar.style';

const FakeInputBar = props => {
  const { t } = useTranslation();
  return (
    <FakeInputBarContainer>
      <div className={'fakeInput-icon_container'}>
        <IconFont icon={'pin'} className={'fakeInput-icon'} color={commonStyles.colors.grey} size={40} />
      </div>
      <Text type={'body'} className={'fakeInput-placeholder'}>{t('main.addressInputPlaceholder')}</Text>
    </FakeInputBarContainer>
  );
};

export default FakeInputBar;
