import { observer } from 'mobx-react-lite';
import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useTranslation } from 'react-i18next';
import { FaFacebook } from 'react-icons/fa';
import { KEYS } from '../../../keys';
import { useStores } from '../../../stores/helpers/use-stores';
import { TooltipWrapper } from '../../shared-components/IconFont/IconFont.style';

const FacebookButton = () => {
  const { t } = useTranslation();
  const { dataStores: { authStore } } = useStores();
  const onFBResponse = (resp) => {
    authStore.setLoaded(resp?.accessToken ? null : 'Ooops, something went wrong!');
    if (resp?.accessToken) {
      authStore.socialLogin({ ...resp, token: resp.accessToken }, 'facebook');
    }
  }

  const handleFail = (e) => {
    authStore.setLoaded();
  }

  return (
    <FacebookLogin
      appId={KEYS.facebookAppId}
      autoLoad={false}
      fields={'email'}
      scope={'public_profile'}
      responseType={'code,token'}
      redirectUri={`${KEYS.DOMAIN_URL}/social-callback/facebook`}
      callback={onFBResponse}
      onClick={authStore.setLoading}
      onFailure={handleFail}
      render={renderProps => (
        <TooltipWrapper tooltip={t('auth.loginWithFB')}>
          <button
            className={'login-facebook-button social_button'}
            onClick={renderProps.onClick}
          >
            <FaFacebook size={26} className={'fb_icon'}/>
          </button>
        </TooltipWrapper>
      )}
    />
  );
};

export default observer(FacebookButton);
