import { KEYS } from '../keys';

export class Analytics {
  static getDataLayer() {
    if (KEYS.IS_DEV) return null;
    const layer = window.dataLayer || [];
    layer.push({ ecommerce: null });
    return layer;
  }

  static viewItemEvent(item, provider, service) {
    const layer = Analytics.getDataLayer();
    if (!layer) return;
    const itemData = {
      item_id: item.artNo ?? item.id,
      item_name: item.name,
      item_brand: provider.name,
      item_category: service,
      item_category2: item.category?.name,
      currency: 'EUR',
      price: item.price,
    };
    // console.log({ view_event: itemData });
    layer.push({
      event: "view_item",
      ecommerce: {
        items: [itemData]
      }
    });
  }

  static addToCartEvent(item, provider, service) {
    const layer = Analytics.getDataLayer();
    if (!layer) return;
    const itemData = {
      item_id: item.artNo ?? item.id,
      item_name: item.name,
      item_brand: provider.name,
      item_category: service,
      item_category2: item.category?.name,
      currency: 'EUR',
      price: item.price,
      quantity: item.qty
    };
    // console.log({ add_to_cart: itemData });
    layer.push({
      event: "add_to_cart",
      ecommerce: {
        items: [itemData]
      }
    });
  }

  static beginCheckoutEvent(orderItems, provider, service) {
    const layer = Analytics.getDataLayer();
    if (!layer) return;
    const items = orderItems.map(item => ({
      item_id: item.artNo ?? item.id,
      item_name: item.name,
      item_brand: provider.name,
      item_category: service,
      item_category2: item.category?.name,
      currency: 'EUR',
      price: item.itemPrice,
      quantity: item.qty
    }));
    // console.log({ checkout_items: items });
    layer.push({
      event: "begin_checkout",
      ecommerce: {
        items
      }
    });
  }

  static purchaseEvent(orderId, orderItems, priceData, provider, service) {
    const layer = Analytics.getDataLayer();
    if (!layer) return;
    const items = orderItems.map(item => ({
      item_id: item.artNo ?? item.id,
      item_name: item.name,
      item_brand: provider.name,
      item_category: service,
      item_category2: item.category?.name,
      currency: 'EUR',
      price: item.itemPrice,
      quantity: item.qty
    }));
    // console.log({ checkout_items: items });
    layer.push({
      event: "purchase",
      ecommerce: {
        transaction_id: orderId,
        value: priceData.orderItemsFare,
        shipping: priceData.deliveryFare,
        currency: "EUR",
        items
      }
    });
  }
}
