import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceTypes from '../../../constants/serviceTypes';
import { useStores } from '../../../stores/helpers/use-stores';
import { Link, Text } from '../../shared-components';
import { CategoriesContainer, CategoryItem, CategoryItemImg } from '../Main.style';
import groceryImage from '../../../assets/img/services/mahlzeit.png';
import dishImage from '../../../assets/img/services/food.png';
import medsImage from '../../../assets/img/services/medikado.png';

export const getServiceImage = serviceSlug => {
  switch (serviceSlug) {
    case ServiceTypes.MAHLZEIT:
      return groceryImage;
    case ServiceTypes.MEDICINE:
      return medsImage;
    default:
      return dishImage;
  }
};

const ServicesList = props => {
  const { t } = useTranslation();
  const { dataStores: { servicesStore } } = useStores();
  const { availableServices } = servicesStore;
  const services = useMemo(() => toJS(availableServices), [availableServices]);

  if (!services?.length) {
    return <CategoriesContainer>
      <Text type="h2">{t('main.noServicesAvailable')}</Text>
    </CategoriesContainer>;
  }

  return (
    <CategoriesContainer>
      {services.map((item, i) => (
        <Link key={`${item.serviceName}-${i}`} className="category-link" href={`/${item.servicePathSlug}/providers${item.providerSlug ? `/${item.providerSlug}` : ''}`}>
          <CategoryItem>
            <CategoryItemImg img={item.img ?? getServiceImage(item.servicePathSlug)} />
            <Text className="category-title" weight={700} type="h4">{item.name ?? t(`serviceTypes.${item.servicePathSlug}`)}</Text>
          </CategoryItem>
        </Link>
      ))}
    </CategoriesContainer>
  );
};

export default observer(ServicesList);
