import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../stores/helpers/use-stores';
import WalletDataItem from './WalletDataItem';

const WalletPointsData = (props) => {
  const { t } = useTranslation();
  const { dataStores: { authStore } } = useStores();

  const { userData: { walletData, loyalty } } = authStore;

  return (
    <WalletDataItem
      title={t('userWallet.points.title')}
      value={t('userWallet.points.value', { points: walletData?.pointsBalance ?? 0 })}
      description={t('userWallet.points.desc', { modifier: loyalty?.multiplier ?? 1, pointsAmount: walletData?.pointsConversionAmt, moneyAmount: walletData?.moneyConversionAmt })}
      type={'points'}
      onTransactionsShow={props.onTransactionsShow}
    />
  );
};

export default observer(WalletPointsData);
