import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  SettingsContainer,
  Column,
  ColumnSection,
  InfoLabel,
} from './Settings.style'
import { BasicPage, Text, Button, Input, Checkbox, Select } from '../shared-components';
import { useTranslation } from "react-i18next";

const paymentMethods = [
  { value: 'card', label: 'Credit card' },
  { value: 'paypal', label: 'PayPal' },
];

const Settings = () => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState();

  return (
    <BasicPage
      type="primary"
      pageTitle={t('settings.title')}
      topSectionContent="test"
    >
      <SettingsContainer>
        <Column>
          <ColumnSection>
            <Text className="settings-title" type="h3" weight="700">{t('settings.personalInfo')}</Text>
            <Input className="settings-input" icon="user" placeholder={t('settings.name')} />
            <Input className="settings-input" icon="email" placeholder="E-Mail" />
            <Input className="settings-input" icon="phone" placeholder={t('settings.phone')} />
            <Input className="settings-input" icon="lock" placeholder={t('settings.password')} />
            <Input className="settings-input" icon="lock" placeholder={t('settings.repeatPassword')} />
          </ColumnSection>
        </Column>
        <Column>
          <ColumnSection>
            <Text className="settings-title" type="h3" weight="700">{t('settings.deliverySettings')}</Text>
            <Input className="settings-input" icon="pin" placeholder={t('settings.address')} />
            <Input className="settings-input" icon="home" placeholder={t('settings.addressDetails')} />
          </ColumnSection>
          <ColumnSection>
            <Text className="settings-title" type="h3" weight="700">{t('settings.paymentOptions')}</Text>
            <Select
              className="settings-input"
              icon="atm"
              placeholder={t('settings.paymentMethod')}
              selectedValue={selectedPayment}
              onValueChange={setSelectedPayment}
              options={paymentMethods}
            />
            <Input className="settings-input" icon="lock" placeholder={t('settings.addPromo')} />
          </ColumnSection>
        </Column>
      </SettingsContainer>
      <SettingsContainer isSingle={true}>
        <Column>
          <ColumnSection>
            <Checkbox className="settings__checkbox" checked={checked} onChange={(value) => setChecked(value)}>
              {t('settings.agreement')}
            </Checkbox>
            <Button type="food" text={t('settings.saveSettings')} />
            <InfoLabel>{t('settings.confirmInfo')}</InfoLabel>
          </ColumnSection>
        </Column>
        <Column />
      </SettingsContainer>
    </BasicPage>
  )
};

export default observer(Settings);
