import { useCallback, useEffect } from 'react';

export const useDebouncedEffect = (effect, delay, deps, cbFunction = () => {}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(effect, deps);

  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
      cbFunction();
    };
    // eslint-disable-next-line
  }, [callback, delay]);
}
