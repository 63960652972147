import styled from 'styled-components';
import { respondTo } from '../commonStyles';

export const WarningContent = styled.div`
  text-align: left;
  & > p {
    &:first-child {
      margin-bottom: 16px; 
      ${respondTo.md`
        margin-bottom: 18px;
      `}
    }
  }
`;

export const SectionContent = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: ${({direction}) => direction}; 
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`;