import styled from 'styled-components';
import commonStyles, { respondTo } from '../../commonStyles';

export const OrderInfoContainer = styled.div.attrs(() => ({}))`
  width: 100%;
  ${respondTo.lg`
    width: 450px;
  `}
`;

export const PaymentInfoSectionContainer = styled.div.attrs(() => ({}))`
  & > .cart_modal-price-info-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    ${respondTo.md`
      align-items: flex-start;
    `}
  }

  & > .checkbox-row {
    margin-top: 30px;
  }

  & .cart_modal-checkbox_question {
    padding-right: 16px;
    width: 90%;
  }

  & > .checkbox-row > .checkbox {
    height: 24px !important;
    width: 24px !important;
    border-radius: 3px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;
    border: 2px solid ${commonStyles.colors.primary};
    cursor: pointer;
    ${respondTo.md`
      margin-top: 5px;
    `}
  }

  & > .checkbox-row > .checkbox.age {
    background-color: ${({ ageChecked }) => ageChecked ? commonStyles.colors.primary : 'transparent'};
  }

  & > .checkbox-row > .checkbox.pfand {
    background-color: ${({ agreedOnPfand }) => agreedOnPfand ? commonStyles.colors.primary : 'transparent'};
  }

  & > .checkbox-row > .checkbox.confirm {
    background-color: ${({ dataChecked }) => dataChecked ? commonStyles.colors.primary : 'transparent'};
  }

`;

export const PaymentButtonsContainer = styled.div.attrs(() => ({}))`
  margin-top: 40px;
`;

export const DeliveryInfoSectionContainer = styled.div.attrs(() => ({}))`
  padding: 30px 0 0;
  margin-bottom: 30px;
  & > .cart-modal_deliveryInfoHeader {
    margin-bottom: 30px;
  }

  & > .cart-modal_textInput {
    margin: 15px 0;
  }
`;

export const DeliveryInputsContainer = styled.div.attrs(() => ({}))`
  margin: 15px 0;
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > .selector:first-child {
    margin-right: 8px;
  }
`;

export const ModalBodyWrapper = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex: 10;
`;

export const ImageContainer = styled.div.attrs(() => ({}))`
  position: relative;
  margin-bottom: 40px;
  flex: 10;
  ${respondTo.md`
    margin-bottom: 0;
    margin-right: 30px;
    flex: 3;
  `}
`;

export const ModalBody = styled.div.attrs(() => ({}))`
  flex: 10;
  ${respondTo.md`
    margin-bottom: 0;
    margin-right: 30px;
    flex: 6;
  `}
  & > p {
    margin-bottom: 20px;
  }
  & .orderModal_successBodyText > span {
    font-weight: 700;
  }
  & .orderModal_successBodyFollow > .fb {
    font-weight: 700;
    color: ${commonStyles.colors.facebook};
  }
  & .orderModal_successBodyFollow > .inst {
    font-weight: 700;
    color: ${commonStyles.colors.instagram}
  }
`;


export const PromosWrapper = styled.div.attrs(() => ({}))`
  padding: 30px 0 0;
  margin-bottom: 30px;
  & > .promos_header {
    margin-bottom: 30px;
  }
  
  & > .checkbox_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    ${respondTo.md`
      align-items: flex-start;
    `}
  }
  
  & > .checkbox_row > .checkbox_question {
    padding-right: 16px;
    width: 90%;
  }

  & > .checkbox_row > .checkbox {
    height: 24px !important;
    width: 24px !important;
    border-radius: 3px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    border: 2px solid ${commonStyles.colors.primary};
    cursor: pointer;
    background-color: ${({ promocodeShown }) => promocodeShown ? commonStyles.colors.primary : 'transparent'};
    ${respondTo.md`
      margin-top: 5px;
    `}
  }
`;

export const PromocodeWrapper = styled.div.attrs(() => ({}))`
  & > .promocode_message {
    font-size: 12px;
    color: ${({ error }) => error ? commonStyles.colors.danger : commonStyles.colors.mahlzeit};
    padding-left: 24px;
  }
`;

export const DiscountSelectorWrapper = styled.div.attrs(() => ({}))`
  
`;
