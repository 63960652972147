import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import discountTypes from '../../../constants/discountTypes';
import { useStores } from '../../../stores/helpers/use-stores';
import { Input, Text } from '../../shared-components';
import { PromocodeWrapper } from './OrderInfo.style';

const PromocodeChecker = () => {
  const { t } = useTranslation();
  const { dataStores: { orderStore } } = useStores();
  const { discountData, promocodeMessage, promocode } = orderStore;

  const promocodeApplied = useMemo(() => discountData?.type === discountTypes.promocode, [discountData]);

  useEffect(() => {
    if (discountData) {
      orderStore.updatePromoCode(discountData?.code);
    }
    // eslint-disable-next-line
  }, [discountData])

  const handlePromocodeCheck = () => {
    if (promocode && !discountData) {
      orderStore.checkPromocode();
    }
    if (discountData?.type === discountTypes.promocode) {
      orderStore.resetPromocode()
    }
  };

  return (
    <PromocodeWrapper error={!discountData && promocodeMessage}>
      <Input
        icon={'gift'}
        tooltip={'Promocode'}
        className={'cart-modal_textInput'}
        inputProps={{
          type: 'text',
          name: 'promocode',
          autocomplete: 'none',
          value: promocode ?? '',
          disabled: discountData?.type === discountTypes.promocode,
          onChange: (e) => orderStore.updatePromoCode(e.target.value)
        }}
        placeholder={'Promocode'}
        hasBtn={true}
        btnName={!promocodeApplied ? t('main.apply') : t('main.cancel')}
        onBtnClick={handlePromocodeCheck}
      />
      {!!promocodeMessage &&
        <Text
          type={'bodySmall'}
          className={'promocode_message'}
        >
          {promocodeMessage}
        </Text>}
    </PromocodeWrapper>
  );
};

export default observer(PromocodeChecker);
