import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../../helpers/priceHelpers';
import { useStores } from '../../../stores/helpers/use-stores';
import { Text } from '../../shared-components';
import { PaymentInfoSectionContainer } from './OrderInfo.style';
import PaymentButtons from './paymentButtons';
import PriceRow from './priceRow';

const PaymentInfoSection = props => {
  const { t } = useTranslation();
  const { dataStores: { orderStore, authStore } } = useStores();
  const { priceData, hasAdultItems, agreedOnPfand, hasPfand } = orderStore;
  const [confirmedAge, setConfirmedAge] = useState(!hasAdultItems);
  const [dataConfirmed, setDataConfirmed] = useState(false);

  if (!priceData) {
    return null;
  }

  return (
    <PaymentInfoSectionContainer ageChecked={confirmedAge} dataChecked={dataConfirmed} agreedOnPfand={agreedOnPfand}>
      <Text type={'h3'} weight={700} className={'cart-modal_itemsHeader'}>
        {t('cart.paymentInfoTitle')}
      </Text>
      {!!priceData.orderItemsFare && <PriceRow name={t('cart.productPrice')} price={priceData.orderItemsFare}/>}
      {!!priceData.deliveryFare && <PriceRow name={t('cart.deliveryFare')} price={priceData.deliveryFare}/>}
      {!!priceData.discountAmt &&
        <PriceRow
          name={`${t('cart.discountAmt')}${priceData.discountPercent ? ` (${priceData.discountPercent}%)` : ''}:`}
          price={priceData.discountAmt}
        />}
      {!!priceData.totalAmt && <PriceRow name={t('cart.totalAmt')} price={priceData.totalAmt} isBold={true}/>}
      {!!hasAdultItems && <div className={'cart_modal-price-info-row checkbox-row'}>
        <Text type={'bodySmall'} className={'cart_modal-checkbox_question'}>{t('cart.ageConfirm')}</Text>
        <div
          className={'checkbox age'}
          onClick={() => setConfirmedAge(!confirmedAge)}
        />
      </div>}
      {!!hasPfand && !!authStore.userData && <div className={'cart_modal-price-info-row checkbox-row'}>
        <Text type={'bodySmall'} className={'cart_modal-checkbox_question'}>{t('cart.pfandConfirm')}</Text>
        <div
          className={'checkbox pfand'}
          onClick={() => orderStore.toggleAgreedOnPfand()}
        />
      </div>}
      <div className={'cart_modal-price-info-row checkbox-row'}>
        <Text
          type={'bodySmall'}
          className={'cart_modal-checkbox_question'}
        >
          {t('cart.confirmText', { price: formatPrice(priceData.totalAmt) })}
        </Text>
        <div
          className={'checkbox confirm'}
          onClick={() => setDataConfirmed(!dataConfirmed)}
        />
      </div>
      <PaymentButtons dataConfirmed={dataConfirmed} ageConfirmed={confirmedAge}/>
    </PaymentInfoSectionContainer>
  );
};

export default observer(PaymentInfoSection);
