import PropTypes from 'prop-types';
import {
  StyledReactModal,
  ModalHeader,
  ModalContent,
  ModalSection,
  ModalContainer,
} from './SideModal.style';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { IconFont } from '../';
import commonStyles from '../../commonStyles';

const SideModal = (props) => {
  const {
          isModalOpened,
          type,
          mainSection,
          secondarySection,
          hasSecondarySection,
          isReversed
        } = props;

  // const { dataStores: { sideModalStore } } = useStores();
  const [openAnimate, setOpenAnimate] = useState(false);

  const handleModalClose = () => {
    props.onHide();
    setOpenAnimate(false);
  }

  return (
    <StyledReactModal
      {...props}
      isOpen={isModalOpened}
      onRequestClose={handleModalClose}
      ariaHideApp={false}
      contentLabel={`${type} Modal`}
      overflow
      onAfterOpen={() => setOpenAnimate(true)}
    >
      <ModalContainer openAnimate={openAnimate} isReversed={isReversed}>
        <ModalHeader>
          <IconFont
            className="modal__close-button"
            icon="cross"
            size="16px"
            color={commonStyles.colors.grey}
            onClick={handleModalClose}
          />
        </ModalHeader>
        <ModalSection>
          <ModalContent>
            {mainSection}
          </ModalContent>
        </ModalSection>
        {hasSecondarySection && <ModalSection>
          <ModalContent>
            {secondarySection}
          </ModalContent>
        </ModalSection>}
      </ModalContainer>
    </StyledReactModal>
  );
};

SideModal.propTypes = {
  isModalOpened: PropTypes.bool,
  isReversed: PropTypes.bool,
  mainSection: PropTypes.element,
  secondarySection: PropTypes.element,
  hasSecondarySection: PropTypes.bool,
  onHide: PropTypes.func,
}

SideModal.defaultProps = {
  isReversed: true,
}

export default observer(SideModal);
