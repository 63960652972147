import { capitalize } from 'lodash';
import { observer } from 'mobx-react-lite';
import React from 'react';
import OrderStatuses from '../../../constants/orderStatuses';
import { useStores } from '../../../stores/helpers/use-stores';
import { Label } from '../../ItemsList/ToolSection/ToolSection.style';
import { Select } from '../../shared-components';
import { StatusSelectRow } from './UserOrders.style';

const statusOptions = Object.values(OrderStatuses).map(s => ({
  label: capitalize(s),
  value: s
}));

const OrderStatusSelectRow = () => {
  const { dataStores: { userStore } } = useStores();
  const { userOrderListStatuses } = userStore;
  return (
    <StatusSelectRow>
      <Label type="bodySmall" weight="700" className={'status_label'}>{'Status:'}</Label>
      <Select
        selectedValue={statusOptions.find(opt => userOrderListStatuses.includes(opt.value))}
        className={'status_select'}
        options={statusOptions}
        outlined
        isClearable
        placeholder={'Status'}
        textAlign={'center'}
        onValueChange={(sel) => userStore.updateUserOrderStatuses(sel?.value ?? null)}
      />
    </StatusSelectRow>
  );
};

export default observer(OrderStatusSelectRow);
