import { Loader } from '@googlemaps/js-api-loader';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { KEYS } from '../../keys';
import { useStores } from '../../stores/helpers/use-stores';

const loader = new Loader({
  apiKey: KEYS.GOOGLE_KEY,
  libraries: ['places', 'visualization'],
  language: 'de'
});

const GoogleApiLoader = () => {
  const { dataStores: { deliveryModalStore } } = useStores();
  const  { isGoogleApiLoaded } = deliveryModalStore;

  useEffect(() => {
    if (isGoogleApiLoaded) return;
    loader
      .load()
      .then(() => {
        deliveryModalStore.setGoogleLibraryLoaded();
      })
      .catch(e => {
        console.error('Failed to Load Google APIs', e.message);
      });
    // eslint-disable-next-line
  }, [isGoogleApiLoaded]);

  return null;
};

export default observer(GoogleApiLoader);
