import { Integrations } from '@sentry/tracing';

export const KEYS = {
  GOOGLE_KEY: 'AIzaSyBwkqAgYIoam33sENJ6c6zi4bqT5Y9JVTk',
  DOMAIN_URL: 'https://order.cabdo.de',
  API_URL: 'https://api.cabdo.de',
  DEFAULT_CITY: 'Dortmund',
  analyticsDataFlow: 'G-0EHT84PY03',
  facebookAppId: '661148130758720',
  googleClientId: '446447446170-f3pfno1fb5apbphbktfgqr48capp2r9e.apps.googleusercontent.com',
  IS_DEV: false,
payPalOptions: {
    'client-id': 'AegCM3asmFriawhrWgRihZJ0DWjhB26wzBpdacqlKk5xEfxoIbDVIo80eCUnO2hIXqYTUV-0q-OmT-Jo',
    currency: 'EUR',
    intent: 'capture',
    locale: 'de_DE'
  },
  sentryOptions: {
    dsn: 'https://5e72796d28a64f37937b11ac0f5a0b10@o563530.ingest.sentry.io/6108398',
    integrations: [new Integrations.BrowserTracing()],
    ignoreErrors: [
      'Error: Detected popup close',
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,
  }
};
