import styled from 'styled-components';
import { respondTo } from '../commonStyles';

export const OrderInfoContent = styled.div`
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  & > p {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  & .order-info__socials {
    & > a {
      text-decoration: none;
      font-weight: 700;
      &:first-child {
        color: #4167b2;
      }
      &:last-child {
        color: #f11c1f;
      }
    }
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100px;
  max-width: 100px;
  margin-bottom: 40px;
  & > svg {
    width: 100%;
    height: auto;
  }
  ${respondTo.md`
    margin-bottom: 0;
    margin-right: 30px;
  `}
  
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > button:first-child {
    margin-bottom: 24px;
   }
  ${respondTo.md`
    flex-direction: row;
    justify-content: center;
     & > button:first-child {
      margin-bottom: 0;
      margin-right: 30px;
     }
  `}
`;

export const ActionModalContainer = styled.div`
  margin: 40px auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${respondTo.md`
    flex-direction: row;
    margin: 60px auto;
  `}
`;
