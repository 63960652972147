import moment from 'moment';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import foodImg from '../../../assets/img/food.png';
import commonStyles from '../../commonStyles';
import {
  CardContainer,
  CardContainerImg,
  CardFooter,
  CardFooterSection,
  Categories
} from './ProviderCard.style';
import { IconFont, Text } from '../../shared-components';

const ProviderCard = props => {
  const { t } = useTranslation();
  const { className, item } = props;
  const {
          name,
          logoUrl: image,
          providerData,
        } = item;

  const willOpenString = useMemo(() => {
    if (!providerData.willOpen) return null;
    const willOpenDate = moment(providerData.willOpen);
    const isDayEqual = moment().day() === willOpenDate.day();
    return `ab ${moment(willOpenDate)
      .locale('de')
      .format(isDayEqual ? 'HH:mm' : 'ddd HH:mm')}`;
  }, [providerData]);

  return (
    <CardContainer img={image || foodImg} className={className} onClick={props.onProviderSelect} isOpened={!providerData.willOpen}>
      <CardContainerImg img={image || foodImg}/>
      <div className={'card-title-container'}>
        <Text className="card-text card-title" type="h3" weight="700">{name}</Text>
        {!!providerData?.willOpen && <Text className="card-text card-will-open-text" type="bodySmall" weight="700">{willOpenString}</Text>}
      </div>
      <Categories>
        {providerData.tags?.slice(0, 3).map((category) => (
          <Text key={`${name}-${category}`} className="card-text" type="bodySmall">{category}</Text>
        ))}
      </Categories>
      <CardFooter>
        <CardFooterSection>
          <IconFont size="20px" icon="wallet" color={commonStyles.colors.grey} tooltip={t('tooltips.minOrder')}/>
          <Text className="card-text" type="bodySmall" weight="700">{providerData.minimumOrder}€</Text>
        </CardFooterSection>
        <CardFooterSection>
          <IconFont size="20px" icon="time" color={commonStyles.colors.grey} tooltip={t('tooltips.deliveryFare')}/>
          <Text className="card-text" type="bodySmall" weight="700">{providerData.deliveryFare}€</Text>
        </CardFooterSection>
      </CardFooter>
    </CardContainer>
  );
};

ProviderCard.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object,
  path: PropTypes.string,
  onProviderSelect: PropTypes.func,
};

export default ProviderCard;
