import styled from 'styled-components';
import { respondTo } from '../commonStyles';

export const Wrapper = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: column;
  & .items_list-grid_container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  & .items_list-warning-message {
    margin-bottom: 30px;
    width: 100%;
  }
  ${respondTo.md`
    flex-direction: row;
    & .items_list-grid_container {
      padding: 0 5px;
    }
  `}
`;

export const GridSection = styled.div.attrs(() => ({}))`
  flex-grow: 1;
  width: 100%;
  & .cards__section {
    margin: 0 0 50px 0;
  }
  ${respondTo.md`
    margin-top: 0;
  `}
`;
