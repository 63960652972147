import styled, { css } from 'styled-components';
import commonStyles, { respondTo } from '../commonStyles';

export const Wrapper = styled.div`
  width: 100%;
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12px;
  width: 100%;
  margin-bottom: 100px;
   & > h2 {
     text-align: center;
   }
`;

export const SearchField = styled.div`
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 568px;

  & .use_current-btn {
    display: none;
  }

  ${respondTo.md`
   margin-top: 30px;
   max-width: 790px;
 `}
`;

export const InputContainer = styled.div`
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  ${respondTo.sm`
    margin-top: 40px;
    margin-bottom: 24px;
  `}
`;

export const Section = styled.div`
  position: relative;
  ${respondTo.md`
    ${({ isTop }) => isTop && css`
      margin-bottom: 260px;
    `}
  `}
`;

export const CategoryItemImg = styled.div`
  background: ${({ img }) => (img && `url(${img}) no-repeat`)};
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
`;

export const CategoryItem = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 10px;
  box-shadow: 0 36px 47px -18px rgba(0, 29, 35, 0.35);
  border-radius: 5px;
  margin: 0 8px 16px;
  overflow: hidden;
  width: 164px;
  height: 100px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: radial-gradient(116.01% 50% at 51.44% 100%, #001D23 0%, rgba(0, 29, 35, 0) 100%);
    z-index: 1;
  }

  & .category-title {
    z-index: 2;
    color: ${commonStyles.colors.white};
  }

  ${respondTo.md`
    width: 278px;
    height: 160px;
    margin: 0 15px 30px;
    &:hover {
      & ${CategoryItemImg} {
        transform: scale(1.05,1.05);
      }
    }
  `}
`;
export const CategoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  padding-bottom: 44px;
  justify-content: center;

  ${respondTo.sm`
    margin: 0 auto;
    width: 100%;
    max-width: 540px;
  `}
  ${respondTo.md`
    max-width: 930px;
    padding-bottom: 60px;
  `}
`;

export const PressableContainer = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  & > h1 {
    text-align: center;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;
