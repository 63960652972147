import React from 'react';
import PropTypes from 'prop-types';
import { DropdownContainer, Suggestion } from '../shared-components/PlacesInput/PlacesInput.styes';

const PlacesDropdown = props => {
  const { visible, options, width } = props;

  if (!visible) return null;

  return (
    <DropdownContainer
      className="places__dropdown"
      show={visible}
      inputWidth={width - 80}
    >
      {options.map((option, idx) => {
        return (
          <Suggestion
            key={`${option.label}-${idx}`}
            onClick={() => props.onSelect(option.value)}
          >
            <span>
              {option.label}
            </span>
          </Suggestion>
        );
      })}
    </DropdownContainer>
  );
};

PlacesDropdown.propTypes = {
  visible: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.object,
  })),
  onSelect: PropTypes.func,
  width: PropTypes.number,
};

export default PlacesDropdown;
