import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';
import { useStores } from '../../stores/helpers/use-stores';

const SocialCallback = props => {
  const { type } = useParams();
  const query = useQuery();
  const location = useLocation();
  const history = useHistory();
  const { dataStores: { authStore } } = useStores();

  const getTokenFromHash = (tokenName) => {
    const hashParams = location.hash;
    const hasToken = hashParams.indexOf(tokenName) >= 0;
    if (!hasToken) {
      return null
    }
    const keyValueString = hashParams.split('&');
    let token;
    keyValueString.forEach(kvs => {
      const [key, value] = kvs.split('=');
      if (key === tokenName) {
        token = value;
      }
    });
    return token;
  }

  useEffect(() => {
    if (type === 'facebook') {
      const token = getTokenFromHash('#access_token');
      if (!token) {
        history.goBack();
      }
      authStore.socialLogin({ token }, 'facebook', history);
    } else if (type === 'google') {
      const token = getTokenFromHash('id_token');
      if (!token) {
        history.push('/');
      }
      authStore.socialLogin({ token }, 'google', history);
    } else if (type === 'apple') {
      console.log(location);
    }
    // eslint-disable-next-line
  }, [type, query, history, location]);

  return null;
};

export default observer(SocialCallback);
