import styled from 'styled-components';
import commonStyles, { respondTo } from '../../commonStyles';


export const CardContainerImg = styled.div`
  background: ${({img}) => (img && `url(${img}) no-repeat`)};
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
`;


export const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 343px;
  height: 140px;
  box-shadow: 0 36px 47px -18px rgba(0, 29, 35, 0.35);
  background: ${({img}) => (img && `url(${img}) no-repeat`)};
  background-size: cover;
  border-radius: 5px;
  margin: 0 12px 24px;
  padding: 16px;
  box-sizing: border-box;
  overflow: hidden;
  & .card-text {
    color: ${commonStyles.colors.white};
    z-index: 1;
  }
  & .card-title {
    display:  -webkit-box;
    text-align: center;
    color: ${commonStyles.colors.white};
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${commonStyles.colors.primary}66;
    z-index: 1;
  }
  ${respondTo.md`
    padding: 0 30px;
    margin: 0 15px 30px;
    width: 300px;
    height: 190px;
    &:hover {
      & ${CardContainerImg} {
        transform: scale(1.05,1.05);
      }
    }
  `}
`;
