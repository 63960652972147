import React from 'react';
import PropTypes from 'prop-types';
import commonStyles from '../commonStyles';
import { IconFont, Text } from '../shared-components';
import { QtyBtn, QtyContainer } from './ProductModal.style';

const QtyTool = props => {
  const { qty, unit, serviceType } = props;

  return (
    <QtyContainer type={serviceType}>
      <QtyBtn
        type={serviceType}
        className={'left'}
        onClick={() => props.onChange(true)}
      >
        <IconFont icon={'minus'} color={commonStyles.colors.white} size={18} />
      </QtyBtn>
      <Text type="body" weight={700} className={'product-modal__product_qty'}>
        {`${qty} ${unit ?? ''}`}
      </Text>
      <QtyBtn
        type={serviceType}
        className={'right'}
        onClick={() => props.onChange()}
      >
        <IconFont icon={'plus'} color={commonStyles.colors.white} size={32} />
      </QtyBtn>
    </QtyContainer>
  );
};

QtyTool.propTypes = {
  qty: PropTypes.number,
  unit: PropTypes.string,
  serviceType: PropTypes.string,
  onChange: PropTypes.func,
};

export default QtyTool;
