import { toJS } from 'mobx';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import GoogleMap from 'google-map-react';
import { useTranslation } from 'react-i18next';
import flag from '../../assets/img/info_icons/location_flag.png';
import { formatAddressString, getAddressObject } from '../../helpers/addressHelpers';
import { useDebouncedEffect } from '../../hooks/useDebouncedCallback';
import { KEYS } from '../../keys';
import commonStyles from '../commonStyles';
import { Button, IconFont } from '../shared-components';
import Text from '../shared-components/Text';
import { FlagImage, MapContainer, GeolocationButton, ApplyPlaceButtonContainer } from './DeliveryToolModal.style';

const mapBreakPoint = 640;

const MapPickTool = props => {
  const { t } = useTranslation();
  const { width, initialLat, initialLng, windowWidth, deliveryZone } = props;
  const [mapInstance, setMapInstance] = useState(null);
  const [mapsInstance, setMapsInstance] = useState(null);
  const [mapToolHeight, setMapToolHeight] = useState(400);
  const [currentLatLng, setCurrentLatLng] = useState([initialLat, initialLng]);
  const timerRef = useRef();

  useDebouncedEffect(() => {
    if (windowWidth > mapBreakPoint && mapToolHeight !== 400) {
      setMapToolHeight(400);
    } else if (windowWidth <= mapBreakPoint && mapToolHeight === 400) {
      setMapToolHeight(500);
    }
  }, 200, [windowWidth]);

  const debouncedSetLatLng = (latLng) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      setCurrentLatLng(latLng);
    }, 400);
  }

  const handleApiLoaded = ({ map, maps }) => {
    setMapsInstance(maps);
    setMapInstance(map);
    map.addListener('center_changed', () => {
      const position = map.getCenter();
      debouncedSetLatLng([position.lat(), position.lng()]);
    });
  };

  useEffect(() => {
    drawZone();
    // eslint-disable-next-line
  }, [mapsInstance, mapInstance, deliveryZone])

  const drawZone = () => {
    if (!mapsInstance || !mapInstance || !deliveryZone) return;
    const coords = toJS(deliveryZone).coordinates[0].map(coord => ({ lat: coord[1], lng: coord[0] }));
    const polygon = new mapsInstance.Polygon({
      paths: coords,
      strokeColor: commonStyles.colors.food,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: commonStyles.colors.food,
      fillOpacity: 0.1
    });
    polygon.setMap(mapInstance);
  }

  const applyPlaceByLatLng = (latLng = currentLatLng) => {
    if (!mapsInstance) return;
    const geocoder = new mapsInstance.Geocoder();
    if (!geocoder) return;
    const location = { lat: latLng[0], lng: latLng[1] };
    geocoder.geocode({ location })
      .then(resp => {
        const addressObject = getAddressObject(resp.results[0]);
        const addressString = formatAddressString(addressObject, false);
        const place = {
          address: addressString,
          location: addressObject.location,
          fullAddress: addressObject.full_address,
          addressData: addressObject
        };
        props.onLocationConfirm(place);
      })
      .catch(e => {
        console.error({ e });
      });
  }

  const handleBrowserLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log({ position });
        const { latitude, longitude } = position.coords;
        applyPlaceByLatLng([latitude, longitude])
      },
      (positionError) => {
        alert(JSON.stringify(positionError.message));
      },
      { enableHighAccuracy: true, maximumAge: 10000 }
    );
  }

  return (
    <MapContainer
      height={mapToolHeight}
      width={width}
    >
      <GoogleMap
        bootstrapURLKeys={{ key: KEYS.GOOGLE_KEY, libraries: ['places', 'visualization'], language: 'de' }}
        defaultCenter={{
          lat: initialLat,
          lng: initialLng,
        }}
        yesIWantToUseGoogleMapApiInternals
        defaultZoom={16}
        options={{
          gestureHandling: 'greedy',
          zoomControlOptions: { position: 7 },
          streetViewControl: false,
          fullscreenControl: false,
        }}
        onGoogleApiLoaded={handleApiLoaded}
      >
      </GoogleMap>
      <FlagImage src={flag} alt={'Location Marker'}/>
      {!!navigator.geolocation && <GeolocationButton onClick={handleBrowserLocation}>
        <IconFont icon={'pin'} size="2.4rem" color={commonStyles.colors.primary}/>
        <Text type="h4" weight="700" align="center">{t('deliveryModal.pressToLocate')}</Text>
      </GeolocationButton>}
      <ApplyPlaceButtonContainer>
        <Button
          type="food"
          text={t('deliveryModal.pickLocation')}
          onClick={() => applyPlaceByLatLng()}
        />
      </ApplyPlaceButtonContainer>
    </MapContainer>
  );
};

MapPickTool.propTypes = {
  windowWidth: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  initialLat: PropTypes.number,
  initialLng: PropTypes.number,
  onLocationConfirm: PropTypes.func,
  deliveryZone: PropTypes.object,
};

export default MapPickTool;
