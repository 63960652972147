import React from 'react';
import { Trans } from 'react-i18next';
import { Text } from '../../shared-components';
import { PoliciesTextContainer } from '../AuthModal.style';

const PolicyText = () => {
  return (
    <PoliciesTextContainer>
      <Text className={'policy_text'} type={'bodySmall'}>
        <Trans i18nKey={'auth.registrationAgreement'}>
          By registering, you agree to our &nbsp;
          <a href={'https://www.cabdo.de/datenschutzbestimmung'} className={'policy_link'} target={'_blank'} rel={'noreferrer'}>Terms of service</a>
          &nbsp;and&nbsp;
          <a href={'https://www.cabdo.de/agb'} className={'policy_link'} target={'_blank'} rel={'noreferrer'}>Privacy Policy</a>
        </Trans>
      </Text>
    </PoliciesTextContainer>
  );
};

export default PolicyText;
