import styled from 'styled-components';
import commonStyles, { respondTo } from '../../commonStyles';

export const Wrapper = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: column;
  margin: 0 24px;
  ${respondTo.md`
    width: 100%;
  `}
`;

export const WalletDataWrapper = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
`;

export const WalletMainRow = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
`;

export const WalletItemTitleWrapper = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  & > .transactions_button {
    margin-left: 16px;
    height: 40px;
    width: 40px;
    & > .transactions_icon {
    }
  }
`;

export const TransactionsWrapper = styled.div.attrs(() => ({}))`
  & > .transactions_title {
    padding: 32px 0;
  }
`;

export const TransactionsItemWrapper = styled.div.attrs(() => ({}))`
  padding: 8px;
  width: 100%;
  &:nth-child(2n) {
    background-color: ${commonStyles.colors.alphaString(commonStyles.colors.grey, 0.4)};
  }
`;

export const TransactionsHeaderRow = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  width: 100%;
`;

export const AmountWrapper = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  & > .transaction_amount {
    margin-left: 8px;
    font-weight: 700;
  }
`;
