import RootStore from '../rootStore';
import { wrapRoot } from 'mobx-easy';

export const createStore = () => {
  const env = {
    isDev: process.env.NODE_ENV === 'development'
  };

  return wrapRoot({
    RootStore: RootStore,
    env
  });
};
