import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useStores } from '../../../stores/helpers/use-stores';
import HorizontalSlider from './HorizontalSlider';
import SideCategories from './SideCategories';

const CategoriesList = props => {
  const { dataStores: { productsStore } } = useStores();
  const { categoryName: currentCategorySlug, providerName: providerSlug } = useParams();
  const { categoriesList } = productsStore;
  const categories = useMemo(() => toJS(categoriesList), [categoriesList]);

  useEffect(() => {
    productsStore.setMainCategoriesList();
    return () => {
      productsStore.setMainCategoriesList();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentCategorySlug && categories) {
      productsStore.setCategory(categories.find((item) => item.slug === currentCategorySlug));
    } else {
      productsStore.setCategory();
    }
    // eslint-disable-next-line
  }, [categories, currentCategorySlug]);

  useEffect(() => {
    if (providerSlug) {
      productsStore.getMainCategoriesList(providerSlug);
    } else {
      productsStore.setMainCategoriesList();
    }
    // eslint-disable-next-line
  }, [providerSlug]);

  return (
    <>
      <HorizontalSlider categories={categoriesList} currentCategorySlug={currentCategorySlug} />
      <SideCategories categories={categoriesList} currentCategorySlug={currentCategorySlug} />
    </>
  );
};

export default observer(CategoriesList);
