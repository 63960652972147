import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import Cookies from 'js-cookie';
import { isHydrated, makePersistable } from 'mobx-persist-store';
import AuthModalState from '../../../constants/authModalStates';
import i18n from '../../../i18n';
import {
  changeUserPass,
  checkReferralCode,
  login,
  logout,
  registerUser,
  resendVerificationCode,
  resetUserPassword, setupUser,
  socialLogin,
  verifyMobileCode
} from '../../../utils/api/auth';
import { getAvailableStorage } from '../../storage';

export default class AuthStore {
  @observable isLoggedIn = false;
  @observable loading = false;
  @observable errorMessage = null;
  @observable userToken = null;
  @observable userData = null;
  @observable preUserData = null;
  @observable resetEmail = null;

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    makePersistable(this, {
      name: 'AuthData',
      properties: [
        'isLoggedIn',
        'userToken',
        'userData'
      ],
      storage: getAvailableStorage(),
    }, {
      delay: 200,
    });
  }

  getUserToken = () => {
    return this.userToken || Cookies.get('cabdo-user-token');
  }

  @action setLoading = () => {
    this.errorMessage = null;
    this.loading = true;
  }

  @action setLoaded = (errorMessage = null) => {
    this.errorMessage = errorMessage;
    this.loading = false;
  }

  @action setUserData = (data) => {
    this.setToken(data.jwtAccessToken);
    this.userData = data.user;
    this.preUserData = null;
  }

  @action updateUserData = (userData) => {
    this.userData = userData;
  }

  @action setToken = (token) => {
    this.isLoggedIn = true;
    this.userToken = token;
    Cookies.set('cabdo-user-token', token, { secure: true, expires: 30 });
  }

  @action resetUserData = () => {
    this.isLoggedIn = null;
    this.userToken = null;
    this.userData = null;
    Cookies.remove('cabdo-user-token', { secure: true });
  }

  @action register = async (formData) => {
    try {
      this.setLoading();
      //  check referral if exist
      if (formData.referralCode) {
        const referralResp = await checkReferralCode(formData.referralCode);
        if (!referralResp?.data?.isValid) {
          this.setLoaded(i18n.t('validation.invalidReferralCode'));
          return;
        } else {
          formData.referralCode = referralResp.data.code;
        }
      }
      //  register user
      formData.userType = 'rider';
      const resp = await registerUser(formData);
      if (resp.success) {
        this.rootStore.dataStores.authModalStore.changeModalState(AuthModalState.mobileConfirm);
        this.setLoaded();
        this.setUserData(resp.data);
      } else {
        this.setLoaded(resp.message);
      }
    } catch (e) {
      this.setLoaded(e.message);
    }
  }

  @action socialRegister = async (formData) => {
    return this.register({ ...this.preUserData, ...formData });
  }

  @action verifyCode = async (code) => {
    try {
      if (!this.userToken) return;
      this.setLoading();
      const resp = await verifyMobileCode(this.userToken, parseFloat(code));
      if (resp.success) {
        this.setLoaded();
        this.rootStore.dataStores.authModalStore.hideModal();
        this.setupUser();
      } else {
        this.setLoaded(resp.message);
      }
    } catch (e) {
      this.setLoaded(e.message);
    }
  }

  @action resendCode = () => {
    if (!this.userToken) return;
    resendVerificationCode(this.userToken);
  }

  @action login = async (credentials) => {
    try {
      this.setLoading();
      const resp = await login(credentials);
      if (resp.success) {
        if (resp.data.user.userType === 'rider') {
          this.setUserData(resp.data);
          this.setLoaded();
          this.rootStore.dataStores.authModalStore.hideModal();
        } else {
          this.setLoaded('Anmeldung nur für Privatkunden möglich!');
        }
      } else {
        console.error(resp.message);
        this.setLoaded('Upsss. Da ist etwas schiefgegangen!');
      }
    } catch (e) {
      this.setLoaded(e.message);
    }
  }

  @action socialLogin = async (socialPayload, type, history = null) => {
    try {
      this.setLoading();
      const resp = await socialLogin(type, socialPayload);
      if (resp.success && resp.data.user) {
        if (resp.data.user.userType === 'rider') {
          this.setUserData(resp.data);
          this.setLoaded();
          this.rootStore.dataStores.authModalStore.hideModal();
          if (history) {
            history.replace('/');
          }
        } else {
          this.setLoaded('Anmeldung nur für Privatkunden möglich!');
        }
      } else if (resp.success && resp.data.preUser) {
        runInAction(() => {
          this.preUserData = resp.data.preUser;
        })
        if (history) {
          history.replace('/');
          this.rootStore.dataStores.authModalStore.showAuthModal(AuthModalState.socialRegister);
        } else {
          this.rootStore.dataStores.authModalStore.changeModalState(AuthModalState.socialRegister);
        }
        this.setLoaded();
      } else {
        this.setLoaded(resp.message);
      }
    } catch (e) {
      this.setLoaded(e.message);
    }
  }

  @action setupUser = async () => {
    try {
      const token = Cookies.get('cabdo-user-token');
      if (!token) {
        return;
      }
      const resp = await setupUser(token);
      if (resp.success) {
        this.setUserData(resp.data);
      } else {
        this.signOut();
      }
    } catch (e) {
      console.error('Failed to get user data on setup')
    }
  }

  @action signOut = async (token = null) => {
    try {
      this.setLoading();
      const tkn = token ?? this.userToken;
      if (!tkn) {
        logout(tkn);
      }
      this.resetUserData();
      this.rootStore.dataStores.userStore.resetStore();
      this.setLoaded();
    } catch (e) {
      this.resetUserData();
      this.setLoaded();
    }
  }

  @action resetPassword = async (resetEmail) => {
    try {
      this.setLoading();
      runInAction(() => {
        this.resetEmail = resetEmail;
      });
      const resp = await resetUserPassword(resetEmail);
      if (resp.success) {
        this.setLoaded();
        this.rootStore.dataStores.authModalStore.changeModalState(AuthModalState.passResetConfirm);
      } else {
        this.setLoaded(resp.message);
      }
    } catch (e) {
      this.setLoaded(e.message);
    }
  }

  @action changePassword = async (form) => {
    try {
      const token = this.getUserToken();
      if (!token) return;
      this.setLoading();
      const resp = await changeUserPass(token, form);
      if (resp.success) {
        this.setLoaded();
        this.rootStore.dataStores.authModalStore.hideModal();
      } else {
        this.setLoaded(i18n.t('auth.changePasswordFailed'));
      }
    } catch (e) {
      this.setLoaded(e.message);
    }
  }

  get isHydrated() {
    return isHydrated(this);
  }
}
