import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';
import { FiLogIn } from 'react-icons/all';
import { Link as RouterLink } from "react-router-dom";
import PropTypes from 'prop-types';
import LangButton from '../LangButton';
import { HeaderWrapper, HeaderInner, HeaderContainer, HeaderSection } from './Header.style';
import { ReduceContainer, Button } from '../shared-components';
import Logo from '../../assets/img/logo_black.png';
import LogoLight from '../../assets/img/logo_white.png';
import { ReactComponent as LogoSmall } from '../../assets/svg/logoSmall.svg';
import { ReactComponent as LogoSmallLight } from '../../assets/svg/logoSmall_light.svg';
import { useStores } from '../../stores/helpers/use-stores';
import UserDetailsButton from './UserDetailsButton';

const Header = (props) => {
  const { type, className, isMain } = props;
  const { t } = useTranslation();
  const {
          dataStores: { deliveryModalStore, servicesStore, authStore, authModalStore },
          uiStore: { windowDimensions }
        } = useStores();
  const { deliveryPlace } = servicesStore;
  const { isLoggedIn } = authStore;
  const [deliveryPlaceData, setDeliveryPlaceData] = useState(null);
  const [loginBtnContent, setLoginBtnContent] = useState(t('auth.loginButton'));

  useEffect(() => {
    if (deliveryPlace) {
      setDeliveryPlaceData(toJS(deliveryPlace))
    }
  }, [deliveryPlace]);

  useEffect(() => {
    if (windowDimensions.width < 600 && loginBtnContent === t('auth.loginButton')) {
      setLoginBtnContent(<FiLogIn size={22} style={{ paddingTop: 8 }}/>);
    } else if (windowDimensions.width >= 600 && loginBtnContent !== t('auth.loginButton')) {
      setLoginBtnContent(t('auth.loginButton'));
    }
    // eslint-disable-next-line
  }, [windowDimensions, t]);

  const getLogo = () => {
    if (isMain) {
      return <img src={Logo} alt={'CABDO Logo'} className="headerLogo"/>
    }
    if (type === 'white' || type === 'food') {
      return (
        <>
          <LogoSmall className="headerLogo_mob"/>
          <img src={Logo} alt={'CABDO Logo'} className="headerLogo"/>
        </>
      )
    }
    return (
      <>
        <LogoSmallLight className="headerLogo_mob"/>
        <img src={LogoLight} alt={'CABDO Logo'} className="headerLogo"/>
      </>
    )
  };

  return (
    <HeaderWrapper className={className} type={type} isMain={isMain}>
      <HeaderInner>
        <ReduceContainer>
          <HeaderContainer>
            <RouterLink className="header__logo--link" to="/">
              {getLogo()}
            </RouterLink>
            <HeaderSection label={deliveryPlaceData?.address ? t('header.addressLabel') : null} type={type}>
              {!isMain && (
                <Button
                  secondary
                  multiline
                  className="header__address-button"
                  type="primary"
                  text={deliveryPlaceData?.address || t('header.addressPlaceholder')}
                  onClick={deliveryModalStore.showDeliveryToolModal}
                />
              )}
              <LangButton type={type}/>
              {!isLoggedIn && <Button
                text={loginBtnContent}
                type={type === 'primary' ? 'ghost' : 'primary'}
                className={'login_button'}
                onClick={() => authModalStore.showAuthModal()}
              />}
              {isLoggedIn && <UserDetailsButton type={type}/>}

            </HeaderSection>
          </HeaderContainer>
        </ReduceContainer>
      </HeaderInner>
    </HeaderWrapper>
  )
};

Header.propTypes = {
  isMain: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.oneOf([
    'white',
    'primary',
    'food',
    'mahlzeit',
  ]).isRequired,
};

Header.defaultProps = {
  isMain: false,
  className: undefined,
};

export default observer(Header);
