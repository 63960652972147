import React from 'react';
import { Wrapper } from './Referrals.style';
import ReferralsEarnings from './ReferralsEarnings';
import ReferralsGeneralInfo from './ReferralsGeneralInfo';

const Referrals = () => {
  return (
    <Wrapper>
      <ReferralsGeneralInfo />
      <ReferralsEarnings />
    </Wrapper>
  );
};

export default Referrals;
