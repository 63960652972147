import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useStores } from '../../../stores/helpers/use-stores';
import commonStyles from '../../commonStyles';
import { Button, Input, Text } from '../../shared-components';
import { EyeIconContainer, PassWrapper, RegisterFormWrapper } from '../AuthModal.style';

const initialState = {
  oldPass: '',
  newPass: '',
  confirmPass: ''
};

const ChangePassword = () => {
  const { t } = useTranslation();
  const [form, setForm] = useState(initialState);
  const [passInputType, setPassInputType] = useState('password');
  const inputRef = useRef();
  const { dataStores: { authStore } } = useStores();
  const { errorMessage, loading } = authStore;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const togglePassVisible = () => {
    setPassInputType(passInputType === 'password' ? 'text' : 'password');
  };

  const errors = useMemo(() => ({
    oldPass: !form.oldPass,
    newPass: !form.newPass,
    confirmPass: !form.confirmPass || form.newPass !== form.confirmPass,
    btnDisabled: loading || Object.values(form).some(v => !v) || form.newPass !== form.confirmPass
  }), [form, loading]);

  const handlePassChange = () => {
    authStore.changePassword(form);
  };

  const listenToEnter = (e) => {
    if (e.key === 'Enter' && !errors.btnDisabled) {
      handlePassChange();
    }
  };

  return (
    <RegisterFormWrapper onKeyDown={listenToEnter}>
      {!!errorMessage && <Text type={'bodySmall'} className={'error_message'}>{errorMessage}</Text>}
      <PassWrapper>
        <Input
          inputRef={inputRef}
          className={'password_input input'}
          tooltip={t('auth.oldPasswordPlaceholder')}
          icon={'lock'}
          inputProps={{
            name: 'currentPassword',
            autocomplete: 'current-password',
            value: form.oldPass,
            onChange: e => setForm({ ...form, oldPass: e.target.value }),
            type: passInputType
          }}
          placeholder={t('auth.oldPasswordPlaceholder')}
          isInvalid={errors.oldPass}
          validationMessage={t('auth.oldPasswordPlaceholder')}
        />
        <EyeIconContainer onClick={togglePassVisible}>
          {passInputType === 'password' ?
            <AiOutlineEyeInvisible size={20} color={commonStyles.colors.grey}/> :
            <AiOutlineEye size={20} color={commonStyles.colors.grey}/>
          }
        </EyeIconContainer>
      </PassWrapper>
      <PassWrapper>
        <Input
          className={'password_input input'}
          tooltip={t('auth.newPasswordPlaceholder')}
          icon={'lock'}
          inputProps={{
            name: 'newPassword',
            autocomplete: 'new-password',
            value: form.newPass,
            onChange: e => setForm({ ...form, newPass: e.target.value }),
            type: passInputType
          }}
          placeholder={t('auth.newPasswordPlaceholder')}
          isInvalid={errors.newPass}
          validationMessage={t('auth.newPasswordPlaceholder')}
        />
        <EyeIconContainer onClick={togglePassVisible}>
          {passInputType === 'password' ?
            <AiOutlineEyeInvisible size={20} color={commonStyles.colors.grey}/> :
            <AiOutlineEye size={20} color={commonStyles.colors.grey}/>
          }
        </EyeIconContainer>
      </PassWrapper>
      <PassWrapper>
        <Input
          className={'password_input input'}
          tooltip={t('auth.confirmPasswordPlaceholder')}
          icon={'lock'}
          inputProps={{
            name: 'confirmPassword',
            autocomplete: 'new-password',
            value: form.confirmPass,
            onChange: e => setForm({ ...form, confirmPass: e.target.value }),
            type: passInputType
          }}
          placeholder={t('auth.confirmPasswordPlaceholder')}
          isInvalid={errors.confirmPass}
          validationMessage={t('auth.confirmPasswordPlaceholder')}
        />
        <EyeIconContainer onClick={togglePassVisible}>
          {passInputType === 'password' ?
            <AiOutlineEyeInvisible size={20} color={commonStyles.colors.grey}/> :
            <AiOutlineEye size={20} color={commonStyles.colors.grey}/>
          }
        </EyeIconContainer>
      </PassWrapper>
      <Button
        type={'primary'}
        text={t('auth.change')}
        disabled={errors.btnDisabled}
        className={'main_button'}
        onClick={handlePassChange}
      />
    </RegisterFormWrapper>
  );
};

export default observer(ChangePassword);
