import React from 'react';
import PropTypes from 'prop-types';
import { Link, Text } from '../shared-components';

const UserMenuItem = props => {
  const { link, label } = props;
  return (
    <Link href={link} className={'user-menu-item'}>
      <Text type={'bodySmall'} className={'user-menu-text'}>
        {label}
      </Text>
    </Link>
  );
};

UserMenuItem.propTypes = {
  link: PropTypes.string,
  label: PropTypes.string,
  onMenuHide: PropTypes.func,
};

export default UserMenuItem;
