import styled from 'styled-components';
import commonStyles, { respondTo } from '../../commonStyles';
import { ToolSectionContainer } from '../../ItemsList/ToolSection/ToolSection.style';

export const Wrapper = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  & > ${ToolSectionContainer} {
    margin-bottom: 0;
  }
`;

export const StatusSelectRow = styled.div.attrs(() => ({}))`
  width: 100%;
  min-height: 70px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  flex-wrap: wrap;
  z-index: 2;
  ${respondTo.md`
    flex-direction: row;
    justify-content: flex-end;
  `}
  
  & > .status_label {
    color: ${commonStyles.colors.primary};
  }
  
  & > .status_select {
    max-width: 220px;
    min-width: 180px;
  }
`;

export const OrderListContainer = styled.div.attrs(() => ({}))`
`;
