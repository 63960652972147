import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from '../../../stores/helpers/use-stores';
import { InfoBox } from '../../shared-components';
import { Wrapper, Hr } from './AccountData.style';
import UserPlaces from './UserPlaces';
import UserPrivacyPolicy from './UserPrivacyPolicy';
import UserProfileData from './UserProfileData';

const AccountData = () => {
  const { dataStores: { userStore } } = useStores();
  const { errorMessage } = userStore;

  return (
    <Wrapper>
      {!!errorMessage &&
        <InfoBox
          type={'primary'}
          imgType={'alert'}
          className={'warning-message'}
        >
          {errorMessage}
        </InfoBox>}
      <UserProfileData/>
      <UserPlaces />
      <Hr/>
      <UserPrivacyPolicy/>
    </Wrapper>
  );
};

export default observer(AccountData);
