export const SENT = 'versendet';
export const RECEIVED = 'empfangen';
export const CONFIRMED = 'bestätigt';
export const EN_ROUTE = 'unterwegs';
export const PAYMENT = 'zahlung';
export const PAYMENT_FAILED = 'zahlung_abgebrochen';
export const CANCELLED = 'abgebrochen';
export const COMPLETED = 'abgeschlossen';
export const TEST = 'testbestellung';

const DeliveryOrderStatuses = {
  SENT,
  RECEIVED,
  CONFIRMED,
  EN_ROUTE,
  CANCELLED,
  COMPLETED,
  PAYMENT,
  PAYMENT_FAILED,
  TEST
};

export default DeliveryOrderStatuses;
