import React from 'react';
import PropTypes from 'prop-types';
import { LoyaltySlidesWrapper } from './Loyalty.style';
import LoyaltySlide from './LoyaltySlide';

const LoyaltyItems = props => {
  const { loyalties, activeLoyaltyName, inviteBonus } = props;
  return (
    <LoyaltySlidesWrapper>
      {loyalties.map((loyalty) => <LoyaltySlide
        key={loyalty._id}
        item={loyalty}
        active={activeLoyaltyName === loyalty.name}
        inviteBonus={inviteBonus}
      />)}
    </LoyaltySlidesWrapper>
  );
};

LoyaltyItems.propTypes = {
  loyalties: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    minTrips: PropTypes.number,
    multiplier: PropTypes.number,
    offPeakDiscount: PropTypes.number,
    preBookingGuarantee: PropTypes.bool,
    referralRides: PropTypes.number,
    freeFoodDeliveries: PropTypes.number,
    color: PropTypes.string,
  })),
  activeLoyaltyName: PropTypes.string,
  inviteBonus: PropTypes.number,
};

export default LoyaltyItems;
