import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import AuthModalState from '../../../constants/authModalStates';
import { REGEXP_EMAIL } from '../../../constants/regExp';
import { useStores } from '../../../stores/helpers/use-stores';
import commonStyles from '../../commonStyles';
import { Button, Input, Text } from '../../shared-components';
import {
  EyeIconContainer,
  FormSwitchContainer,
  LoginFormWrapper,
  PassForgerContainer,
  PassWrapper, SocialButtonsContainer
} from '../AuthModal.style';
import AppleButton from '../socialButtons/AppleButton';
import FacebookButton from '../socialButtons/FBButton';
import GoogleButton from '../socialButtons/GoogleButton';

const LoginForm = () => {
  const { t } = useTranslation();
  const { dataStores: { authModalStore, authStore } } = useStores();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passInputType, setPassInputType] = useState('password');
  const emailInputRef = useRef();

  const { errorMessage, loading } = authStore;

  useEffect(() => {
    if (emailInputRef?.current) {
      emailInputRef.current.focus();
    }
  }, [emailInputRef]);

  const togglePassVisible = () => {
    setPassInputType(passInputType === 'password' ? 'text' : 'password');
  };

  const checkDisabled = () => {
    return !email ||
      !REGEXP_EMAIL.test(email) ||
      !password ||
      loading;
  };

  const handleLogin = () => {
    authStore.login({
      email,
      password
    });
  };

  const listenToEnter = (e) => {
    if (e.key === 'Enter' && !checkDisabled()) {
      handleLogin();
    }
  }

  return (
    <LoginFormWrapper onKeyDown={listenToEnter}>
      {!!errorMessage && <Text type={'bodySmall'} className={'error_message'}>{errorMessage}</Text>}
      <Input
        inputRef={emailInputRef}
        className={'email_input input'}
        tooltip={t('tooltips.email')}
        icon={'email'}
        inputProps={{
          value: email,
          onChange: e => setEmail(e.target.value)
        }}
        placeholder={t('tooltips.email')}
        isInvalid={!REGEXP_EMAIL.test(email)}
        validationMessage={t('validation.email')}
      />
      <PassWrapper>
        <Input
          className={'password_input input'}
          tooltip={t('tooltips.password')}
          icon={'lock'}
          inputProps={{
            value: password,
            onChange: e => setPassword(e.target.value),
            type: passInputType
          }}
          placeholder={t('tooltips.password')}
          isInvalid={!password}
          validationMessage={t('validation.password')}
        />
        <EyeIconContainer onClick={togglePassVisible}>
          {passInputType === 'password' ?
            <AiOutlineEyeInvisible size={20} color={commonStyles.colors.grey}/> :
            <AiOutlineEye size={20} color={commonStyles.colors.grey}/>
          }
        </EyeIconContainer>
      </PassWrapper>
      <PassForgerContainer>
        <Text
          type={'bodySmall'}
          className={'forget_pass'}
          onClick={() => authModalStore.changeModalState(AuthModalState.passReset)}
        >
          {t('auth.forgotPassword')}
        </Text>
      </PassForgerContainer>
      <Button
        type={'primary'}
        text={t('auth.loginButton')}
        disabled={checkDisabled()}
        className={'main_button'}
        onClick={handleLogin}
      />
      <Text type={'bodySmall'} className={'social_title'}>{t('auth.orSignInWith')}</Text>
      <SocialButtonsContainer>
        <FacebookButton />
        <GoogleButton />
        <AppleButton />
      </SocialButtonsContainer>
      <FormSwitchContainer>
        <Text className={'form_switch_text'} type={'bodySmall'}>
          <Trans i18nKey={'auth.switchToRegister'} values={{ highlighted: t('auth.register') }}>
            Not registered yet?&nbsp;
            <span
              className={'form_switch_trigger'}
              onClick={() => authModalStore.changeModalState(AuthModalState.register)}
            >
              Register
            </span>
          </Trans>
        </Text>
      </FormSwitchContainer>
    </LoginFormWrapper>
  );
};

export default observer(LoginForm);
