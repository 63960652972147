import React from 'react';
import { AboutItem, Wrapper, ItemImage } from './AboutSection.style';
import { Text } from '../../shared-components';
import driver from '../../../assets/img/driver.png';
import partner from '../../../assets/img/partner.png';
import other from '../../../assets/img/other.png';
import { useTranslation } from "react-i18next";

const AboutSection = () => {
  const { t } = useTranslation();

  const aboutData = [
    {
      title: t('main.becomeDriver'),
      img: driver,
      text: t('main.becomeDriverText'),
      accent: false,
      href: 'https://www.cabdo.de/fahrer'
    },
    {
      title: t('footer.cabdoBusiness'),
      img: partner,
      text: t('main.becomePartnerText'),
      accent: true,
      href: 'https://www.cabdo.de/business'
    },
    {
      title: t('main.becomePartner2'),
      img: other,
      text: t('main.becomePartner2Text'),
      accent: false,
      href: 'https://www.cabdo.de/foodpartner'
    },
  ];


  return (
    <Wrapper>
      {aboutData.map((item, index) => (
        <AboutItem key={`about-${index}`}>
          <ItemImage img={item.img} accent={item.accent}/>
          <Text type="h3" weight="700">{item.title}</Text>
          <Text className="about-text" type="body" align="center">
            {item.text}
          </Text>
          <a href={item.href} target={'_blank'} rel={'opener noreferrer'} className={'about_button'}>
            <Text
              className="about_button_text"
              type="body"
              weight="700"
            >
              {t('main.joinUs')}
            </Text>
          </a>
        </AboutItem>
      ))}
    </Wrapper>
  )
};

export default AboutSection;
