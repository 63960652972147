import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { useHistory, useLocation } from 'react-router-dom';
import { findParentCategory } from '../../../helpers/utils';
import { useDrag } from '../../../hooks/useDrag';
import { usePreventBodyScroll } from '../../../hooks/usePreventScroll';
import { useStores } from '../../../stores/helpers/use-stores';
import { Text } from '../../shared-components';
import { HorizontalCategoriesContainer, HorizontalCategoryItem } from './Categories.style';

const HorizontalSlider = props => {
  const { categories, currentCategorySlug, exact } = props;
  const { dataStores: { productsStore, servicesStore } } = useStores();
  const { selectedCategory } = productsStore;
  const { selectedService } = servicesStore;
  const history = useHistory();
  const location = useLocation();
  const { dragStart, dragStop, dragMove, dragging, onWheel } = useDrag();
  const { disableScroll, enableScroll } = usePreventBodyScroll();
  const scrollRef = useRef();

  const list = useMemo(() => {
    if (!selectedCategory) return categories;
    if (selectedCategory && selectedCategory.subCategories?.length) {
      return selectedCategory.subCategories;
    }
    if (selectedCategory.level > 0) {
      const parentCat = findParentCategory(categories, selectedCategory);
      if (!!parentCat) {
        return parentCat.subCategories;
      }
    }

    return categories;
  }, [selectedCategory, categories]);

  const handleDrag = ({ scrollContainer }) => (ev) =>
    dragMove(ev, (posDiff) => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft += posDiff;
      }
    });

  const handleItemClick = (slug) => () => {
    if (dragging) {
      return false;
    }
    if (exact) {
      history.push(slug);
      return;
    }
    if (!currentCategorySlug) {
      history.push(`${location.pathname}/${slug}`);
    } else {
      history.push(`${slug}`);
    }
  };

  useEffect(() => {
    if (list.length && selectedCategory) {
      const listIndex = list.findIndex(cat => cat.slug === selectedCategory.slug);
      if (listIndex >= 0) {
        scrollRef.current?.scrollToItem(scrollRef.current?.getItemByIndex(listIndex));
      }
    }
  }, [selectedCategory, list, scrollRef]);

  return (
    <HorizontalCategoriesContainer>
      <div
        onMouseLeave={() => {
          enableScroll();
          dragStop();
        }}
        onMouseEnter={disableScroll}
      >
        <ScrollMenu
          onWheel={onWheel}
          onMouseDown={() => dragStart}
          onMouseUp={() => dragStop}
          onMouseMove={handleDrag}
          apiRef={scrollRef}
        >
          {list.map((cat, index) =>
            <HorizontalCategoryItem
              key={`hor-${cat.slug}-${index}`}
              active={exact ? currentCategorySlug === cat.slug : selectedCategory?.slug === cat.slug}
              type={selectedService}
              onClick={handleItemClick(cat.slug)}
            >
              <Text type={'body'} className={'horizontal-categoryItem'}>{cat.name}</Text>
            </HorizontalCategoryItem>)}
        </ScrollMenu>
      </div>
    </HorizontalCategoriesContainer>
  );
};

HorizontalSlider.propTypes = {
  categories: PropTypes.array.isRequired,
  currentCategorySlug: PropTypes.string,
  exact: PropTypes.bool,
}

export default observer(HorizontalSlider);
