import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import AuthModalState from '../../../constants/authModalStates';
import { REGEXP_EMAIL, REGEXP_PHONE } from '../../../constants/regExp';
import { useStores } from '../../../stores/helpers/use-stores';
import commonStyles from '../../commonStyles';
import { Button, Input, Text } from '../../shared-components';
import { EyeIconContainer, FormSwitchContainer, PassWrapper, RegisterFormWrapper } from '../AuthModal.style';
import PolicyText from './PolicyText';

const initialRegisterData = {
  fname: '',
  lname: '',
  email: '',
  phoneNo: '',
  password: '',
  confirmPassword: '',
  referralCode: '',
};

const RegisterForm = () => {
  const { t } = useTranslation();
  const { dataStores: { authModalStore, authStore } } = useStores();
  const [formData, setFormData] = useState(initialRegisterData);
  const [isBtnDisabled, toggleBtnDisable] = useState(false);
  const [passInputType, setPassInputType] = useState('password');
  const { errorMessage, loading } = authStore;

  const errors = useMemo(() => {
    return {
      fname: !formData.fname,
      lname: !formData.lname,
      email: !REGEXP_EMAIL.test(formData.email),
      phoneNo: !REGEXP_PHONE.test(formData.phoneNo) || !formData.phoneNo,
      password: !formData.password,
      confirmPassword: !formData.confirmPassword || formData.password !== formData.confirmPassword,
    };
  }, [formData]);

  useEffect(() => {
    toggleBtnDisable(loading || Object.values(errors).some(Boolean));
  }, [errors, loading]);

  const handleRegister = () => {
    authStore.register(formData);
  }

  const updateFormField = (key, e) => {
    setFormData({ ...formData, [key]: e.target.value });
  }

  const togglePassVisible = () => {
    setPassInputType(passInputType === 'password' ? 'text' : 'password');
  };

  return (
    <RegisterFormWrapper>
      {!!errorMessage && <Text type={'bodySmall'} className={'error_message'}>{errorMessage}</Text>}
      <Input
        className={'text_input input'}
        tooltip={t('auth.firstName')}
        icon={'user'}
        inputProps={{
          value: formData.fname,
          onChange: e => updateFormField('fname', e)
        }}
        placeholder={t('auth.firstName')}
        isInvalid={errors.fname}
        validationMessage={t('validation.firstName')}
      />
      <Input
        className={'text_input input'}
        tooltip={t('auth.lastName')}
        icon={'user'}
        inputProps={{
          value: formData.lname,
          onChange: e => updateFormField('lname', e)
        }}
        placeholder={t('auth.lastName')}
        isInvalid={errors.lname}
        validationMessage={t('validation.lastName')}
      />
      <Input
        className={'text_input input'}
        tooltip={t('tooltips.email')}
        icon={'email'}
        inputProps={{
          value: formData.email,
          onChange: e => updateFormField('email', e)
        }}
        placeholder={t('tooltips.email')}
        isInvalid={errors.email}
        validationMessage={t('validation.email')}
      />
      <Input
        icon={'phone'}
        tooltip={t('tooltips.phoneNo')}
        className={'input'}
        inputProps={{
          type: 'tel',
          value: formData.phoneNo,
          onChange: e => updateFormField('phoneNo', e)
        }}
        placeholder={t('cart.phonePlaceholder')}
        mask="+4\9 999 9999999"
        isInvalid={errors.phoneNo}
        validationMessage={t('validation.phone')}
      />
      <PassWrapper>
        <Input
          className={'password_input input'}
          tooltip={t('tooltips.password')}
          icon={'lock'}
          inputProps={{
            value: formData.password,
            onChange: e => updateFormField('password', e),
            type: passInputType
          }}
          placeholder={t('tooltips.password')}
          isInvalid={errors.password}
          validationMessage={t('validation.password')}
        />
        <EyeIconContainer onClick={togglePassVisible}>
          {passInputType === 'password' ?
            <AiOutlineEyeInvisible size={20} color={commonStyles.colors.grey}/> :
            <AiOutlineEye size={20} color={commonStyles.colors.grey}/>
          }
        </EyeIconContainer>
      </PassWrapper>
      <PassWrapper>
        <Input
          className={'password_input input'}
          tooltip={t('auth.confirmPassword')}
          icon={'lock'}
          inputProps={{
            value: formData.confirmPassword,
            onChange: e => updateFormField('confirmPassword', e),
            type: passInputType
          }}
          placeholder={t('auth.confirmPassword')}
          isInvalid={errors.confirmPassword}
          validationMessage={t('validation.confirmPassword')}
        />
        <EyeIconContainer onClick={togglePassVisible}>
          {passInputType === 'password' ?
            <AiOutlineEyeInvisible size={20} color={commonStyles.colors.grey}/> :
            <AiOutlineEye size={20} color={commonStyles.colors.grey}/>
          }
        </EyeIconContainer>
      </PassWrapper>
      <Input
        className={'text_input input'}
        tooltip={t('auth.referralCode')}
        icon={'gift'}
        inputProps={{
          value: formData.referralCode,
          onChange: e => updateFormField('referralCode', e)
        }}
        placeholder={t('auth.referralCode')}
      />
      <Button
        type={'primary'}
        text={t('auth.register')}
        disabled={isBtnDisabled}
        className={'main_button'}
        onClick={handleRegister}
      />
      <FormSwitchContainer>
        <Text className={'form_switch_text'} type={'bodySmall'}>
          <Trans i18nKey={'auth.switchToLogin'} values={{ highlighted: t('auth.login') }}>
            Already registered?&nbsp;
            <span
              className={'form_switch_trigger'}
              onClick={() => authModalStore.changeModalState(AuthModalState.login)}
            >
              Register
            </span>
          </Trans>
        </Text>
      </FormSwitchContainer>
      <PolicyText />
    </RegisterFormWrapper>
  );
};

export default observer(RegisterForm);
