import styled from 'styled-components';
import commonStyles, { respondTo } from '../../commonStyles';
import { InputContainer } from '../../shared-components/PlacesInput/PlacesInput.styes';

export const Wrapper = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: column;
  ${respondTo.md`
    margin: 0 24px;
    width: 100%;
  `}
`;

export const FormWrapper = styled.div.attrs(() => ({}))`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 16px;

  & .cards__section {
    margin: 0 0 50px 0;
  }

  ${respondTo.md`
    margin-top: 24px;
    justify-content: flex-start;
  `}
  & > .textInput {
    width: 100%;
    margin: 0 8px 20px;
    ${respondTo.sm`
      max-width: 280px;
      min-width: 280px;
    `}
  }
`;

export const ButtonsContainer = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 100%;
  max-width: unset;

  & > .apply_button {
    height: 50px;
    margin-bottom: 20px;
    ${respondTo.sm`
      margin-right: 16px;
      max-width: 300px;
    `}
  }

  & > .revert_button {
    height: 50px;
    margin-bottom: 20px;
    ${respondTo.sm`
      margin-right: 16px;
    `}
  }

  ${respondTo.md`
    justify-content: flex-start;
  `}
`;

export const Hr = styled.div.attrs(() => ({}))`
  width: 100%;
  height: 3px;
  margin: 24px 0;
  border-style: solid;
  border-color: ${commonStyles.colors.primary};
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-right-width: 0;
  border-left-width: 0;
`;

export const PrivacyWrapper = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: column;
`;

export const PrivacyElementWrapper = styled.div.attrs(() => ({}))`
  margin-top: 40px;
  width: 100%;
`;

export const PrivacyHeaderRow = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 10;
  margin-bottom: 16px;

  & > .privacy_element_title {
    flex: 8;
  }
`;

export const PrivacyDescriptionWrapper = styled.div.attrs(() => ({}))`
  & > .privacy_element_desc {
    & > .italic {
      font-style: italic
    }
  }
`;

export const PlacesWrapper = styled.div.attrs(() => ({}))`
  width: 100%;
`;

export const UserPlaceToolWrapper = styled.div.attrs(() => ({}))`
  width: 100%;
  max-width: 420px;
  min-width: 300px;

  & > .label {
    margin-bottom: 8px;
  }
`;


export const InlineWrapper = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  & > .add_icon {
    margin-right: 8px;
  }
`;

export const PlaceToolContainer = styled.div.attrs(() => ({}))`
  margin: 16px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  
  ${respondTo.lg`
    justify-content: space-between;
  `}

  & > ${UserPlaceToolWrapper} {
    margin: 0 0 16px 0;
    ${respondTo.md`
      margin: 0 16px 24px 0;
    `}
  }
`;

export const AddButtonWrapper = styled.div.attrs(() => ({}))`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 420px;
  min-width: 300px;
  margin-top: 20px;
  
  ${respondTo.md`
    margin-top: unset;
  `}

  & .add_button {
    height: 40px;
    transition: 0.4s all;
    max-width: 200px;

    &:hover {
      & > ${InlineWrapper} {
        & > .add_icon {
          fill: ${commonStyles.colors.white};
        }

        & > .add_text {
          color: ${commonStyles.colors.white};
        }
      }
    }
  }
`;

export const InputWrapper = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80%;
  
  & .warning_message {
    color: ${commonStyles.colors.warning};
    margin-bottom: 16px;
    font-size: 12px;
    margin-left: 32px;
  }
`;

export const InputGroupWrapper = styled.div.attrs(() => ({}))`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  & > ${InputWrapper} > ${InputContainer} {
    width: 100%
  }

  & > .button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    min-height: 40px;
    width: 40px;
    min-width: 40px;
    transition: 0.4s all;
    cursor: pointer;
  }

  & > .clear_btn {
    border-radius: 5px;
    background: ${commonStyles.colors.white};
    border: 1px solid ${commonStyles.colors.danger};
    margin: 0 8px;

    & > .clear_icon {
      fill: ${commonStyles.colors.danger};
    }

    &:hover {
      background: ${commonStyles.colors.danger};

      & > .clear_icon {
        fill: ${commonStyles.colors.white};
      }
    }
  }

  & > .map_btn {
    border-radius: 5px;
    border: 1px solid ${commonStyles.colors.mahlzeit};
    background: ${commonStyles.colors.white};

    & > .map_icon {
      fill: ${commonStyles.colors.mahlzeit};
      height: 20px;
      width: 20px;
    }

    &:hover {
      background: ${commonStyles.colors.mahlzeit};

      & > .map_icon {
        fill: ${commonStyles.colors.white};
      }
    }
  }
`;

