import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Text } from '../../shared-components';
import ProductCard from '../ProductCard';
import { FoundProviderContainer, ProductsRow, ProviderDataRaw, } from './ProviderFoundCard.style';

const ProviderFoundCard = props => {
  const { t } = useTranslation();
  const { providerData } = props;
  return (
    <FoundProviderContainer onClick={props.onSelect}>
      <ProviderDataRaw>
        <Text type={'h3'} className={'found_provider-providerName'}>{providerData.fname}</Text>
        <Text
          type={'bodySmall'}
          className={'found_provider-moreText'}
          weight={700}
        >
          {t('products.seeMore')}
        </Text>
      </ProviderDataRaw>
      <ProductsRow>
        {providerData.products.map((item, index) =>
          <ProductCard
            key={`found_productCard-${item.name}-${index}`}
            className={'found_productCard'}
            item={item}
            showPlus={false}
            handleCardClick={() => {}}
            handlePlusClick={() => {}}
          />)
        }
      </ProductsRow>
    </FoundProviderContainer>
  );
};

ProviderFoundCard.propTypes = {
  providerData: PropTypes.object,
  onSelect: PropTypes.func,
};

export default ProviderFoundCard;
