import styled from 'styled-components';
import commonStyles, { respondTo } from '../../commonStyles';

export const FakeInputBarContainer = styled.div.attrs(() => ({}))`
  height: 40px;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  background-color: ${commonStyles.colors.white};
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 568px;
  
  &:hover {
    box-shadow: 1px 1px 10px ${commonStyles.colors.alphaString(commonStyles.colors.primary, 0.25)};
    transition: all 0.2s;
    transform: scale(1.01);
  }

  ${respondTo.md`
    height: 60px;
    max-width: 790px;
  `}
  & .fakeInput-icon_container {
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${commonStyles.colors.primary};

    & .fakeInput-icon {
      height: 28px;
      width: 28px;
      ${respondTo.md`
        height: 40px;
        width: 40px;
      `}
    }
    
    ${respondTo.md`
      width: 60px;
    `}
  }

  & .fakeInput-placeholder {
    color: ${commonStyles.colors.grey};
    margin-left: 20px;
    ${respondTo.md`
      margin-left: 30px;
    `}
  }
`;
