import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import {
  getAvailablePromo,
  getMainCategoriesList,
  getProductList,
  getProductImage, searchItems
} from '../../../utils/api/products';
import { getAvailableStorage } from '../../storage';

export default class ProductsStore {
  @observable isFetching = false;
  @observable isError = false;
  @observable errorMessage = null;
  @observable productList = [];
  @observable categoriesList = [];
  @observable searchItemsResult = [];
  @observable activePromo = null;
  @observable selectedCategory = null;
  @observable selectedProduct = null;
  @observable totalProducts = 0;
  @observable searchMeta = {
    limit: 40,
    offset: 0,
  };
  @observable sortParam = {
    field: 'name',
    order: 'asc'
  };

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    makePersistable(this, {
      name: 'ProductsData',
      properties: [
        'selectedCategory',
        'activePromo',
      ],
      storage: getAvailableStorage(),
      expireIn: 6 * 60 * 60 * 100,
      removeOnExpiration: true,
    }, {
      delay: 200,
    });
  }

  @action setOffset = () => {
    this.searchMeta.offset += this.searchMeta.limit;
  };

  @action setMetaLimit = (limit) => {
    this.searchMeta.limit = limit;
    this.searchMeta.offset = 0;
  };

  @action setSortField = (field) => {
    this.sortParam.field = field;
    this.searchMeta.offset = 0;
  };

  @action setSortOrder = (order) => {
    this.sortParam.order = order;
    this.searchMeta.offset = 0;
  };

  @action setSearchMetaToDefault = () => {
    this.searchMeta = {
      limit: 40,
      offset: 0,
    };
    this.sortParam = {
      field: 'name',
      order: 'asc'
    };
  };

  @action setLoading = () => {
    this.isFetching = true;
    this.isError = false;
    this.errorMessage = null;
  };

  @action setLoaded = (isError = false, errorMessage = null) => {
    this.isFetching = false;
    this.isError = isError;
    this.errorMessage = errorMessage;
  };

  @action setCategory = (category = null) => {
    this.searchMeta = {
      limit: 40,
      offset: 0,
    };
    this.productList = [];
    this.totalProducts = 0;
    this.selectedCategory = category;
  };

  @action setProduct = (product = null) => {
    this.selectedProduct = product;
  };

  get categorySlug() {
    const currentSelected = this.selectedCategory?.slug;
    return currentSelected ?? null;
  }

  @action getProductsList = async (categorySlug = null, searchQuery = null) => {
    try {
      const providerSlug = this.rootStore.dataStores.providersStore.providerSlug;
      if (!providerSlug) {
        return;
      }
      this.setLoading();
      const resp = await getProductList(
        this.rootStore.dataStores.servicesStore.bookerToken,
        providerSlug,
        categorySlug,
        {
          searchQuery,
          meta: this.searchMeta,
          sortParam: this.sortParam,
        }
      );
      if (resp.success) {
        runInAction(() => {
          this.productList = this.searchMeta.offset ? [...this.productList, ...resp.data] : resp.data;
          this.totalProducts = resp.totalItems;
        });
        this.setLoaded();
      } else {
        this.setLoaded(true, resp.message);
      }
    } catch (e) {
      console.error('Error fetching products', e);
      this.setLoaded(true, e.message);
    }
  };

  @action searchItemsGlobally = async (serviceType = null, searchQuery) => {
    try {
      runInAction(() => {
        this.searchItemsResult = [];
      });
      this.setLoading();
      const resp = await searchItems(
        this.rootStore.dataStores.servicesStore.bookerToken,
        serviceType,
        searchQuery
      );
      if (resp.success) {
        runInAction(() => {
          this.searchItemsResult = resp.data;
        });
        this.setLoaded();
      } else {
        this.setLoaded(true, resp.message);
      }
    } catch (e) {
      console.error('Error searching products', e);
      this.setLoaded(true, e.message);
    }
  };

  @action setMainCategoriesList = (cats = []) => {
    this.categoriesList = cats;
  }

  @action getMainCategoriesList = async (providerSlug) => {
    try {
      this.setLoading();
      const resp = await getMainCategoriesList(
        this.rootStore.dataStores.servicesStore.bookerToken,
        providerSlug
      );
      if (resp.success) {
        runInAction(() => {
          this.categoriesList = resp.data;
        });
        this.setLoaded();
      } else {
        this.setLoaded(true, resp.message);
      }
    } catch (e) {
      console.error('Error fetching main categories', e);
      this.setLoaded(true, e.message);
    }
  };

  @action getAvailablePromos = async () => {
    try {
      const providerSlug = this.rootStore.dataStores.providersStore.providerSlug;
      const resp = await getAvailablePromo(
        this.rootStore.dataStores.servicesStore.bookerToken,
        providerSlug,
      );
      if (resp.success) {
        runInAction(() => {
          this.activePromo = resp.data;
        });
      }
    } catch (e) {
      console.error('Error fetching sub categories', e);
    }
  };

  @action getProductFullImage = async (productId) => {
    try {
      const resp = await getProductImage(
        this.rootStore.dataStores.servicesStore.bookerToken,
        productId
      );
      return resp.success ? resp.data : null;
    } catch (e) {
      console.error('Error fetching sub categories', e);
      return null;
    }
  }

  @action clearStore = () => {
    this.setLoaded();
    this.activePromo = null;
    this.subCategoriesList = [];
    this.mainCategoriesList = [];
    this.productList = [];
    this.totalProducts = 0;
    this.setCategory();
    this.searchMeta = {
      limit: 40,
      offset: 0,
    };
    this.sortParam = {
      field: 'name',
      order: 'asc'
    }
  }
}
