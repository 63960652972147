import { observer } from 'mobx-react-lite';
import React from 'react';
import OrderList from './OrderList';
import OrderStatusSelectRow from './OrderStatusSelectRow';
import ToolSection from './ToolSection';
import { Wrapper } from './UserOrders.style';

const UserOrders = () => {
  return (
    <Wrapper>
      <ToolSection />
      <OrderStatusSelectRow />
      <OrderList />
    </Wrapper>
  );
};

export default observer(UserOrders);
