import styled from 'styled-components';
import { respondTo } from '../commonStyles';

export const Container = styled.div`
  width: 100%;
  margin: 80px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center; 
  & .not-found__image {
    width: 100%;
  }
  & .not-found__title {
    margin: 40px 0;
    text-align: center;
  }
  
  ${respondTo.sm`
    margin-top: 100px;
    & .not-found__title {
      margin: 50px 0;
    }
    & .not-found__image {
      max-width: 360px;
    }
  `}
  
  ${respondTo.md`
    margin-top: 100px;
    & .not-found__title {
      margin: 50px 0;
    }
    & .not-found__image {
      max-width: 600px;
    }
  `}
  
  ${respondTo.lg`
    margin-top: 100px;
    & .not-found__title {
      margin: 50px 0;
    }
    & .not-found__image {
      max-width: 995px;
    }
  `}
`;
