import { KEYS } from '../../keys';
import api, { getTokenHeader } from './api';


export async function getProductList(token, providerSlug, categorySlug, body) {
  const headers = getTokenHeader(token);
  const options = {
    url: `${KEYS.API_URL}/api/booker/products/${providerSlug}/${categorySlug ?? ''}`,
    data: body,
    ...headers,
  };
  return api.post(options);
}

export async function searchItems(token, serviceType, query) {
  const headers = getTokenHeader(token);
  const options = {
    url: `${KEYS.API_URL}/api/booker/search-items/${serviceType ?? ''}?search=${query}`,
    ...headers,
  };
  return api.get(options);
}

export async function getMainCategoriesList(token, providerSlug) {
  const headers = getTokenHeader(token);
  const options = {
    url: `${KEYS.API_URL}/api/booker/categories/${providerSlug}`,
    ...headers
  };
  return api.get(options);
}

export async function getSubCategoriesList(token, providerSlug, categorySlug) {
  const headers = getTokenHeader(token);
  const options = {
    url: `${KEYS.API_URL}/api/booker/sub-categories/${providerSlug}/${categorySlug}`,
    ...headers
  };
  return api.get(options);
}

export async function getAvailablePromo(token, providerSlug) {
  const headers = getTokenHeader(token);
  const options = {
    url: `${KEYS.API_URL}/api/booker/promo/${providerSlug}`,
    ...headers
  };
  return api.get(options);
}

export async function getProductImage(token, productId) {
  const headers = getTokenHeader(token);
  const options = {
    url: `${KEYS.API_URL}/api/booker/products/image/${productId}`,
    ...headers
  };
  return api.get(options);
}
