import styled, { css } from 'styled-components';
import Select from 'react-select';
import commonStyles from '../../commonStyles';

const BaseStyles = css`
  position: relative;
  width: 100%;
  font-size: 24px;
  & > div {
    min-height: 32px;
  }
  ${(props) => props.validation === 'failed'
  && css`
      ::placeholder {
        color: ${commonStyles.colors.danger};
      }
    `}
`;

const OutlinedStyles = css`
  & .outlined {
    &__control {
      border: 1px solid ${commonStyles.colors.primary};
      padding: 8px;
      border-radius: 5px;
      ${({ isDisabled }) => isDisabled && css`
        border: 1px solid ${commonStyles.colors.grey};
      `}
    }
    &__value-container {
      font-size: 18px;
      padding-right: 10px;
      & .outlined__single-value {
        margin: 0;
        ${({isDisabled}) => isDisabled && css`
          color: ${commonStyles.colors.grey};
        `}
      }
    }
    &__indicators {
      & .select__icon {
        margin: 0;
      }
    }
    &--inversion {
      &__control {
        border: 1px solid ${commonStyles.colors.white};
        border-radius: 5px;
        padding: 0 8px;
      }
      &__single-value {
        font-size: 18px;
        color: ${commonStyles.colors.white};
      }
      &__indicators {
         & svg.select__icon path {
          fill: ${commonStyles.colors.white};
        }
      }
    }
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 100px;
  width: 100%; 
  & .select__icon {
    margin-right: 12px;
  }
`;

export const StyledSelect = styled(Select)`
  ${BaseStyles}
  ${({ classNamePrefix }) => classNamePrefix && css`
    ${OutlinedStyles}
  `}
`;

export const CustomStyles = {
  control: (provided, state) => {
    const getIconColor = () => {
      if (state.isFocused) {
        return commonStyles.colors.primary;
      }
      if (state.isDisabled) {
        return commonStyles.colors.grey;
      }
      return commonStyles.colors.grey;
    };
    const getBorderColor = () => {
      if (state.isFocused) {
        return commonStyles.colors.primary;
      }
      if (state.isDisabled) {
        return commonStyles.colors.grey;
      }
      return commonStyles.colors.grey;
    };
    return {
      ...provided,
      border: 'none',
      borderRadius: '0',
      borderBottom: `1px solid ${getBorderColor()}66`,
      padding: 0,
      boxShadow: 'none',
      ':hover': {
        cursor: 'pointer',
      },
      '& svg.select__icon': {
        transform: state.menuIsOpen && `rotate(180deg)`,
      },
      '& svg.select__icon path': {
        fill: getIconColor(),
      },
      ':hover svg.icon-svg': {
        fill: !state.isFocused && commonStyles.colors.grey,
      },
      backgroundColor: state.isDisabled && 'transparent',
    };
  },
  menu: (provided, state) => {
    if (state.selectProps.suppressMenu) {
      return { display: 'none' };
    }
    return {
      ...provided,
      padding: '0',
      boxShadow: '0px 4px 16px #0B33561A',
    };
  },
  menuList: (provided) => ({
    ...provided,
    borderRadius: 5,
    padding: 0,
    borderWidth: 1,
    borderColor: commonStyles.colors.white,
    borderStyle: 'solid',
    boxShadow: '0px 4px 16px #0B33561A',
    maxHeight: '212px',
    overflow: 'auto',
    width: '100%',
    height: '100%',
    paddingBottom: '8px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0',
    paddingRight: '12px',
    fontSize: '24px',
  }),
  menuPortal: (base) => ({
    ...base,
    fontSize: '12px',
    zIndex: 9999,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    ...state.selectProps.isMobile ? commonStyles.typo.mob.body : commonStyles.typo.bodySmall,
    color: state.isDisabled ? commonStyles.colors.grey : commonStyles.colors.grey,
  }),
  input: (provided, state) => ({
    ...provided,
    margin: '0',
    padding: '0',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    ...state.selectProps.isMobile ? commonStyles.typo.mob.body : commonStyles.typo.bodySmall,
    color: state.selectProps.fontColor,
    textAlign: state.selectProps.textAlign
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    padding: 0
  })
};
