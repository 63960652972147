import styled, { css } from 'styled-components';
import commonStyles, { respondTo } from '../../commonStyles';

export const ItemImage = styled.div`
  box-shadow: 0px 36px 47px -18px rgba(0, 29, 35, 0.35);
  background: ${({ img }) => (img && `url(${img}) no-repeat`)};
  background-size: cover;
  border-radius: 5px;
  margin-bottom: 24px;
  max-width: 340px;
  width: 100%;
  height: 160px;
  
  ${respondTo.md`
    width: 278px;
    height: 160px;
    margin-bottom: 36px;
  `}
  
  ${respondTo.lg`
    ${({ accent }) => accent && css`
    width: 380px;
    height: 245px;
    `}
  `}
`;

export const AboutItem = styled.div`
  width: 100%;
  max-width: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 70px;
  box-sizing: border-box;

  & .about-text {
    margin-bottom: 24px;
    max-width: 340px;
    width: 100%;
    ${respondTo.md`
      max-width: unset;
      width: unset;
    `}
  }

  & .about_button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: none;
    border-radius: 5px;
    background-color: ${commonStyles.colors.primary};
    transition: 0.1s ease-in;
    text-decoration: none;
    height: 56px;
    width: 100%;
    max-width: 340px;


    & .about_button_text {
      transition: 0.1s ease-in;
      color: ${commonStyles.colors.white};
      padding: 0 32px;
    }

    &:hover, &:active {
      cursor: pointer;
      background-color: ${commonStyles.colors.food};

      & .about_button_text {
        color: ${commonStyles.colors.primary};
      }
    }

    ${respondTo.md`
      width: 380px;
    `}
  }

  ${respondTo.sm`
    &:last-child {
      margin: 0;
    }
    & .about-text {
      margin-bottom: 40px;
    }
  `}
  ${respondTo.md`
    margin-bottom: 30px;
    margin-right: 30px;
  `}
  ${respondTo.lg`
    margin-bottom: 0;
  `}
`;


export const Wrapper = styled.div`
  width: 100%;
  max-width: 380px;
  margin: 24px auto 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  ${respondTo.md`
    max-width: 100%;
    margin-top: 40px;
  `}
  & ${AboutItem}:last-child {
    margin-bottom: 0;
  }
  
  & ${AboutItem}:first-child, & ${AboutItem}:last-child {
    padding-top: 0;
    ${respondTo.lg`
      padding-top: 30px;
    `}
  }
  
`;
