import styled, { css } from 'styled-components';
import { respondTo } from '../commonStyles';

export const InfoLabel = styled.div`
  font-size: 16px;
  width: 100%;
  margin-top: 20px;
  ${respondTo.md`
    margin-top: 10px;
    max-width: 380px;
    font-size: 14px;
  `}
`;

export const ColumnSection = styled.div`
  margin-bottom: 60px;
  ${respondTo.sm`
    margin-bottom: 36px;
    &:last-child {
      margin: 0;
    }
  `}
`;

export const Column = styled.div`
  width: 100%;
  ${({isEmpty}) => isEmpty && css`
    display: none;
  `}
  ${respondTo.sm`
    display: block;
    margin-bottom: 56px;
    width: auto;
    flex-grow: 1;
    flex-basis: 0;
    &:nth-child(odd) {
      margin-right: 30px;
    }
  `}
`;
export const SettingsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  & .settings-title {
    margin-bottom: 10px;
  }
  & .settings-input {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ${respondTo.sm`
    flex-direction: row;
    & .settings-input {
      margin-bottom: 30px;
    }
  `}
  ${({isSingle}) => isSingle && css`
    & ${Column} {
      margin: 0;
      & ${ColumnSection} {
        margin-bottom: 0;
      }
      &:last-child {
        display: none;
      }
      & .settings__checkbox {
        margin-bottom: 24px;
      }
      ${respondTo.sm`
        margin-right: 0;
        &:last-child {
         display: block;
        }
      `}
      ${respondTo.md`
        & .settings__checkbox {
          margin-bottom: 40px;
        }
      `}
    }
  `}
`;
