import styled, { css } from 'styled-components';
import Text from '../Text';
import commonStyles from '../../commonStyles';

export const CheckRegion = styled.div`
  border-radius: 4px;

  box-sizing: content-box;
  border: 2px solid ${commonStyles.colors.primary};
  margin: 2px;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;

  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${commonStyles.colors.primary};
  }
  ${({checked}) => checked && css`
    background-color: ${commonStyles.colors.primary};
  `}
`;

export const Label = styled(Text).attrs({ type: 'bodySmall' })`
  margin-left: 8px;
  user-select: none;
  color: ${commonStyles.colors.black};
`;

export const Wrapper = styled.label`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    box-shadow: unset;
    width: 0;
    height: 0;
    margin: 0;
  }


`;
