import { toJS } from 'mobx';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../stores/helpers/use-stores';
import { Button, IconFont, Text } from '../../shared-components';
import {
  AddButtonWrapper,
  ButtonsContainer,
  Hr,
  InlineWrapper,
  PlacesWrapper,
  PlaceToolContainer
} from './AccountData.style';
import MapToolModal from './MapToolModal';
import PlaceInputTool from './PlaceInputTool';

const UserPlaces = () => {
  const { t } = useTranslation();
  const { dataStores: { userStore } } = useStores();
  const [mapPickState, setMapPickState] = useState({ visible: false, onConfirm: () => {}, initialPlace: null });
  const { userPlaces, initialUserPlaces } = userStore;
  const [problematicAddress, toggleProblematicAddresses] = useState(toJS(userPlaces).map(() => false));

  const addressesData = useMemo(() => {
    const places = toJS(userPlaces);
    const resp = {
      home: null,
      favorite: null,
      addresses: []
    };
    if (!places) return resp;
    places.forEach((p, index) => {
      if (p.name === 'home') {
        resp.home = { ...p, index };
      }
      if (p.name === 'favorite') {
        resp.favorite = { ...p, index };
      }
      if (p.name.includes('place')) {
        resp.addresses.push({ ...p, index });
      }
    });
    // resp.home = places.find(p => p.name === 'home');
    // resp.favorite = places.find(p => p.name === 'favorite');
    // resp.addresses = places.filter(p => !['home', 'favorite'].includes(p.name));
    return resp;
  }, [userPlaces]);

  const isChanged = useMemo(() => !_.isEqual(toJS(userPlaces), toJS(initialUserPlaces)), [userPlaces, initialUserPlaces]);

  useEffect(() => {
    userStore.getUserPlaces();
    // eslint-disable-next-line
  }, []);

  const handlePlaceDataUpdate = (index, placeData, hasError = false) => {
    userStore.updatePlaceData(index, placeData);
    const problems = [...problematicAddress];
    problems[index] = hasError;
    toggleProblematicAddresses(problems);
  };

  const handleUserPlacesUpdate = _.debounce(() => {
    userStore.updateUserPlaces();
  }, 400);

  const handleChangesReset = () => {
    userStore.revertPlacesChanges();
  };

  const handleAddAddress = () => {
    userStore.addDeliveryPlace();
  };

  const handleMapBtnPress = (index, place = null, title) => {
    setMapPickState({
      title,
      visible: true,
      initialPlace: place,
      onConfirm: (placeData) => {
        handlePlaceDataUpdate(index, placeData);
        handleMapModalHide();
      }
    });
  };

  const handleMapModalHide = () => {
    setMapPickState({ visible: false, onConfirm: () => {}, initialPlace: null });
  };

  return (<>
      <Hr/>
      <PlacesWrapper>
        <Text className={'privacy_header'} type={'h3'}>{t('userPlaces.title')}</Text>
        <PlaceToolContainer>
          <PlaceInputTool
            key={'home'}
            data={addressesData.home}
            onPlaceUpdate={handlePlaceDataUpdate}
            onMapPress={handleMapBtnPress}
            hasAddressProblem={problematicAddress[addressesData?.home?.index ?? 0]}
          />
          <PlaceInputTool
            key={'favorite'}
            data={addressesData.favorite}
            onPlaceUpdate={handlePlaceDataUpdate}
            onMapPress={handleMapBtnPress}
            hasAddressProblem={problematicAddress[addressesData?.favorite?.index ?? 0]}
          />
          {addressesData.addresses.map((place, idx) => <PlaceInputTool
            key={`place-${idx}`}
            data={place}
            onPlaceUpdate={handlePlaceDataUpdate}
            onMapPress={handleMapBtnPress}
            hasAddressProblem={problematicAddress[place.index]}
          />)}
          {/*  Add Button */}
          <AddButtonWrapper>
            <Button
              type={'ghost'}
              className={'add_button'}
              content={<InlineWrapper>
                <IconFont size={18} icon={'plus'} className={'add_icon'}/>
                <Text type={'bodySmall'} weight={700} className={'add_text'}>{t('userPlaces.addAddress')}</Text>
              </InlineWrapper>}
              onClick={handleAddAddress}
            />
          </AddButtonWrapper>
        </PlaceToolContainer>
        {isChanged && <ButtonsContainer>
          <Button
            text={t('auth.updateInformation')}
            type={'primary'}
            className={'apply_button'}
            disabled={problematicAddress.some(Boolean)}
            onClick={handleUserPlacesUpdate}
          />
          <Button
            text={t('userPlaces.revert')}
            type={'ghost'}
            className={'revert_button'}
            onClick={handleChangesReset}
          />
        </ButtonsContainer>}
        <MapToolModal onHide={handleMapModalHide} {...mapPickState} />
      </PlacesWrapper>
    </>
  );
};

export default observer(UserPlaces);
