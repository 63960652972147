import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { REGEXP_EMAIL, REGEXP_PHONE } from '../../../constants/regExp';
import { useStores } from '../../../stores/helpers/use-stores';
import { Input, Text } from '../../shared-components';
import DeliveryTimeInputs from './deliveryTimeInputs';
import { DeliveryInfoSectionContainer } from './OrderInfo.style';

const DeliveryInfoSection = props => {
  const { t } = useTranslation();
  const { dataStores: { orderStore, servicesStore, deliveryModalStore, authStore } } = useStores();
  const { clientPhoneNo, clientEmail, orderComment, clientOrderLName } = orderStore;
  const { deliveryPlace } = servicesStore;
  const { isPlaceInZone } = deliveryModalStore;
  const { userData } = authStore;

  const [isDeliveryPlaceChanged, setIsDeliveryPlaceChanged] = useState(false);

  const handleDeliveryToolOpen = () => {
    deliveryModalStore.showDeliveryToolModal();
  };

  useEffect(() => {
    if (userData) {
      if (!clientOrderLName) {
        orderStore.setClientLName(userData.lname ?? '');
      }
      if (!clientEmail) {
        orderStore.setClientEmail(userData.email ?? '');
      }
      if (!clientPhoneNo) {
        orderStore.setClientPhone(userData.phoneNo ?? '');
      }
      orderStore.setClientName(userData.fname ?? '');
    }
    // eslint-disable-next-line
  }, [userData]);

  useEffect(() => {
    if (!clientOrderLName ||
      (!REGEXP_PHONE.test(clientPhoneNo) || !clientPhoneNo) ||
      !REGEXP_EMAIL.test(clientEmail) ||
      !isPlaceInZone
    ) {
      deliveryModalStore.setValidationError(true);
    } else {
      deliveryModalStore.setValidationError(false)
    }
    // eslint-disable-next-line
  }, [clientOrderLName, clientPhoneNo, clientEmail, deliveryPlace, isPlaceInZone]);

  useEffect(() => {
    setIsDeliveryPlaceChanged(true)
  }, [deliveryPlace]);

  return (
    <DeliveryInfoSectionContainer>
      <Text type={'h3'} weight={700} className={'cart-modal_deliveryInfoHeader'}>
        {t('cart.deliveryInfoTitle')}
      </Text>
      <Input
        icon={'pin'}
        tooltip={t('tooltips.deliveryAddress')}
        className={'cart-modal_textInput'}
        inputProps={{
          type: 'text',
          value: deliveryPlace.address,
          disabled: true
        }}
        placeholder={t('deliveryModal.deliveryAddressPlaceholder')}
        hasBtn={true}
        btnName={t('cart.change')}
        onBtnClick={handleDeliveryToolOpen}
        isInvalid={!deliveryPlace.address || !deliveryPlace.location.latitude || !deliveryPlace.location.longitude || !isPlaceInZone}
        validationMessage={isPlaceInZone ? t('validation.place') : t('validation.outOfDeliveryZone')}
        isFieldEdited={isDeliveryPlaceChanged}
      />
      <DeliveryTimeInputs/>
      <Input
        icon={'user'}
        className={'cart-modal_textInput'}
        tooltip={t('tooltips.client')}
        inputProps={{
          type: 'text',
          value: clientOrderLName,
          onChange: e => orderStore.setClientLName(e.target.value)
        }}
        placeholder={t('cart.userName')}
        isInvalid={!clientOrderLName}
        validationMessage={t('validation.name')}
      />
      <Input
        icon={'phone'}
        tooltip={t('tooltips.phoneNo')}
        className={'cart-modal_textInput'}
        inputProps={{
          type: 'tel',
          value: clientPhoneNo,
          onChange: e => orderStore.setClientPhone(e.target.value)
        }}
        placeholder={t('cart.phonePlaceholder')}
        mask="+4\9 999 99999999"
        isInvalid={!REGEXP_PHONE.test(clientPhoneNo) || !clientPhoneNo}
        validationMessage={t('validation.phone')}
      />
      <Input
        icon={'email'}
        className={'cart-modal_textInput'}
        tooltip={t('tooltips.email')}
        inputProps={{
          type: 'email',
          value: clientEmail,
          onChange: e => orderStore.setClientEmail(e.target.value)
        }}
        placeholder={t('cart.emailPlaceholder')}
        isInvalid={!REGEXP_EMAIL.test(clientEmail)}
        validationMessage={t('validation.email')}
      />
      <Input
        icon={'comment'}
        tooltip={t('tooltips.orderComment')}
        className={'cart-modal_textInput'}
        inputProps={{
          value: orderComment,
          onChange: e => orderStore.setOrderComment(e.target.value)
        }}
        placeholder={t('cart.commentPlaceholder')}
      />
    </DeliveryInfoSectionContainer>
  );
};

export default observer(DeliveryInfoSection);
