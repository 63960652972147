import { capitalize } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ServiceTypes from '../../../../constants/serviceTypes';
import { formatPrice } from '../../../../helpers/priceHelpers';
import { SideModal, Text } from '../../../shared-components';
import InfoRow from './InfoRow';
import { OrderInfoWrapper } from './OrderInfoModal.style';

const OrderInfoModal = props => {
  const { t } = useTranslation();
  const { visible, orderData } = props;
  if (!visible) return null;
  return (
    <SideModal
      isModalOpened={visible}
      hasSecondarySection={false}
      onHide={props.onHide}
      mainSection={<OrderInfoWrapper>
        <Text
          type={'h4'}
          className={'order_title'}
        >
          {t(`userOrders.order`)}
          <span>{orderData.type === ServiceTypes.FOOD ? '#' : ''}{orderData.orderNo}</span>
          ({t(`userOrders.${orderData.isSelfPickup ? 'selfPickup' : 'delivery'}`)})
        </Text>
        <InfoRow title={t('userOrders.provider')} value={orderData.provider.name}/>
        <InfoRow title={t('userOrders.orderStatus')} value={capitalize(orderData.orderStatus)}/>
        <InfoRow title={t('userOrders.orderValue')} value={formatPrice(orderData.priceData.orderItemsFare)}/>
        {!!orderData.priceData.discountAmt && <InfoRow
          title={`${t('userOrders.orderValue')} (${orderData.priceData.discountPercent}%)`}
          value={formatPrice(orderData.priceData.discountAmt)}
        />}
        {!!orderData.priceData.deliveryFare && <InfoRow
          title={t('userOrders.deliveryFee')}
          value={formatPrice(orderData.priceData.deliveryFare)}
        />}
        <InfoRow title={t('userOrders.total')} value={formatPrice(orderData.priceData.totalAmt)}/>
        <InfoRow title={t('userOrders.payment')} value={t(`wayOfPaymentMode.${orderData.paymentMethod}`)}/>
        <Text type={'body'} weight={700} className={'order_products'}>{t('userOrders.products')}:</Text>
        {orderData.orderItems.map((item, idx) => <InfoRow
          key={`${item.name}-${idx}`}
          title={item.name}
          value={`${item.qty}x`}
        />)}
      </OrderInfoWrapper>}
    />
  );
};

OrderInfoModal.propTypes = {
  visible: PropTypes.bool,
  onHide: PropTypes.func,
  orderData: PropTypes.shape({
    deliveryDate: PropTypes.string,
    deliveryPlace: PropTypes.shape({
      address: PropTypes.string,
      location: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      })
    }),
    fullDeliveryAddress: PropTypes.string,
    isSelfPickup: PropTypes.bool,
    provider: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      slug: PropTypes.string,
      emplacement: PropTypes.shape({
        address: PropTypes.string,
        location: PropTypes.shape({
          latitude: PropTypes.number,
          longitude: PropTypes.number,
        })
      }),
      phoneNo: PropTypes.string,
      logoUrl: PropTypes.string,
      providerData: PropTypes.any,
    }),
    clientPhoneNo: PropTypes.string,
    clientEmail: PropTypes.string,
    clientOrderName: PropTypes.string,
    clientOrderLName: PropTypes.string,
    orderItems: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      qty: PropTypes.number,
      itemPrice: PropTypes.number,
      mwst: PropTypes.number,
      comment: PropTypes.string,
      artNo: PropTypes.string,
    })),
    priceData: PropTypes.shape({
      orderItemsFare: PropTypes.number,
      deliveryFare: PropTypes.number,
      discountPercent: PropTypes.number,
      discountAmt: PropTypes.number,
      commissionPercent: PropTypes.number,
      commissionAmt: PropTypes.number,
      totalAmt: PropTypes.number,
    }).isRequired,
    itemsQty: PropTypes.number,
    orderStatus: PropTypes.string,
    orderComment: PropTypes.string,
    paymentMethod: PropTypes.string,
    slotLength: PropTypes.number,
    type: PropTypes.string,
  }),
};

export default OrderInfoModal;
