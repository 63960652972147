import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthModalState from '../../../constants/authModalStates';
import { REGEXP_PHONE } from '../../../constants/regExp';
import { useStores } from '../../../stores/helpers/use-stores';
import { Button, Input, Text } from '../../shared-components';
import { ButtonsContainer, FormWrapper } from './AccountData.style';

const initialUserData = {
  fname: '',
  lname: '',
  phoneNo: '',
};

const UserProfileData = () => {
  const { t } = useTranslation();
  const { dataStores: { authStore, authModalStore, userStore } } = useStores();
  const [userForm, setUserForm] = useState(initialUserData);
  const { userData, loading } = authStore;

  useEffect(() => {
    const formData = { ...userForm };
    formData.fname = userData.fname;
    formData.lname = userData.lname;
    formData.phoneNo = userData.phoneNo;
    setUserForm(formData);
    // eslint-disable-next-line
  }, [userData]);

  const isChanged = useMemo(() => {
    return userForm.fname !== userData.fname ||
      userForm.lname !== userData.lname ||
      userForm.phoneNo !== userData.phoneNo;
  }, [userData, userForm]);

  const errors = useMemo(() => {
    return {
      fname: !userForm.fname,
      lname: !userForm.lname,
      phoneNo: !REGEXP_PHONE.test(userForm.phoneNo) || !userForm.phoneNo
    };
  }, [userForm]);

  const handleVerifyPress = () => {
    authStore.resendCode();
    authModalStore.showAuthModal(AuthModalState.mobileConfirm);
  };

  const handleChangePassModal = () => {
    authModalStore.showAuthModal(AuthModalState.changePass);
  }

  const handleUserDataUpdate = () => {
    userStore.updateUserData(userForm);
  }

  return (
    <>
      <Text className={'privacy_header'} type={'h3'}>{t('userSettings.userData')}</Text>
      <FormWrapper>
        <Input
          icon={'user'}
          className={'textInput'}
          tooltip={t('auth.firstName')}
          inputProps={{
            type: 'text',
            value: userForm.fname,
            onChange: e => setUserForm({ ...userForm, fname: e.target.value })
          }}
          placeholder={t('auth.firstName')}
          isInvalid={errors.fname}
          validationMessage={t('validation.firstName')}
        />
        <Input
          icon={'user'}
          className={'textInput'}
          tooltip={t('auth.lastName')}
          inputProps={{
            type: 'text',
            value: userForm.lname,
            onChange: e => setUserForm({ ...userForm, lname: e.target.value })
          }}
          placeholder={t('auth.lastName')}
          isInvalid={errors.lname}
          validationMessage={t('validation.lastName')}
        />
        <Input
          icon={'email'}
          className={'textInput'}
          tooltip={t('tooltips.email')}
          inputProps={{
            type: 'email',
            value: userData.email,
            disabled: true
          }}
          placeholder={t('cart.emailPlaceholder')}
        />
        <Input
          icon={'phone'}
          tooltip={t('tooltips.phoneNo')}
          className={'textInput'}
          inputProps={{
            type: 'tel',
            value: userForm.phoneNo,
            onChange: e => setUserForm({ ...userForm, phoneNo: e.target.value })
          }}
          placeholder={t('cart.phonePlaceholder')}
          mask="+4\9 999 99999999"
          isInvalid={errors.phoneNo}
          validationMessage={t('validation.phone')}
          hasBtn={!userData.isVerified}
          onBtnClick={handleVerifyPress}
          btnName={t('auth.verify')}
        />
        <Input
          className={'textInput'}
          tooltip={t('auth.userReferralCode')}
          icon={'gift'}
          inputProps={{
            value: userData.referralCode,
            onChange: () => {}
          }}
          placeholder={t('auth.userReferralCode')}
        />
      </FormWrapper>
      <ButtonsContainer>
        <Button
          text={t('auth.changePassword')}
          type={'primary'}
          className={'apply_button'}
          onClick={handleChangePassModal}
        />
        {isChanged &&
          <Button
            text={t('auth.updateInformation')}
            type={'primary'}
            className={'apply_button'}
            disabled={loading}
            onClick={handleUserDataUpdate}
          />}
      </ButtonsContainer>
    </>
  );
};

export default observer(UserProfileData);
