import styled, {css} from 'styled-components';
import commonStyles, { respondTo } from '../../../commonStyles';

export const StatusPoint = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: ${commonStyles.colors.grey};
  justify-content: space-around;
  position: relative;
  white-space: nowrap;
  &:before {
    content: '${({label}) => label}';
    display: block;
    position: absolute;
    left: -20px;
    top: 20px;
    font-size: ${({ small }) => small ? 10 : 12}px;
    text-transform: capitalize; 
    color: ${commonStyles.colors.grey};
    font-weight: 700;
  }
  &:after {
    content: '';
    position: absolute;
    display: block;
    height: 2px;
    width: 100px;
    left: 10px;
    background-color: ${commonStyles.colors.grey};
    z-index: -1;
  }
  &:first-child {
    &:before {
      left: 0;
    }
  }
  &:last-child {
    &:before {
      left: auto;
      right: 0;
    }
    &:after {
      content: none;
    }
  }
  ${({status}) => (status !== undefined || status !== null) && css`
    &:nth-child(-n+${status + 1}) {
      background-color: ${commonStyles.colors.food};
    }
    &:nth-child(-n+${status + 1}):before {
      color: ${commonStyles.colors.white};
    }
    &:nth-child(-n+${status}):after {
      background-color: ${commonStyles.colors.food};
    }
  `}
`;

export const StatusBar = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`;

export const OrderInfo = styled.div`
  z-index: 1;
  & .order-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${commonStyles.colors.grey};
    &.bold {
      font-weight: 700;
      color: ${commonStyles.colors.white};
    }
    & span {
      color: ${commonStyles.colors.white};
      font-weight: 700;
      margin-left: 8px;
    }
  }
`;
export const RepeatButton = styled.div`
  & .order-text {
    color: ${commonStyles.colors.white};
  }
`;

export const CardFooterSection = styled.div`
  display: flex;
  align-items: end;
  & > * {
    margin-right: 8px;
    &:last-child {
      margin: 0;
    }
  }
  
  & > .time-text {
    font-size: 12px;
    color: ${commonStyles.colors.white};
  }
  
  ${respondTo.md`
    align-items: initial;
  `}
  
`;
export const CardFooter = styled.div`
  position: absolute;
  height: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 16px;
  background-color: ${commonStyles.colors.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  ${({isStatusBar}) => isStatusBar && css`
    background: none;
    height: 45px;
  `}
  ${respondTo.md`
    height: 50px;
    padding: 0 20px;
  `}
`;

export const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 343px;
  height: 180px;
  box-shadow: 0 36px 47px -18px ${commonStyles.colors.alphaString(commonStyles.colors.primary, 0.35)};
  background: ${({img}) => (img && `url(${img}) no-repeat`)};
  background-size: cover;
  border-radius: 5px;
  margin: 0 12px 24px;
  padding: 16px 16px 50px;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  & .food-text {
    color: ${commonStyles.colors.white};
    z-index: 1;
  }
  & .food-title {
    display: -webkit-box;
    text-align: center;
    color: ${commonStyles.colors.white};
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${({ isCompleted }) => commonStyles.colors.alphaString(commonStyles.colors.primary, isCompleted ? 0.7 : 0.8)};
    z-index: 0;
  }
  &:hover {
    transform: scale(1.05,1.05);
    cursor: pointer;
  }
  ${respondTo.md`
    padding: 20px 20px 60px;
    margin: 0 15px 30px;
    width: 300px;
    height: 200px;
  `}
`;
