import moment from 'moment';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import DeliveryOrderStatuses, { SENT } from '../../constants/delivery-order-statuses';
import ServiceTypes from '../../constants/serviceTypes';
import { roundNumber } from '../../helpers/priceHelpers';
import { useTitle } from '../../hooks/useTitle';
import { useStores } from '../../stores/helpers/use-stores';
import NoItemsFound from '../ItemsList/NoItemsFound';
import {
  OrderStatusContainer,
  OrderStatusImg,
  OrderStatusInfo,
  InfoSection,
  StatusBar,
  StatusPoint,
} from './OrderStatus.style';
import cabdoDelivery from '../../assets/img/cabdo_delivery.jpeg';
import { Loader, Text } from '../shared-components';

const SEND_MESSAGE_TIME_APPEND = 90;

export const statusesList = (t, isSelfPickup = false) => {
  const extractKey = isSelfPickup ? 'pickupStatuses' : 'deliveryStatuses';
  return [
    {
      statusId: 0,
      label: DeliveryOrderStatuses.RECEIVED,
      text: t(`orderStatus.${extractKey}.${DeliveryOrderStatuses.RECEIVED}`),
    },
    {
      statusId: 1,
      label: DeliveryOrderStatuses.CONFIRMED,
      text: t(`orderStatus.${extractKey}.${DeliveryOrderStatuses.CONFIRMED}`),
    },
    {
      statusId: 2,
      label: DeliveryOrderStatuses.EN_ROUTE,
      text: t(`orderStatus.${extractKey}.${DeliveryOrderStatuses.EN_ROUTE}`),
    },
    {
      statusId: 3,
      label: DeliveryOrderStatuses.COMPLETED,
      text: t(`orderStatus.${extractKey}.${DeliveryOrderStatuses.COMPLETED}`),
    },
  ];
};

const OrderStatus = props => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const { dataStores: { orderStatusStore, servicesStore } } = useStores();
  const { orderData, isLoading } = orderStatusStore;
  const intervalRef = useRef(null);
  const [tooLongInfo, setTooLongInfo] = useState({ showMessage: false, hoursToSend: 0 });

  const statuses = useMemo(() => statusesList(t), [t]);

  useEffect(() => {
    orderStatusStore.getOrderStatus(orderId);
    intervalRef.current = setInterval(() => {
      orderStatusStore.getOrderStatus(orderId);
    }, 15 * 1000);
    return () => {
      clearInterval(intervalRef.current);
    }
    // eslint-disable-next-line
  }, [orderId]);

  useTitle(orderData?.orderNo ? `Bestellung ${orderData.orderNo}` : t('main.pageTitle'));

  useEffect(() => {
    if (orderData) {
      const resp = {
        showMessage: false,
        hoursToSend: 0,
      };
      if (orderData.orderStatus !== SENT) return setTooLongInfo(resp);
      const providerData = orderData?.provider?.providerData;
      if (!providerData) return;
      const cutoffTime = (providerData.cutOffTime ?? 0) + SEND_MESSAGE_TIME_APPEND;
      const toBeSentDate = moment(orderData.deliveryDate).subtract(cutoffTime, 'minutes');
      resp.showMessage = moment().isBefore(toBeSentDate);
      resp.hoursToSend = roundNumber(cutoffTime / 60, 0.5);
      return resp;
    }
  }, [orderData]);

  useEffect(() => {
    servicesStore.setSelectedService('white');
    // eslint-disable-next-line
  }, []);

  return (
    <OrderStatusContainer>
      <OrderStatusImg>
        <img src={cabdoDelivery} alt={'Delivery Placeholder'} />
      </OrderStatusImg>
      <OrderStatusInfo>
        {!orderData && <NoItemsFound isLoading={isLoading} text={t('orderStatus.notFound')} />}
        {!!orderData && <>
          <InfoSection>
            <Text type="body">
              <Trans
                i18nKey={'orderStatus.info'}
                values={{ highlighted: orderData.orderNo }}
              >
                Order <strong>{orderData.type === ServiceTypes.FOOD ? `#${orderData.orderNo}` : orderData.orderNo}</strong>
              </Trans> <span className="order-date__nowrap">{t('orderStatus.placedDateTime',
              {
                placedDate: moment(orderData.orderDate).format('DD.MM.YYYY'),
                placedTime: moment(orderData.orderDate).format('HH:mm'),
              })}</span>
            </Text>
            <Text type="body" className="order__status">
              <Trans i18nKey={'orderStatus.status'} values={{ highlighted: orderData.orderStatus }}>
                Status <strong>{orderData.orderStatus}</strong>
              </Trans>
            </Text>
            {tooLongInfo.showMessage && <Text type="bodySmall" className="order__wait_message">
              {t('orderStatus.tooLongToOrder', { partner: orderData.provider.fname, hours: tooLongInfo.hoursToSend })}
            </Text>}

          </InfoSection>
          <InfoSection>
            <Text type="body" weight="700">{orderData.provider.fname}</Text>
            <Text type="bodySmall" className="order__description">
              {
                orderData.orderItems.map((item) => `${item.qty} x ${item.name}`).join(', ')
              }
            </Text>
            <StatusBar>
              {statuses.map((status) => (
                <StatusPoint
                  key={status.statusId}
                  status={statuses.find((item) => item.label === orderData.orderStatus)?.statusId}
                  type={orderData.type}
                  label={status.text}
                />
              ))}
            </StatusBar>
          </InfoSection>
        </>}
      </OrderStatusInfo>
      {isLoading && <Loader />}
    </OrderStatusContainer>
  );
};

export default observer(OrderStatus);
