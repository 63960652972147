import _ from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Analytics } from '../../../helpers/analytics';
import { findCategory } from '../../../helpers/utils';
import { useDebouncedEffect } from '../../../hooks/useDebouncedCallback';
import { useStores } from '../../../stores/helpers/use-stores';
import { CardsSection, Text } from '../../shared-components';
import NoItemsFound from '../NoItemsFound';
import ProductCard from '../ProductCard';
import { ProductCardContainer } from './ProductList.style';

const ProductList = props => {
  const { t } = useTranslation();
  const { searchQuery } = props;
  const { serviceType, providerName: providerSlug, categoryName: categorySlug } = useParams();
  const history = useHistory();
  const { dataStores: { servicesStore, providersStore, productsStore, sideModalStore, orderStore } } = useStores();
  const { providerList, selectedProvider } = providersStore;
  const { selectedService, deliveryPlace } = servicesStore;
  const { productList, selectedCategory, categoriesList, totalProducts, searchMeta, sortParam, isFetching } = productsStore;

  const categoryProductList = useMemo(() => toJS(productList), [productList]);
  const hasDeliveryData = useMemo(() => !!deliveryPlace?.location?.latitude && deliveryPlace?.location?.longitude && !!deliveryPlace.address, [deliveryPlace]);

  useEffect(() => {
    if (selectedService !== serviceType) {
      servicesStore.setSelectedService(serviceType);
    }
    // eslint-disable-next-line
  }, [serviceType, selectedService]);

  useEffect(() => {
    if (!toJS(providerList)?.length) {
      providersStore.getProviderList();
    }
    // eslint-disable-next-line
  }, [providerSlug, providerList]);

  useEffect(() => {
    const newProvider = toJS(providerList).find((p) => p.slug === providerSlug);
    if (!_.isEqual(toJS(newProvider), toJS(selectedProvider))) {
      providersStore.selectProvider(newProvider);
    }
    // eslint-disable-next-line
  }, [providerList]);

  useDebouncedEffect(() => {
    const newCat = findCategory(categoriesList, categorySlug);
    if ((!_.isEqual(toJS(newCat), toJS(selectedCategory)) || !selectedCategory) && newCat) {
      productsStore.setCategory(newCat);
    }
    // eslint-disable-next-line
  }, 50, [selectedCategory, categorySlug, categoriesList]);

  useEffect(() => {
    if (!!selectedProvider) {
      productsStore.getProductsList(categorySlug, searchQuery);
    }
    // eslint-disable-next-line
  }, [selectedProvider, categorySlug, sortParam.field, sortParam.order, searchMeta.limit, searchMeta.offset, searchQuery]);

  const handleProductClick = (product) => {
    productsStore.setProduct(product);
    Analytics.viewItemEvent(product, selectedProvider, selectedService);
    sideModalStore.showProductModal();
  };

  const handlePlusClick = (product, modalMandatory) => {
    if (modalMandatory) {
      handleProductClick(product);
    } else {
      orderStore.addToCart(_.cloneDeep({
        ...product,
        itemPrice: product.price,
        attributes: [],
        qty: 1
      }));
    }
  };

  return (
    <ProductCardContainer>
      {!!searchQuery && <Text type={'h3'} className={'product_list-searchText'}>{t('products.searchResults', { query: searchQuery })}</Text>}
      <CardsSection
        buttonText={categoryProductList.length < totalProducts && t('products.showMore')}
        onButtonClick={productsStore.setOffset}
        className="cards__section"
        justifyContent="left"
      >
        {categoryProductList.map((item, index) => (
          <ProductCard
            key={`productCard-${item.name}-${item.id}-${index}`}
            item={item}
            handleCardClick={() => handleProductClick(item)}
            handlePlusClick={(modalRequired) => handlePlusClick(item, modalRequired)}
            showPlus={!!hasDeliveryData}
          />
        ))}
        {!categoryProductList?.length && <NoItemsFound
          isLoading={isFetching}
          hasButton={true}
          btnText={t('products.back')}
          btnAction={() => history.goBack()}
        />}
      </CardsSection>
    </ProductCardContainer>
  );
};

ProductList.propTypes = {
  searchQuery: PropTypes.string,
}

export default observer(ProductList);
