import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTitle } from '../../../hooks/useTitle';
import { useStores } from '../../../stores/helpers/use-stores';

const BasicPage = ({ children, pageName, pageTitle}) => {

  const {
    dataStores: {
      servicesStore
    }
  } = useStores();

  useEffect(() => {
    servicesStore.setCurrentPageMeta({
      name: pageName,
      title: pageTitle,
    });
    // eslint-disable-next-line
  }, [pageName, pageTitle]);

  useTitle(pageTitle);

  return children;
};

export default observer(BasicPage);
