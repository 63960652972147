import Cookies from 'js-cookie';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import moment from 'moment';
import { KEYS } from '../../../keys';
import { isHydrated, makePersistable } from 'mobx-persist-store';
import { getServices, getToken } from '../../../utils/api/services';
import { getAvailableStorage } from '../../storage';

export default class ServicesData {
  @observable bookerToken = null;
  @observable currentCityName = KEYS.DEFAULT_CITY;
  @observable currentCityId = null;
  @observable deliveryPlace = {
    address: null,
    location: {
      latitude: null,
      longitude: null
    }
  };
  @observable fullDeliveryAddress = null;
  @observable desiredOrderDate = null;
  @observable availableServices = [];
  @observable selectedService = null;
  @observable currentPageMeta = null;

  constructor(rootStore) {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'ServicesFastData',
      properties: [
        'bookerToken',
        'desiredOrderDate',
        'availableServices',
      ],
      storage: getAvailableStorage(),
      expireIn: 2 * 60 * 60 * 100,
      removeOnExpiration: true,
    }, {
      delay: 200,
    });
    makePersistable(this, {
      name: 'ServicesLongData',
      properties: [
        'currentCityName',
        'currentCityId',
        'deliveryPlace',
        'fullDeliveryAddress',
      ],
      storage: getAvailableStorage(),
    }, {
      delay: 400,
    });
    this.rootStore = rootStore;
  }

  @action getBookerToken = async () => {
    try {
      const desiredDate = this.desiredOrderDate;
      if (desiredDate && moment(desiredDate).isBefore(moment())) {
        runInAction(() => {
          this.desiredOrderDate = null;
        });
        return;
      }
      const resp = await getToken({
        deliveryPlace: this.deliveryPlace,
        desiredOrderDate: this.desiredOrderDate,
        currentCityId: this.currentCityId,
        currentCityName: this.currentCityName,
      });
      if (resp.success) {
        const { token, bookerData } = resp.data;
        runInAction(() => {
          this.setBookerToken(token);
          this.setCityData(bookerData);
          Cookies.set('cabdo-booker-data', JSON.stringify(bookerData), { secure: true, expires: 30 });
          if (!this.deliveryPlace.location.latitude || !this.deliveryPlace.location.longitude) {
            this.updateDeliveryPlace({
              address: null,
              location: {
                latitude: bookerData.lat ? parseFloat(bookerData.lat) : null,
                longitude: bookerData.lng ? parseFloat(bookerData.lng) : null,
              }
            });
          }
          if (!bookerData.cityId) {
            this.setAvailableServices([]);
          }
        });
      } else {
        this.setBookerToken(null);
        this.setAvailableServices([]);
      }
    } catch (e) {
      console.error('Failed To Get Booker Token', e);
    }
  }

  @action setSelectedService = (service = null) => {
    this.selectedService = service;
  }

  @action getAvailableServices = async () => {
    if (!this.bookerToken) {
      this.setAvailableServices([]);
      return;
    }
    try {
      const resp = await getServices(this.bookerToken);
      if (resp.success) {
        this.setAvailableServices(resp.data);
      } else {
        this.setAvailableServices([]);
      }
    } catch (e) {
      console.error('Failed To Get Available Services', e);
    }
  }

  @action setAvailableServices = (services = []) => {
    this.availableServices = services;
  }

  @action setBookerToken = (token) => {
    this.bookerToken = token;
  }

  @action setCityData = (cityData = null) => {
    this.currentCityName = cityData?.cityName ?? null;
    this.currentCityId = cityData?.cityId ?? null;
  }

  @action setOrderDate = (orderDate = null) => {
    this.desiredOrderDate = orderDate;
  }

  @action updateDeliveryPlace = (deliveryPlace) => {
    this.deliveryPlace = deliveryPlace;
    this.fullDeliveryAddress = deliveryPlace.fullAddress ?? '';
    this.rootStore.dataStores.deliveryModalStore.setPlacesSearchOptions(deliveryPlace);
  }

  @action setCurrentPageMeta = (data) => {
    this.currentPageMeta = { ...data };
  };

  get currentServiceType() {
    return this.selectedService;
  }

  get isHydrated() {
    return isHydrated(this);
  }
}
