import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Text } from '../shared-components';
import AttributeItem from './attributeItem';
import {
  AttributeItemContainer,
  AttributeRow,
  AttributeSectionContainer,
  RequiredLabel,
  RowElem
} from './ProductModal.style';

const COLLAPSED_NUM = 3;

const AttributeSection = props => {
  const { t } = useTranslation();
  const { data } = props;
  const [isCollapsed, toggleCollapse] = useState(data.isMulti && data.attributes?.length > COLLAPSED_NUM);

  const numberOfSelected = useMemo(() => data.attributes.filter(a => a.selected).length, [data]);

  const showPrice = useMemo(() => (!data.numOfFreeItems) || (!data.isMulti) ||
    (data.numOfFreeItems && numberOfSelected > data.numOfFreeItems), [data, numberOfSelected])

  const handleAttributeCheck = (index) => {
    let attrs = _.cloneDeep(data.attributes);
    if (data.isMulti) {
      attrs[index].selected = !attrs[index].selected;
    } else {
      attrs = attrs.map((a, i) => ({ ...a, selected: i === index }));
    }
    props.onChange(attrs);
  };

  return (
    <AttributeSectionContainer>
      <AttributeRow>
        <Text type={'h4'} className={'attribute_header'}>{data.name}</Text>
        {data.isMandatory && <RequiredLabel>
          <Text type={'bodySmall'} className={'attribute_required'}>{t('productModal.required')}</Text>
        </RequiredLabel>}
      </AttributeRow>
      {!!data.description && <Text type={'bodySmall'} className={'attribute_desc'}>
        {data.description}
      </Text>}
      {!!data.numOfFreeItems && <Text type={'bodySmall'} className={'attribute_desc'}>
        {t('productModal.freeItems', { num: data.numOfFreeItems })}
      </Text>}
      {data.attributes
        .filter((a, i) => !isCollapsed || i < COLLAPSED_NUM)
        .map((a, i) => <AttributeItem
          key={`${data.name}-${a.name}-${i}`}
          data={a}
          isDefault={!data.isMulti && !numberOfSelected}
          isDisabled={!data.isMulti && !!numberOfSelected && !a.selected}
          showPrice={showPrice && !!a.price}
          showPlus={data.addToPrice}
          onAttributeCheck={() => handleAttributeCheck(i)}
        />)}
      {data.isMulti && data.attributes?.length > COLLAPSED_NUM && <AttributeItemContainer
        className={`attribute_container attribute_collapse`}
        onClick={() => toggleCollapse(!isCollapsed)}
      >
        <RowElem>
          <Text type={'body'} className={'attribute_name'}>
            {t(`productModal.${isCollapsed ? 'moreExtras' : 'lessExtras'}`, { number: data.attributes.length - COLLAPSED_NUM })}
          </Text>
        </RowElem>
      </AttributeItemContainer>}
    </AttributeSectionContainer>
  );
};

AttributeSection.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
    isMulti: PropTypes.bool,
    isMandatory: PropTypes.bool,
    addToPrice: PropTypes.bool,
    numOfFreeItems: PropTypes.number,
    attributes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.number,
      selected: PropTypes.bool,
    })),
  }),
  onChange: PropTypes.func,
};

export default AttributeSection;
