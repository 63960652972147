import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { REGEXP_EMAIL } from '../../../constants/regExp';
import { useStores } from '../../../stores/helpers/use-stores';
import { Button, Input, Text } from '../../shared-components';
import { RegisterFormWrapper } from '../AuthModal.style';

const PassRecoveryForm = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const { dataStores: { authStore } } = useStores();
  const { errorMessage, loading } = authStore;
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);


  const handlePassReset = () => {
    authStore.resetPassword(email);
  };

  const validation = useMemo(() => {
    const emailInvalid = !email || !REGEXP_EMAIL.test(email);
    return {
      emailInvalid,
      btnDisabled: loading || emailInvalid,
    }
  }, [email, loading]);

  const listenToEnter = (e) => {
    if (e.key === 'Enter' && !validation.btnDisabled) {
      handlePassReset();
    }
  };

  return (
    <RegisterFormWrapper onKeyDown={listenToEnter}>
      {!!errorMessage && <Text type={'bodySmall'} className={'error_message'}>{errorMessage}</Text>}
      <Input
        inputRef={inputRef}
        className={'text_input input'}
        tooltip={t('tooltips.email')}
        icon={'email'}
        inputProps={{
          value: email,
          onChange: e => setEmail(e.target.value)
        }}
        placeholder={t('tooltips.email')}
        isInvalid={validation.emailInvalid}
        validationMessage={t('validation.email')}
      />
      <Button
        type={'primary'}
        text={t('auth.reset')}
        disabled={validation.btnDisabled}
        className={'main_button'}
        onClick={handlePassReset}
      />
    </RegisterFormWrapper>
  );
};

export default observer(PassRecoveryForm);
