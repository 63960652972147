import { action, makeAutoObservable, observable } from 'mobx';
import AuthModalState from '../../../constants/authModalStates';

const defaultModalState = AuthModalState.login;

export class AuthModalStore {
  @observable modalVisible = false;
  @observable modalState = defaultModalState;

  constructor() {
    makeAutoObservable(this);
  }

  @action showAuthModal = (modalState = null) => {
    this.modalVisible = true;
    if (modalState) {
      this.changeModalState(modalState);
    }
  }

  @action hideModal = () => {
    this.modalVisible = false;
    this.modalState = defaultModalState;
  }

  @action changeModalState = (state) => {
    this.modalState = state;
  }
}
