import styled from 'styled-components';
import commonStyles, { respondTo } from '../../commonStyles';

export const SearchbarContainer = styled.div.attrs(() => ({}))`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const SearchInput = styled.input.attrs(() => ({}))`
  width: 100%;
  background: ${commonStyles.colors.white};
  box-shadow: 0 0 15px ${commonStyles.colors.alphaString(commonStyles.colors.primary, 0.15)};
  border-radius: 5px;
  border: none;
  height: 40px;
  padding: 0 0 0 44px;
  font-size: 16px;
  line-height: 24px;

  &::placeholder {
    font-family: Circe, Montserrat, serif;
    color: ${commonStyles.colors.grey};
  }

  &:focus {
    outline: none;
  }

  ${respondTo.md`
    height: 50px;
    margin-top: 0;
    font-size: 18px;
    line-height: 36px;
  `}
`;

export const IconContainer = styled.div.attrs(() => ({}))`
  position: absolute;
  left: 12px;
  top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${respondTo.md`
    top: 13px;
  `}
`;

export const ClearContainer = styled.div.attrs(() => ({}))`
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in;
  & > svg {
    transition: all 0.2s ease-in;
  }
  &:hover {
    & > svg {
      height: 14px;
      width: 14px;
      transform: translate(2px, -2px);
      fill: ${commonStyles.colors.danger};
    }
  }
  ${respondTo.md`
    top: 20px;
  `}
`;
