import React from 'react';
import PropTypes from 'prop-types';

import {
  Wrapper, CheckRegion, Label,
} from './Checkbox.style';

const Checkbox = ({
  id,
  className,
  children,
  disabled,
  checked,
  onChange,
  name,
}) => {
  const handleOnChange = (e) => {
    if (onChange) onChange(e.target.checked);
  };

  return (
    <Wrapper className={className} disabled={disabled} checked={checked}>
      <input
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={handleOnChange}
        disabled={disabled}
      />
      <CheckRegion checked={checked} className="checkbox-square" role="checkbox" aria-checked={checked} />
      {children && <Label className="label" id={`${id}-label`}>{children}</Label>}
    </Wrapper>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
};

Checkbox.defaultProps = {
  className: undefined,
  children: undefined,
  disabled: false,
  checked: false,
  onChange: undefined,
  name: undefined,
};

export default Checkbox;
