import styled, { css } from 'styled-components';
import commonStyles from '../../commonStyles';

export const ProductChoiceInfoContainer = styled.div.attrs(() => ({}))`
  ${({ isCollapsible }) => css`
    background-color: ${isCollapsible ? 'transparent' : commonStyles.colors.alphaString(commonStyles.colors.grey, 0.4)};
    border-radius: 5px;
    padding: ${isCollapsible ? '8px' : '10px 16px 20px'};
    margin: ${isCollapsible ? '8px' : '30px 0'};
    
    & > .product_choice-expand_container, .product_choice-choice_container {
      cursor: ${isCollapsible ? 'pointer' : 'default'};
    }

    & > .product_choice-expand_container > .product_choice-with_extras {
      margin-left: 8px;
      font-style: italic;
    }

    & > .product_choice-with_extras,
    .product_choice-header,
    .product_choice-sub_header,
    .product_choice-with,
    .product_choice-attribute_name,
    .product_choice-attribute_choice {
      color: ${commonStyles.colors.primary};
      font-style: italic;
    }

    & > .product_choice-choice_container > .product_choice-header {
      margin-bottom: 16px;
    }

    & > .product_choice-choice_container > .product_choice-header,
    .product_choice-sub_header,
    .product_choice-with {
      font-style: normal;
    }

    & > .product_choice-expand_container,
    .product_choice-sub_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    & > .product_choice-choice_container > .product_choice-choices_container > .product_choice-attribute_choice {
      margin-left: 20px;
    }
  `}
`;
