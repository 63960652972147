import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../../../shared-components';
import { InfoRowWrapper } from './OrderInfoModal.style';

const InfoRow = props => {
  const { title, value, valueElement } = props;
  return (
    <InfoRowWrapper>
      <Text type={'body'} className={'info_header'}>{title}</Text>
      {!!value && <Text type={'body'} className={'info_value'}>{value}</Text>}
      {!value && valueElement}
    </InfoRowWrapper>
  );
};

InfoRow.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  valueElement: PropTypes.element,
};

export default InfoRow;
