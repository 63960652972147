  import styled, { css } from 'styled-components';
  import commonStyles, { isWinOrSafari, respondTo } from '../../commonStyles';

  const winCompensateStr = isWinOrSafari() ? `
    transform: translateY(0.01em);
  ` : '';

  export const InputIcon = styled.div`
    &.main-search-icon {
      display: none;
    }
  
    ${respondTo.md`
      &.search-icon {
        display: none;
      }
      &.main-search-icon {
        display: block;
      }
    `}
  `;

  export const IconContainer = styled.div`
    margin-right: 12px;
  
    ${({ isMain, isSearch }) => isSearch && css`
      position: absolute;
      margin: 0;
      top: 9px;
      left: 12px;
      ${respondTo.md`
        top: 14px;
        ${isMain && css`
        top: 0;
        left: 0;
        height: 60px;
        width: 60px;
        background-color: ${commonStyles.colors.primary};
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      `}
      `}
    `}
  `;

  export const InputField = styled.input`
    width: 100%;
    height: 32px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid ${commonStyles.colors.grey}66;
    color: ${({ disabled }) => disabled ? commonStyles.colors.grey : commonStyles.colors.primary} !important;
    -webkit-text-fill-color: ${({ disabled }) => disabled ? commonStyles.colors.grey : commonStyles.colors.primary} !important;
    margin-right: ${({ hasBtn }) => hasBtn ? 8 : 1}px;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 3px;
  
    ${commonStyles.typo.mob.body};
    ${winCompensateStr}
  
    &::placeholder {
      color: ${commonStyles.colors.grey} !important;
      -webkit-text-fill-color: ${commonStyles.colors.grey};
      opacity: 1;
    }
  
    &:placeholder-shown {
      color: ${commonStyles.colors.grey} !important;
      -webkit-text-fill-color: ${commonStyles.colors.grey};
      opacity: 1;
    }
  
    &:focus {
      outline: none;
    }
  
    ${respondTo.md`
      ${commonStyles.typo.bodySmall};
      ${winCompensateStr}
    `}
  
    ${({ isSearch, isMain }) => isSearch && css`
      height: 40px;
      padding: 0 0 0 40px;
      background-color: ${commonStyles.colors.white};
      border: none;
      ${respondTo.md`
        height: 50px;
        ${isMain && css`
          padding-left: 30px;
          height: 60px;
        `}
      `}
    `}
  
    ${({ isInvalid }) => isInvalid && css`
      border-bottom-color: ${commonStyles.colors.danger};
    `}
  `;

  export const InputContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    overflow: hidden;
  
    & > .input-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      position: relative;
      padding: 0 16px;
  
      border: 1px solid ${commonStyles.colors.primary};
      border-radius: 5px;
      background-color: ${commonStyles.colors.primary};
      font-weight: 700;
      color: ${commonStyles.colors.white};
      transition: 0.1s ease-in;
      font-size: 16px;
      line-height: 18px;
      &:hover, &:active {
        cursor: pointer;
        background-color: ${commonStyles.colors.white};
        color: ${commonStyles.colors.primary};
      }
  
      &:disabled {
        background-color: ${commonStyles.colors.grey};
        pointer-events: none;
        color: ${commonStyles.colors.white};
      }
  
      ${respondTo.md`
        font-size: 18px;
        line-height: 24px;
      `}
    }
  
    ${({ isSearch, isMain }) => isSearch && css`
      border-radius: 5px;
      box-shadow: 1px 1px 10px rgba(0, 29, 35, 0.25);
      ${isMain && css`
        border-radius: 10px;
      `}
    `}
    ${({ isInvalid, validationMessage }) => isInvalid && validationMessage && css`
      overflow: visible;
      
      &:before {
        content: '${({ validationMessage }) => validationMessage}';
        position: absolute;
        bottom: -12px;
        left: 32px;
        color: ${commonStyles.colors.danger};
        display: block;
        font-size: 12px;
      }
    `}
  `;
