import _ from 'lodash';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import {
  getLoyaltyList,
  getReferralInfo, getUserPlaces,
  getUserTransactions,
  updateUserData, updateUserPlaces,
  updateUserPolicy
} from '../../../utils/api/auth';
import { getUserOrdersList } from '../../../utils/api/order';
import { getAvailableStorage } from '../../storage';

export default class UserStore {
  @observable errorMessage = null;
  @observable userTransactions = null;
  @observable userOrderList = [];
  @observable loyaltyList = [];
  @observable userPlaces = [];
  @observable initialUserPlaces = [];
  @observable ordersSearchMeta = {
    limit: 40,
    offset: 0,
  };
  @observable ordersSortParam = {
    field: 'date',
    order: 'desc'
  };
  @observable userOrderListStatuses = [];
  @observable totalUserOrders = 0;
  @observable referralData = null;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'UserData',
      properties: [],
      storage: getAvailableStorage(),
      expireIn: 2 * 60 * 60 * 100,
    }, {
      delay: 200,
    });
  }

  @action setLoading = () => {
    this.errorMessage = null;
    this.rootStore.dataStores.providersStore.setLoading();
  }

  @action setLoaded = (message = null) => {
    this.rootStore.dataStores.providersStore.setLoaded();
    if (message) {
      this.errorMessage = message;
    }
  }

  @action updateUserOrderStatuses = (status = null) => {
    this.userOrderListStatuses = status ? [status] : [];
    this.ordersSearchMeta.offset = 0;
  }

  @action setOrdersOffset = () => {
    this.ordersSearchMeta.offset += this.ordersSearchMeta.limit;
  };

  @action setOrdersMetaLimit = (limit) => {
    this.ordersSearchMeta.limit = limit;
    this.ordersSearchMeta.offset = 0;
  };

  @action setOrdersSortField = (field) => {
    this.ordersSortParam.field = field;
    this.ordersSearchMeta.offset = 0;
  };

  @action setOrdersSortOrder = (order) => {
    this.ordersSortParam.order = order;
    this.ordersSearchMeta.offset = 0;
  };

  @action setOrdersSearchMetaToDefault = () => {
    this.ordersSearchMeta = {
      limit: 40,
      offset: 0,
    };
    this.ordersSortParam = {
      field: 'date',
      order: 'desc'
    };
  };

  @action resetStore = () => {
    this.errorMessage = null;
    this.userTransactions = null;
    this.userOrderList = [];
    this.loyaltyList = [];
    this.userPlaces = [];
    this.initialUserPlaces = [];
    this.userOrderListStatuses = [];
    this.totalUserOrders = 0;
    this.referralData = null;
  }

  @action getUserOrders = async () => {
    try {
      const token = this.rootStore.dataStores.authStore.getUserToken();
      if (!token) {
        return;
      }
      this.setLoading();
      const resp = await getUserOrdersList(token, {
        meta: this.ordersSearchMeta,
        sortParam: this.ordersSortParam,
        orderStatuses: this.userOrderListStatuses
      });
      if (resp.success) {
        runInAction(() => {
          this.userOrderList = this.ordersSearchMeta.offset ?
            [...this.userOrderList, ...resp.data] :
            resp.data;
          this.totalUserOrders = resp.totalItems;
        });
        this.setLoaded();
      } else {
        this.setLoaded(resp.message);
      }
    } catch (e) {
      this.setLoaded(e.message);
    }
  }

  @action updateUserPolicy = async (newPolicy) => {
    try {
      const token = this.rootStore.dataStores.authStore.getUserToken();
      if (!token) {
        return;
      }
      this.setLoading();
      const resp = await updateUserPolicy(token, newPolicy);
      if (resp.success) {
        this.setLoaded();
        const userData = this.rootStore.dataStores.authStore.userData;
        this.rootStore.dataStores.authStore.updateUserData({ ...userData, privacyPolicyAgreement: resp.data });
      } else {
        this.setLoaded(resp.message);
      }
    } catch (e) {
      this.setLoaded(e.message);
    }
  }

  @action updateUserData = async (updatedData) => {
    try {
      const token = this.rootStore.dataStores.authStore.getUserToken();
      if (!token) {
        return;
      }
      this.setLoading();
      const resp = await updateUserData(token, updatedData);
      if (resp.success) {
        this.setLoaded();
        this.rootStore.dataStores.authStore.updateUserData(resp.data);
      } else {
        this.setLoaded(resp.message);
      }
    } catch (e) {
      this.setLoaded(e.message);
    }
  }

  @action getUserTransactions = async () => {
    try {
      const token = this.rootStore.dataStores.authStore.getUserToken();
      if (!token) {
        return;
      }
      this.setLoading();
      const resp = await getUserTransactions(token);
      if (!resp.hasOwnProperty('success')) {
        this.setLoaded();
        runInAction(() => {
          this.userTransactions = resp;
        });
      } else {
        this.setLoaded(resp.message);
      }
    } catch (e) {
      this.setLoaded(e.message);
    }
  }

  @action getLoyaltyList = async () => {
    try {
      const token = this.rootStore.dataStores.authStore.getUserToken();
      if (!token) {
        return;
      }
      const resp = await getLoyaltyList(token);
      if (resp.success) {
        runInAction(() => {
          this.loyaltyList = resp.data;
        });
      } else {
        this.setLoaded(resp.message);
      }
    } catch (e) {
      this.setLoaded(e.message);
    }
  }

  @action getUserReferralsInfo = async () => {
    try {
      const token = this.rootStore.dataStores.authStore.getUserToken();
      if (!token) {
        return;
      }
      const resp = await getReferralInfo(token);
      if (resp.success && resp.message === 'OK') {
        const { referralUsers, earnedReferralMoney, expectedReferralMoney } = resp;
        runInAction(() => {
          this.referralData = { referralUsers, earnedReferralMoney, expectedReferralMoney };
        });
      }
    } catch (e) {
      console.error(`Error in getUserReferralsInfo`, e);
    }
  }

  @action getUserPlaces = async () => {
    try {
      const token = this.rootStore.dataStores.authStore.getUserToken();
      if (!token) {
        return;
      }
      const resp = await getUserPlaces(token);
      runInAction(() => {
        this.userPlaces = resp.success ? resp.data : [];
        this.initialUserPlaces = resp.success ? resp.data : [];
      });
      this.setLoaded();
    } catch (e) {
      console.error(`Error in getUserPlaces`, e);
    }
  }

  @action revertPlacesChanges = () => {
    this.userPlaces = _.cloneDeep(this.initialUserPlaces);
  }

  @action addDeliveryPlace = () => {
    const currentPlaces = [...this.userPlaces];
    let lastIndex = 0;
    currentPlaces.forEach(place => {
      if (place.name.includes('place')) {
        lastIndex = parseFloat(place.name.split('-')[1]) + 1;
      }
    });
    currentPlaces.push({
      name: `place-${lastIndex}`,
      place: {
        address: '',
        location: {
          latitude: 0,
          longitude: 0
        }
      }
    });
    this.userPlaces = _.cloneDeep(currentPlaces);
  }

  @action updatePlaceData = (index, place) => {
    const currentPlaces = [...this.userPlaces];
    currentPlaces[index]._id = null;
    currentPlaces[index].place = place;
    this.userPlaces = _.cloneDeep(currentPlaces);
  }

  @action updateUserPlaces = async () => {
    try {
      const token = this.rootStore.dataStores.authStore.getUserToken();
      if (!token) {
        return;
      }
      this.setLoading();
      const resp = await updateUserPlaces(token, this.userPlaces);
      if (!resp.success) {
        this.setLoaded(resp.message);
      } else {
        this.getUserPlaces();
      }
    } catch (e) {
      console.error(`Error in updateUserPlaces`, e);
    }
  }
};
