import React from 'react';
import PropTypes from 'prop-types';
import {
  CustomText, Header1, Header2, Header3, Header4,
} from './Text.style';

const Text = (props) => {
  const component = () => {
    switch (props.type) {
      case 'h1':
        return <Header1 {...props} />;
      case 'h2':
        return <Header2 {...props} />;
      case 'h3':
        return <Header3 {...props} />;
      case 'h4':
        return <Header4 {...props} />;
      case 'body':
      case 'bodySmall':
        return <CustomText {...props} />;
      default:
        return null;
    }
  };

  return component();
};

Text.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  weight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'body',
    'bodySmall',
  ]).isRequired,
};

Text.defaultProps = {
  children: '',
  className: undefined,
  weight: undefined,
};

export default Text;
