import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { isHydrated, makePersistable } from 'mobx-persist-store';
import { getAvailableStorage } from '../storage';

export class UiStore {
  @observable windowDimensions = {
    width: window.innerWidth,
    height: window.innerHeight
  }
  @observable urlParams = {};
  @observable lang = 'de';
  @observable cookieConsentApproved = false;

  constructor(rootStore) {
    makeAutoObservable(this);
    window.onresize = () => {
      runInAction(() => {
        this.windowDimensions = this.getWindowDimensions();
      });
    }
    makePersistable(this, {
      name: 'uiStore',
      properties: [
        'lang',
        'cookieConsentApproved'
      ],
      storage: getAvailableStorage(),
    }, {
      delay: 100,
    });
  }

  @action setCookieConsent = (value = false) => {
    this.cookieConsentApproved = value;
  }

  @action updateLanguage = (lang = 'de') => {
    this.lang = lang;
  }

  @action updateUrlParams = params => {
    this.urlParams = params;
  }

  @computed getWindowDimensions() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  get isHydrated() {
    return isHydrated(this);
  }
}
