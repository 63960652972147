import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import commonStyles from '../../commonStyles';
import { Text } from '../../shared-components';
import { ProgressCircleWrapper } from './Loyalty.style';

const PointsProgressCircle = props => {
  const { t } = useTranslation();
  const { maxPoints, currentPoints, color } = props;
  return (
    <ProgressCircleWrapper color={color}>
      <CircularProgressbarWithChildren
        value={currentPoints}
        maxValue={maxPoints}
        minValue={0}
        styles={buildStyles({
          trailColor: commonStyles.colors.alphaString(commonStyles.colors.grey, 0.3),
          pathColor: color,
          rotation: 0.5
        })}
      >
        <Text type={'h1'} className={'points_number'}>
          {currentPoints}
        </Text>
        <Text type={'h3'} className={'points_text'}>
          {t('userWallet.points.title')}
        </Text>
      </CircularProgressbarWithChildren>
    </ProgressCircleWrapper>
  );
};

PointsProgressCircle.propTypes = {
  maxPoints: PropTypes.number,
  currentPoints: PropTypes.number,
  color: PropTypes.string,
};

export default PointsProgressCircle;
