import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AppleSignin from 'react-apple-signin-auth';
import { FaApple } from 'react-icons/fa';
import { KEYS } from '../../../keys';
import { useStores } from '../../../stores/helpers/use-stores';
import { TooltipWrapper } from '../../shared-components/IconFont/IconFont.style';

const AppleButton = () => {
  const { t } = useTranslation();
  const { dataStores: { authStore } } = useStores();

  const onAppleResponse = resp => {
    authStore.setLoaded(resp?.authorization ? null : 'Ooops, something went wrong!');
    if (resp?.authorization) {
      const { user, authorization } = resp;
      const requestBody = {
        email: user?.email,
        fname: user?.name?.givenName,
        lname: user?.name?.familyName,
        token: authorization.id_token,
        authCode: authorization.code,
      };
      authStore.socialLogin(requestBody, 'apple');
    }
  };

  const onFail = (resp) => {
    authStore.setLoaded();
    console.error(resp);
  }

  return (
    <AppleSignin
      authOptions={{
        clientId: 'de.cabdo.order',
        scope: 'email name',
        redirectURI: `${KEYS.DOMAIN_URL}/social-callback/apple`,
        state: 'state',
        nonce: 'nonce',
        usePopup: true,
      }}
      onSuccess={onAppleResponse}
      onError={onFail}
      render={renderProps => (
        <TooltipWrapper tooltip={t('auth.loginWithApple')}>
          <button
            className={'login-apple-button social_button'}
            onClick={() => {
              authStore.setLoading();
              renderProps.onClick()
            }}
          >
            <FaApple size={28} className={'apple_icon'}/>
          </button>
        </TooltipWrapper>
      )}
    />
  );
};

export default observer(AppleButton);
